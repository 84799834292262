import React from "react";

import { Box } from "@mui/material";

import { QuestionTypes } from "../../../../types/Blocks";
import NewQuestionRequiredOption from "../../Options/QuestionRequiredOption";
import NewConditionalVisibilityOptions from "../../Options/ConditionalVisibilityOptions";
import DynamicQuestionOption from "../../Options/DynamicQuestionOption";
import ControlledSelect from "../../../Inputs/ControlledSelect";
import {
  FROM_NUMBER_SCALE,
  TO_NUMBER_SCALE,
} from "../../../../data/BlockFields";
import TabsLayout from "../../../Layout/TabsLayout";

type ScaleBlockSettingsPanelProps = {
  name: string;
  pageIndex: number;
  blockIndex: number;
};

export default function ScaleBlockSettingsPanel({
  name,
  pageIndex,
  blockIndex,
}: ScaleBlockSettingsPanelProps) {
  return (
    <>
      <TabsLayout
        tabOptions={{
          options: {
            label: "Options",
            tabPanel: (
              <>
                <Box sx={{ mt: 1 }}>
                  <ControlledSelect
                    label="From"
                    name={`${name}].fromNumber`}
                    id={`${name}].fromNumber`}
                    options={FROM_NUMBER_SCALE}
                  />
                </Box>
                <Box sx={{ mt: 1 }}>
                  <ControlledSelect
                    label="To"
                    name={`${name}].toNumber`}
                    id={`${name}].toNumber`}
                    options={TO_NUMBER_SCALE}
                  />
                </Box>

                <Box sx={{ mt: 1 }}>
                  <NewQuestionRequiredOption
                    name={name}
                  ></NewQuestionRequiredOption>
                </Box>
                <Box sx={{ mt: 3 }}>
                  <DynamicQuestionOption
                    name={name}
                    pageIndex={pageIndex}
                    blockIndex={blockIndex}
                  ></DynamicQuestionOption>
                </Box>
              </>
            ),
          },
          visibility: {
            label: "Visibility",
            tabPanel: (
              <NewConditionalVisibilityOptions
                name={name}
                itemType={QuestionTypes.SCALE}
                itemTitle="question"
                pageIndex={pageIndex}
                blockIndex={blockIndex}
              ></NewConditionalVisibilityOptions>
            ),
          },
        }}
      />
    </>
  );
}
