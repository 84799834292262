import React, { useState } from "react";

import { useField } from "formik";

import { Box, TextField, TextFieldProps, Popover } from "@mui/material";

import { ChromePicker } from "react-color";

import { Square, ColorLens } from "@mui/icons-material";

type ControlledColorPickerTextFieldProps = TextFieldProps & {
  name: string;
};

export default function ControlledColorPickerTextField({
  name,
  ...otherProps
}: ControlledColorPickerTextFieldProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [selectedColor, setSelectedColor] = useState("#ffffff");

  const [field, meta, helpers] = useField(name);

  const handleColorPickerOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleColorPickerClose = () => {
    setAnchorEl(null);
  };

  const handleColorChange = (color: any) => {
    setSelectedColor(color.hex);
    helpers.setValue(color.hex);
  };

  const textFieldConfig: TextFieldProps = {
    fullWidth: true,
    variant: "outlined",
    size: "small",
    InputProps: {
      startAdornment: <Square sx={{ mr: 1, color: field.value }} />,
    },
    ...field,
    ...otherProps,
    onClick: handleColorPickerOpen,
  };

  if (meta && meta.touched && meta.error) {
    textFieldConfig.error = true;
    textFieldConfig.helperText = meta.error;
  }

  return (
    <Box>
      <TextField {...textFieldConfig} />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleColorPickerClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ChromePicker color={selectedColor} onChange={handleColorChange} />
      </Popover>
    </Box>
  );
}
