import React, { useState } from "react";

import * as yup from "yup";
import { Form, Formik, FormikProps } from "formik";
import ControlledTextField from "../Inputs/ControlledTextField";
import { CHANGE_PASSWORD_URL } from "../../data/constants";
import { useProtectedApi } from "../../hooks/useProtectedApi";
import { useAuth } from "../../hooks/useAuth";
import { Alert } from "@mui/material";

const FORM_VALIDATION = yup.object().shape({
  currentPassword: yup.string().required("You current password is required."),
  newPassword: yup.string().required("You current password is required."),
  confirmNewPassword: yup
    .string()
    .required("You current password is required."),
});

const INITIAL_FORM_STATE = {
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

type EditPasswordFormFields = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

type EditPasswordFormProps = {
  submitButton: (loading: boolean) => React.ReactNode;
};

export default function EditPasswordForm({
  submitButton,
}: EditPasswordFormProps) {
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const { auth, setAuth } = useAuth();

  const ProtectedApi = useProtectedApi();

  const handleSubmit = async (values: EditPasswordFormFields) => {
    try {
      setError("");
      setIsLoading(true);

      await ProtectedApi.post(CHANGE_PASSWORD_URL, values);

      setAuth({
        user: "",
        accessToken: "",
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // @ts-ignore
      if (!error?.response) {
        setError("No response from server.");
        // @ts-ignore
      } else if (error?.response) {
        // @ts-ignore
        setError(error.response.data.error.message);
      } else {
        setError("Unable to login.");
      }
    }
  };

  return (
    <Formik
      initialValues={INITIAL_FORM_STATE as EditPasswordFormFields}
      validationSchema={FORM_VALIDATION}
      onSubmit={async (values: any) => {
        await handleSubmit(values);
      }}
      validateOnChange
      validateOnBlur
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
      }: FormikProps<EditPasswordFormFields>) => {
        return (
          <Form>
            <Alert sx={{ mb: 3 }} severity="info">
              Note : Once your password changes, you will be logged out and
              required to log back in with your new password.
            </Alert>

            {error && (
              <Alert sx={{ my: 3 }} severity="error">
                {error}
              </Alert>
            )}
            <ControlledTextField
              name="currentPassword"
              label="Current Password"
              required
              type="password"
            ></ControlledTextField>

            <ControlledTextField
              name="newPassword"
              label="New Password"
              required
              type="password"
              sx={{
                mt: 3,
              }}
            ></ControlledTextField>

            <ControlledTextField
              name="confirmNewPassword"
              label="Confirm New Password"
              required
              type="password"
              sx={{
                mt: 3,
              }}
            ></ControlledTextField>

            {submitButton(loading)}
          </Form>
        );
      }}
    </Formik>
  );
}
