import React from "react";

import { Box, BoxProps, Card, CardContent, Divider } from "@mui/material";

import { InsertDriveFileOutlined } from "@mui/icons-material";

import { useField } from "formik";

import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

import { BackgroundTypes, PageBlockFields } from "../../../../types/Blocks";
import NewBlockCardTopHeader from "../../BlockTopHeader";
import { useSurveyTheme } from "../../../../providers/surveyTheme";

import FormScreenLAyout from "../../../Layout/FormScreenLayout";
import SurveyButton from "../../../SurveyComponents/SurveyButton";

type PageBlockProps = {
  name: string;
  formScreenContentStyle?: BoxProps["sx"];
  children: React.ReactNode;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  pageIndex: number;
  numOfPages: number;
  deleteHandler: () => void;
  settingsHandler: () => void;
};

export default function PageBlock({
  name,
  formScreenContentStyle = {},
  children,
  dragHandleProps,
  pageIndex,
  numOfPages,
  deleteHandler,
  settingsHandler,
}: PageBlockProps) {
  const surveyTheme = useSurveyTheme();

  const [field] = useField<PageBlockFields>(name);

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        my: 2,
        minHeight: "90vh",
        maxHeight: "90vh",
      }}
    >
      <CardContent {...dragHandleProps}>
        <NewBlockCardTopHeader
          icon={<InsertDriveFileOutlined />}
          title="Page"
          settingsClickHandler={settingsHandler}
          deleteHandler={deleteHandler}
        ></NewBlockCardTopHeader>
      </CardContent>

      <Divider />

      <FormScreenLAyout
        contentBoxStyling={{ ...formScreenContentStyle }}
        surveyTheme={surveyTheme}
        sx={{
          ...(field.value.hasCustomBackground &&
            field.value.customBackgroundSettings.backgroundType ===
              BackgroundTypes.IMAGE &&
            field.value.customBackgroundSettings.imageBackgroundSettings
              .imageUrl && {
              backgroundImage: `url(${field.value.customBackgroundSettings.imageBackgroundSettings.imageUrl})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }),

          ...(field.value.hasCustomBackground &&
            field.value.customBackgroundSettings.backgroundType ===
              BackgroundTypes.COLOR &&
            field.value.customBackgroundSettings.colorBackgroundSettings
              .color && {
              backgroundColor:
                field.value.customBackgroundSettings.colorBackgroundSettings
                  .color,
            }),
        }}
      >
        {children}
        <CardContent>
          <Box
            sx={{
              display: "flex",
              ...(surveyTheme && {
                ...(surveyTheme.contentTextAlignment && {
                  justifyContent: surveyTheme.contentTextAlignment,
                }),
              }),
            }}
          >
            {pageIndex === numOfPages - 1 && (
              <SurveyButton>Submit</SurveyButton>
            )}

            {pageIndex !== numOfPages - 1 && <SurveyButton>Next</SurveyButton>}

            {/* NOTE : For now we should not have any back button */}
            {/* {pageIndex !== 0 && pageIndex < numOfPages - 1 && (
              <SurveyButton sx={{ mx: 2 }}>Previouse</SurveyButton>
            )} */}

            {/* {pageIndex !== 0 && pageIndex < numOfPages - 1 && (
              <SurveyButton sx={{ mx: 2 }}>Next</SurveyButton>
            )} */}
          </Box>
        </CardContent>
      </FormScreenLAyout>
    </Card>
  );
}
