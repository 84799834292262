import React, { useEffect } from "react";

import { Box, BoxProps, CircularProgress, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { LiveSurveyThemeDocument } from "../../../../types/SurveyTheme";
import { generateQuestionTextStyle } from "../../../../utils/styles/generateQuestionTextStyle";

type LiveRedirectActionBlockProps = BoxProps & {
  redirectionUrl: string;
  surveyTheme: LiveSurveyThemeDocument | null;
};

export default function LiveRedirectActionBlock({
  redirectionUrl,
  surveyTheme,
  sx,
}: LiveRedirectActionBlockProps) {
  const theme = useTheme();
  useEffect(() => {
    if (redirectionUrl) {
      window.location.href = redirectionUrl;
    }
  }, [redirectionUrl]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        my: 5,
        ...sx,
      }}
    >
      <CircularProgress></CircularProgress>
      <Typography
        sx={{
          mt: 5,
          ...(surveyTheme && {
            ...generateQuestionTextStyle(surveyTheme),
            fontSize: theme.typography.h6.fontSize,
            fontWeight: theme.typography.h6.fontWeight,
          }),
        }}
        variant="h6"
      >
        One moment...
      </Typography>
      <Typography
        sx={{
          mt: 1,
          mb: 2,
          ...(surveyTheme && {
            ...generateQuestionTextStyle(surveyTheme),
            fontSize: theme.typography.fontSize,
            fontWeight: theme.typography.fontWeightRegular,
          }),
        }}
      >
        We are redirecting you.
      </Typography>
    </Box>
  );
}
