import { Typography, Box } from "@mui/material";

import { EditTabs } from "../../types/Blocks";
import { SelectImageTabs } from "../Images/SelectImageDialog";
import { EditSurveyThemeSettingsPanelTabs } from "../../types/Drawers";

interface ITabPanelProps {
  children?: React.ReactNode;
  index: EditTabs | SelectImageTabs | EditSurveyThemeSettingsPanelTabs | string;
  value: EditTabs | SelectImageTabs | EditSurveyThemeSettingsPanelTabs | string;
}

export default function TabPanel({
  children,
  value,
  index,
  ...other
}: ITabPanelProps) {
  //   const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}
