import { BackgroundSettings, VisibilitySettings } from "./Blocks";

export enum Actions {
  REDIRECT = "action.redirect",
  FILE = "action.file",
  API_REQUEST = "action.api_request",
  ACTION_PATH = "action.action_path",
  HEADING = "action.heading",
  PARAGRAPH = "action.paragraph",
}

export type ActionCard = {
  actionType: Actions;
  label: string;
  icon: React.ReactNode;
};

export type ActionFields = {
  actionId: string;
  actionType: Actions;
  hasConditionalVisibility: boolean;
  visibilitySettings: VisibilitySettings;
};

export type RedirectActionFields = ActionFields & {
  actionType: Actions.REDIRECT;
  redirectionUrl: string;
};

export type FileActionFields = ActionFields & {
  actionType: Actions.FILE;
};

export type ApiRequestActionFields = ActionFields & {
  actionType: Actions.API_REQUEST;
};

export type HeadingActionFields = ActionFields & {
  actionType: Actions.HEADING;
  heading: string;
};

export type ParagraphActionFields = ActionFields & {
  actionType: Actions.PARAGRAPH;
  paragraph: string;
};

export type ActionPathFields = {
  actionPathId: string;
  hasConditionalVisibility: boolean;
  visibilitySettings: VisibilitySettings;
  hasCustomBackground: boolean;
  customBackgroundSettings: BackgroundSettings;
  actions: ActionFields[];
};

export type LiveActionPathDocument = ActionPathFields & {
  _id: string;
  actions: LiveActionDocument[];
};

export type LiveActionDocument = ActionFields & {
  _id: string;
};

export type LiveRedirectActionDocument = RedirectActionFields &
  LiveActionDocument;

export type LiveHeadingActionDocument = HeadingActionFields &
  LiveActionDocument;

export type LiveParagraphActionDocument = ParagraphActionFields &
  LiveActionDocument;

// TODO - Each action in the actions list should be though of
// TODO - as a page except for actions such as "Redirect" and
// TODO - "API request" which will run as soon as their
// TODO - "action page" is visible. Other actions can be
// TODO - success screen or disqualification scren
