import React, { useState } from "react";

import { useField } from "formik";

import {
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  FormControlProps,
  FormHelperText,
  RadioGroupProps,
} from "@mui/material";

type ControlledRadioButtonsProps = RadioGroupProps & {
  name: string;
  options: { [key: string]: any };
};

export default function ControlledRadioButtons({
  name,
  options,
  ...otherPRops
}: ControlledRadioButtonsProps) {
  const [errorMessage, setErrorMessage] = useState("");
  const [field, meta, helpers] = useField(name);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    helpers.setValue(value);
  };

  const radioGroupConfig: RadioGroupProps = {
    ...field,
    ...otherPRops,
    onChange: handleChange,
  };

  if (meta && meta.touched && meta.error) {
    setErrorMessage(meta.error);
  }

  return (
    <>
      <RadioGroup {...radioGroupConfig}>
        {Object.keys(options).map((item, index) => {
          return (
            <FormControlLabel
              key={item}
              value={item}
              control={<Radio />}
              label={options[item]}
            />
          );
        })}
      </RadioGroup>
      <FormHelperText error={Boolean(errorMessage)}>
        {errorMessage}
      </FormHelperText>
    </>
  );
}
