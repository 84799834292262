import React from "react";

import { Box, BoxProps, Slider } from "@mui/material";
import { LiveSurveyThemeDocument } from "../../../../types/SurveyTheme";

import LiveSurveyQuestionText from "../../../SurveyComponents/LiveSurveyQuestionText";
import { useField } from "formik";
import { generateSliderStyling } from "../../../../utils/styles/generateSliderStyling";
import LiveSurveyCaptionText from "../../../SurveyComponents/LiveSurveyCaptionText";

type LiveSliderBlockProps = BoxProps & {
  name: string;
  questionText: string;
  questionDescription?: string;
  isQuestionRequired: boolean;
  surveyTheme: LiveSurveyThemeDocument | null;
  sliderMinNumber: number;
  sliderMaxNumber: number;
  sliderSteps: number;
  sliderHasCustomLabels: boolean;
  sliderMinLabel?: string;
  sliderMidLabel?: string;
  sliderMaxLabel?: string;
};

export default function LiveSliderBlock({
  name,
  isQuestionRequired,
  questionText,
  questionDescription,
  surveyTheme,
  sliderMinNumber,
  sliderMaxNumber,
  sliderSteps,
  sliderHasCustomLabels,
  sliderMinLabel,
  sliderMidLabel,
  sliderMaxLabel,
  sx,
}: LiveSliderBlockProps) {
  const [field] = useField<number>(name);

  return (
    <Box sx={{ mb: 3, ...sx }}>
      <LiveSurveyQuestionText
        questionText={questionText}
        questionDescription={questionDescription}
        isQuestionRequired={isQuestionRequired}
        surveyTheme={surveyTheme}
        sx={{
          mb: 3,
        }}
      />

      <Slider
        name={name}
        value={field.value === undefined ? 0 : field.value}
        valueLabelDisplay="auto"
        min={sliderMinNumber}
        max={sliderMaxNumber}
        step={sliderSteps ?? 1}
        onChange={field.onChange}
        sx={{
          ...(surveyTheme && generateSliderStyling(surveyTheme)),
        }}
      />
      {!sliderHasCustomLabels && (
        <Box
          sx={{
            mt: 1.5,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <LiveSurveyCaptionText surveyTheme={surveyTheme}>
            {sliderMinNumber}
          </LiveSurveyCaptionText>
          <LiveSurveyCaptionText surveyTheme={surveyTheme}>
            {sliderMaxNumber}
          </LiveSurveyCaptionText>
        </Box>
      )}

      {sliderHasCustomLabels && (
        <Box
          sx={{
            mt: 1.5,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <LiveSurveyCaptionText surveyTheme={surveyTheme}>
            {sliderMinLabel}
          </LiveSurveyCaptionText>
          <LiveSurveyCaptionText surveyTheme={surveyTheme}>
            {sliderMidLabel}
          </LiveSurveyCaptionText>
          <LiveSurveyCaptionText surveyTheme={surveyTheme}>
            {sliderMaxLabel}
          </LiveSurveyCaptionText>
        </Box>
      )}
    </Box>
  );
}
