import React, { useState } from "react";

import * as yup from "yup";
import { REQUEST_SET_PASSWORD_URL } from "../../data/constants";
import { useProtectedApi } from "../../hooks/useProtectedApi";
import { useAuth } from "../../hooks/useAuth";
import { Alert, Box, Typography } from "@mui/material";

type RequestToSetPasswordProps = {
  submitButton: (
    submitHandler: () => Promise<void>,
    loading: boolean,
    success: string
  ) => React.ReactNode;
};

export default function RequestToSetPassword({
  submitButton,
}: RequestToSetPasswordProps) {
  const [success, setSuccess] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const ProtectedApi = useProtectedApi();

  const requestSetPassword = async () => {
    try {
      setError("");
      setIsLoading(true);

      await ProtectedApi.post(REQUEST_SET_PASSWORD_URL);

      // setAuth({
      //   user: "",
      //   accessToken: "",
      // });

      setIsLoading(false);
      setSuccess("We have sent you an email");
    } catch (error) {
      setSuccess("");
      setIsLoading(false);
      // @ts-ignore
      if (!error?.response) {
        setError("No response from server.");
        // @ts-ignore
      } else if (error?.response) {
        // @ts-ignore
        setError(error.response.data.error.message);
      } else {
        setError("Unable to login.");
      }
    }
  };

  return (
    <Box>
      <Typography>
        You are currently signed up to Lively Forms with your Social Media
        profile. You can set request to set a password for your account.
      </Typography>

      <Typography
        sx={{
          mt: 2,
        }}
      >
        Would you like us to send a link to your email to set your password?
      </Typography>

      {success && (
        <Alert sx={{ my: 2 }} severity="success">
          {success}
        </Alert>
      )}

      {error && (
        <Alert sx={{ my: 3 }} severity="error">
          {error}
        </Alert>
      )}

      {submitButton(requestSetPassword, loading, success)}
    </Box>
  );
}
