import React from "react";

import { useField, useFormikContext } from "formik";

import { Box, Typography } from "@mui/material";

import ControlledSwitch from "../../Inputs/ControlledSwitch";
import ControlledSelect from "../../Inputs/ControlledSelect";

import { IFormCanvasBlocksFields } from "../../FormBuilder";
import {
  FormSubItemFields,
  FormSubItemTypes,
  PageBlockFields,
  QuestionFields,
} from "../../../types/Blocks";
import { QuestionOptions } from "./VisibilityConditionRow";
import ControlledTextField from "../../Inputs/ControlledTextField";
import InfoTooltip from "../../InfoTooltip";

type DynamicQuestionOptionProps = {
  // blockIndex: number;
  name: string;
  pageIndex: number;
  blockIndex?: number;
};

export default function DynamicQuestionOption({
  name,
  pageIndex,
  blockIndex,
}: DynamicQuestionOptionProps) {
  const { values: formValues } = useFormikContext<IFormCanvasBlocksFields>();
  const [field] = useField<QuestionFields>(name);

  const questionsFiltered = formValues.pages.reduce(
    (
      previousValue: FormSubItemFields[],
      currentValue: PageBlockFields,
      currentPageIndex: number
    ) => {
      return [
        ...previousValue,
        ...currentValue.subItems.filter(
          (formBlock: FormSubItemFields, currentBlockIndex: number) => {
            if (formBlock.subItemType !== FormSubItemTypes.QUESTION) {
              return false;
            }
            if (pageIndex <= currentPageIndex) {
              return false;
            }
            return !(
              currentPageIndex === pageIndex && currentBlockIndex === blockIndex
            );
          }
        ),
      ];
    },
    []
  ) as QuestionFields[];

  const questionOptions = questionsFiltered.reduce(
    (previousValue: QuestionOptions, currentValue: QuestionFields) => {
      previousValue[currentValue.subItemId] = currentValue.question;
      return previousValue;
    },
    {}
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              mr: 1,
            }}
          >
            Dynamic Question
          </Typography>
          <InfoTooltip title="Making a question dynamic will make the question text be generated based on an answer by a respondant to a previous question." />
        </Box>
        <ControlledSwitch
          name={`${name}.isDynamicQuestion`}
          id={`${name}.isDynamicQuestion`}
          size="small"
        />
      </Box>

      {field.value.isDynamicQuestion && (
        <Box
          sx={{
            mt: 2,
          }}
        >
          <Typography sx={{ mb: 2 }}>
            Generate this question based on :
          </Typography>

          <ControlledSelect
            name={`${name}.dynamicQuestionSettings.questionId`}
            id={`${name}.dynamicQuestionSettings.questionId`}
            label="Question"
            options={questionOptions}
            required
            disabled={Object.keys(questionOptions).length <= 0}
          />

          <ControlledTextField
            sx={{
              mt: 3,
            }}
            label="Context"
            name={`${name}.dynamicQuestionSettings.dynamicQuestionContext`}
            id={`${name}.dynamicQuestionSettings.dynamicQuestionContext`}
            required
            multiline
            InputProps={{
              endAdornment: (
                <InfoTooltip title="You can provide 'Context' to our AI to have a background for what kind of question it should generate." />
              ),
            }}
          />
        </Box>
      )}
    </>
  );
}
