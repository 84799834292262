import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    // mode: "dark",
    primary: {
      main: "#1d85b5",
      100: "#b4e3f1",
      200: "#85d1e8",
      300: "#5cbfdf",
      400: "#42b2da",
      500: "#2da5d5",
      600: "#2697c8",
      700: "#1d85b5",
      800: "#1b74a1",
      900: "#115580",
    },
    secondary: {
      main: "#b51d85",
      100: "#f4c0e4",
      200: "#f196d3",
      300: "#f16bbf",
      400: "#f247ae",
      500: "#f71b9a",
      600: "#e41d94",
      700: "#cc1d8c",
      800: "#b51d85",
      900: "#8d1b79",
    },
  },
});
