import React from "react";

import { useField } from "formik";

import { Box, Typography } from "@mui/material";

import ControlledTextField from "../../Inputs/ControlledTextField";
import ControlledSwitch from "../../Inputs/ControlledSwitch";
import { QuestionFields } from "../../../types/Blocks";
import InfoTooltip from "../../InfoTooltip";

type INewQuestionRequiredOptionProps = {
  // blockIndex: number;
  name: string;
};

export default function NewQuestionRequiredOption({
  // blockIndex,
  name,
}: INewQuestionRequiredOptionProps) {
  // const [field] = useField(`formBlocks[${blockIndex}]`);
  const [field] = useField<QuestionFields>(name);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="body1" sx={{ mr: 1 }}>
            Required
          </Typography>
          <InfoTooltip title="Marking this question as required will require respondants to provide an answer to this question before they can proceed." />
        </Box>
        <ControlledSwitch
          name={`${name}.isQuestionRequired`}
          id={`${name}.isQuestionRequired`}
          size="small"
        />
      </Box>

      {field.value.isQuestionRequired && (
        <Box
          sx={{
            mt: 2,
          }}
        >
          <ControlledTextField
            label="Required Text"
            name={`${name}.requiredText`}
            id={`${name}.requiredText`}
            multiline
            maxRows={5}
            required
            InputProps={{
              endAdornment: (
                <InfoTooltip title="You can control the text that is shown to the user using 'Required Text'." />
              ),
            }}
          />
        </Box>
      )}
    </>
  );
}
