import React from "react";

import { useField, useFormikContext, FormikTouched } from "formik";

import { Box, Typography, Button } from "@mui/material";

import { Add } from "@mui/icons-material";

import ControlledSwitch from "../../Inputs/ControlledSwitch";
import ControlledSelect from "../../Inputs/ControlledSelect";
import { VISIBILITY_OPTIONS } from "../../../data/BlockFields";
import NewVisibilityConditionRow from "./VisibilityConditionRow";
import { IFormCanvasBlocksFields } from "../../FormBuilder";
import {
  Blocks,
  FormSubBlockTypes,
  FormSubItemFields,
  FormSubItemTypes,
  PageBlockFields,
  QuestionFields,
  QuestionTypes,
  SecondaryVisibilityCondition,
  VisibilityLogicalOperators,
  VisibilityLogicalUnions,
} from "../../../types/Blocks";
import { ActionFields, Actions } from "../../../types/Actions";
import InfoTooltip from "../../InfoTooltip";

type INewConditionalVisibilityOptionsProps = {
  // blockIndex: number;
  name: string;
  pageIndex: number;
  blockIndex?: number;
  itemType: QuestionTypes | Blocks | Actions | FormSubBlockTypes;
  itemTitle: string;
};

export default function NewConditionalVisibilityOptions({
  // blockIndex,
  name,
  itemType,
  itemTitle,
  pageIndex,
  blockIndex,
}: INewConditionalVisibilityOptionsProps) {
  const { values: formValues, touched } =
    useFormikContext<IFormCanvasBlocksFields>();
  const [field, meta, helpers] = useField<QuestionFields | ActionFields>(name);

  const questionsFiltered = formValues.pages.reduce(
    (
      previousValue: FormSubItemFields[],
      currentValue: PageBlockFields,
      currentPageIndex: number
    ) => {
      return [
        ...previousValue,
        ...currentValue.subItems.filter(
          (formBlock: FormSubItemFields, currentBlockIndex: number) => {
            if (
              formBlock.subItemType === FormSubItemTypes.QUESTION &&
              ((formBlock as QuestionFields).questionType ===
                QuestionTypes.ADDRESS ||
                (formBlock as QuestionFields).questionType ===
                  QuestionTypes.RANKING)
            ) {
              // NOTE : TEMPORARILY disable conditinal visibility for Ranking and address
              return false;
            } else if (itemType === Blocks.PAGE) {
              return (
                formBlock.subItemType === FormSubItemTypes.QUESTION &&
                !(currentPageIndex === pageIndex)
              );
            } else if (
              Object.values(QuestionTypes).includes(itemType as QuestionTypes)
            ) {
              if (pageIndex <= currentPageIndex) {
                return false;
              }

              return (
                formBlock.subItemType === FormSubItemTypes.QUESTION &&
                !(
                  currentPageIndex === pageIndex &&
                  currentBlockIndex === blockIndex
                )
              );
            } else {
              return formBlock.subItemType === FormSubItemTypes.QUESTION;
            }
          }
        ),
      ];
    },
    []
  ) as QuestionFields[];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="body1" sx={{ mr: 1 }}>
            Conditional Visibility
          </Typography>
          <InfoTooltip title="Control my visibility based on answers by respondants to previous questions." />
        </Box>
        <ControlledSwitch
          name={`${name}.hasConditionalVisibility`}
          id={`${name}.hasConditionalVisibility`}
          size="small"
        />
      </Box>

      {field.value.hasConditionalVisibility && (
        <Box
          sx={{
            mt: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ControlledSelect
              name={`${name}.visibilitySettings.isVisible`}
              id={`${name}.visibilitySettings.isVisible`}
              options={VISIBILITY_OPTIONS}
              required
              fullWidth={false}
              sx={{ width: 100 }}
            />
            <Typography
              sx={{
                mx: 2,
              }}
            >
              this {itemTitle} if
            </Typography>
          </Box>
          <NewVisibilityConditionRow
            name={`${name}.visibilitySettings.primaryCondition`}
            questions={questionsFiltered}
          ></NewVisibilityConditionRow>

          {field.value.visibilitySettings.secondaryConditions.map(
            (
              visibilityCondition: SecondaryVisibilityCondition,
              visibilityConditionIndex: number
            ) => {
              return (
                <NewVisibilityConditionRow
                  key={visibilityConditionIndex}
                  name={`${name}.visibilitySettings.secondaryConditions[${visibilityConditionIndex}]`}
                  questions={questionsFiltered}
                  isSecondaryCondition
                  deleteHandler={() => {
                    // TODO - Need to be modified to also handle actions nad actions paths
                    helpers.setTouched({
                      // @ts-ignore
                      ...meta.touched,
                      // ...(Object.values(Blocks).includes(itemType as Blocks) &&
                      // itemType === Blocks.PAGE
                      //   ? touched?.pages?.[pageIndex]
                      //   : touched?.pages?.[pageIndex]?.subItems?.[
                      //       blockIndex!
                      //     ]),
                      ...(Object.values(Blocks).includes(itemType as Blocks)
                        ? itemType === Blocks.PAGE
                          ? touched?.pages?.[pageIndex]
                          : touched?.pages?.[pageIndex].subItems?.[blockIndex!]
                        : Object.values(Actions).includes(
                            itemType as Actions
                          ) && itemType === Actions.ACTION_PATH
                        ? touched?.actionPaths?.[pageIndex]
                        : touched?.actionPaths?.[pageIndex].actions?.[
                            blockIndex!
                          ]),
                      // ...(Object.values(Actions).includes(
                      //   itemType as Actions
                      // ) && itemType === Actions.ACTION_PATH
                      //   ? touched?.actionPaths?.[pageIndex]
                      //   : touched?.actionPaths?.[pageIndex]?.actions?.[
                      //       blockIndex!
                      //     ]),
                      // ...touched?.pages?.[pageIndex]?.subItems?.[blockIndex],
                      visibilitySettings: {
                        // ...touched?.pages?.[pageIndex]?.subItems?.[blockIndex]
                        //   .visibilitySettings,
                        // ...(Object.values(Blocks).includes(
                        //   itemType as Blocks
                        // ) && itemType === Blocks.PAGE
                        //   ? touched?.pages?.[pageIndex]
                        //   : touched?.pages?.[pageIndex]?.subItems?.[
                        //       blockIndex!
                        //     ]
                        // )?.visibilitySettings,
                        ...(Object.values(Blocks).includes(itemType as Blocks)
                          ? itemType === Blocks.PAGE
                            ? touched?.pages?.[pageIndex]
                            : touched?.pages?.[pageIndex].subItems?.[
                                blockIndex!
                              ]
                          : Object.values(Actions).includes(
                              itemType as Actions
                            ) && itemType === Actions.ACTION_PATH
                          ? touched?.actionPaths?.[pageIndex]
                          : touched?.actionPaths?.[pageIndex].actions?.[
                              blockIndex!
                            ]
                        )?.visibilitySettings,
                        secondaryConditions:
                          // @ts-ignore
                          // touched?.pages?.[pageIndex]?.subItems?.[
                          //   blockIndex
                          // ].visibilitySettings.secondaryConditions.filter(
                          // (Object.values(Blocks).includes(itemType as Blocks) &&
                          // itemType === Blocks.PAGE
                          //   ? touched?.pages?.[pageIndex]
                          //   : touched?.pages?.[pageIndex]?.subItems?.[
                          //       blockIndex!
                          //     ]
                          // )?.visibilitySettings.secondaryConditions.filter(
                          (Object.values(Blocks).includes(itemType as Blocks)
                            ? itemType === Blocks.PAGE
                              ? touched?.pages?.[pageIndex]
                              : touched?.pages?.[pageIndex].subItems?.[
                                  blockIndex!
                                ]
                            : Object.values(Actions).includes(
                                itemType as Actions
                              ) && itemType === Actions.ACTION_PATH
                            ? touched?.actionPaths?.[pageIndex]
                            : touched?.actionPaths?.[pageIndex].actions?.[
                                blockIndex!
                              ]
                          )?.visibilitySettings?.secondaryConditions.filter(
                            (
                              _: FormikTouched<SecondaryVisibilityCondition>,
                              currentVisibilityConditionIndex: number
                            ) => {
                              return (
                                visibilityConditionIndex !==
                                currentVisibilityConditionIndex
                              );
                            }
                          ),
                      },
                    });

                    helpers.setValue({
                      ...field.value,
                      visibilitySettings: {
                        ...field.value.visibilitySettings,
                        secondaryConditions:
                          field.value.visibilitySettings.secondaryConditions.filter(
                            (
                              _: SecondaryVisibilityCondition,
                              currentVisibilityConditionIndex: number
                            ) => {
                              return (
                                visibilityConditionIndex !==
                                currentVisibilityConditionIndex
                              );
                            }
                          ),
                      },
                    });
                  }}
                ></NewVisibilityConditionRow>
              );
            }
          )}

          <Button
            sx={{
              mt: 1,
            }}
            fullWidth
            variant="text"
            startIcon={<Add />}
            onClick={() => {
              helpers.setValue({
                ...field.value,
                visibilitySettings: {
                  ...field.value.visibilitySettings,
                  secondaryConditions: [
                    ...field.value.visibilitySettings.secondaryConditions,
                    {
                      logicalUnion: VisibilityLogicalUnions.AND,
                      questionId: "",
                      logicalOperator: VisibilityLogicalOperators.IS,
                      answerValue: "",
                    },
                  ],
                },
              });
            }}
          >
            Add New Condition
          </Button>
        </Box>
      )}
    </>
  );
}
