import React from "react";

import { useField } from "formik";

import { TextField, TextFieldProps } from "@mui/material";

type ControlledTextFieldProps = TextFieldProps & {
  name: string;
};

export default function ControlledTextField({
  name,
  ...otherProps
}: ControlledTextFieldProps) {
  const [field, meta] = useField(name);

  const textFieldConfig: TextFieldProps = {
    fullWidth: true,
    variant: "outlined",
    size: "small",
    ...field,
    // "field" contains "value" so put value after field
    // Set the value to empty to string
    // @ts-ignore
    value: field.value === undefined ? "" : field.value,
    ...otherProps,
  };

  if (meta && meta.touched && meta.error) {
    textFieldConfig.error = true;
    textFieldConfig.helperText = meta.error;
  }

  return <TextField {...textFieldConfig} />;
}
