import React from "react";

import { Box, BoxProps, Stack, Typography } from "@mui/material";

import { useTheme } from "@mui/material/styles";

import ControlledTextField from "../../../Inputs/ControlledTextField";
import { LiveSurveyThemeDocument } from "../../../../types/SurveyTheme";

import { generateAnswerTextStyle } from "../../../../utils/styles/generateAnswerTextStyle";
import LiveSurveyQuestionText from "../../../SurveyComponents/LiveSurveyQuestionText";
import { AddressSettings } from "../../../../types/Blocks";
import { generateTextFieldStyle } from "../../../../utils/styles/generateTextFieldStyle";

export type AddressFieldSettings = {
  addressLine1: AddressSettings;
  addressLine2: AddressSettings;
  addressTown: AddressSettings;
  addressState: AddressSettings;
  addressPostCode: AddressSettings;
  addressCountry: AddressSettings;
};

type LiveAddressBlockProps = BoxProps & {
  name: string;
  questionText: string;
  questionDescription?: string;
  isQuestionRequired: boolean;
  surveyTheme: LiveSurveyThemeDocument | null;
  addressFieldSettings: AddressFieldSettings;
};

export default function LiveAddressBlock({
  name,
  isQuestionRequired,
  questionText,
  questionDescription,
  surveyTheme,
  addressFieldSettings,
  sx,
}: LiveAddressBlockProps) {
  const theme = useTheme();
  return (
    <Box sx={{ mb: 3, ...sx }}>
      <LiveSurveyQuestionText
        questionText={questionText}
        questionDescription={questionDescription}
        isQuestionRequired={isQuestionRequired}
        surveyTheme={surveyTheme}
        sx={{
          mb: 3,
        }}
      />
      {addressFieldSettings.addressLine1 &&
        addressFieldSettings.addressLine1.isVisible && (
          <>
            <Stack direction="row">
              {addressFieldSettings.addressLine1.isRequired && (
                <Typography sx={{ mr: 0.5, color: "red" }}>*</Typography>
              )}

              <Typography
                sx={{
                  mb: 1,
                }}
              >
                {addressFieldSettings.addressLine1.label ?? "Address Line 1"}
              </Typography>
            </Stack>
            <ControlledTextField
              name={`${name}.addressLine1`}
              id={`${name}.addressLine1`}
              placeholder="1 Blue Road"
              variant={(surveyTheme && surveyTheme.fieldVariant) ?? "outlined"}
              size="small"
              fullWidth
              sx={{
                ...generateTextFieldStyle(surveyTheme),
              }}
              inputProps={{
                style: {
                  ...theme.typography.h6,
                  ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
                },
              }}
            />
          </>
        )}

      {addressFieldSettings.addressLine2 &&
        addressFieldSettings.addressLine2.isVisible && (
          <>
            <Stack direction="row">
              {addressFieldSettings.addressLine2.isRequired && (
                <Typography sx={{ mr: 0.5, color: "red" }}>*</Typography>
              )}

              <Typography
                sx={{
                  my: 1,
                }}
              >
                {addressFieldSettings.addressLine2.label ?? "Address Line 2"}
              </Typography>
            </Stack>
            <ControlledTextField
              name={`${name}.addressLine2`}
              id={`${name}.addressLine2`}
              placeholder="Block 2"
              variant={(surveyTheme && surveyTheme.fieldVariant) ?? "outlined"}
              size="small"
              fullWidth
              sx={{
                ...generateTextFieldStyle(surveyTheme),
              }}
              inputProps={{
                style: {
                  ...theme.typography.h6,
                  ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
                },
              }}
            />
          </>
        )}

      {addressFieldSettings.addressTown &&
        addressFieldSettings.addressTown.isVisible && (
          <>
            <Stack direction="row">
              {addressFieldSettings.addressTown.isRequired && (
                <Typography sx={{ mr: 0.5, color: "red" }}>*</Typography>
              )}

              <Typography
                sx={{
                  my: 1,
                }}
              >
                {addressFieldSettings.addressTown.label ?? "Town / City"}
              </Typography>
            </Stack>
            <ControlledTextField
              name={`${name}.addressTown`}
              id={`${name}.addressTown`}
              placeholder="Westminister"
              variant={(surveyTheme && surveyTheme.fieldVariant) ?? "outlined"}
              // TODO - Remove reuqired from all other controlled text fields in at least live form mode
              //   required
              size="small"
              fullWidth
              sx={{
                ...generateTextFieldStyle(surveyTheme),
              }}
              inputProps={{
                style: {
                  ...theme.typography.h6,
                  ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
                },
              }}
            />
          </>
        )}

      {addressFieldSettings.addressState &&
        addressFieldSettings.addressState.isVisible && (
          <>
            <Stack direction="row">
              {addressFieldSettings.addressState.isRequired && (
                <Typography sx={{ mr: 0.5, color: "red" }}>*</Typography>
              )}

              <Typography
                sx={{
                  my: 1,
                }}
              >
                {addressFieldSettings.addressState.label ?? "State/ County"}
              </Typography>
            </Stack>
            <ControlledTextField
              name={`${name}.addressState`}
              id={`${name}.addressState`}
              placeholder="London"
              variant={(surveyTheme && surveyTheme.fieldVariant) ?? "outlined"}
              size="small"
              fullWidth
              sx={{
                ...generateTextFieldStyle(surveyTheme),
              }}
              inputProps={{
                style: {
                  ...theme.typography.h6,
                  ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
                },
              }}
            />
          </>
        )}

      {addressFieldSettings.addressPostCode &&
        addressFieldSettings.addressPostCode.isVisible && (
          <>
            <Stack direction="row">
              {addressFieldSettings.addressPostCode.isRequired && (
                <Typography sx={{ mr: 0.5, color: "red" }}>*</Typography>
              )}

              <Typography
                sx={{
                  my: 1,
                }}
              >
                {addressFieldSettings.addressPostCode.label ?? "ZIP/ Post Code"}
              </Typography>
            </Stack>
            <ControlledTextField
              name={`${name}.addressPostCode`}
              id={`${name}.addressPostCode`}
              placeholder="WE1 2X"
              variant={(surveyTheme && surveyTheme.fieldVariant) ?? "outlined"}
              size="small"
              fullWidth
              sx={{
                ...generateTextFieldStyle(surveyTheme),
              }}
              inputProps={{
                style: {
                  ...theme.typography.h6,
                  ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
                },
              }}
            />
          </>
        )}

      {addressFieldSettings.addressCountry &&
        addressFieldSettings.addressCountry.isVisible && (
          <>
            <Stack direction="row">
              {addressFieldSettings.addressCountry.isRequired && (
                <Typography sx={{ mr: 0.5, color: "red" }}>*</Typography>
              )}

              <Typography
                sx={{
                  my: 1,
                }}
              >
                {addressFieldSettings.addressCountry.label ?? "Country"}
              </Typography>
            </Stack>
            <ControlledTextField
              name={`${name}.addressCountry`}
              id={`${name}.addressCountry`}
              placeholder="United Kingdom"
              variant={(surveyTheme && surveyTheme.fieldVariant) ?? "outlined"}
              size="small"
              fullWidth
              sx={{
                ...generateTextFieldStyle(surveyTheme),
              }}
              inputProps={{
                style: {
                  ...theme.typography.h6,
                  ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
                },
              }}
            />
          </>
        )}
    </Box>
  );
}
