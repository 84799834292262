import React, { useState } from "react";

import LiveFormPage from "../components/LiveFormPage";
import { LiveActionPathDocument } from "../types/Actions";
import LiveActionPaths from "../components/LiveActionPaths";
import LiveGenericCompletionPage from "../components/LiveGenericCompletionPage";

type LiveFormProps = {};

export enum LiveFormViewMode {
  FORM_PAGE,
  ACTION_PATHS,
  GENERIC_COMPLETED,
}

export type FormValues = {
  [key: string]: any;
};

export default function LiveForm({}: LiveFormProps) {
  const [actionPaths, setActionPaths] = useState<LiveActionPathDocument[]>();

  const [currentViewMode, setCurrentViewMode] = useState(
    LiveFormViewMode.FORM_PAGE
  );

  const updateCurrentViewMode = (viewMode: LiveFormViewMode) => {
    setCurrentViewMode(viewMode);
  };

  const updateActionPaths = (newActionPaths: LiveActionPathDocument[]) => {
    setActionPaths(newActionPaths);
  };

  return (
    <>
      {currentViewMode === LiveFormViewMode.FORM_PAGE && (
        <LiveFormPage
          updateActionPaths={updateActionPaths}
          updateViewMode={updateCurrentViewMode}
        ></LiveFormPage>
      )}

      {actionPaths &&
        actionPaths?.length > 0 &&
        currentViewMode === LiveFormViewMode.ACTION_PATHS && (
          <LiveActionPaths actionPaths={actionPaths}></LiveActionPaths>
        )}

      {currentViewMode === LiveFormViewMode.GENERIC_COMPLETED && (
        <LiveGenericCompletionPage />
      )}
    </>
  );
}
