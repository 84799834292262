import React from "react";

import { Box } from "@mui/material";

import { QuestionTypes } from "../../../../types/Blocks";
import NewQuestionRequiredOption from "../../Options/QuestionRequiredOption";
import NewConditionalVisibilityOptions from "../../Options/ConditionalVisibilityOptions";
import ControlledSelect from "../../../Inputs/ControlledSelect";
import {
  STAR_RATING_ICONS,
  STAR_RATING_SCALE,
} from "../../../../data/BlockFields";
import ControlledColorPickerTextField from "../../../Inputs/ControlledColorPickerTextField";
import DynamicQuestionOption from "../../Options/DynamicQuestionOption";
import TabsLayout from "../../../Layout/TabsLayout";

type StarRatingBlockSettingsPanelProps = {
  name: string;
  pageIndex: number;
  blockIndex: number;
};

export default function StarRatingBlockSettingsPanel({
  name,
  pageIndex,
  blockIndex,
}: StarRatingBlockSettingsPanelProps) {
  return (
    <>
      <TabsLayout
        tabOptions={{
          options: {
            label: "Options",
            tabPanel: (
              <>
                <ControlledSelect
                  sx={{ mt: 1, mb: 2 }}
                  label="Scale"
                  name={`${name}.scale`}
                  id={`${name}.scale`}
                  options={STAR_RATING_SCALE}
                  required
                />

                <ControlledSelect
                  sx={{ mb: 2 }}
                  label="Icon"
                  name={`${name}.icon`}
                  id={`${name}.icon`}
                  options={STAR_RATING_ICONS}
                  required
                />

                <ControlledColorPickerTextField
                  sx={{ mb: 2 }}
                  label="Color"
                  name={`${name}.color`}
                  id={`${name}.color`}
                  required
                />

                <Box sx={{ mt: 1 }}>
                  <NewQuestionRequiredOption
                    name={name}
                  ></NewQuestionRequiredOption>
                </Box>
                <Box sx={{ mt: 3 }}>
                  <DynamicQuestionOption
                    name={name}
                    pageIndex={pageIndex}
                    blockIndex={blockIndex}
                  ></DynamicQuestionOption>
                </Box>
              </>
            ),
          },
          visibility: {
            label: "Visibility",
            tabPanel: (
              <NewConditionalVisibilityOptions
                name={name}
                itemType={QuestionTypes.STAR_RATING}
                itemTitle="question"
                pageIndex={pageIndex}
                blockIndex={blockIndex}
              ></NewConditionalVisibilityOptions>
            ),
          },
        }}
      />
    </>
  );
}
