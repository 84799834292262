import Api from "../api/api";
import { LOGOUT_URL } from "../data/constants";
import { useAuth } from "./useAuth";

export const useLogout = () => {
  const { setAuth } = useAuth();

  const logout = async () => {
    setAuth({ user: {}, accessToken: "" });
    try {
      await Api.post(
        LOGOUT_URL,
        {},
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
    } catch (err) {
      console.error(err);
    }
  };

  return logout;
};
