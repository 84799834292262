import { CountryCode } from "libphonenumber-js";

export enum QuestionTypes {
  TEXT = "question.text",
  CHECKBOXES = "question.checkboxes",
  STAR_RATING = "question.star_rating",
  YESNO = "question.yes_no",
  SCALE = "question.scale",
  SLIDER = "question.slider",
  RANKING = "question.ranking",
  IMAGE_CHOICE = "question.image_choice",
  DATE = "question.date",
  RADIO_GROUP = "question.radio_group",
  DROPDOWN = "question.dropdown",
  EMAIL = "question.email",
  PHONE = "question.phone",
  LINK = "question.link",
  ADDRESS = "question.address",
}

export enum Blocks {
  PAGE = "block.page",
}

// export enum Blocks {
//   TEXT = "block.text",
//   CHECKBOXES = "block.checkboxes",
//   STAR_RATING = "block.star_rating",
//   PAGE = "block.page",
// }

export type BlockCard = {
  type: QuestionTypes | Blocks | FormSubBlockTypes;
  label: string;
  icon: React.ReactNode;
};

// Types of views on a block card inside the form view
export enum ViewMode {
  EDIT,
  PREVIEW,
}

// Tabs in the "Edit" view for blocks
export enum EditTabs {
  OPTIONS = "Options",
  VALIDATION = "Validation",
  VISABILITY = "Visability",
  STYLE = "Style",
}

export enum VisibilityOption {
  SHOW = "show",
  HIDE = "hide",
}

export enum VisibilityLogicalUnions {
  AND = "and",
  OR = "or",
}

export enum VisibilityLogicalOperators {
  IS = "is",
  IS_NOT = "isNot",
  GREATER_THAN = "greaterThan",
  GREATER_THAN_OR_EQUAL_TO = "greaterThanOrEqualTo",
  LESS_THAN = "lessThan",
  LESS_THAN_OR_EQUAL_TO = "lessThanOrEqualTo",
  HAS_SENTIMENT = "hasSentiment",
  HAS_NOT_SENTIMENT = "hasNotSentiment",
  CONTAINS = "contains",
  CONTAINS_NOT = "containsNot",
  STARTS_WITH = "startsWith",
  ENDS_WITH = "endsWith",
  // Date operators
  IS_ON = "isOn",
  IS_NOT_ON = "isNotOn",
  IS_BEFORE = "isBefore",
  IS_AFTER = "isAfter",
  IS_BEFORE_OR_ON = "isBeforeOrOn",
  IS_AFTER_OR_ON = "isAfterOrOn",
}

export enum StarRatingIcons {
  STAR = "star",
  HEART = "heart",
  THUMBS_UP = "thumbs_up",
}

export type PrimaryVisibilityCondition = {
  questionId: string;
  logicalOperator: VisibilityLogicalOperators;
  answerValue: string;
};

export type SecondaryVisibilityCondition = PrimaryVisibilityCondition & {
  logicalUnion: VisibilityLogicalUnions;
};

export enum VisibilityConditionAnswerFields {
  TEXT_FIELD = "text",
  DROPDOWN = "dropdown",
  DATE = "date",
  NUMBER = "number",
}

// export type VisibilitySettings = {
//   isVisible: VisibilityOption;
//   union: VisibilityLogicalUnions;
//   conditions: VisibilityCondition[];
// };

export type VisibilitySettings = {
  isVisible: VisibilityOption;
  primaryCondition: PrimaryVisibilityCondition;
  secondaryConditions: SecondaryVisibilityCondition[];
};

export type DynamicQuestionSettings = {
  questionId: string;
  dynamicQuestionContext: string;
};

export enum FormSubItemTypes {
  QUESTION = "question",
  BLOCK = "block",
}

export type FormSubItemFields = {
  subItemId: string;
  subItemType: FormSubItemTypes;
  hasConditionalVisibility: boolean;
  visibilitySettings: VisibilitySettings;
};

export enum FormSubBlockTypes {
  HEADING = "heading",
  PARAGRAPH = "paragraph",
}

export type FormSubBlockFields = FormSubItemFields & {
  subItemType: FormSubItemTypes.BLOCK;
  blockType: FormSubBlockTypes;
  heading?: string;
  paragraph?: string;
};

export type HeadingBlockFields = FormSubBlockFields & {
  blockType: FormSubBlockTypes.HEADING;
  heading: string;
};

export type ParagraphBlockFields = FormSubBlockFields & {
  blockType: FormSubBlockTypes.PARAGRAPH;
  paragraph: string;
};

export type QuestionFields = FormSubItemFields & {
  subItemType: FormSubItemTypes.QUESTION;
  questionType: QuestionTypes;
  question: string;
  questionDescription?: string;
  isQuestionRequired: boolean;
  requiredText: string;
  isDynamicQuestion: boolean;
  dynamicQuestionSettings: DynamicQuestionSettings;
  checkboxes?: CheckboxValue[];
  scale?: number;
  icon?: StarRatingIcons;
  color?: string;
};

// export type QuestionFields = {
//   questionId: string;
//   questionType: QuestionTypes;
//   question: string;
//   questionDescription?: string;
//   isQuestionRequired: boolean;
//   requiredText: string;
//   hasConditionalVisibility: boolean;
//   visibilitySettings: VisibilitySettings;
//   isDynamicQuestion: boolean;
//   dynamicQuestionSettings: DynamicQuestionSettings;
// };

export type TextBlockFields = QuestionFields & {
  questionType: QuestionTypes.TEXT;
};

export type CheckboxValue = {
  checkboxId: string;
  value: string;
};

export type RadioGroupValue = {
  radioGroupValueId: string;
  value: string;
};

export type DropdownValue = {
  dropdownValueId: string;
  value: string;
};

export type RankingValue = {
  rankingId: string;
  value: string;
};

export type ImageChoiceValue = {
  imageChoiceId: string;
  imageUrl: string;
  imageCaption: string;
};

export type CheckboxBlockFields = QuestionFields & {
  questionType: QuestionTypes.CHECKBOXES;
  checkboxes: CheckboxValue[];
};

export type StarRatingBlockBlockFields = QuestionFields & {
  questionType: QuestionTypes.STAR_RATING;
  scale: number;
  icon: StarRatingIcons;
  color: string;
};

export type YesNoBlockFields = QuestionFields & {
  questionType: QuestionTypes.YESNO;
};

export type ScaleBlockFields = QuestionFields & {
  questionType: QuestionTypes.SCALE;
  fromNumber: number;
  toNumber: number;
  minLabel?: string;
  middleLabel?: string;
  maxLabel?: string;
};

export type SliderBlockFields = QuestionFields & {
  questionType: QuestionTypes.SLIDER;
  sliderMaxNumber: number;
  sliderMinNumber: number;
  sliderStep: number;
  sliderHasCustomLabels: boolean;
  sliderMnLabel?: string;
  sliderMiddleLabel?: string;
  sliderMaxLabel?: string;
};

export type RankingBlockFields = QuestionFields & {
  questionType: QuestionTypes.RANKING;
  rankingValues: RankingValue[];
};

export type ImageChoiceBlockFields = QuestionFields & {
  questionType: QuestionTypes.IMAGE_CHOICE;
  imageChoiceValues: ImageChoiceValue[];
  imageChoiceHasMultipleChoices: boolean;
};

// For now we will use "-" as a seperator but when using it
// we will find and reaplce the "-" with the selected seperator
// These date formats have been adapted for Date FNS
export enum AllowedDateFormats {
  DDMMYYYY = "dd-MM-yyyy",
  MMDDYYYY = "MM-dd-yyyy",
  YYYYMMDD = "yyyy-MM-dd",
}

export enum AllowedDateSeperators {
  SLASH = "/",
  DASH = "-",
  PERIOD = ".",
}

export type DateBlockFields = QuestionFields & {
  questionType: QuestionTypes.DATE;
  dateFormat: AllowedDateFormats;
  dateSeperator: AllowedDateSeperators;
};

export type RadioGroupBlockFields = QuestionFields & {
  questionType: QuestionTypes.RADIO_GROUP;
  radioGroupValues: RadioGroupValue[];
};

export type DropdownBlockFields = QuestionFields & {
  questionType: QuestionTypes.DROPDOWN;
  dropdownValues: DropdownValue[];
};

export type EmailBlockFields = QuestionFields & {
  questionType: QuestionTypes.EMAIL;
};

export type PhoneBlockFields = QuestionFields & {
  questionType: QuestionTypes.PHONE;
  phoneDefaultCountry: CountryCode;
};

export type LinkBlockFields = QuestionFields & {
  questionType: QuestionTypes.LINK;
};

export type LiveAddressAnswer = {
  addressLine1?: string;
  addressLine2?: string;
  addressTown?: string;
  addressState?: string;
  addressPostCode?: string;
  addressCountry?: string;
};

export type AddressSettings = {
  isVisible: boolean;
  label: string;
  isRequired: boolean;
};

export type AddressBlockFields = QuestionFields & {
  questionType: QuestionTypes.ADDRESS;
  addressLine1: AddressSettings;
  addressLine2: AddressSettings;
  addressTown: AddressSettings;
  addressState: AddressSettings;
  addressPostCode: AddressSettings;
  addressCountry: AddressSettings;
};

export enum YesNoValues {
  YES = "yes",
  NO = "no",
}

export enum BackgroundTypes {
  IMAGE = "image",
  COLOR = "color",
}

export type ColorBackgroundSettings = {
  color: string;
};

export type ImageBackgroundSettings = {
  imageUrl: string;
};

export type BackgroundSettings = {
  backgroundType: BackgroundTypes;
  imageBackgroundSettings: ImageBackgroundSettings;
  colorBackgroundSettings: ColorBackgroundSettings;
};

export type PageBlockFields = {
  pageId: string;
  hasConditionalVisibility: boolean;
  visibilitySettings: VisibilitySettings;
  hasCustomBackground: boolean;
  customBackgroundSettings: BackgroundSettings;
  subItems: (QuestionFields | FormSubBlockFields)[];
};

export type LivePageDocument = PageBlockFields & {
  _id: string;
  subItems: (LiveQuestionDocument | LiveFormSubBlockDocument)[];
};

export type LiveQuestionDocument = QuestionFields & {
  _id: string;
  checkboxes: CheckboxValue[];
  scale: number;
  icon: StarRatingIcons;
  color: string;
  // Scale
  fromNumber: number;
  toNumber: number;
  minLabel?: string;
  midLabel?: string;
  maxLabel?: string;
  // Slider
  sliderMaxNumber: number;
  sliderMinNumber: number;
  sliderStep: number;
  sliderHasCustomLabels: boolean;
  sliderMnLabel?: string;
  sliderMiddleLabel?: string;
  sliderMaxLabel?: string;
  // Ranking
  rankingValues: RankingValue[];
  // Image Choice
  imageChoiceValues: ImageChoiceValue[];
  imageChoiceHasMultipleChoices: boolean;
  // Date Block
  dateFormat: AllowedDateFormats;
  dateSeperator: AllowedDateSeperators;
  // Radio Group Values
  radioGroupValues: RadioGroupValue[];
  // Dropdown Block
  dropdownValues: DropdownValue[];
  // Phone Block
  phoneDefaultCountry: CountryCode;
  // Addess block
  addressLine1: AddressSettings;
  addressLine2: AddressSettings;
  addressTown: AddressSettings;
  addressState: AddressSettings;
  addressPostCode: AddressSettings;
  addressCountry: AddressSettings;
};

export type LiveFormSubItemDocument = FormSubItemFields & {
  _id: string;
};

export type LiveFormSubBlockDocument = FormSubBlockFields & {
  _id: string;
  heading: string;
  paragraph: string;
};

export type LiveImageDocument = {
  _id: string;
  owner: string;
  originalFileName: string;
  savedFileName: string;
  filePath: string;
};

export type FormPage = {
  id: string;
  formBlocks: QuestionFields[];
};

export enum ItemTypes {
  ITEM = "item",
  SUB_ITEM = "subItem",
}
