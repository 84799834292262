import React from "react";

import { TextField } from "@mui/material";

import { useTheme } from "@mui/material/styles";

import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

import { useSurveyTheme } from "../../../../providers/surveyTheme";

import { generateAnswerTextStyle } from "../../../../utils/styles/generateAnswerTextStyle";
import { generateTextFieldStyle } from "../../../../utils/styles/generateTextFieldStyle";
import SurveyBlockLayout from "../../../SurveyComponents/SurveyBlockLayout";
import SurveyQuestionBlockHeader from "../../../SurveyComponents/SurveyQuestionBlockHeader";

interface INewTextFieldBlockProps {
  // formik: FormikContextType<IFormCanvasBlocksFields>;
  name: string;
  // index: number;
  pageIndex: number;
  blockIndex: number;
  settingsHandler: () => void;
  deleteHandler: () => void;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  settingsPanelActive: boolean;
}

export interface INewTextFieldBlockFormFields {
  question: string;
  isQuestionRequired: boolean;
  requiredText: string;
}

export default function NewNewTextFieldBlock({
  name,
  pageIndex,
  blockIndex,
  deleteHandler,
  settingsHandler,
  dragHandleProps,
  settingsPanelActive,
}: INewTextFieldBlockProps) {
  const surveyTheme = useSurveyTheme();
  const theme = useTheme();

  return (
    <SurveyBlockLayout
      name={name}
      settingsHandler={settingsHandler}
      deleteHandler={deleteHandler}
      settingsPanelActive={settingsPanelActive}
      dragHandleProps={dragHandleProps}
    >
      <SurveyQuestionBlockHeader name={name} />

      <TextField
        placeholder="Type your answer here."
        value=""
        variant={(surveyTheme && surveyTheme.fieldVariant) ?? "outlined"}
        size="small"
        required
        fullWidth
        sx={{
          ...generateTextFieldStyle(surveyTheme),
        }}
        inputProps={{
          style: {
            ...theme.typography.h6,
            ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
          },
        }}
      />
    </SurveyBlockLayout>
  );
}
