import React from "react";

import { Typography, TypographyProps } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { useSurveyTheme } from "../../providers/surveyTheme";
import { generateAnswerTextStyle } from "../../utils/styles/generateAnswerTextStyle";
import { LiveSurveyThemeDocument } from "../../types/SurveyTheme";

type LiveSurveyCaptionTextProps = TypographyProps & {
  sx?: TypographyProps["sx"];
  surveyTheme: LiveSurveyThemeDocument | null;
  children: React.ReactNode;
};

export default function LiveSurveyCaptionText({
  surveyTheme,
  sx,
  children,
  ...otherProps
}: LiveSurveyCaptionTextProps) {
  const theme = useTheme();

  return (
    <Typography
      variant="caption"
      sx={{
        ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
        ...(surveyTheme && {
          // We are using this override certain aspects genrated by
          // answer text style
          fontSize: theme.typography.caption.fontSize,
          fontWeight: theme.typography.caption.fontWeight,
        }),
      }}
      {...otherProps}
    >
      {children}
    </Typography>
  );
}
