import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Box, Button, ButtonBase, IconButton } from "@mui/material";
import { useTheme, alpha, lighten } from "@mui/material/styles";
import { hexToRGBA } from "../../utils/hexToRGBA";
import {
  DeleteOutlineOutlined,
  EditOutlined,
  PhotoSizeSelectLargeOutlined,
} from "@mui/icons-material";
import { useField } from "formik";
import { ImageChoiceValue } from "../../types/Blocks";
import ControlledTextField from "../Inputs/ControlledTextField";
import { LiveSurveyThemeDocument } from "../../types/SurveyTheme";
import { generateAnswerTextStyle } from "../../utils/styles/generateAnswerTextStyle";
import { generateImageChoiceBoxStyle } from "../../utils/styles/generateImageChoiceBoxStyle";

type SortableImageChoiceCardProps = {
  name: string;
  id: string;
  uploadImageClickHandler: () => void;
  deleteImageChoiceClickHandler: () => void;
  imageUrl?: string;
  surveyTheme: LiveSurveyThemeDocument | null;
};

export default function SortableImageChoiceCard({
  name,
  id,
  imageUrl,
  uploadImageClickHandler,
  deleteImageChoiceClickHandler,
  surveyTheme,
}: SortableImageChoiceCardProps) {
  const [field] = useField<ImageChoiceValue>(name);
  const theme = useTheme();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: id });

  return (
    <ButtonBase
      ref={setNodeRef}
      sx={{
        // display: "flex",
        // flexDirection: "column",
        width: "25%",
        minHeight: 200,
        px: 1,
        mb: 2,
        ":hover .edit-image-choice-buttons": {
          display: "flex",
        },
      }}
      {...listeners}
      {...attributes}
      disableRipple
    >
      <Box
        sx={{
          transform: CSS.Transform.toString(transform),
          transition,
          zIndex: isDragging ? "100" : "auto",
          opacity: isDragging ? 0.3 : 1,
          ...generateImageChoiceBoxStyle(surveyTheme, theme),
        }}
      >
        <Box
          className="edit-image-choice-buttons"
          sx={{
            position: "absolute",
            top: 3,
            right: 3,
            display: "none",
          }}
        >
          {field.value.imageUrl && (
            <IconButton
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                uploadImageClickHandler();
              }}
            >
              <EditOutlined color="primary" />
            </IconButton>
          )}
          <IconButton size="small">
            <DeleteOutlineOutlined
              color="error"
              onClick={(event) => {
                event.stopPropagation();
                deleteImageChoiceClickHandler();
              }}
            />
          </IconButton>
        </Box>

        {field.value.imageUrl && (
          <Box
            sx={{
              height: 175,
              display: "flex",
            }}
          >
            <img
              style={{
                display: "block",
                margin: "auto",
                width: "100%",
                // height: "100%",
              }}
              src={imageUrl}
              alt="Uploaded Choice "
            />
          </Box>
        )}

        {!field.value.imageUrl && (
          <Box
            sx={{
              height: 175,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <PhotoSizeSelectLargeOutlined
                color="primary"
                sx={{
                  mb: 1,
                  ...(surveyTheme && {
                    color: surveyTheme.colorSettings.answerTextColor,
                  }),
                }}
                fontSize="large"
              />
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  uploadImageClickHandler();
                }}
                sx={{
                  ...(surveyTheme && {
                    color: surveyTheme.colorSettings.answerTextColor,
                    "&:hover": {
                      backgroundColor: alpha(
                        lighten(
                          surveyTheme.colorSettings.answerTextColor,
                          0.25
                        ),
                        0.15
                      ),
                    },
                  }),
                }}
              >
                Upload Image
              </Button>
            </Box>
          </Box>
        )}
        <ControlledTextField
          sx={{
            mt: 3,
          }}
          name={`${name}.imageCaption`}
          id={`${name}.imageCaption`}
          variant="standard"
          size="small"
          multiline
          required
          placeholder="Add caption"
          autoComplete="off"
          InputProps={{
            disableUnderline: true,
          }}
          inputProps={{
            style: {
              ...theme.typography.body1,
              ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
              ...(surveyTheme && {
                fontSize: theme.typography.body1.fontSize,
                fontWeight: theme.typography.body1.fontWeight,
              }),
              ...(surveyTheme && {
                textAlign: surveyTheme.contentTextAlignment,
              }),
            },
          }}
        />
      </Box>
    </ButtonBase>
  );
}
