import React from "react";

import { Rating } from "@mui/material";

import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

import { useField } from "formik";

import { StarRatingBlockBlockFields } from "../../../../types/Blocks";
import { STAR_RATING_ICON_SHAPES } from "../../../../data/BlockFields";
import SurveyBlockLayout from "../../../SurveyComponents/SurveyBlockLayout";
import SurveyQuestionBlockHeader from "../../../SurveyComponents/SurveyQuestionBlockHeader";

interface IStarRatingBlockProps {
  // formik: FormikContextType<IFormCanvasBlocksFields>;
  name: string;
  // index: number;
  pageIndex: number;
  blockIndex: number;
  settingsHandler: () => void;
  deleteHandler: () => void;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  settingsPanelActive: boolean;
}

export interface IStarRatingBlockFormFields {
  question: string;
  isQuestionRequired: boolean;
  requiredText: string;
}

export default function StarRatingBlock({
  // formik,
  name,
  // index,
  pageIndex,
  blockIndex,
  deleteHandler,
  settingsHandler,
  dragHandleProps,
  settingsPanelActive,
}: IStarRatingBlockProps) {
  const [field] = useField<StarRatingBlockBlockFields>(name);

  return (
    <SurveyBlockLayout
      name={name}
      settingsHandler={settingsHandler}
      deleteHandler={deleteHandler}
      settingsPanelActive={settingsPanelActive}
      dragHandleProps={dragHandleProps}
    >
      <SurveyQuestionBlockHeader name={name} />

      <Rating
        // TODO - Fix the type of the scale value that is set. Make sure it is a number when set
        // @ts-ignore
        value={Math.floor(parseInt(field.value.scale) / 2)}
        // @ts-ignore
        max={parseInt(field.value.scale)}
        icon={React.cloneElement(
          STAR_RATING_ICON_SHAPES[field.value.icon].icon,
          {
            sx: {
              fontSize: "40px",
              color: field.value.color,
            },
          }
        )}
        emptyIcon={React.cloneElement(
          STAR_RATING_ICON_SHAPES[field.value.icon].emptyIcon,
          {
            sx: {
              fontSize: "40px",
              color: field.value.color,
            },
          }
        )}
      />
    </SurveyBlockLayout>
  );
}
