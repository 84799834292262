import React, { useState } from "react";

import {
  Card,
  CardContent,
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Chip,
} from "@mui/material";

import { formatDistanceToNow } from "date-fns";

import {
  CheckCircleOutline,
  ChecklistOutlined,
  DeleteOutlineOutlined,
  EditNoteOutlined,
  EditOutlined,
  MoreVert,
  ShareOutlined,
} from "@mui/icons-material";

type FormListCardProps = {
  formTitle: string;
  isFormPublished: boolean;
  updatedAt?: Date | null;
  numOfQuestions: number;
  numOfResponses: number;
  editClickHandler: () => void;
  deleteFormHandler: () => void;
  copyPublishedLinkHandler: () => void;
  viewResponsesHandler: () => void;
};

export default function FormListCard({
  formTitle,
  isFormPublished,
  updatedAt,
  numOfResponses,
  numOfQuestions,
  editClickHandler,
  deleteFormHandler,
  copyPublishedLinkHandler,
  viewResponsesHandler,
}: FormListCardProps) {
  const [anchorElFormOptions, setAnchorElFormOptions] =
    useState<null | HTMLElement>(null);

  const handleOpenFormCardMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElFormOptions(event.currentTarget);
  };

  const handleCloseFormCardMenu = () => {
    setAnchorElFormOptions(null);
  };

  return (
    <Card
      sx={{
        mb: 2,
      }}
    >
      <CardContent>
        <Box sx={{ display: "flex" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  // height: "100%",
                  pr: {
                    xs: 0,
                    md: 2,
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                    }}
                  >
                    <Typography variant="h6" sx={{ mr: 1 }}>
                      {formTitle}{" "}
                      {isFormPublished ? (
                        <Chip
                          icon={<CheckCircleOutline fontSize="small" />}
                          label="Published"
                          color="primary"
                          size="small"
                        />
                      ) : (
                        <Chip
                          icon={<EditNoteOutlined fontSize="small" />}
                          label="Draft"
                          color="secondary"
                          size="small"
                        />
                      )}
                    </Typography>
                  </Box>
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 1,
                      display: {
                        md: "none",
                      },
                    }}
                  >
                    Updated{" "}
                    {updatedAt
                      ? formatDistanceToNow(updatedAt, {
                          addSuffix: true,
                        })
                      : "#NA"}
                  </Typography>
                </Box>

                <Button
                  onClick={editClickHandler}
                  startIcon={<EditOutlined />}
                  sx={{
                    display: {
                      xs: "none",
                      md: "flex",
                    },
                  }}
                >
                  Edit
                </Button>
                <IconButton
                  onClick={handleOpenFormCardMenu}
                  sx={{
                    display: {
                      xs: "block",
                      md: "none",
                    },
                  }}
                >
                  <MoreVert color="primary" />
                </IconButton>
              </Box>
            </Grid>
            <Grid
              item
              md={2}
              sx={{
                display: {
                  xs: "none",
                  md: "block",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    mb: 0.5,
                  }}
                  variant="caption"
                >
                  Updated
                </Typography>
                <Typography variant="body1">
                  {updatedAt
                    ? formatDistanceToNow(updatedAt, {
                        addSuffix: true,
                      })
                    : "#NA"}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    mb: 0.5,
                  }}
                  variant="caption"
                >
                  Questions
                </Typography>
                <Typography variant="body1">{numOfQuestions}</Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    mb: 0.5,
                  }}
                  variant="caption"
                >
                  Responses
                </Typography>
                <Typography variant="body1">{numOfResponses}</Typography>
              </Box>
            </Grid>
          </Grid>

          <Box>
            <IconButton
              onClick={handleOpenFormCardMenu}
              sx={{
                display: {
                  xs: "none",
                  md: "block",
                },
              }}
            >
              <MoreVert color="primary" />
            </IconButton>
          </Box>

          <Menu
            sx={{ mt: "45px" }}
            anchorEl={anchorElFormOptions}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElFormOptions)}
            onClose={handleCloseFormCardMenu}
          >
            <MenuItem
              onClick={() => {
                editClickHandler();
                handleCloseFormCardMenu();
              }}
              sx={{
                display: {
                  md: "none",
                },
              }}
            >
              <ListItemIcon>
                <EditOutlined color="primary" fontSize="small" />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>

            {isFormPublished && (
              <MenuItem
                onClick={() => {
                  copyPublishedLinkHandler();
                  handleCloseFormCardMenu();
                }}
              >
                <ListItemIcon>
                  <ShareOutlined color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText>Share Form</ListItemText>
              </MenuItem>
            )}

            {isFormPublished && (
              <MenuItem
                onClick={() => {
                  viewResponsesHandler();
                  handleCloseFormCardMenu();
                }}
              >
                <ListItemIcon>
                  <ChecklistOutlined color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText>View Responses</ListItemText>
              </MenuItem>
            )}

            <MenuItem
              onClick={() => {
                deleteFormHandler();
                handleCloseFormCardMenu();
              }}
            >
              <ListItemIcon>
                <DeleteOutlineOutlined color="error" fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      </CardContent>
    </Card>
  );
}
