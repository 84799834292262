import { BackgroundSettings } from "./Blocks";

export type SurveyThemeColors = {
  questionTextColor: string;
  answerTextColor: string;
  buttonBackgroundColor: string;
  buttonTextColor: string;
};

export enum FieldVariant {
  OUTLINED = "outlined",
  UNDERLINE = "standard",
}

export enum VerticalLogoPositions {
  TOP = "top",
  BOTTOM = "bottom",
}

export enum HorizontalLogoPosition {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}

export enum LogoRelativeToOptions {
  CONTENT = "content",
  PAGE = "page",
}

export type SurveyThemeLogoSettings = {
  hasSurveyLogo: boolean;
  logoUrl: string;
  relativeTo: LogoRelativeToOptions;
  verticalPosition: VerticalLogoPositions;
  horizontalPosition: HorizontalLogoPosition;
  logoAltText: string;
};

export enum FontWeights {
  LIGHT = "light",
  REGULAR = "regular",
  MEDIUM = "medium",
  BOLD = "bold",
}

export type FontSettings = {
  fontFamily: string;
  fontSize: number;
  fontWeight: FontWeights;
};

export type GranularFontSetting = {
  questionText: FontSettings;
  answerText: FontSettings;
};

export enum ContentSectionPlacements {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}

export enum TextAlignmentPositions {
  LEFT = "left",
  CENTER = "center",
  END = "end",
}

export enum VerticalAlignmentPositions {
  TOP = "top",
  CENTER = "center",
  BOTTOM = "bottom",
}

export interface SurveyThemeFormFields {
  title: string;
  colorSettings: SurveyThemeColors;
  logoSettings: SurveyThemeLogoSettings; // DONE
  mainBackgroundSettings: {
    hasCustomBackground: boolean;
    customBackgroundSettings: BackgroundSettings;
  };
  contentBackgroundSettings: {
    hasCustomBackground: boolean;
    customBackgroundSettings: BackgroundSettings;
  };
  globalFont?: string;
  hasGranularFontSettings: boolean;
  granularFontSettings: GranularFontSetting;
  contentSectionPlacement: ContentSectionPlacements;
  contentTextAlignment: TextAlignmentPositions;
  contentVerticalAlignment: VerticalAlignmentPositions;
  fieldVariant: FieldVariant;
}

// Define the interface for the User document
export interface LiveSurveyThemeDocument {
  _id: string;
  title: string;
  colorSettings: SurveyThemeColors;
  logoSettings: SurveyThemeLogoSettings; // DONE
  mainBackgroundSettings: {
    hasCustomBackground: boolean;
    customBackgroundSettings: BackgroundSettings;
  };
  contentBackgroundSettings: {
    hasCustomBackground: boolean;
    customBackgroundSettings: BackgroundSettings;
  };
  globalFont?: string;
  hasGranularFontSettings: boolean;
  granularFontSettings: GranularFontSetting;
  contentSectionPlacement: ContentSectionPlacements;
  contentTextAlignment: TextAlignmentPositions;
  contentVerticalAlignment: VerticalAlignmentPositions;
  fieldVariant: FieldVariant;
}
