import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { AxiosError } from "axios";
import { Alert, Box, CircularProgress } from "@mui/material";

import { RESPONSE_URL, SURVEY_PUBLISHED_URL } from "../data/constants";
import Api from "../api/api";

import {
  FormSubBlockTypes,
  FormSubItemTypes,
  LiveFormSubBlockDocument,
  LiveFormSubItemDocument,
  LivePageDocument,
  LiveQuestionDocument,
  QuestionTypes,
  RankingValue,
} from "../types/Blocks";
import { Form, Formik, FormikProps } from "formik";
import * as yup from "yup";
import { LiveActionPathDocument } from "../types/Actions";
import { LiveFormViewMode } from "../pages/LiveForm";
import { useSurveyTheme, useUpdateSurveyTheme } from "../providers/surveyTheme";
import FormScreenLayout from "./Layout/FormScreenLayout";
import SurveyButton from "./SurveyComponents/SurveyButton";
import LiveTextFieldBlock from "./Blocks/FormBlocks/TextFieldBlock/LiveTextFieldBlock";
import LiveCheckboxesBlock from "./Blocks/FormBlocks/CheckboxesBlock/LiveCheckboxesBlock";
import LiveStarRatingBlock from "./Blocks/FormBlocks/StarRatingBlock/LiveStarRatingBlock";
import LiveHeadingBlock from "./Blocks/FormBlocks/HeadingBlock/LiveHeadingBlock";
import LiveParagraphBlock from "./Blocks/FormBlocks/ParagraphBlock/LiveParagraphBlock";
import LiveYesNoBlock from "./Blocks/FormBlocks/YesNoBlock/LiveYesNoBlock";
import LiveScaleBlock from "./Blocks/FormBlocks/ScaleBlock/LiveScaleBlock";
import LiveSliderBlock from "./Blocks/FormBlocks/SliderBlock/LiveSliderBlock";
import LiveRankingBlock from "./Blocks/FormBlocks/RankingBlock/LiveRankingBlock";
import LiveImageChoiceBlock from "./Blocks/FormBlocks/ImageChoiceBlock/LiveImageChoiceBlock";
import LiveDateBlock from "./Blocks/FormBlocks/DateBlock/LiveDateBlock";
import LiveRadioGroupBlock from "./Blocks/FormBlocks/RadioGroupBlock/LiveRadioGroupBlock";
import LiveDropdownBlock from "./Blocks/FormBlocks/DropdownBlock/LiveDropdownBlock";
import LiveEmailBlock from "./Blocks/FormBlocks/EmailBlock/LiveEmailBlock";
import LivePhoneBlock from "./Blocks/FormBlocks/PhoneBlock/LivePhoneBlock";
import LiveLinkBlock from "./Blocks/FormBlocks/LinkBlock/LiveLinkBlock";
import LiveAddressBlock from "./Blocks/FormBlocks/AddressBlock/LiveAddressBlock";

type LiveFormPageProps = {
  updateActionPaths: (actionPaths: LiveActionPathDocument[]) => void;
  updateViewMode: (viewMode: LiveFormViewMode) => void;
};

export type FormValues = {
  [key: string]: any;
};

export default function LiveFormPage({
  updateActionPaths,
  updateViewMode,
}: LiveFormPageProps) {
  const { identifier } = useParams();

  const updateSurveyTheme = useUpdateSurveyTheme();
  const surveyTheme = useSurveyTheme();

  const [loading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [formPage, setFormPage] = useState<LivePageDocument>();

  const [numOfPages, setNumOfPages] = useState<number>(1);

  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [initialFormValues, setInitialFormValues] = useState<FormValues>();
  const [validationSchema, setValidationSchema] = useState(
    yup.object().shape({})
  );

  const [submitResponseLoading, setSubmitResponseLoading] = useState(false);
  const [submitResponseError, setSubmitResponseError] = useState("");

  const [isResponseCompleted, setIsResponseCompleted] = useState(false);

  const createInitialFormValues = (pageData: LivePageDocument) => {
    return pageData.subItems.reduce(
      (result, currentSubItem: LiveFormSubItemDocument) => {
        return {
          ...result,
          ...(currentSubItem.subItemType === FormSubItemTypes.QUESTION &&
            ((currentSubItem as LiveQuestionDocument).questionType ===
              QuestionTypes.TEXT ||
              (currentSubItem as LiveQuestionDocument).questionType ===
                QuestionTypes.EMAIL ||
              (currentSubItem as LiveQuestionDocument).questionType ===
                QuestionTypes.LINK ||
              (currentSubItem as LiveQuestionDocument).questionType ===
                QuestionTypes.PHONE ||
              (currentSubItem as LiveQuestionDocument).questionType ===
                QuestionTypes.RADIO_GROUP ||
              (currentSubItem as LiveQuestionDocument).questionType ===
                QuestionTypes.DROPDOWN) && {
              [currentSubItem._id]: "",
            }),

          ...(currentSubItem.subItemType === FormSubItemTypes.QUESTION &&
            (currentSubItem as LiveQuestionDocument).questionType ===
              QuestionTypes.CHECKBOXES && {
              [currentSubItem._id]: [],
            }),

          ...(currentSubItem.subItemType === FormSubItemTypes.QUESTION &&
            (currentSubItem as LiveQuestionDocument).questionType ===
              QuestionTypes.STAR_RATING && {
              [currentSubItem._id]: 0,
            }),

          ...(currentSubItem.subItemType === FormSubItemTypes.QUESTION &&
            (currentSubItem as LiveQuestionDocument).questionType ===
              QuestionTypes.RANKING && {
              [currentSubItem._id]: (
                currentSubItem as LiveQuestionDocument
              ).rankingValues.map((rankingValue: RankingValue) => {
                return rankingValue.value;
              }),
            }),

          ...(currentSubItem.subItemType === FormSubItemTypes.QUESTION &&
            (currentSubItem as LiveQuestionDocument).questionType ===
              QuestionTypes.IMAGE_CHOICE && {
              [currentSubItem._id]: [],
            }),

          ...(currentSubItem.subItemType === FormSubItemTypes.QUESTION &&
            (currentSubItem as LiveQuestionDocument).questionType ===
              QuestionTypes.SLIDER && {
              [currentSubItem._id]: 0,
            }),

          ...(currentSubItem.subItemType === FormSubItemTypes.QUESTION &&
            (currentSubItem as LiveQuestionDocument).questionType ===
              QuestionTypes.DATE && {
              [currentSubItem._id]: null,
            }),

          ...(currentSubItem.subItemType === FormSubItemTypes.QUESTION &&
            (currentSubItem as LiveQuestionDocument).questionType ===
              QuestionTypes.YESNO && {
              [currentSubItem._id]: "",
            }),

          ...(currentSubItem.subItemType === FormSubItemTypes.QUESTION &&
            (currentSubItem as LiveQuestionDocument).questionType ===
              QuestionTypes.ADDRESS && {
              [currentSubItem._id]: {
                addressLine1: "",
                addressLine2: "",
                addressTown: "",
                addressState: "",
                addressPostCode: "",
                addressCountry: "",
              },
            }),
        };
      },
      {}
    );
  };

  const createValidationSchema = (pageData: LivePageDocument) => {
    return yup.object().shape({
      ...pageData.subItems.reduce(
        (result, currentSubItem: LiveFormSubItemDocument) => {
          return {
            ...result,

            // TODO - Need to add relevant validation for all the different types of questions if needed
            ...(currentSubItem.subItemType === FormSubItemTypes.QUESTION &&
              (currentSubItem as LiveQuestionDocument).questionType ===
                QuestionTypes.CHECKBOXES && {
                [currentSubItem._id]: (currentSubItem as LiveQuestionDocument)
                  .isQuestionRequired
                  ? yup
                      .array()
                      .required(
                        (currentSubItem as LiveQuestionDocument).requiredText
                      )
                  : yup.array(),
              }),

            ...(currentSubItem.subItemType === FormSubItemTypes.QUESTION &&
              (currentSubItem as LiveQuestionDocument).questionType ===
                QuestionTypes.TEXT && {
                [currentSubItem._id]: (currentSubItem as LiveQuestionDocument)
                  .isQuestionRequired
                  ? yup
                      .string()
                      .required(
                        (currentSubItem as LiveQuestionDocument).requiredText
                      )
                  : yup.string(),
              }),

            ...(currentSubItem.subItemType === FormSubItemTypes.QUESTION &&
              (currentSubItem as LiveQuestionDocument).questionType ===
                QuestionTypes.STAR_RATING && {
                [currentSubItem._id]: (currentSubItem as LiveQuestionDocument)
                  .isQuestionRequired
                  ? yup
                      .number()
                      .required(
                        (currentSubItem as LiveQuestionDocument).requiredText
                      )
                  : yup.number(),
              }),

            ...(currentSubItem.subItemType === FormSubItemTypes.QUESTION &&
              (currentSubItem as LiveQuestionDocument).questionType ===
                QuestionTypes.EMAIL && {
                [currentSubItem._id]: (currentSubItem as LiveQuestionDocument)
                  .isQuestionRequired
                  ? yup
                      .string()
                      .email("Invalid email")
                      .required(
                        (currentSubItem as LiveQuestionDocument).requiredText
                      )
                  : yup.string().email("Invalid email"),
              }),

            ...(currentSubItem.subItemType === FormSubItemTypes.QUESTION &&
              (currentSubItem as LiveQuestionDocument).questionType ===
                QuestionTypes.PHONE && {
                [currentSubItem._id]: (currentSubItem as LiveQuestionDocument)
                  .isQuestionRequired
                  ? yup
                      .string()
                      .required(
                        (currentSubItem as LiveQuestionDocument).requiredText
                      )
                  : yup.string(),
              }),

            ...(currentSubItem.subItemType === FormSubItemTypes.QUESTION &&
              (currentSubItem as LiveQuestionDocument).questionType ===
                QuestionTypes.LINK && {
                [currentSubItem._id]: (currentSubItem as LiveQuestionDocument)
                  .isQuestionRequired
                  ? yup
                      .string()
                      .matches(
                        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                        "Invalid Url"
                      )
                      .required(
                        (currentSubItem as LiveQuestionDocument).requiredText
                      )
                  : yup
                      .string()
                      .matches(
                        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                        "Invalid Url"
                      ),
              }),

            ...(currentSubItem.subItemType === FormSubItemTypes.QUESTION &&
              (currentSubItem as LiveQuestionDocument).questionType ===
                QuestionTypes.ADDRESS && {
                [currentSubItem._id]: (currentSubItem as LiveQuestionDocument)
                  .isQuestionRequired
                  ? yup
                      .object()
                      .shape({
                        // TODO - ===============================
                        // TODO - Create a proper strategy for handling validation
                        // TODO - for the different fields to avoid duplication
                        // TODO - and allow for code re-use andm ore clenare code
                        // TODO - ===============================

                        addressLine1: (currentSubItem as LiveQuestionDocument)
                          .addressLine1.isRequired
                          ? yup
                              .string()
                              .required(
                                `${
                                  (currentSubItem as LiveQuestionDocument)
                                    .addressLine1.label ?? "Address Line 1"
                                } is reuqired.`
                              )
                          : yup.string(),
                        addressLine2: (currentSubItem as LiveQuestionDocument)
                          .addressLine2.isRequired
                          ? yup
                              .string()
                              .required(
                                `${
                                  (currentSubItem as LiveQuestionDocument)
                                    .addressLine2.label ?? "Address Line 2"
                                } is reuqired.`
                              )
                          : yup.string(),
                        addressTown: (currentSubItem as LiveQuestionDocument)
                          .addressTown.isRequired
                          ? yup
                              .string()
                              .required(
                                `${
                                  (currentSubItem as LiveQuestionDocument)
                                    .addressTown.label ?? "Town/ City"
                                } is reuqired.`
                              )
                          : yup.string(),
                        addressState: (currentSubItem as LiveQuestionDocument)
                          .addressState.isRequired
                          ? yup
                              .string()
                              .required(
                                `${
                                  (currentSubItem as LiveQuestionDocument)
                                    .addressState.label ?? "State/ County"
                                } is reuqired.`
                              )
                          : yup.string(),
                        addressPostCode: (
                          currentSubItem as LiveQuestionDocument
                        ).addressPostCode.isRequired
                          ? yup
                              .string()
                              .required(
                                `${
                                  (currentSubItem as LiveQuestionDocument)
                                    .addressPostCode.label ?? "ZIP/ Post Code"
                                } is reuqired.`
                              )
                          : yup.string(),
                        addressCountry: (currentSubItem as LiveQuestionDocument)
                          .addressCountry.isRequired
                          ? yup
                              .string()
                              .required(
                                `${
                                  (currentSubItem as LiveQuestionDocument)
                                    .addressCountry.label ?? "Country"
                                } is reuqired.`
                              )
                          : yup.string(),
                      })
                      .required(
                        (currentSubItem as LiveQuestionDocument).requiredText
                      )
                  : yup.object().shape({
                      addressLine1: (currentSubItem as LiveQuestionDocument)
                        .addressLine1.isRequired
                        ? yup
                            .string()
                            .required(
                              `${
                                (currentSubItem as LiveQuestionDocument)
                                  .addressLine1.label ?? "Address Line 1"
                              } is reuqired.`
                            )
                        : yup.string(),
                      addressLine2: (currentSubItem as LiveQuestionDocument)
                        .addressLine2.isRequired
                        ? yup
                            .string()
                            .required(
                              `${
                                (currentSubItem as LiveQuestionDocument)
                                  .addressLine2.label ?? "Address Line 2"
                              } is reuqired.`
                            )
                        : yup.string(),
                      addressTown: (currentSubItem as LiveQuestionDocument)
                        .addressTown.isRequired
                        ? yup
                            .string()
                            .required(
                              `${
                                (currentSubItem as LiveQuestionDocument)
                                  .addressTown.label ?? "Town/ City"
                              } is reuqired.`
                            )
                        : yup.string(),
                      addressState: (currentSubItem as LiveQuestionDocument)
                        .addressState.isRequired
                        ? yup
                            .string()
                            .required(
                              `${
                                (currentSubItem as LiveQuestionDocument)
                                  .addressState.label ?? "State/ County"
                              } is reuqired.`
                            )
                        : yup.string(),
                      addressPostCode: (currentSubItem as LiveQuestionDocument)
                        .addressPostCode.isRequired
                        ? yup
                            .string()
                            .required(
                              `${
                                (currentSubItem as LiveQuestionDocument)
                                  .addressPostCode.label ?? "ZIP/ Post Code"
                              } is reuqired.`
                            )
                        : yup.string(),
                      addressCountry: (currentSubItem as LiveQuestionDocument)
                        .addressCountry.isRequired
                        ? yup
                            .string()
                            .required(
                              `${
                                (currentSubItem as LiveQuestionDocument)
                                  .addressCountry.label ?? "Country"
                              } is reuqired.`
                            )
                        : yup.string(),
                    }),
              }),
          };
        },
        {}
      ),
    });
  };

  const getFormData = async () => {
    try {
      setIsLoading(true);
      setError("");

      // TODO - Need to handle if we need to show an action path if the form has alreadty been completed

      const response = await Api.get(`${SURVEY_PUBLISHED_URL}/${identifier}`, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });

      if (response.data.success.data.surveyPage) {
        setFormPage(response.data.success.data.surveyPage);
        setNumOfPages(response.data.success.data.numOfPages);

        if (response.data.success.data.surveyTheme) {
          updateSurveyTheme &&
            updateSurveyTheme(response.data.success.data.surveyTheme);
        }
        // TODO - Will need to confirm we have questions to show other wise we might neeed to show action paths or generic completion
        const INITIAL_FORM_VALUES = createInitialFormValues(
          response.data.success.data.surveyPage as LivePageDocument
        );

        const FORM_VALIDATION = createValidationSchema(
          response.data.success.data.surveyPage as LivePageDocument
        );

        setValidationSchema(FORM_VALIDATION);
        setInitialFormValues(INITIAL_FORM_VALUES);
      } else if (response.data.success.data?.actionPaths) {
        if (response.data.success.data.surveyTheme) {
          updateSurveyTheme &&
            updateSurveyTheme(response.data.success.data.surveyTheme);
        }

        updateActionPaths(response.data.success.data?.actionPaths);
        updateViewMode(LiveFormViewMode.ACTION_PATHS);
      } else {
        throw new Error();
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error instanceof AxiosError && !error?.response) {
        setError("No response from server.");
      } else if (error instanceof AxiosError && error?.response?.data) {
        // @ts-ignore
        if (error.response.data.error.message === "ALREADY RESPONDED") {
          updateViewMode(LiveFormViewMode.GENERIC_COMPLETED);
          setIsResponseCompleted(true);
        } else {
          // @ts-ignore
          setError(error.response.data.error.message);
        }
      } else {
        setError("Unable to get form data");
      }
    }
  };

  useEffect(() => {
    getFormData();
  }, []);

  const handleSubmit = async (values: FormValues) => {
    try {
      setSubmitResponseError("");
      setSubmitResponseLoading(true);

      const answersList = Object.entries(values).map(([id, answerValue]) => {
        return {
          question: id,
          answerValue: answerValue,
        };
      });

      const response = await Api.post(
        `${RESPONSE_URL}/${identifier}`,
        { currentPageId: formPage?._id, answers: answersList },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      if (response.data.success.data?.nextPage) {
        setFormPage(response.data.success.data.nextPage);

        const INITIAL_FORM_VALUES = createInitialFormValues(
          response.data.success.data.nextPage as LivePageDocument
        );

        const FORM_VALIDATION = createValidationSchema(
          response.data.success.data.nextPage as LivePageDocument
        );

        setValidationSchema(FORM_VALIDATION);

        setCurrentPageIndex(response.data.success.data.nextPageIndex);

        setInitialFormValues(() => {
          return INITIAL_FORM_VALUES;
        });
      } else if (response.data.success.data?.actionPaths) {
        updateActionPaths(response.data.success.data?.actionPaths);
        updateViewMode(LiveFormViewMode.ACTION_PATHS);
      } else {
        updateViewMode(LiveFormViewMode.GENERIC_COMPLETED);
        setIsResponseCompleted(true);
      }

      setSubmitResponseLoading(false);
    } catch (error) {
      setSubmitResponseLoading(false);
      // @ts-ignore
      if (!error?.response) {
        setSubmitResponseError("No response from server.");
        // @ts-ignore
      } else if (error?.response) {
        // @ts-ignore
        setSubmitResponseError(error.response.data.error.message);
      } else {
        setSubmitResponseError("Unable to proceed.");
      }
    }
  };

  return (
    <FormScreenLayout
      sx={{
        height: "100vh",
      }}
      contentBoxStyling={{
        py: 7,
        px: {
          xs: 3,
          sm: 5,
          md: 10,
          lg: 15,
        },
        // display: "flex",
        // alignItems: "center",
      }}
      surveyTheme={surveyTheme}
    >
      {error && (
        <Alert sx={{ my: 3 }} severity="error">
          ERROR ERROR {error}
        </Alert>
      )}

      {isResponseCompleted &&
        !submitResponseLoading &&
        !submitResponseError && (
          <Alert sx={{ my: 3 }} severity="success">
            Thank you for filling out this form!
          </Alert>
        )}

      {loading && !error && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 5,
          }}
        >
          <CircularProgress></CircularProgress>
        </Box>
      )}

      {formPage &&
        initialFormValues &&
        identifier &&
        !isResponseCompleted &&
        !loading &&
        !error && (
          <Formik
            // initialValues={INITIAL_FORM_STATE as IFormCanvasBlocksFields}
            initialValues={{ ...initialFormValues } as FormValues}
            onSubmit={async (values: FormValues, formikHelpers) => {
              await handleSubmit(values);
            }}
            validationSchema={validationSchema}
            enableReinitialize
            validateOnChange
            validateOnBlur
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
              handleChange,
              handleBlur,
            }: FormikProps<FormValues>) => {
              return (
                <Form>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {formPage.subItems.map(
                      (subItem: LiveFormSubItemDocument) => {
                        if (
                          subItem.subItemType === FormSubItemTypes.QUESTION &&
                          (subItem as LiveQuestionDocument).questionType ===
                            QuestionTypes.TEXT
                        ) {
                          return (
                            <LiveTextFieldBlock
                              key={subItem._id}
                              name={subItem._id}
                              questionText={
                                (subItem as LiveQuestionDocument).question
                              }
                              questionDescription={
                                (subItem as LiveQuestionDocument)
                                  .questionDescription ?? ""
                              }
                              isQuestionRequired={
                                (subItem as LiveQuestionDocument)
                                  .isQuestionRequired
                              }
                              surveyTheme={surveyTheme}
                            />
                          );
                        } else if (
                          subItem.subItemType === FormSubItemTypes.QUESTION &&
                          (subItem as LiveQuestionDocument).questionType ===
                            QuestionTypes.CHECKBOXES
                        ) {
                          return (
                            <LiveCheckboxesBlock
                              key={subItem._id}
                              name={subItem._id}
                              questionText={
                                (subItem as LiveQuestionDocument).question
                              }
                              questionDescription={
                                (subItem as LiveQuestionDocument)
                                  .questionDescription ?? ""
                              }
                              isQuestionRequired={
                                (subItem as LiveQuestionDocument)
                                  .isQuestionRequired
                              }
                              surveyTheme={surveyTheme}
                              checkboxes={
                                (subItem as LiveQuestionDocument).checkboxes
                              }
                            />
                          );
                        } else if (
                          subItem.subItemType === FormSubItemTypes.QUESTION &&
                          (subItem as LiveQuestionDocument).questionType ===
                            QuestionTypes.STAR_RATING
                        ) {
                          return (
                            <LiveStarRatingBlock
                              key={subItem._id}
                              name={subItem._id}
                              questionText={
                                (subItem as LiveQuestionDocument).question
                              }
                              questionDescription={
                                (subItem as LiveQuestionDocument)
                                  .questionDescription ?? ""
                              }
                              isQuestionRequired={
                                (subItem as LiveQuestionDocument)
                                  .isQuestionRequired
                              }
                              surveyTheme={surveyTheme}
                              // TODO - Fix this as NOTE : Using ts ignore can be dangerouse as errors will go unnotices.
                              // @ts-ignore
                              starRatingScale={
                                (subItem as LiveQuestionDocument).scale
                              }
                              starRatingIcon={
                                (subItem as LiveQuestionDocument).icon
                              }
                              starRatingColor={
                                (subItem as LiveQuestionDocument).color
                              }
                            />
                          );
                        } else if (
                          subItem.subItemType === FormSubItemTypes.QUESTION &&
                          (subItem as LiveQuestionDocument).questionType ===
                            QuestionTypes.YESNO
                        ) {
                          return (
                            <LiveYesNoBlock
                              key={subItem._id}
                              name={subItem._id}
                              questionText={
                                (subItem as LiveQuestionDocument).question
                              }
                              questionDescription={
                                (subItem as LiveQuestionDocument)
                                  .questionDescription ?? ""
                              }
                              isQuestionRequired={
                                (subItem as LiveQuestionDocument)
                                  .isQuestionRequired
                              }
                              surveyTheme={surveyTheme}
                            />
                          );
                        } else if (
                          subItem.subItemType === FormSubItemTypes.QUESTION &&
                          (subItem as LiveQuestionDocument).questionType ===
                            QuestionTypes.SCALE
                        ) {
                          return (
                            <LiveScaleBlock
                              key={subItem._id}
                              name={subItem._id}
                              questionText={
                                (subItem as LiveQuestionDocument).question
                              }
                              questionDescription={
                                (subItem as LiveQuestionDocument)
                                  .questionDescription ?? ""
                              }
                              isQuestionRequired={
                                (subItem as LiveQuestionDocument)
                                  .isQuestionRequired
                              }
                              surveyTheme={surveyTheme}
                              fromNumber={
                                (subItem as LiveQuestionDocument).fromNumber
                              }
                              toNumber={
                                (subItem as LiveQuestionDocument).toNumber
                              }
                              minLabel={
                                (subItem as LiveQuestionDocument).minLabel ?? ""
                              }
                              midLabel={
                                (subItem as LiveQuestionDocument).midLabel ?? ""
                              }
                              maxLabel={
                                (subItem as LiveQuestionDocument).maxLabel ?? ""
                              }
                            />
                          );
                        } else if (
                          subItem.subItemType === FormSubItemTypes.QUESTION &&
                          (subItem as LiveQuestionDocument).questionType ===
                            QuestionTypes.SLIDER
                        ) {
                          return (
                            <LiveSliderBlock
                              key={subItem._id}
                              name={subItem._id}
                              questionText={
                                (subItem as LiveQuestionDocument).question
                              }
                              questionDescription={
                                (subItem as LiveQuestionDocument)
                                  .questionDescription ?? ""
                              }
                              isQuestionRequired={
                                (subItem as LiveQuestionDocument)
                                  .isQuestionRequired
                              }
                              surveyTheme={surveyTheme}
                              sliderMinNumber={
                                (subItem as LiveQuestionDocument)
                                  .sliderMinNumber
                              }
                              sliderMaxNumber={
                                (subItem as LiveQuestionDocument)
                                  .sliderMaxNumber
                              }
                              sliderSteps={
                                (subItem as LiveQuestionDocument).sliderStep ??
                                1
                              }
                              sliderHasCustomLabels={
                                (subItem as LiveQuestionDocument)
                                  .sliderHasCustomLabels
                              }
                              sliderMinLabel={
                                (subItem as LiveQuestionDocument).minLabel ?? ""
                              }
                              sliderMidLabel={
                                (subItem as LiveQuestionDocument)
                                  .sliderMiddleLabel ?? ""
                              }
                              sliderMaxLabel={
                                (subItem as LiveQuestionDocument)
                                  .sliderMaxLabel ?? ""
                              }
                            />
                          );
                        } else if (
                          subItem.subItemType === FormSubItemTypes.QUESTION &&
                          (subItem as LiveQuestionDocument).questionType ===
                            QuestionTypes.RANKING
                        ) {
                          return (
                            <LiveRankingBlock
                              key={subItem._id}
                              name={subItem._id}
                              questionText={
                                (subItem as LiveQuestionDocument).question
                              }
                              questionDescription={
                                (subItem as LiveQuestionDocument)
                                  .questionDescription ?? ""
                              }
                              isQuestionRequired={
                                (subItem as LiveQuestionDocument)
                                  .isQuestionRequired
                              }
                              surveyTheme={surveyTheme}
                              rankingValues={
                                (subItem as LiveQuestionDocument).rankingValues
                              }
                            />
                          );
                        } else if (
                          subItem.subItemType === FormSubItemTypes.QUESTION &&
                          (subItem as LiveQuestionDocument).questionType ===
                            QuestionTypes.IMAGE_CHOICE
                        ) {
                          return (
                            <LiveImageChoiceBlock
                              key={subItem._id}
                              name={subItem._id}
                              questionText={
                                (subItem as LiveQuestionDocument).question
                              }
                              questionDescription={
                                (subItem as LiveQuestionDocument)
                                  .questionDescription ?? ""
                              }
                              isQuestionRequired={
                                (subItem as LiveQuestionDocument)
                                  .isQuestionRequired
                              }
                              surveyTheme={surveyTheme}
                              imageChoiceValues={
                                (subItem as LiveQuestionDocument)
                                  .imageChoiceValues
                              }
                              allowMultipleChoices={
                                (subItem as LiveQuestionDocument)
                                  .imageChoiceHasMultipleChoices
                              }
                            />
                          );
                        } else if (
                          subItem.subItemType === FormSubItemTypes.QUESTION &&
                          (subItem as LiveQuestionDocument).questionType ===
                            QuestionTypes.DATE
                        ) {
                          return (
                            <LiveDateBlock
                              key={subItem._id}
                              name={subItem._id}
                              questionText={
                                (subItem as LiveQuestionDocument).question
                              }
                              questionDescription={
                                (subItem as LiveQuestionDocument)
                                  .questionDescription ?? ""
                              }
                              isQuestionRequired={
                                (subItem as LiveQuestionDocument)
                                  .isQuestionRequired
                              }
                              surveyTheme={surveyTheme}
                              dateFormat={
                                (subItem as LiveQuestionDocument).dateFormat
                              }
                              dateSeperator={
                                (subItem as LiveQuestionDocument).dateSeperator
                              }
                            />
                          );
                        } else if (
                          subItem.subItemType === FormSubItemTypes.QUESTION &&
                          (subItem as LiveQuestionDocument).questionType ===
                            QuestionTypes.RADIO_GROUP
                        ) {
                          return (
                            <LiveRadioGroupBlock
                              key={subItem._id}
                              name={subItem._id}
                              questionText={
                                (subItem as LiveQuestionDocument).question
                              }
                              questionDescription={
                                (subItem as LiveQuestionDocument)
                                  .questionDescription ?? ""
                              }
                              isQuestionRequired={
                                (subItem as LiveQuestionDocument)
                                  .isQuestionRequired
                              }
                              surveyTheme={surveyTheme}
                              radioGroupValues={
                                (subItem as LiveQuestionDocument)
                                  .radioGroupValues
                              }
                            />
                          );
                        } else if (
                          subItem.subItemType === FormSubItemTypes.QUESTION &&
                          (subItem as LiveQuestionDocument).questionType ===
                            QuestionTypes.DROPDOWN
                        ) {
                          return (
                            <LiveDropdownBlock
                              key={subItem._id}
                              name={subItem._id}
                              questionText={
                                (subItem as LiveQuestionDocument).question
                              }
                              questionDescription={
                                (subItem as LiveQuestionDocument)
                                  .questionDescription ?? ""
                              }
                              isQuestionRequired={
                                (subItem as LiveQuestionDocument)
                                  .isQuestionRequired
                              }
                              surveyTheme={surveyTheme}
                              dropdownValues={
                                (subItem as LiveQuestionDocument).dropdownValues
                              }
                            />
                          );
                        } else if (
                          subItem.subItemType === FormSubItemTypes.QUESTION &&
                          (subItem as LiveQuestionDocument).questionType ===
                            QuestionTypes.EMAIL
                        ) {
                          return (
                            <LiveEmailBlock
                              key={subItem._id}
                              name={subItem._id}
                              questionText={
                                (subItem as LiveQuestionDocument).question
                              }
                              questionDescription={
                                (subItem as LiveQuestionDocument)
                                  .questionDescription ?? ""
                              }
                              isQuestionRequired={
                                (subItem as LiveQuestionDocument)
                                  .isQuestionRequired
                              }
                              surveyTheme={surveyTheme}
                            />
                          );
                        } else if (
                          subItem.subItemType === FormSubItemTypes.QUESTION &&
                          (subItem as LiveQuestionDocument).questionType ===
                            QuestionTypes.PHONE
                        ) {
                          return (
                            <LivePhoneBlock
                              key={subItem._id}
                              name={subItem._id}
                              questionText={
                                (subItem as LiveQuestionDocument).question
                              }
                              questionDescription={
                                (subItem as LiveQuestionDocument)
                                  .questionDescription ?? ""
                              }
                              isQuestionRequired={
                                (subItem as LiveQuestionDocument)
                                  .isQuestionRequired
                              }
                              surveyTheme={surveyTheme}
                              defaultCountry={
                                (subItem as LiveQuestionDocument)
                                  .phoneDefaultCountry
                              }
                            />
                          );
                        } else if (
                          subItem.subItemType === FormSubItemTypes.QUESTION &&
                          (subItem as LiveQuestionDocument).questionType ===
                            QuestionTypes.LINK
                        ) {
                          return (
                            <LiveLinkBlock
                              key={subItem._id}
                              name={subItem._id}
                              questionText={
                                (subItem as LiveQuestionDocument).question
                              }
                              questionDescription={
                                (subItem as LiveQuestionDocument)
                                  .questionDescription ?? ""
                              }
                              isQuestionRequired={
                                (subItem as LiveQuestionDocument)
                                  .isQuestionRequired
                              }
                              surveyTheme={surveyTheme}
                            />
                          );
                        } else if (
                          subItem.subItemType === FormSubItemTypes.QUESTION &&
                          (subItem as LiveQuestionDocument).questionType ===
                            QuestionTypes.ADDRESS
                        ) {
                          return (
                            <LiveAddressBlock
                              key={subItem._id}
                              name={subItem._id}
                              questionText={
                                (subItem as LiveQuestionDocument).question
                              }
                              questionDescription={
                                (subItem as LiveQuestionDocument)
                                  .questionDescription ?? ""
                              }
                              isQuestionRequired={
                                (subItem as LiveQuestionDocument)
                                  .isQuestionRequired
                              }
                              surveyTheme={surveyTheme}
                              addressFieldSettings={{
                                addressLine1: (subItem as LiveQuestionDocument)
                                  .addressLine1,
                                addressLine2: (subItem as LiveQuestionDocument)
                                  .addressLine2,
                                addressTown: (subItem as LiveQuestionDocument)
                                  .addressTown,
                                addressState: (subItem as LiveQuestionDocument)
                                  .addressState,
                                addressPostCode: (
                                  subItem as LiveQuestionDocument
                                ).addressPostCode,
                                addressCountry: (
                                  subItem as LiveQuestionDocument
                                ).addressCountry,
                              }}
                            />
                          );
                        } else if (
                          subItem.subItemType === FormSubItemTypes.BLOCK &&
                          (subItem as LiveFormSubBlockDocument).blockType ===
                            FormSubBlockTypes.HEADING
                        ) {
                          return (
                            <LiveHeadingBlock
                              key={subItem._id}
                              heading={
                                (subItem as LiveFormSubBlockDocument).heading
                              }
                              surveyTheme={surveyTheme}
                            />
                          );
                        } else if (
                          subItem.subItemType === FormSubItemTypes.BLOCK &&
                          (subItem as LiveFormSubBlockDocument).blockType ===
                            FormSubBlockTypes.PARAGRAPH
                        ) {
                          return (
                            <LiveParagraphBlock
                              key={subItem._id}
                              paragraphText={
                                (subItem as LiveFormSubBlockDocument).paragraph
                              }
                              surveyTheme={surveyTheme}
                            />
                          );
                        }
                      }
                    )}

                    {submitResponseError && (
                      <Alert sx={{ my: 3 }} severity="error">
                        {submitResponseError}
                      </Alert>
                    )}

                    <Box
                      sx={{
                        display: "flex",
                        ...(surveyTheme && {
                          ...(surveyTheme.contentTextAlignment && {
                            justifyContent: surveyTheme.contentTextAlignment,
                          }),
                        }),
                      }}
                    >
                      {currentPageIndex === numOfPages - 1 && (
                        <SurveyButton type="submit">
                          {submitResponseLoading ? (
                            <CircularProgress color="primary" />
                          ) : (
                            "Submit"
                          )}
                        </SurveyButton>
                      )}

                      {currentPageIndex < numOfPages - 1 && (
                        <SurveyButton type="submit">
                          {submitResponseLoading ? (
                            <CircularProgress color="primary" />
                          ) : (
                            "Next"
                          )}
                        </SurveyButton>
                      )}
                    </Box>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        )}
    </FormScreenLayout>
  );
}
