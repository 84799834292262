import React from "react";

type EmptyActionPathIconProps = React.SVGProps<SVGSVGElement> & {
  style?: React.CSSProperties;
};

export default function EmptyActionPathIcon({
  style,
  ...otherProps
}: EmptyActionPathIconProps) {
  return (
    <svg
      //   width="589"
      viewBox="0 0 589 589"
      style={{
        ...style,
      }}
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path d="M271.548 0.353561C251.812 2.73137 245.629 3.68254 235.286 5.70366C125.193 27.8175 36.3807 112.349 8.79796 221.254C1.66448 249.55 0 263.579 0 294.491C0 325.521 1.78337 340.145 8.79796 367.609C35.4296 471.877 116.989 553.436 221.019 579.83C249.077 586.963 263.225 588.628 294.137 588.628C325.049 588.628 339.078 586.963 367.493 579.83C404.944 570.319 443.583 551.653 473.069 528.825C476.873 525.853 480.915 522.762 481.866 522.049C483.293 520.86 482.699 519.552 477.824 513.726C474.614 509.803 471.88 506.474 471.761 506.355C471.642 506.117 467.243 509.09 462.131 513.013C424.204 542.022 381.522 559.975 333.252 567.584C314.468 570.437 273.807 570.437 255.022 567.584C214.242 561.164 176.554 546.897 143.502 525.259C48.2699 462.96 1.3078 349.894 24.2538 238.493C45.5354 134.939 124.717 52.6658 227.558 26.9852C321.125 3.68254 420.756 31.2653 489 99.39C575.91 186.418 594.932 322.073 535.13 428.362C524.905 446.434 524.905 446.553 524.786 436.447C524.786 431.81 524.43 425.509 524.073 422.418L523.241 416.949H514.562C509.806 416.949 505.645 417.305 505.407 417.781C505.169 418.375 505.407 431.81 506.001 447.86C507.666 490.542 506.12 489.472 544.998 473.541C560.216 467.359 572.818 462.009 573.175 461.771C573.532 461.295 567.944 445.958 566.993 444.532C566.755 444.294 561.405 446.315 555.104 449.049C548.683 451.665 543.214 453.686 542.977 453.448C542.739 453.211 544.641 449.525 547.257 445.245C615.738 332.536 598.618 185.586 505.764 89.522C460.109 42.2033 401.615 12.3616 335.155 2.49364C323.741 0.829147 280.227 -0.716446 271.548 0.353561Z" />
      <path d="M154.44 116.748L151.468 119.602V161.332V203.063L154.44 205.917L157.293 208.889H199.024H240.755L243.608 205.917L246.581 203.063V161.332V119.602L243.608 116.748L240.755 113.776H199.024H157.293L154.44 116.748ZM213.291 133.155C213.291 133.274 203.661 143.023 191.891 154.793L170.49 176.194V154.437V132.798H191.891C203.661 132.798 213.291 132.917 213.291 133.155ZM227.558 168.466V189.866H205.92H184.163L205.563 168.466C217.333 156.696 227.082 147.065 227.201 147.065C227.439 147.065 227.558 156.696 227.558 168.466Z" />
      <path d="M275.114 142.31V151.821H360.716H446.318V142.31V132.798H360.716H275.114V142.31Z" />
      <path d="M275.114 180.355V189.866H322.671H370.228V180.355V170.844H322.671H275.114V180.355Z" />
      <path d="M154.44 249.906L151.468 252.76V294.491V336.222L154.44 339.075L157.293 342.047H199.024H240.755L243.608 339.075L246.581 336.222V294.491V252.76L243.608 249.906L240.755 246.934H199.024H157.293L154.44 249.906ZM213.291 266.313C213.291 266.432 203.661 276.181 191.891 287.952L170.49 309.352V287.595V265.957H191.891C203.661 265.957 213.291 266.076 213.291 266.313ZM227.558 301.624V323.025H205.92H184.163L205.563 301.624C217.333 289.854 227.082 280.224 227.201 280.224C227.439 280.224 227.558 289.854 227.558 301.624Z" />
      <path d="M275.114 275.468V284.979H360.716H446.318V275.468V265.957H360.716H275.114V275.468Z" />
      <path d="M275.114 313.513V323.025H322.671H370.228V313.513V304.002H322.671H275.114V313.513Z" />
      <path d="M154.44 383.065L151.468 385.918V427.649V469.38L154.44 472.233L157.293 475.205H199.024H240.755L243.608 472.233L246.581 469.38V427.649V385.918L243.608 383.065L240.755 380.092H199.024H157.293L154.44 383.065ZM213.291 399.472C213.291 399.591 203.661 409.34 191.891 421.11L170.49 442.51V420.753V399.115H191.891C203.661 399.115 213.291 399.234 213.291 399.472ZM227.558 434.782V456.183H205.92H184.163L205.563 434.782C217.333 423.012 227.082 413.382 227.201 413.382C227.439 413.382 227.558 423.012 227.558 434.782Z" />
      <path d="M275.114 408.626V418.138H360.716H446.318V408.626V399.115H360.716H275.114V408.626Z" />
      <path d="M275.114 446.672V456.183H322.671H370.228V446.672V437.16H322.671H275.114V446.672Z" />
    </svg>
  );
}
