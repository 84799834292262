import React from "react";

import { useField } from "formik";

import {
  FormControlLabel,
  Checkbox,
  FormControlProps,
  FormControl,
  FormGroup,
  FormHelperText,
  Typography,
  BoxProps,
  CheckboxProps,
} from "@mui/material";
import { CheckboxValue } from "../../types/Blocks";

type ControlledCheckboxesProps = FormControlProps & {
  name: string;
  checkboxes: CheckboxValue[];
  checkboxStyle?: CheckboxProps["sx"];
  checkboxLabelStyles?: BoxProps["sx"];
};

export default function ControlledCheckboxes({
  name,
  checkboxes,
  checkboxStyle = {},
  checkboxLabelStyles,
  ...otherPRops
}: ControlledCheckboxesProps) {
  const [field, meta] = useField(name);

  const checkboxesConfig: FormControlProps = {
    ...field,
    ...otherPRops,
    variant: "standard",
  };

  return (
    <FormControl {...checkboxesConfig}>
      <FormGroup>
        {checkboxes.map((checkbox: CheckboxValue) => {
          return (
            <FormControlLabel
              key={checkbox.checkboxId}
              control={
                <Checkbox
                  name={name}
                  value={checkbox.checkboxId}
                  onChange={field.onChange}
                  sx={{
                    ...checkboxStyle,
                  }}
                />
              }
              label={
                <Typography sx={{ ...checkboxLabelStyles }}>
                  {checkbox.value ?? "No choice title"}
                </Typography>
              }
              //   label={checkbox.value ? checkbox.value : "No choice title."}
            />
          );
        })}
      </FormGroup>
      {meta.touched && Boolean(meta.error) && (
        <FormHelperText error={meta.touched && Boolean(meta.error)}>
          {String(meta.error)}
        </FormHelperText>
      )}
    </FormControl>
  );
}
