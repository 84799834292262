import React from "react";

import { useField } from "formik";

import {
  ToggleButtonGroup,
  ToggleButton,
  ToggleButtonGroupProps,
} from "@mui/material";

type ControlledToggleButtonGroupProps = ToggleButtonGroupProps & {
  name: string;
  label?: string;
  options: { [key: string]: any };
};

export default function ControlledToggleButtonGroup({
  name,
  options,
  label,
  ...otherPRops
}: ControlledToggleButtonGroupProps) {
  const [field, meta, helpers] = useField(name);

  const handleChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any
  ) => {
    helpers.setValue(value);
  };

  const toggleButtonGroupConfig: ToggleButtonGroupProps = {
    ...field,
    ...otherPRops,
    value: field.value,
    onChange: handleChange,
    exclusive: true,
  };

  return (
    <ToggleButtonGroup {...toggleButtonGroupConfig}>
      {Object.keys(options).map((item, index) => {
        return (
          <ToggleButton key={item} value={item}>
            {options[item]}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}
