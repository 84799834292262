import React from "react";

import NewConditionalVisibilityOptions from "../../Options/ConditionalVisibilityOptions";
import { Actions } from "../../../../types/Actions";
import CustomBackgroundSettingsOption from "../../Options/CustomBackgroundSettingsOption";
import TabsLayout from "../../../Layout/TabsLayout";

type ActionPathBlockSettingsPanelProps = {
  name: string;
  actionPathIndex: number;
};

export default function ActionPathBlockSettingsPanel({
  name,
  actionPathIndex,
}: ActionPathBlockSettingsPanelProps) {
  return (
    <TabsLayout
      tabOptions={{
        style: {
          label: "Style",
          tabPanel: (
            <>
              <CustomBackgroundSettingsOption
                name={name}
              ></CustomBackgroundSettingsOption>
            </>
          ),
        },
        visibility: {
          label: "Visibility",
          tabPanel: (
            <NewConditionalVisibilityOptions
              name={name}
              itemType={Actions.ACTION_PATH}
              itemTitle="action path"
              pageIndex={actionPathIndex}
            ></NewConditionalVisibilityOptions>
          ),
        },
      }}
    />
  );
}
