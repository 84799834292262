import React from "react";
import { Tooltip, TooltipProps, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

type InfoTooltipProps = {
  title: string;
};

export default function InfoTooltip({
  title,
  ...otherProps
}: InfoTooltipProps) {
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            minWidth: 200,
            maxWidth: 200,
          },
        },
      }}
      title={title}
    >
      <InfoOutlined
        fontSize="small"
        sx={{
          color: (theme) => theme.palette.grey[500],
        }}
      />
    </Tooltip>
  );
}
