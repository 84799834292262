import React, { useState } from "react";

import { useField } from "formik";

import { Box, Button, Typography } from "@mui/material";

import { useTheme } from "@mui/material/styles";

import ControlledSwitch from "../../Inputs/ControlledSwitch";
import ControlledRadioButtons from "../../Inputs/ControlledRadioButtons";
import { BACKGROUND_TYPES_LIST } from "../../../data/BlockFields";
import { BackgroundTypes, PageBlockFields } from "../../../types/Blocks";
import ControlledColorPickerTextField from "../../Inputs/ControlledColorPickerTextField";
import {
  InsertPhotoOutlined,
  PhotoSizeSelectLargeOutlined,
  DeleteOutlineOutlined,
} from "@mui/icons-material";
import { hexToRGBA } from "../../../utils/hexToRGBA";
import SelectImageDialog from "../../Images/SelectImageDialog";
import InfoTooltip from "../../InfoTooltip";

type ICustomBackgroundSettingsOptionProps = {
  // blockIndex: number;
  name: string;
  label?: string;
};

export default function CustomBackgroundSettingsOption({
  // blockIndex,
  name,
  label,
}: ICustomBackgroundSettingsOptionProps) {
  const theme = useTheme();

  const [showSelectImageDialog, setShowSelectImageDialog] = useState(false);

  const [field, meta, helpers] = useField<PageBlockFields>(name);

  const handleDeleteImage = () => {
    helpers.setValue({
      ...field.value,
      customBackgroundSettings: {
        ...field.value.customBackgroundSettings,
        imageBackgroundSettings: {
          imageUrl: "",
        },
      },
    });
  };

  const setBackgroundImageUrl = (newImageUrl: string) => {
    helpers.setValue({
      ...field.value,
      customBackgroundSettings: {
        ...field.value.customBackgroundSettings,
        imageBackgroundSettings: {
          imageUrl: newImageUrl,
        },
      },
    });
  };

  const handleSelectImageDialogOpen = () => {
    setShowSelectImageDialog(true);
  };

  const handleSelectImageDialogClose = () => {
    setShowSelectImageDialog(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              mr: 1,
            }}
          >
            {label ?? "Background"}
          </Typography>
          <InfoTooltip title="Set a custom background for this `Page` / `Action Path`. This will superceed the background sent in the survey theme." />
        </Box>
        <ControlledSwitch
          name={`${name}.hasCustomBackground`}
          id={`${name}.hasCustomBackground`}
          size="small"
        />
      </Box>

      {field.value.hasCustomBackground && (
        <Box
          sx={{
            mt: 1,
          }}
        >
          <ControlledRadioButtons
            name={`${name}.customBackgroundSettings.backgroundType`}
            id={`${name}.customBackgroundSettings.backgroundType`}
            options={BACKGROUND_TYPES_LIST}
          ></ControlledRadioButtons>
        </Box>
      )}

      {field.value.hasCustomBackground &&
        field.value.customBackgroundSettings.backgroundType ===
          BackgroundTypes.IMAGE && (
          <>
            <Box
              sx={{
                borderWidth: "1px",
                borderStyle: "dashed",
                borderColor: hexToRGBA(theme.palette.primary.light, 0.25),
                borderRadius: "6px",
                backgroundColor: hexToRGBA(theme.palette.primary.light, 0.075),
                p: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: 200,
                overflow: "hidden",
                mb: 2,
              }}
            >
              <Box sx={{}}>
                {field.value.customBackgroundSettings.imageBackgroundSettings
                  .imageUrl && (
                  <img
                    style={{
                      display: "block",
                      margin: "auto",
                      width: "100%",
                      height: "100%",
                    }}
                    src={
                      field.value.customBackgroundSettings
                        .imageBackgroundSettings.imageUrl
                    }
                    alt="Uploaded Background"
                  />
                )}
                {!field.value.customBackgroundSettings.imageBackgroundSettings
                  .imageUrl && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <PhotoSizeSelectLargeOutlined
                      color="primary"
                      sx={{ mb: 1 }}
                      fontSize="large"
                    />
                    <Typography variant="body2" color="primary">
                      No Image Uploaded
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Button
                fullWidth
                component="label"
                startIcon={<InsertPhotoOutlined />}
                onClick={handleSelectImageDialogOpen}
              >
                Upload
              </Button>
              {field.value.customBackgroundSettings.imageBackgroundSettings
                .imageUrl && (
                <Button
                  fullWidth
                  component="label"
                  color="error"
                  startIcon={<DeleteOutlineOutlined />}
                  onClick={handleDeleteImage}
                >
                  Delete
                </Button>
              )}
            </Box>
          </>
        )}

      <SelectImageDialog
        show={showSelectImageDialog}
        closeHandler={handleSelectImageDialogClose}
        setImageHandler={setBackgroundImageUrl}
      />

      {field.value.hasCustomBackground &&
        field.value.customBackgroundSettings.backgroundType ===
          BackgroundTypes.COLOR && (
          <ControlledColorPickerTextField
            name={`${name}.customBackgroundSettings.colorBackgroundSettings.color`}
          ></ControlledColorPickerTextField>
        )}
    </>
  );
}
