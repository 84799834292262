import React from "react";

import { Typography, TypographyProps } from "@mui/material";
import { useTheme, lighten } from "@mui/material/styles";

import { LiveSurveyThemeDocument } from "../../../../types/SurveyTheme";

type LiveParagraphBlockProps = TypographyProps & {
  paragraphText: string;
  surveyTheme: LiveSurveyThemeDocument | null;
};

export default function LiveParagraphBlock({
  paragraphText,
  surveyTheme,
  sx,
}: LiveParagraphBlockProps) {
  const theme = useTheme();

  return (
    <Typography
      sx={{
        mb: 3,
        ...theme.typography.body1,
        ...(surveyTheme && {
          color: lighten(surveyTheme.colorSettings.questionTextColor, 0.2),
          ...(surveyTheme.contentTextAlignment && {
            textAlign: surveyTheme.contentTextAlignment,
          }),

          ...(surveyTheme.globalFont &&
            !surveyTheme.hasGranularFontSettings && {
              fontFamily: surveyTheme.globalFont,
            }),

          ...(surveyTheme.hasGranularFontSettings &&
            surveyTheme.granularFontSettings.questionText && {
              fontFamily:
                surveyTheme.granularFontSettings.questionText.fontFamily,
            }),
        }),
        ...sx,
      }}
    >
      {paragraphText ?? "No Paragraph Provided"}
    </Typography>
  );
}
