import React from "react";

import { BackgroundTypes } from "../../types/Blocks";
import {
  ContentSectionPlacements,
  HorizontalLogoPosition,
  LiveSurveyThemeDocument,
  LogoRelativeToOptions,
  VerticalAlignmentPositions,
  VerticalLogoPositions,
} from "./../../types/SurveyTheme";
import SurveyLogo from "../SurveyComponents/SurveyLogo";
import { Box, BoxProps, Container } from "@mui/material";

type FormScreenLayoutProps = BoxProps & {
  children: React.ReactNode;
  surveyTheme: LiveSurveyThemeDocument | null;
  contentBoxStyling?: BoxProps["sx"];
};

export default function FormScreenLAyout({
  children,
  surveyTheme,
  contentBoxStyling = {},
  sx,
}: FormScreenLayoutProps) {
  return (
    <Box
      sx={{
        ...sx,
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        overflow: "auto",

        ...(surveyTheme && {
          ...(surveyTheme.mainBackgroundSettings.hasCustomBackground && {
            ...(surveyTheme.mainBackgroundSettings.customBackgroundSettings
              .backgroundType === BackgroundTypes.IMAGE &&
              surveyTheme.mainBackgroundSettings.customBackgroundSettings
                .imageBackgroundSettings.imageUrl && {
                backgroundImage: `url(${surveyTheme.mainBackgroundSettings.customBackgroundSettings.imageBackgroundSettings.imageUrl})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }),

            ...(surveyTheme.mainBackgroundSettings.customBackgroundSettings
              .backgroundType === BackgroundTypes.COLOR &&
              surveyTheme.mainBackgroundSettings.customBackgroundSettings
                .colorBackgroundSettings.color && {
                backgroundImage: "",
                backgroundColor:
                  surveyTheme.mainBackgroundSettings.customBackgroundSettings
                    .colorBackgroundSettings.color,
              }),

            ...(surveyTheme.logoSettings.hasSurveyLogo &&
              surveyTheme.logoSettings.relativeTo ===
                LogoRelativeToOptions.PAGE && {
                position: "relative",
              }),
          }),
        }),
      }}
    >
      {surveyTheme &&
        surveyTheme?.logoSettings.hasSurveyLogo &&
        surveyTheme?.logoSettings.logoUrl &&
        surveyTheme?.logoSettings.relativeTo === LogoRelativeToOptions.PAGE && (
          <SurveyLogo
            logoUrl={surveyTheme?.logoSettings.logoUrl}
            verticalPosition={surveyTheme?.logoSettings.verticalPosition}
            horizontalPosition={surveyTheme?.logoSettings.horizontalPosition}
          />
        )}

      <Box
        sx={{
          ...contentBoxStyling,

          ...(surveyTheme && {
            ...(surveyTheme.contentSectionPlacement && {
              ...((surveyTheme.contentSectionPlacement ===
                ContentSectionPlacements.LEFT ||
                surveyTheme.contentSectionPlacement ===
                  ContentSectionPlacements.RIGHT) && {
                width: { xs: "100%", md: "50%" },
              }),

              ...(surveyTheme.contentSectionPlacement ===
                ContentSectionPlacements.RIGHT && {
                ml: { xs: "0%", md: "50%" },
              }),
            }),

            ...(surveyTheme.contentVerticalAlignment && {
              display: "flex",
              ...(surveyTheme.contentVerticalAlignment ===
                VerticalAlignmentPositions.TOP && {
                alignItems: "start",
              }),
              ...(surveyTheme.contentVerticalAlignment ===
                VerticalAlignmentPositions.CENTER && {
                alignItems: "center",
              }),

              ...(surveyTheme.contentVerticalAlignment ===
                VerticalAlignmentPositions.BOTTOM && {
                alignItems: "end",
              }),
            }),
          }),

          ...(surveyTheme && {
            ...(surveyTheme.contentBackgroundSettings.hasCustomBackground && {
              ...(surveyTheme.contentBackgroundSettings.customBackgroundSettings
                .backgroundType === BackgroundTypes.IMAGE &&
                surveyTheme.contentBackgroundSettings.customBackgroundSettings
                  .imageBackgroundSettings.imageUrl && {
                  backgroundImage: `url(${surveyTheme.contentBackgroundSettings.customBackgroundSettings.imageBackgroundSettings.imageUrl})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }),

              ...(surveyTheme.contentBackgroundSettings.customBackgroundSettings
                .backgroundType === BackgroundTypes.COLOR &&
                surveyTheme.contentBackgroundSettings.customBackgroundSettings
                  .colorBackgroundSettings.color && {
                  backgroundImage: "",
                  backgroundColor:
                    surveyTheme.contentBackgroundSettings
                      .customBackgroundSettings.colorBackgroundSettings.color,
                }),
            }),

            ...(surveyTheme.logoSettings.hasSurveyLogo &&
              surveyTheme.logoSettings.relativeTo ===
                LogoRelativeToOptions.CONTENT && {
                position: "relative",
              }),

            ...(surveyTheme.logoSettings.verticalPosition ===
              VerticalLogoPositions.TOP &&
              !(
                surveyTheme.logoSettings.relativeTo ===
                  LogoRelativeToOptions.PAGE &&
                surveyTheme.logoSettings.horizontalPosition ===
                  HorizontalLogoPosition.RIGHT
              ) && {
                paddingTop: "100px",
              }),
          }),
          flexGrow: 1,
        }}
      >
        {surveyTheme &&
          surveyTheme?.logoSettings.hasSurveyLogo &&
          surveyTheme?.logoSettings.logoUrl &&
          surveyTheme?.logoSettings.relativeTo ===
            LogoRelativeToOptions.CONTENT && (
            <SurveyLogo
              logoUrl={surveyTheme?.logoSettings.logoUrl}
              verticalPosition={surveyTheme?.logoSettings.verticalPosition}
              horizontalPosition={surveyTheme?.logoSettings.horizontalPosition}
            />
          )}

        {/* {children} */}

        <Container maxWidth="md">{children}</Container>
      </Box>
    </Box>
  );
}
