import React from "react";

type StartBuildingFormIconProps = React.SVGProps<SVGSVGElement> & {
  style?: React.CSSProperties;
};

export default function StartBuildingFormIcon({
  style,
  ...otherProps
}: StartBuildingFormIconProps) {
  return (
    <svg
      //   width="438"
      viewBox="0 0 438 602"
      style={{
        ...style,
      }}
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path d="M24.5947 1.32164C16.0042 3.67516 8.35515 10.0298 3.29499 19.091C0.706069 23.7981 0.706069 24.3865 0.353035 79.8129L0 135.945L3.17731 138.417C7.06069 141.476 10.8264 141.594 14.3567 138.652C17.0633 136.534 17.181 135.475 17.5341 121.589L18.0048 106.761H219.117H420.229V317.405C420.229 552.173 420.935 532.403 411.874 538.052C409.285 539.699 406.578 542.406 405.872 544.054C403.989 548.055 405.872 552.879 410.109 554.88C415.051 557.351 422.347 554.174 428.937 546.76C438.704 535.816 437.881 560.646 437.645 276.806L437.292 23.7981L434.703 19.091C431.291 12.7364 424.465 6.14641 417.993 2.9691L412.58 0.262497L221.353 0.0271454C70.0185 -0.0905304 28.9488 0.144821 24.5947 1.32164ZM410.815 19.4441C413.051 20.5032 416.11 23.2098 417.522 25.4457C420.111 29.329 420.229 30.2704 420.229 59.2193V89.1095H218.999H17.7694V59.2193C17.7694 30.2704 17.8871 29.329 20.476 25.4457C21.8881 23.2098 24.9478 20.5032 27.1837 19.4441C31.067 17.4435 42.5995 17.3259 218.999 17.3259C395.399 17.3259 406.931 17.4435 410.815 19.4441Z" />
      <path d="M53.6613 28.9761C51.0724 30.1528 46.9536 33.2125 44.6001 35.9191C29.1842 52.9824 41.5404 79.6954 64.723 79.6954C89.0824 79.6954 100.615 50.3935 82.8455 33.5655C75.6671 26.7402 62.9579 24.7397 53.6613 28.9761ZM71.313 47.3339C77.0792 53.1001 73.1959 62.0436 64.8407 62.0436C61.3104 62.0436 59.7805 61.3376 57.8977 58.984C50.6016 49.6874 62.9578 38.9787 71.313 47.3339Z" />
      <path d="M124.268 28.9761C118.031 31.8003 111.206 39.5671 109.441 45.8041C104.734 63.3381 117.325 79.6954 135.448 79.6954C159.807 79.6954 171.222 50.3935 153.452 33.5655C146.274 26.7402 133.565 24.7397 124.268 28.9761ZM141.92 47.3339C147.686 53.1001 143.803 62.0436 135.448 62.0436C131.917 62.0436 130.387 61.3376 128.505 58.984C121.208 49.6874 133.565 38.9787 141.92 47.3339Z" />
      <path d="M194.875 28.9761C188.638 31.8003 181.813 39.5671 180.048 45.8041C175.341 63.3381 187.932 79.6954 206.055 79.6954C230.414 79.6954 241.829 50.3935 224.059 33.5655C216.881 26.7402 204.172 24.7397 194.875 28.9761ZM212.527 47.3339C218.293 53.1001 214.41 62.0436 206.055 62.0436C202.524 62.0436 200.994 61.3376 199.112 58.984C191.816 49.6874 204.172 38.9787 212.527 47.3339Z" />
      <path d="M130.976 141.712C130.152 142.065 128.74 143.712 127.799 145.595C125.563 149.831 127.328 155.362 131.447 157.245C135.565 159.128 302.433 159.128 306.552 157.245C310.671 155.362 312.436 149.831 310.2 145.595C309.258 143.83 307.611 142.065 306.669 141.594C304.316 140.77 133.212 140.77 130.976 141.712Z" />
      <path d="M2.70665 161.246L0.117733 163.953V346.942C0.117733 550.055 -0.470658 535.816 7.8845 545.583C10.2381 548.29 14.5922 551.82 17.5341 553.703L23.065 556.88L143.921 557.233C263.835 557.469 264.894 557.469 267.247 555.115C271.719 550.643 269.836 543.347 263.599 541.112C260.893 540.17 225.707 539.817 144.627 539.817C17.4164 539.817 24.8302 540.288 20.123 532.403C17.7695 528.755 17.7695 525.107 17.7695 346.472V164.306L14.8275 161.482C11.1795 157.716 6.11932 157.598 2.70665 161.246Z" />
      <path d="M88.6116 218.203C86.4934 219.262 83.5515 221.733 82.1393 223.733C79.6681 227.146 79.5504 228.676 79.5504 246.798C79.5504 268.569 80.0211 270.099 88.494 274.923C92.7304 277.395 93.5541 277.395 218.999 277.395C344.444 277.395 345.268 277.395 349.504 274.923C357.977 270.099 358.448 268.569 358.448 246.798C358.448 228.558 358.33 227.146 355.859 223.733C350.093 215.731 358.565 216.202 218.764 216.202C104.851 216.202 92.142 216.437 88.6116 218.203ZM341.384 246.798V259.743H218.999H96.6138V246.798V233.854H218.999H341.384V246.798Z" />
      <path d="M92.8481 316.229C87.7879 317.758 82.9631 321.759 80.8449 326.113C79.4327 329.173 78.962 333.88 78.962 347.178C78.962 362.829 79.1974 364.477 81.6686 368.125C83.0808 370.243 86.1404 373.302 88.2586 374.715L92.2597 377.421H218.999H345.739L349.74 374.715C351.858 373.302 354.917 370.243 356.33 368.125C358.801 364.477 359.036 362.829 359.036 347.178C359.036 327.408 357.977 323.76 350.446 318.817L345.621 315.64L220.882 315.405C152.158 315.287 94.6132 315.64 92.8481 316.229ZM341.149 346.472L341.502 359.769H218.999H96.6138V347.06C96.6138 340 96.9668 333.88 97.4375 333.527C97.7906 333.056 152.746 332.821 219.47 332.939L340.796 333.292L341.149 346.472Z" />
      <path d="M184.755 425.434C162.514 436.966 162.984 469.328 185.461 480.39C189.697 482.508 193.11 482.743 216.999 483.096C247.83 483.449 252.184 482.743 259.833 475.212C275.249 460.267 271.954 435.201 253.243 425.434L247.006 422.139H218.999H190.992L184.755 425.434ZM246.771 441.909C250.301 444.733 251.949 448.381 251.949 453.324C251.949 456.736 251.008 458.619 247.948 461.679L243.947 465.68H218.999H194.051L190.05 461.679C185.696 457.325 184.755 451.676 187.344 446.498C190.639 440.026 191.698 439.791 218.999 439.791C241.24 439.791 244.653 440.144 246.771 441.909Z" />
      <path d="M298.197 444.615C296.196 445.204 293.136 447.322 291.489 449.323L288.429 452.971V511.574V570.178L291.371 573.826C294.902 577.945 301.256 580.298 305.846 579.239C307.611 578.886 314.319 575.356 320.791 571.59C328.793 567.001 332.794 565.236 333.382 566.059C333.853 566.883 338.09 574.061 342.797 582.181C356.447 605.364 360.213 606.541 381.395 594.302C395.87 585.947 398.458 583.005 398.458 575.356C398.458 570.649 397.164 567.589 389.986 555.115C385.396 546.995 381.395 539.699 381.16 538.758C380.924 537.817 385.514 534.404 393.163 529.932C402.46 524.401 405.99 521.695 407.637 518.635C410.109 513.928 409.756 507.573 406.814 503.102C404.578 499.689 310.553 445.086 305.375 444.145C303.492 443.792 300.315 444.027 298.197 444.615ZM347.974 487.686C370.216 500.513 388.456 511.339 388.456 511.692C388.456 512.045 383.984 514.869 378.453 517.929C367.509 524.048 363.037 528.52 361.978 534.286C361.037 539.229 362.214 542.171 372.216 559.587C376.688 567.589 380.336 574.297 380.101 574.532C379.159 575.356 365.038 583.358 364.449 583.358C364.214 583.358 359.742 576.18 354.682 567.354C344.562 549.82 341.502 546.878 333.265 546.878C329.381 546.878 325.851 548.29 317.143 553.35L306.081 559.705V512.163C306.081 485.921 306.434 464.503 306.905 464.503C307.258 464.503 325.851 474.976 347.974 487.686Z" />
    </svg>
  );
}
