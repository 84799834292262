import React from "react";

import { Button, ButtonProps } from "@mui/material";

import { darken } from "@mui/material/styles";

import { useSurveyTheme } from "../../providers/surveyTheme";

type SurveyButtonProps = ButtonProps & {
  children: React.ReactNode;
};

export default function SurveyButton({
  children,
  sx,
  ...otherProps
}: SurveyButtonProps) {
  const surveyTheme = useSurveyTheme();

  return (
    <Button
      variant="contained"
      size="large"
      {...otherProps}
      sx={{
        ...sx,
        ...(surveyTheme && {
          ...(surveyTheme.colorSettings.buttonBackgroundColor && {
            backgroundColor: surveyTheme.colorSettings.buttonBackgroundColor,
            "&:hover": {
              backgroundColor: darken(
                surveyTheme.colorSettings.buttonBackgroundColor,
                0.2
              ),
            },
          }),
          ...(surveyTheme.colorSettings.buttonTextColor && {
            color: surveyTheme.colorSettings.buttonTextColor,
          }),
        }),
      }}
    >
      {children}
    </Button>
  );
}
