import { lighten, alpha, Theme } from "@mui/material/styles";
import { LiveSurveyThemeDocument } from "../../types/SurveyTheme";
import { hexToRGBA } from "../hexToRGBA";
import { BoxProps } from "@mui/material";

export const generateImageChoiceBoxStyle = (
  surveyTheme: LiveSurveyThemeDocument | null,
  theme: Theme,
  isSelected?: boolean
): BoxProps["sx"] => {
  return {
    backgroundColor: hexToRGBA(theme.palette.primary.light, 0.075),
    borderWidth: "1px",
    borderStyle: "dashed",
    borderColor: hexToRGBA(theme.palette.primary.light, 0.25),
    borderRadius: "6px",
    width: "100%",
    height: "100%",
    pt: 3,
    px: 1,
    pb: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
    position: "relative",

    ...(isSelected && {
      borderWidth: "3px",
      borderStyle: "solid",
    }),

    ...(surveyTheme && {
      backgroundColor: alpha(
        lighten(surveyTheme.colorSettings.answerTextColor, 0.8),
        0.1
      ),
      borderColor: lighten(surveyTheme.colorSettings.answerTextColor, 0.15),

      ...(isSelected && {
        borderColor: surveyTheme.colorSettings.answerTextColor,
      }),

      "&:hover": {
        backgroundColor: alpha(
          lighten(surveyTheme.colorSettings.answerTextColor, 0.5),
          0.25
        ),
        borderColor: surveyTheme.colorSettings.answerTextColor,
        ...(isSelected && {
          borderWidth: "3px",
          borderStyle: "solid",
        }),
      },
    }),
  };
};
