import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import {
  Box,
  Container,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Alert,
  Button,
} from "@mui/material";

import Api from "../api/api";

import LivelyFormsLogo from "../assets/Lively_Forms_Logo_Blue.svg";
import { VERIFY_EMAIL_URL } from "../data/constants";

type VerifyEmailProps = {};

export default function VerifyEmail({}: VerifyEmailProps) {
  const { emailVerificationToken } = useParams();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const verifyEmailToken = async () => {
    try {
      setError("");
      setLoading(true);

      await Api.post(
        `${VERIFY_EMAIL_URL}/${emailVerificationToken}`,
        {},
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      setLoading(false);
    } catch (error) {
      setLoading(false);
      // @ts-ignore
      if (!error?.response) {
        setError("No response from server.");
        // @ts-ignore
      } else if (error?.response) {
        // @ts-ignore
        setError(error.response.data.error.message);
      } else {
        setError("Unable to verift email at the moment.");
      }
    }
  };

  useEffect(() => {
    verifyEmailToken();
  }, []);

  return (
    <Box
      sx={{
        pt: 7,
      }}
    >
      <Container maxWidth="xs">
        <Card
          sx={{
            py: 5,
            px: 2,
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: 3,
              }}
            >
              <img
                src={LivelyFormsLogo}
                alt="Lively Forms Logo"
                height={40}
                width={240}
              />
            </Box>

            {loading && !error && (
              <>
                <Typography sx={{ mb: 5 }} variant="body1" align="center">
                  Hold on, While we verify you...
                </Typography>
                <CircularProgress />
              </>
            )}

            {error && !loading && (
              <>
                <Typography sx={{ mb: 5 }} variant="body1" align="center">
                  Oops! Something is wrong.
                </Typography>
                <Alert
                  severity="error"
                  variant="standard"
                  sx={{
                    width: "100%",
                  }}
                >
                  {error}
                </Alert>
              </>
            )}

            {!error && !loading && (
              <>
                <Typography sx={{ mb: 5 }} variant="body1" align="center">
                  Awesome!
                </Typography>
                <Alert
                  severity="success"
                  variant="standard"
                  sx={{
                    width: "100%",
                  }}
                >
                  Your email has been successfully verified. You can now Log In!
                </Alert>
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate("/login");
                  }}
                  sx={{
                    mt: 5,
                  }}
                  size="large"
                >
                  Log In
                </Button>
              </>
            )}
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}
