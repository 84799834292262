import React, { useState } from "react";

import { Box, BoxProps, Typography } from "@mui/material";

import { useTheme } from "@mui/material/styles";

import { LiveSurveyThemeDocument } from "../../../../types/SurveyTheme";

import LiveSurveyQuestionText from "../../../SurveyComponents/LiveSurveyQuestionText";
import { useField } from "formik";
import { RankingValue } from "../../../../types/Blocks";
import { DragIndicatorOutlined } from "@mui/icons-material";
import {
  DragDropContext,
  Draggable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { StrictModeDroppable } from "../../../Helpers/StrictModeDroppable";
import { reorder } from "../../../FormBuilder";
import { generateRankingBoxStyle } from "../../../../utils/styles/generateRankingBoxStyle";
import { generateAnswerTextStyle } from "../../../../utils/styles/generateAnswerTextStyle";

type LiveRankingBlockProps = BoxProps & {
  name: string;
  rankingValues: RankingValue[];
  questionText: string;
  questionDescription?: string;
  isQuestionRequired: boolean;
  surveyTheme: LiveSurveyThemeDocument | null;
};

export default function LiveRankingBlock({
  name,
  rankingValues,
  isQuestionRequired,
  questionText,
  questionDescription,
  surveyTheme,
  sx,
}: LiveRankingBlockProps) {
  const theme = useTheme();

  const [currentRankingValues, setCurrentRankingValues] = useState([
    ...rankingValues,
  ]);

  const [field, meta, helpers] = useField<string[]>(name);

  const onDragEndHandler = (
    result: DropResult,
    provided: ResponderProvided
  ) => {
    if (result.source && result.destination) {
      setCurrentRankingValues((previousrankingValues: RankingValue[]) => {
        const newRankingValuesIndex = reorder(
          previousrankingValues,
          result.source.index,
          // @ts-ignore
          result.destination.index
        );

        helpers.setValue(
          newRankingValuesIndex.map((rankingValue: RankingValue) => {
            return rankingValue.value;
          })
        );

        return newRankingValuesIndex;
      });
    }
  };

  return (
    <Box sx={{ mb: 3, ...sx }}>
      <LiveSurveyQuestionText
        questionText={questionText}
        questionDescription={questionDescription}
        isQuestionRequired={isQuestionRequired}
        surveyTheme={surveyTheme}
        sx={{
          mb: 3,
        }}
      />

      <DragDropContext
        onDragEnd={(result: DropResult, provided: ResponderProvided) => {
          onDragEndHandler(result, provided);
        }}
      >
        <StrictModeDroppable droppableId={name} type="droppableRankingValue">
          {(provided) => {
            return (
              <Box {...provided.droppableProps} ref={provided.innerRef}>
                {currentRankingValues &&
                  currentRankingValues.map(
                    (rankingValue: RankingValue, rankingValueIndex: number) => {
                      return (
                        <Draggable
                          key={rankingValue.rankingId}
                          draggableId={rankingValue.rankingId}
                          index={rankingValueIndex}
                        >
                          {(provided, snapshot) => {
                            return (
                              <Box
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                sx={{
                                  ...generateRankingBoxStyle(
                                    snapshot.isDragging,
                                    surveyTheme,
                                    theme
                                  ),
                                  py: 1.5,
                                }}
                              >
                                <DragIndicatorOutlined
                                  sx={{
                                    mr: 1,
                                    ...(surveyTheme && {
                                      color:
                                        surveyTheme.colorSettings
                                          .answerTextColor,
                                    }),
                                  }}
                                  fontSize="small"
                                />
                                <Typography
                                  variant="body1"
                                  sx={{
                                    ...theme.typography.body1,
                                    ...(surveyTheme &&
                                      generateAnswerTextStyle(surveyTheme)),
                                  }}
                                >
                                  {rankingValue.value}
                                </Typography>
                              </Box>
                            );
                          }}
                        </Draggable>
                      );
                    }
                  )}
                {provided.placeholder}
              </Box>
            );
          }}
        </StrictModeDroppable>
      </DragDropContext>
    </Box>
  );
}
