import React from "react";

import { Box, BoxProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { LiveSurveyThemeDocument } from "../../../../types/SurveyTheme";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import LiveSurveyQuestionText from "../../../SurveyComponents/LiveSurveyQuestionText";
import { buildDateFormatString } from "../../../../utils/buildDateFormatString";
import {
  AllowedDateFormats,
  AllowedDateSeperators,
} from "../../../../types/Blocks";
import { useField } from "formik";
import { generateTextFieldStyle } from "../../../../utils/styles/generateTextFieldStyle";
import { getTextColorForBackground } from "../../../../utils/getTextColorForBackground";
import { generateAnswerTextStyle } from "../../../../utils/styles/generateAnswerTextStyle";

type LiveDateBlockProps = BoxProps & {
  name: string;
  questionText: string;
  questionDescription?: string;
  isQuestionRequired: boolean;
  surveyTheme: LiveSurveyThemeDocument | null;
  dateFormat: AllowedDateFormats;
  dateSeperator: AllowedDateSeperators;
};

export default function LiveDateBlock({
  name,
  isQuestionRequired,
  questionText,
  questionDescription,
  surveyTheme,
  sx,
  dateFormat,
  dateSeperator,
}: LiveDateBlockProps) {
  const theme = useTheme();
  const [field, meta, helpers] = useField(name);

  return (
    <Box sx={{ mb: 3, ...sx }}>
      <LiveSurveyQuestionText
        questionText={questionText}
        questionDescription={questionDescription}
        isQuestionRequired={isQuestionRequired}
        surveyTheme={surveyTheme}
        sx={{
          mb: 3,
        }}
      />

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          value={field.value === undefined ? null : field.value}
          onChange={(value) => helpers.setValue(value)}
          sx={{}}
          slotProps={{
            textField: {
              variant: (surveyTheme && surveyTheme.fieldVariant) ?? "outlined",
              fullWidth: true,
              size: "small",
              name: name,
              id: name,
              sx: {
                ...generateTextFieldStyle(surveyTheme),
                ...(surveyTheme && {
                  "& .MuiIconButton-root": {
                    color: surveyTheme.colorSettings.answerTextColor, // Customize the color here
                  },
                }),
              },
              inputProps: {
                sx: {
                  ...theme.typography.h6,
                  ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
                },
              },
            },
            popper: {
              sx: {
                ...(surveyTheme && {
                  // **** NOTE **** Leaving a space between & and . has a meaning. Leaving a space targets child elements. Leaving no sapce targets elements that have the current elements class and Mui-selected
                  "& .Mui-selected": {
                    backgroundColor: `${surveyTheme.colorSettings.answerTextColor} !important`,
                    color: `${getTextColorForBackground(
                      surveyTheme.colorSettings.answerTextColor
                    )} !important`,
                  },
                }),
              },
            },
          }}
          format={buildDateFormatString(dateFormat, dateSeperator)}
        />
      </LocalizationProvider>
    </Box>
  );
}
