import React, { useState } from "react";

import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

import { Formik, Form, FormikProps } from "formik";
import * as yup from "yup";
import {
  Typography,
  Card,
  Container,
  CardContent,
  Button,
  Box,
  CircularProgress,
  Alert,
} from "@mui/material";

import ControlledTextField from "../components/Inputs/ControlledTextField";

import { useAuth } from "../hooks/useAuth";
import {
  API_BASE_URL,
  AuthCallbackErrorMessages,
  LOGIN_URL,
} from "../data/constants";

import Api from "../api/api";

import LivelyFormsLogo from "../assets/Lively_Forms_Logo_Blue.svg";

import { AuthCallbackErrors } from "../types/Auth";
import MicrosoftLogoIcon from "../components/Icons/MicrosoftLogoIcon";
import GoogleLetterIcon from "../components/Icons/GoogleLetterIcon";

const INITIAL_FORM_STATE = {
  email: "",
  password: "",
};

const FORM_VALIDATION = yup.object().shape({
  email: yup.string().required("Email/Username is requried"),
  password: yup.string().required("Password is required"),
});

export interface LoginFormFields {
  email: string;
  password: string;
}

type LoginProps = {};

export default function Login({}: LoginProps) {
  const [searchParams] = useSearchParams();

  const [callbackError, setCallbackError] = useState(
    searchParams.get("callbackError")
  );

  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (values: LoginFormFields) => {
    try {
      setError("");
      setCallbackError("");
      setIsLoading(true);

      const response = await Api.post(LOGIN_URL, values, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });

      setAuth({
        user: response.data.success.data.user,
        accessToken: response.data.success.data.token,
      });

      setIsLoading(false);
      navigate(from, { replace: true });
    } catch (error) {
      setIsLoading(false);
      // @ts-ignore
      if (!error?.response) {
        setError("No response from server.");
        // @ts-ignore
      } else if (error?.response) {
        // @ts-ignore
        setError(error.response.data.error.message);
      } else {
        setError("Unable to login.");
      }
    }
  };

  const attemptGoogleLogin = () => {
    // window.open(`${API_BASE_URL}/auth/google/callback`);

    window.location.href = `${API_BASE_URL}/auth/google/callback`;
  };

  const attemptMicrosoftLogin = () => {
    // window.open(`${API_BASE_URL}/auth/google/callback`);

    window.location.href = `${API_BASE_URL}/auth/microsoft/callback`;
  };

  return (
    <Box
      sx={{
        pt: 7,
      }}
    >
      <Container maxWidth="xs">
        <Card
          sx={{
            py: 5,
            px: 2,
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: 3,
              }}
            >
              <img
                src={LivelyFormsLogo}
                alt="Lively Forms Logo"
                height={40}
                width={240}
              />
            </Box>

            <Typography sx={{ mb: 2 }} variant="body1" align="center">
              Hello, Come on in!
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                mb: 3,
              }}
            >
              <Button
                sx={{
                  textTransform: "none",
                }}
                onClick={() => {
                  navigate("/signup");
                }}
              >
                New around here? Sign Up
              </Button>
            </Box>

            <Formik
              initialValues={INITIAL_FORM_STATE as LoginFormFields}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values: any) => {
                handleSubmit(values);
              }}
              validateOnChange
              validateOnBlur
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
              }: FormikProps<LoginFormFields>) => {
                return (
                  <Form>
                    {error && (
                      <Alert sx={{ my: 3 }} severity="error">
                        {error}
                      </Alert>
                    )}

                    {callbackError &&
                      AuthCallbackErrorMessages[
                        callbackError as AuthCallbackErrors
                      ] && (
                        <Alert sx={{ my: 3 }} severity="error">
                          {
                            AuthCallbackErrorMessages[
                              callbackError as AuthCallbackErrors
                            ]
                          }
                        </Alert>
                      )}

                    <ControlledTextField
                      name="email"
                      label="Email/ Username"
                      required
                      sx={{ mb: 2 }}
                    ></ControlledTextField>

                    <ControlledTextField
                      name="password"
                      label="Password"
                      type="password"
                      sx={{ mb: 4 }}
                      required
                    ></ControlledTextField>

                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      size="large"
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress color="primary" />
                      ) : (
                        "Log In"
                      )}
                    </Button>

                    <Button
                      color="error"
                      sx={{ mt: 2 }}
                      onClick={() => {
                        navigate("/forgot-password");
                      }}
                    >
                      Forgot Password?
                    </Button>
                  </Form>
                );
              }}
            </Formik>

            <Button
              variant="outlined"
              fullWidth
              size="large"
              disabled={loading}
              sx={{
                mt: 3,
                color: "#1f1f1f",
                borderColor: "#747775",
                "&:hover": {
                  borderColor: "#747775",
                },
              }}
              onClick={() => {
                attemptGoogleLogin();
              }}
              startIcon={
                <Box>
                  <GoogleLetterIcon
                    style={{
                      height: "20px",
                      marginRight: "12px",
                      minWidth: "20px",
                      width: "20px",
                    }}
                  />
                </Box>
              }
            >
              Continue with Google
            </Button>

            <Button
              variant="outlined"
              fullWidth
              size="large"
              disabled={loading}
              sx={{
                mt: 3,
                color: "#1f1f1f",
                borderColor: "#747775",
                "&:hover": {
                  borderColor: "#747775",
                },
              }}
              onClick={() => {
                attemptMicrosoftLogin();
              }}
              startIcon={
                <Box>
                  <MicrosoftLogoIcon
                    style={{
                      height: "20px",
                      marginRight: "12px",
                      minWidth: "20px",
                      width: "20px",
                    }}
                  />
                </Box>
              }
            >
              Continue with Microsoft
            </Button>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}
