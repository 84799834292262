import React from "react";

import { Box, Button, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { v4 as uuid } from "uuid";

import {
  Draggable,
  DraggableProvidedDragHandleProps,
} from "react-beautiful-dnd";

import SurveyQuestionBlockHeader from "../../../SurveyComponents/SurveyQuestionBlockHeader";
import ControlledTextField from "../../../Inputs/ControlledTextField";
import { useField } from "formik";
import { RankingBlockFields, RankingValue } from "../../../../types/Blocks";
import { Add, Close, DragIndicatorOutlined } from "@mui/icons-material";
import { useSurveyTheme } from "../../../../providers/surveyTheme";
import { StrictModeDroppable } from "../../../Helpers/StrictModeDroppable";
import { generateAnswerTextStyle } from "../../../../utils/styles/generateAnswerTextStyle";
import { generateRankingBoxStyle } from "../../../../utils/styles/generateRankingBoxStyle";
import SurveyBlockLayout from "../../../SurveyComponents/SurveyBlockLayout";

interface RankingBlockProps {
  // formik: FormikContextType<IFormCanvasBlocksFields>;
  name: string;
  // index: number;
  pageIndex: number;
  blockIndex: number;
  settingsHandler: () => void;
  deleteHandler: () => void;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  settingsPanelActive: boolean;
}

export default function RankingBlock({
  // formik,
  name,
  // index,
  pageIndex,
  blockIndex,
  deleteHandler,
  settingsHandler,
  dragHandleProps,
  settingsPanelActive,
}: RankingBlockProps) {
  const surveyTheme = useSurveyTheme();
  const [field, meta, helpers] = useField<RankingBlockFields>(name);
  const theme = useTheme();

  return (
    <SurveyBlockLayout
      name={name}
      settingsHandler={settingsHandler}
      deleteHandler={deleteHandler}
      settingsPanelActive={settingsPanelActive}
      dragHandleProps={dragHandleProps}
    >
      <SurveyQuestionBlockHeader name={name} />

      <StrictModeDroppable
        droppableId={field.value.subItemId}
        type="droppableRankingValue"
      >
        {(provided) => {
          return (
            <Box {...provided.droppableProps} ref={provided.innerRef}>
              {field.value.rankingValues &&
                field.value.rankingValues.map(
                  (rankingValue: RankingValue, rankingValueIndex: number) => {
                    return (
                      <Draggable
                        key={rankingValue.rankingId}
                        draggableId={rankingValue.rankingId}
                        index={rankingValueIndex}
                      >
                        {(provided, snapshot) => {
                          return (
                            <Box
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              sx={{
                                ...generateRankingBoxStyle(
                                  snapshot.isDragging,
                                  surveyTheme,
                                  theme
                                ),
                              }}
                            >
                              <DragIndicatorOutlined
                                sx={{
                                  mr: 1,
                                  ...(surveyTheme && {
                                    color:
                                      surveyTheme.colorSettings.answerTextColor,
                                  }),
                                }}
                                fontSize="small"
                              />
                              <ControlledTextField
                                name={`${name}.rankingValues[${rankingValueIndex}].value`}
                                id={`${name}.rankingValues[${rankingValueIndex}].value`}
                                variant="standard"
                                size="small"
                                multiline
                                required
                                placeholder="Type your question here."
                                autoComplete="off"
                                InputProps={{
                                  disableUnderline: true,
                                  endAdornment: (
                                    <Box>
                                      {field.value.rankingValues.length > 1 && (
                                        <IconButton
                                          onClick={() => {
                                            // @ts-ignore
                                            helpers.setTouched({
                                              // @ts-ignore
                                              ...(meta.touched as FormikTouched<RankingBlockFields>),
                                              // @ts-ignore
                                              rankingValues:
                                                // @ts-ignore
                                                meta.touched?.rankingValues
                                                  // @ts-ignore
                                                  ?.filter(
                                                    (
                                                      _: string,
                                                      currentRankingValueIndex: number
                                                    ) => {
                                                      return (
                                                        rankingValueIndex !==
                                                        currentRankingValueIndex
                                                      );
                                                    }
                                                  ),
                                            });

                                            helpers.setValue({
                                              ...field.value,
                                              rankingValues:
                                                field.value.rankingValues.filter(
                                                  (
                                                    _,
                                                    currentRankingValueIndex
                                                  ) => {
                                                    return (
                                                      rankingValueIndex !==
                                                      currentRankingValueIndex
                                                    );
                                                  }
                                                ),
                                            });
                                          }}
                                        >
                                          <Close color="error" />
                                        </IconButton>
                                      )}
                                    </Box>
                                  ),
                                }}
                                inputProps={{
                                  style: {
                                    ...theme.typography.body1,
                                    ...(surveyTheme &&
                                      generateAnswerTextStyle(surveyTheme)),
                                  },
                                }}
                              />
                            </Box>
                          );
                        }}
                      </Draggable>
                    );
                  }
                )}
              {provided.placeholder}
            </Box>
          );
        }}
      </StrictModeDroppable>

      <Box>
        <Button
          sx={{
            my: 2,
          }}
          variant="outlined"
          startIcon={<Add />}
          onClick={() => {
            helpers.setValue({
              ...field.value,
              rankingValues: [
                ...(field.value.rankingValues
                  ? [...field.value.rankingValues]
                  : []),
                {
                  rankingId: uuid(),
                  value: "",
                },
              ],
            });
          }}
        >
          Add New Choice
        </Button>
      </Box>
    </SurveyBlockLayout>
  );
}
