import {
  ViewStreamOutlined,
  QuestionAnswerOutlined,
  SettingsOutlined,
  FiberSmartRecordOutlined,
} from "@mui/icons-material";

import { BuilderDrawerPanels, MiniDrawerButton } from "../types/Drawers";

export const MINI_DRAWER_BUTTONS: MiniDrawerButton[] = [
  {
    icon: <ViewStreamOutlined />,
    label: "Blocks",
    panel: BuilderDrawerPanels.BLOCKS,
  },
  {
    icon: <FiberSmartRecordOutlined fontSize="small" />,
    label: "Actions",
    panel: BuilderDrawerPanels.ACTIONS,
  },
  // {
  //   icon: <QuestionAnswerOutlined fontSize="small" />,
  //   label: "Questions",
  //   panel: BuilderDrawerPanels.QUESTIONS,
  // },
  {
    icon: <SettingsOutlined fontSize="small" />,
    label: "Settings",
    panel: BuilderDrawerPanels.SETTINGS,
  },
];
