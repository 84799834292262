import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";

import { useAuth } from "../../hooks/useAuth";
import { useRefreshToken } from "../../hooks/useRefreshToken";
import GlobalSplashScreen from "../Helpers/GlobalSplashScreen";

type AutoLoginProps = {};

export default function AutoLogin({}: AutoLoginProps) {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth } = useAuth();

  const verifyRefreshToken = async (isMounted: boolean) => {
    try {
      await refresh();
    } catch (err) {
      console.error(err);
    } finally {
      isMounted && setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    // persist added here AFTER tutorial video
    // Avoids unwanted call to verifyRefreshToken
    !auth?.accessToken ? verifyRefreshToken(isMounted) : setIsLoading(false);

    return () => {
      isMounted = false;
    };
  }, []);

  // Just for Debugging
  // useEffect(() => {
  //   console.log(`isLoading: ${isLoading}`);
  //   console.log(`aT: ${JSON.stringify(auth?.accessToken)}`);
  // }, [isLoading]);

  return <>{isLoading ? <GlobalSplashScreen /> : <Outlet />}</>;
}
