import React from "react";

import { Box, Typography } from "@mui/material";

import { useTheme } from "@mui/material/styles";

import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

import { useSurveyTheme } from "../../../../providers/surveyTheme";

import SurveyQuestionBlockHeader from "../../../SurveyComponents/SurveyQuestionBlockHeader";
import { useField } from "formik";
import { ScaleBlockFields } from "../../../../types/Blocks";
import ControlledTextField from "../../../Inputs/ControlledTextField";
import TransparentSurveyButton from "../../../SurveyComponents/TransparentSurveyButton";
import { generateAnswerTextStyle } from "../../../../utils/styles/generateAnswerTextStyle";
import SurveyBlockLayout from "../../../SurveyComponents/SurveyBlockLayout";

interface ScaleBlockProps {
  name: string;
  pageIndex: number;
  blockIndex: number;
  settingsHandler: () => void;
  deleteHandler: () => void;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  settingsPanelActive: boolean;
}

export interface INewTextFieldBlockFormFields {
  question: string;
  isQuestionRequired: boolean;
  requiredText: string;
}

export default function ScaleBlock({
  name,
  pageIndex,
  blockIndex,
  deleteHandler,
  settingsHandler,
  dragHandleProps,
  settingsPanelActive,
}: ScaleBlockProps) {
  const [field] = useField<ScaleBlockFields>(name);
  const surveyTheme = useSurveyTheme();
  const theme = useTheme();

  const scaleValues: number[] = [];
  for (let i = field.value.fromNumber; i <= field.value.toNumber; i++) {
    scaleValues.push(i);
  }

  return (
    <SurveyBlockLayout
      name={name}
      settingsHandler={settingsHandler}
      deleteHandler={deleteHandler}
      settingsPanelActive={settingsPanelActive}
      dragHandleProps={dragHandleProps}
    >
      <SurveyQuestionBlockHeader name={name} />
      <Box
        sx={{
          display: "flex",
        }}
      >
        {scaleValues.map((value, index) => {
          return (
            <TransparentSurveyButton
              key={index}
              variant="outlined"
              size="large"
              surveyTheme={surveyTheme}
              sx={{
                flexGrow: 1,
                ...(value !== scaleValues[0] && {
                  ml: 1,
                }),
                py: 2,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
                }}
              >
                {value}
              </Typography>
            </TransparentSurveyButton>
          );
        })}
      </Box>
      <Box
        sx={{
          mt: 1.5,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <ControlledTextField
          name={`${name}.minLabel`}
          id={`${name}.minLabel`}
          variant="standard"
          size="small"
          multiline
          required
          placeholder="Add min label (optional)"
          autoComplete="off"
          InputProps={{
            disableUnderline: true,
          }}
          inputProps={{
            style: {
              ...theme.typography.caption,
              ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
              ...(surveyTheme && {
                // We are using this override certain aspects genrated by
                // answer text style
                fontSize: theme.typography.caption.fontSize,
                fontWeight: theme.typography.caption.fontWeight,
              }),
            },
          }}
        />
        <ControlledTextField
          name={`${name}.midLabel`}
          id={`${name}.midLabel`}
          variant="standard"
          size="small"
          multiline
          required
          placeholder="Add mid label (optional)"
          autoComplete="off"
          InputProps={{
            disableUnderline: true,
          }}
          inputProps={{
            style: {
              ...theme.typography.caption,
              ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
              ...(surveyTheme && {
                // We are using this override certain aspects genrated by
                // answer text style
                fontSize: theme.typography.caption.fontSize,
                fontWeight: theme.typography.caption.fontWeight,
              }),
              textAlign: "center",
            },
          }}
        />
        <ControlledTextField
          name={`${name}.maxLabel`}
          id={`${name}.maxLabel`}
          variant="standard"
          size="small"
          multiline
          required
          placeholder="Add max label (optional)"
          autoComplete="off"
          InputProps={{
            disableUnderline: true,
          }}
          inputProps={{
            style: {
              ...theme.typography.caption,
              ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
              ...(surveyTheme && {
                // We are using this override certain aspects genrated by
                // answer text style
                fontSize: theme.typography.caption.fontSize,
                fontWeight: theme.typography.caption.fontWeight,
              }),
              textAlign: "end",
            },
          }}
        />
      </Box>
    </SurveyBlockLayout>
  );
}
