import React from "react";

import { Box, TextField, FormControl, Select, MenuItem } from "@mui/material";

import { useTheme } from "@mui/material/styles";

import {
  format,
  parse,
  getCountryCallingCode,
  CountryCode,
} from "libphonenumber-js";

import countries from "i18n-iso-countries";

import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

import { useSurveyTheme } from "../../../../providers/surveyTheme";

import { generateAnswerTextStyle } from "../../../../utils/styles/generateAnswerTextStyle";
import SurveyQuestionBlockHeader from "../../../SurveyComponents/SurveyQuestionBlockHeader";
import getUnicodeFlagIcon from "country-flag-icons/unicode";

import ExamplePhoneNumbers from "../../../../utils/examplePhoneNumbers.json";
import { useField } from "formik";
import { PhoneBlockFields } from "../../../../types/Blocks";
import { generateTextFieldStyle } from "../../../../utils/styles/generateTextFieldStyle";
import { FieldVariant } from "../../../../types/SurveyTheme";
import { generateSelectFieldStyle } from "../../../../utils/styles/generateSelectFieldStyle";
import SurveyBlockLayout from "../../../SurveyComponents/SurveyBlockLayout";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));

interface PhoneBlockProps {
  // formik: FormikContextType<IFormCanvasBlocksFields>;
  name: string;
  // index: number;
  pageIndex: number;
  blockIndex: number;
  settingsHandler: () => void;
  deleteHandler: () => void;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  settingsPanelActive: boolean;
}

export default function PhoneBlock({
  // formik,
  name,
  // index,
  pageIndex,
  blockIndex,
  deleteHandler,
  settingsHandler,
  dragHandleProps,
  settingsPanelActive,
}: PhoneBlockProps) {
  const [field] = useField<PhoneBlockFields>(name);
  const surveyTheme = useSurveyTheme();
  const theme = useTheme();

  const countryOptions = Object.keys(countries.getNames("en"))
    .filter((countryCode) => {
      return !["AQ", "BV", "TF", "HM", "PN", "GS", "UM"].includes(countryCode);
    })
    .map((countryCode) => ({
      label: countries.getName(countryCode, "en"),
      value: countryCode,
      icon: getUnicodeFlagIcon(countryCode),
      callingCode: "+" + getCountryCallingCode(countryCode as CountryCode),
      id: countryCode,
    }));

  // const myCountryOptions = Object.keys(countries.getNames("en"))
  //   .filter((countryCode) => {
  //     return !["AQ", "BV", "TF", "HM", "PN", "GS", "UM"].includes(countryCode);
  //   })
  //   .map((countryCode) => ({
  //     value: countryCode,
  //     callingCode: getCountryCallingCode(countryCode as CountryCode),
  //     id: countryCode,
  //   }));

  // const handlePhoneFormat = (value, countryCode: CountryCode) => {
  //   const callingCode = getCountryCallingCode(countryCode);
  //   const phoneNumber = parse(value, countryCode);
  //   // const formattedNumber = format(
  //   //   // @ts-ignore
  //   //   phoneNumber as string,
  //   //   countyCode,
  //   //   Numb
  //   // );
  //   return `+${callingCode} ${formattedNumber}`;
  // };

  const getExamplePhoneNumberFormat = (
    countryCode: CountryCode
  ): string | undefined => {
    const findExampleNumber = ExamplePhoneNumbers.find((value: any) => {
      return value.id === countryCode;
    });

    if (findExampleNumber) {
      return format(
        parse(findExampleNumber.exampleNumber, countryCode),
        "INTERNATIONAL"
      );
    }
  };

  return (
    <SurveyBlockLayout
      name={name}
      settingsHandler={settingsHandler}
      deleteHandler={deleteHandler}
      settingsPanelActive={settingsPanelActive}
      dragHandleProps={dragHandleProps}
    >
      <SurveyQuestionBlockHeader name={name} />

      <Box
        sx={{
          display: "flex",
          alignItems: "stretch",
        }}
      >
        <FormControl
          sx={{
            mr: 1,
          }}
        >
          <Select
            readOnly
            size="small"
            variant={(surveyTheme && surveyTheme.fieldVariant) ?? "outlined"}
            value={field.value.phoneDefaultCountry ?? "GB"}
            SelectDisplayProps={{
              style: {
                ...theme.typography.h6,
                ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
                lineHeight: undefined,
              },
            }}
            sx={{
              ...generateSelectFieldStyle(surveyTheme),
            }}
            renderValue={(value: any) => {
              return (
                <Box
                  sx={{
                    // fontSize: 30,
                    // fontSize: theme.typography.h6.fontSize,
                    ...(surveyTheme && {
                      ...generateAnswerTextStyle(surveyTheme),
                      ...(surveyTheme.fieldVariant === FieldVariant.OUTLINED
                        ? {
                            mr: 1,
                          }
                        : {
                            mx: 1,
                          }),
                    }),
                  }}
                >
                  {getUnicodeFlagIcon(value)}
                </Box>
              );
            }}
          >
            {countryOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Box sx={{ mr: 1 }}>{option.icon}</Box>

                    {option.label}
                  </Box>
                  {option.callingCode}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          placeholder={getExamplePhoneNumberFormat(
            field.value.phoneDefaultCountry
          )}
          value=""
          variant={(surveyTheme && surveyTheme.fieldVariant) ?? "outlined"}
          size="small"
          required
          fullWidth
          sx={{
            ...generateTextFieldStyle(surveyTheme),
          }}
          inputProps={{
            style: {
              // fontSize: theme.typography.h6.fontSize,
              ...theme.typography.h6,
              ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
            },
          }}
        />
      </Box>
    </SurveyBlockLayout>
  );
}
