import axios from "axios";

import { API_BASE_URL } from "../data/constants";

export default axios.create({
  baseURL: API_BASE_URL,
});

export const ApiProtected = axios.create({
  baseURL: API_BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
