import React, { useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { Formik, Form, FormikProps } from "formik";
import * as yup from "yup";

import {
  Typography,
  Card,
  Container,
  CardContent,
  Button,
  Box,
  CircularProgress,
  Alert,
} from "@mui/material";

import ControlledTextField from "../components/Inputs/ControlledTextField";

import { RESET_PASSWORD_URL } from "../data/constants";

import Api from "../api/api";

import LivelyFormsLogo from "../assets/Lively_Forms_Logo_Blue.svg";
import { useAuth } from "../hooks/useAuth";

const INITIAL_FORM_STATE = {
  newPassword: "",
  confirmPassword: "",
};

const FORM_VALIDATION = yup.object().shape({
  newPassword: yup.string().required("Password is requried"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword")], "Passwords must match")
    .required("Confirm Password is required"),
});

export interface ResetPasswordFormFields {
  newPassword: string;
  confirmPassword: string;
}

type ResetPasswordProps = {};

export default function ResetPassword({}: ResetPasswordProps) {
  const { passwordResetToken } = useParams();

  const navigate = useNavigate();

  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isSuccess, setIsSuccess] = useState("");

  const { auth, setAuth } = useAuth();

  const handleSubmit = async (values: ResetPasswordFormFields) => {
    try {
      setError("");
      setIsSuccess("");
      setIsLoading(true);

      await Api.post(`${RESET_PASSWORD_URL}/${passwordResetToken}`, values, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });

      setAuth({ user: {}, accessToken: "" });

      setIsSuccess("Your password was successfully reset. You can now Log In.");
      setIsLoading(false);
    } catch (error) {
      setIsSuccess("");
      setIsLoading(false);
      // @ts-ignore
      if (!error?.response) {
        setError("No response from server.");
        // @ts-ignore
      } else if (error?.response) {
        // @ts-ignore
        setError(error.response.data.error.message);
      } else {
        setError("Unable to reset password.");
      }
    }
  };

  return (
    <Box
      sx={{
        pt: 7,
      }}
    >
      <Container maxWidth="xs">
        <Card
          sx={{
            py: 5,
            px: 2,
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: 3,
              }}
            >
              <img
                src={LivelyFormsLogo}
                alt="Lively Forms Logo"
                height={40}
                width={240}
              />
            </Box>

            <Typography sx={{ mb: 3 }} variant="body1" align="center">
              Reset Password
            </Typography>

            {!isSuccess && (
              <>
                {/* <Typography sx={{ mb: 3 }} variant="body2">
                  Pick a strong and secure password.
                </Typography> */}
                <Formik
                  initialValues={INITIAL_FORM_STATE as ResetPasswordFormFields}
                  validationSchema={FORM_VALIDATION}
                  onSubmit={(values: any) => {
                    handleSubmit(values);
                  }}
                  validateOnChange
                  validateOnBlur
                >
                  {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }: FormikProps<ResetPasswordFormFields>) => {
                    return (
                      <Form>
                        {error && (
                          <Alert sx={{ my: 3 }} severity="error">
                            {error}
                          </Alert>
                        )}

                        <ControlledTextField
                          name="newPassword"
                          label="New Password"
                          type="password"
                          required
                          sx={{ mb: 2 }}
                          autoFocus
                        ></ControlledTextField>

                        <ControlledTextField
                          name="confirmPassword"
                          label="Confirm Password"
                          type="password"
                          required
                          sx={{ mb: 2 }}
                        ></ControlledTextField>

                        <Button
                          type="submit"
                          variant="contained"
                          fullWidth
                          size="large"
                          disabled={loading}
                        >
                          {loading ? (
                            <CircularProgress color="primary" />
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </Form>
                    );
                  }}
                </Formik>
              </>
            )}

            {isSuccess && !loading && !error && (
              <>
                <Alert severity="success" variant="standard">
                  {isSuccess}
                </Alert>
              </>
            )}

            <Button
              sx={{ mt: 2, textTransform: "none" }}
              onClick={() => {
                navigate("/login");
              }}
            >
              Back to Log In
            </Button>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}
