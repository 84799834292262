import React from "react";

import { Outlet } from "react-router-dom";

import Header from "./Header";

type AppLayoutProps = {};

export default function AppLayout({}: AppLayoutProps) {
  return (
    <>
      <Header />
      <Outlet />
    </>
  );
}
