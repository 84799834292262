import React from "react";

import { Drawer, Toolbar } from "@mui/material";

export const SETTINGS_DRAWER_WIDTH = 280;

type SettingsDrawerPRops = {
  hidden: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

export default function SettingsDrawer({
  hidden,
  onClose,
  children,
}: SettingsDrawerPRops) {
  return (
    // <Box sx={{ width: SETTINGS_DRAWER_WIDTH }}>
    <Drawer
      hidden={hidden}
      variant="permanent"
      anchor="right"
      // open={true}
      onClose={onClose}
      sx={{
        width: SETTINGS_DRAWER_WIDTH,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: SETTINGS_DRAWER_WIDTH,
        },
      }}
    >
      <Toolbar />

      {children}
    </Drawer>
    // </Box>
  );
}
