import React from "react";

import { TextField, Typography, Stack } from "@mui/material";

import { useTheme } from "@mui/material/styles";

import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

import { useSurveyTheme } from "../../../../providers/surveyTheme";

import { generateAnswerTextStyle } from "../../../../utils/styles/generateAnswerTextStyle";
import SurveyQuestionBlockHeader from "../../../SurveyComponents/SurveyQuestionBlockHeader";
import ControlledTextField from "../../../Inputs/ControlledTextField";
import { useField } from "formik";
import { AddressBlockFields } from "../../../../types/Blocks";
import { generateTextFieldStyle } from "../../../../utils/styles/generateTextFieldStyle";
import SurveyBlockLayout from "../../../SurveyComponents/SurveyBlockLayout";

interface AddressBlockProps {
  // formik: FormikContextType<IFormCanvasBlocksFields>;
  name: string;
  // index: number;
  pageIndex: number;
  blockIndex: number;
  settingsHandler: () => void;
  deleteHandler: () => void;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  settingsPanelActive: boolean;
}

export default function AddressBlock({
  // formik,
  name,
  // index,
  pageIndex,
  blockIndex,
  deleteHandler,
  settingsHandler,
  dragHandleProps,
  settingsPanelActive,
}: AddressBlockProps) {
  const [field] = useField<AddressBlockFields>(name);
  const surveyTheme = useSurveyTheme();
  const theme = useTheme();

  return (
    <SurveyBlockLayout
      name={name}
      settingsHandler={settingsHandler}
      deleteHandler={deleteHandler}
      settingsPanelActive={settingsPanelActive}
      dragHandleProps={dragHandleProps}
    >
      <SurveyQuestionBlockHeader name={name} />

      {field.value.addressLine1.isVisible && (
        <>
          <Stack direction="row">
            {field.value.addressLine1.isRequired && (
              <Typography sx={{ mr: 0.5, color: "red" }}>*</Typography>
            )}

            <ControlledTextField
              name={`${name}.addressLine1.label`}
              id={`${name}.addressLine1.label`}
              variant="standard"
              size="small"
              multiline
              required
              placeholder="Type Address LIne 1 label"
              autoComplete="off"
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                mb: 1,
              }}
            />
          </Stack>
          <TextField
            placeholder="1 Blue Road"
            value=""
            variant={(surveyTheme && surveyTheme.fieldVariant) ?? "outlined"}
            size="small"
            required
            fullWidth
            sx={{
              ...generateTextFieldStyle(surveyTheme),
            }}
            inputProps={{
              style: {
                ...theme.typography.h6,
                ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
              },
            }}
          />
        </>
      )}
      {field.value.addressLine2.isVisible && (
        <>
          <Stack direction="row">
            {field.value.addressLine2.isRequired && (
              <Typography sx={{ mr: 0.5, color: "red" }}>*</Typography>
            )}

            <ControlledTextField
              name={`${name}.addressLine2.label`}
              id={`${name}.addressLine2.label`}
              variant="standard"
              size="small"
              multiline
              required
              placeholder="Type Address LIne 2 label"
              autoComplete="off"
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                my: 1,
              }}
            />
          </Stack>
          <TextField
            placeholder="Block 2"
            value=""
            variant={(surveyTheme && surveyTheme.fieldVariant) ?? "outlined"}
            size="small"
            required
            fullWidth
            sx={{
              ...generateTextFieldStyle(surveyTheme),
            }}
            inputProps={{
              style: {
                ...theme.typography.h6,
                ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
              },
            }}
          />
        </>
      )}
      {field.value.addressTown.isVisible && (
        <>
          <Stack direction="row">
            {field.value.addressTown.isRequired && (
              <Typography sx={{ mr: 0.5, color: "red" }}>*</Typography>
            )}
            <ControlledTextField
              name={`${name}.addressTown.label`}
              id={`${name}.addressTown.label`}
              variant="standard"
              size="small"
              multiline
              required
              placeholder="Type City/ Town label"
              autoComplete="off"
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                my: 1,
              }}
            />
          </Stack>
          <TextField
            placeholder="Westminister"
            value=""
            variant={(surveyTheme && surveyTheme.fieldVariant) ?? "outlined"}
            size="small"
            required
            fullWidth
            sx={{
              ...generateTextFieldStyle(surveyTheme),
            }}
            inputProps={{
              style: {
                ...theme.typography.h6,
                ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
              },
            }}
          />
        </>
      )}
      {field.value.addressState.isVisible && (
        <>
          <Stack direction="row">
            {field.value.addressState.isRequired && (
              <Typography sx={{ mr: 0.5, color: "red" }}>*</Typography>
            )}

            <ControlledTextField
              name={`${name}.addressState.label`}
              id={`${name}.addressState.label`}
              variant="standard"
              size="small"
              multiline
              required
              placeholder="Type State/ Provinc label"
              autoComplete="off"
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                my: 1,
              }}
            />
          </Stack>
          <TextField
            placeholder="London"
            value=""
            variant={(surveyTheme && surveyTheme.fieldVariant) ?? "outlined"}
            size="small"
            required
            fullWidth
            sx={{
              ...generateTextFieldStyle(surveyTheme),
            }}
            inputProps={{
              style: {
                ...theme.typography.h6,
                ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
              },
            }}
          />
        </>
      )}
      {field.value.addressPostCode.isVisible && (
        <>
          <Stack direction="row">
            {field.value.addressPostCode.isRequired && (
              <Typography sx={{ mr: 0.5, color: "red" }}>*</Typography>
            )}

            <ControlledTextField
              name={`${name}.addressPostCode.label`}
              id={`${name}.addressPostCode.label`}
              variant="standard"
              size="small"
              multiline
              required
              placeholder="Type ZIP / Post Code label"
              autoComplete="off"
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                my: 1,
              }}
            />
          </Stack>
          <TextField
            placeholder="WE1 2X"
            value=""
            variant={(surveyTheme && surveyTheme.fieldVariant) ?? "outlined"}
            size="small"
            required
            fullWidth
            sx={{
              ...generateTextFieldStyle(surveyTheme),
            }}
            inputProps={{
              style: {
                ...theme.typography.h6,
                ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
              },
            }}
          />
        </>
      )}
      {field.value.addressCountry.isVisible && (
        <>
          <Stack direction="row">
            {field.value.addressCountry.isRequired && (
              <Typography sx={{ mr: 0.5, color: "red" }}>*</Typography>
            )}

            <ControlledTextField
              name={`${name}.addressCountry.label`}
              id={`${name}.addressCountry.label`}
              variant="standard"
              size="small"
              multiline
              required
              placeholder="Type Country label"
              autoComplete="off"
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                my: 1,
              }}
            />
          </Stack>
          <TextField
            placeholder="United Kingdom"
            value=""
            variant={(surveyTheme && surveyTheme.fieldVariant) ?? "outlined"}
            size="small"
            required
            fullWidth
            sx={{
              ...generateTextFieldStyle(surveyTheme),
            }}
            inputProps={{
              style: {
                ...theme.typography.h6,
                ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
              },
            }}
          />
        </>
      )}
    </SurveyBlockLayout>
  );
}
