import React from "react";

import { useField } from "formik";

import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { QuestionFields } from "../../types/Blocks";
import ControlledTextField from "../Inputs/ControlledTextField";
import { useSurveyTheme } from "../../providers/surveyTheme";
import { generateQuestionTextStyle } from "../../utils/styles/generateQuestionTextStyle";
import { generateQuestionDescriptionTextStyle } from "../../utils/styles/generateQuestionDescriptionTextStyle";

type SurveyQuestionBlockHeaderProps = {
  name: string;
};

export default function SurveyQuestionBlockHeader({
  name,
}: SurveyQuestionBlockHeaderProps) {
  const theme = useTheme();
  const surveyTheme = useSurveyTheme();
  const [field] = useField<QuestionFields>(name);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "start",
        mb: 2,
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <Box sx={{ display: "flex", flexGrow: 1 }}>
          {field.value.isQuestionRequired && (
            <Typography sx={{ mr: 0.5, color: "red" }} variant="h6">
              *
            </Typography>
          )}
          {!field.value.isDynamicQuestion && (
            <ControlledTextField
              name={`${name}.question`}
              id={`${name}.question`}
              variant="standard"
              size="small"
              multiline
              required
              placeholder="Type your question here."
              autoComplete="off"
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{
                style: {
                  ...theme.typography.h6,
                  ...(surveyTheme && generateQuestionTextStyle(surveyTheme)),
                },
              }}
            />
          )}
          {field.value.isDynamicQuestion && (
            <Typography variant="h6">Dynmically Genrated Question</Typography>
          )}
        </Box>
        {!field.value.isDynamicQuestion && (
          <ControlledTextField
            name={`${name}.questionDescription`}
            id={`${name}.questionDescription`}
            variant="standard"
            size="small"
            multiline
            required
            placeholder="Add a description (optional)"
            autoComplete="off"
            InputProps={{
              disableUnderline: true,
            }}
            inputProps={{
              style: {
                ...theme.typography.body2,
                ...(surveyTheme &&
                  generateQuestionDescriptionTextStyle(surveyTheme)),
              },
            }}
          />
        )}
      </Box>
    </Box>
  );
}
