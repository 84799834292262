import React from "react";

import { Box, Typography, CircularProgress } from "@mui/material";

type GlobalSplashScreenProps = {};

export default function GlobalSplashScreen({}: GlobalSplashScreenProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
      }}
    >
      <Typography fontWeight="bold" sx={{ mb: 5 }} variant="h5" align="center">
        Lively Forms
      </Typography>
      <CircularProgress />
    </Box>
  );
}
