import React from "react";

import { Box, Typography } from "@mui/material";

import { QuestionTypes } from "../../../../types/Blocks";
import NewQuestionRequiredOption from "../../Options/QuestionRequiredOption";
import NewConditionalVisibilityOptions from "../../Options/ConditionalVisibilityOptions";
import DynamicQuestionOption from "../../Options/DynamicQuestionOption";
import ControlledSwitch from "../../../Inputs/ControlledSwitch";
import TabsLayout from "../../../Layout/TabsLayout";
import InfoTooltip from "../../../InfoTooltip";

type ImageChoiceBlockSettingsPanelProps = {
  name: string;
  pageIndex: number;
  blockIndex: number;
};

export default function ImageChoiceBlockSettingsPanel({
  name,
  pageIndex,
  blockIndex,
}: ImageChoiceBlockSettingsPanelProps) {
  return (
    <TabsLayout
      tabOptions={{
        options: {
          label: "Options",
          tabPanel: (
            <>
              <Box
                sx={{
                  mt: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1" sx={{ mr: 1 }}>
                      Multiple Choices
                    </Typography>
                    <InfoTooltip title="Control whether or not respondants can select mutiple images or a single image." />
                  </Box>
                  <ControlledSwitch
                    name={`${name}.imageChoiceHasMultipleChoices`}
                    id={`${name}.imageChoiceHasMultipleChoices`}
                    size="small"
                  />
                </Box>
              </Box>

              <Box sx={{ mt: 3 }}>
                <NewQuestionRequiredOption
                  name={name}
                ></NewQuestionRequiredOption>
              </Box>
              <Box sx={{ mt: 3 }}>
                <DynamicQuestionOption
                  name={name}
                  pageIndex={pageIndex}
                  blockIndex={blockIndex}
                ></DynamicQuestionOption>
              </Box>
            </>
          ),
        },
        visibility: {
          label: "Visibility",
          tabPanel: (
            <NewConditionalVisibilityOptions
              name={name}
              itemType={QuestionTypes.IMAGE_CHOICE}
              itemTitle="question"
              pageIndex={pageIndex}
              blockIndex={blockIndex}
            ></NewConditionalVisibilityOptions>
          ),
        },
      }}
    />
  );
}
