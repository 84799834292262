import { lighten, alpha } from "@mui/material/styles";

import { LiveSurveyThemeDocument } from "../../types/SurveyTheme";

export const generateTextFieldStyle = (
  surveyTheme: LiveSurveyThemeDocument | null
) => {
  return {
    ...(surveyTheme && {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: alpha(
            lighten(surveyTheme.colorSettings.answerTextColor, 0.25),
            0.6
          ),
        },
        "&:hover fieldset": {
          borderColor: lighten(surveyTheme.colorSettings.answerTextColor, 0.5),
        },
        "&.Mui-focused fieldset": {
          borderColor: surveyTheme.colorSettings.answerTextColor,
        },
      },
      "& .MuiInput-underline": {
        ":before": {
          borderColor: alpha(
            lighten(surveyTheme.colorSettings.answerTextColor, 0.25),
            0.6
          ),
        },
        ":after": {
          borderColor: surveyTheme.colorSettings.answerTextColor,
        },
      },
      "& .MuiInput-root": {
        "&:not(.Mui-disabled):hover::before": {
          borderColor: lighten(surveyTheme.colorSettings.answerTextColor, 0.5),
        },
      },
    }),
  };
};
