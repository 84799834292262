import React from "react";

import { Box, Typography, IconButton } from "@mui/material";

import { DeleteOutlineOutlined, SettingsOutlined } from "@mui/icons-material";

type INewBlockCardTopHeaderProps = {
  icon: React.ReactNode;
  title: string;
  settingsClickHandler: () => void;
  deleteHandler: () => void;
};

export default function NewBlockCardTopHeader({
  icon,
  title,
  settingsClickHandler,
  deleteHandler,
}: INewBlockCardTopHeaderProps) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {React.cloneElement(icon as React.ReactElement, {
          ...{
            sx: {
              mr: 1,
            },
            color: "primary",
            fontSize: "small",
          },
        })}
        <Typography variant="h6" color="text.secondary">
          {title}
        </Typography>
      </Box>
      <Box>
        <IconButton onClick={settingsClickHandler}>
          <SettingsOutlined fontSize="small" />
        </IconButton>
        <IconButton onClick={deleteHandler}>
          <DeleteOutlineOutlined fontSize="small" color="error" />
        </IconButton>
      </Box>
    </Box>
  );
}
