import * as yup from "yup";
import { QuestionTypes } from "../types/Blocks";

import { Actions } from "../types/Actions";

const VISIBILITY_CONDITION_VALIDATION = {
  questionId: yup
    .string()
    .required("You must selecta question id for the condition."),
  logicalOperator: yup
    .string()
    .required("You must select a select a logical operator for the condition."),
  answerValue: yup
    .string()
    .required("You must provide an answer value for the condition."),
};

const VISIBILITY_SETTINGS_VALIDATION = yup
  .object()
  .when(
    "hasConditionalVisibility",
    ([hasConditionalVisibility]: any[], schema) => {
      return hasConditionalVisibility
        ? schema.shape({
            isVisible: yup
              .string()
              .required(
                "You must select whether or not the action path should be hidden."
              ),
            primaryCondition: yup
              .object()
              .shape(VISIBILITY_CONDITION_VALIDATION),
            secondaryConditions: yup
              .array()
              .of(
                yup.object().shape({
                  ...VISIBILITY_CONDITION_VALIDATION,
                  logicalUnion: yup
                    .string()
                    .required(
                      "You must provide a logical union between conditions"
                    ),
                })
              )
              .min(1, "At least one condition is required"),
          })
        : schema;
    }
  );

export const FORM_VALIDATION = yup.object().shape({
  pages: yup.array().of(
    yup.object().shape({
      questions: yup
        .array()
        .of(
          yup.object().shape({
            type: yup.string().required("You must provide a block type."),
            question: yup.string().required("You must provide a question."),
            isQuestionRequired: yup
              .boolean()
              .required(
                "You must specify whether or not this question should be reuqired."
              ),
            requiredText: yup
              .string()
              .required(
                "You must provide text for an error message to appear when the question is not answered."
              ),
            hasConditionalVisibility: yup
              .boolean()
              .required(
                "You must specify whether or not this question's visibility should be conditional."
              ),
            visibilitySettings: VISIBILITY_SETTINGS_VALIDATION,
            // checkboxes: yup.string(),
            checkboxes: yup
              .array()
              .when("type", (type: QuestionTypes[], schema) => {
                return type[0] === QuestionTypes.CHECKBOXES
                  ? schema
                      .of(
                        yup.object().shape({
                          id: yup
                            .string()
                            .required("An Id is required for a checkbox"),
                          value: yup
                            .string()
                            .required(
                              "You must provide a value for the option name."
                            ),
                        })
                      )
                      .min(1, "At least one checkbox is required")
                  : schema;
              }),
          })
        )
        .required("You must provide a list of form blocks"),
      hasConditionalVisibility: yup
        .boolean()
        .required(
          "You must specify whether or not this pages's visibility should be conditional."
        ),
      visibilitySettings: VISIBILITY_SETTINGS_VALIDATION,
    })
  ),
  actionPaths: yup.array().of(
    yup.object().shape({
      actions: yup.array().of(
        yup.object().shape({
          type: yup.string().required("You must provide an action type."),
          hasConditionalVisibility: yup
            .boolean()
            .required(
              "You must specify whether or not this action's visibility should be conditional."
            ),
          visibilitySettings: VISIBILITY_SETTINGS_VALIDATION,
          redirectionUrl: yup
            .string()
            .when("type", (types: Actions[], schema) => {
              return types[0] === Actions.REDIRECT
                ? schema
                    .matches(
                      /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
                      "URL is not valid"
                    )
                    .required("You must provide a redirection Url.")
                : schema;
            }),
        })
      ),
      hasConditionalVisibility: yup
        .boolean()
        .required(
          "You must specify whether or not this action path's visibility should be conditional."
        ),
      visibilitySettings: VISIBILITY_SETTINGS_VALIDATION,
    })
  ),
});
