import React from "react";

import { useField } from "formik";

import { TextField, TextFieldProps, MenuItem } from "@mui/material";

type ControlledSelectProps = TextFieldProps & {
  name: string;
  options: { [key: string]: any };
};

export default function ControlledSelect({
  name,
  options,
  children,
  ...otherPRops
}: ControlledSelectProps) {
  const [field, meta, helpers] = useField(name);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    helpers.setValue(value);
  };

  const textFieldConfig: TextFieldProps = {
    fullWidth: true,
    variant: "outlined",
    size: "small",
    ...field,

    // Set value to empty string
    // @ts-ignore
    value: field.value === undefined ? "" : field.value,
    ...otherPRops,
    select: true,
    onChange: handleChange,
  };

  if (meta && meta.touched && meta.error) {
    textFieldConfig.error = true;
    textFieldConfig.helperText = meta.error;
  }

  return (
    <TextField {...textFieldConfig}>
      {!children &&
        Object.keys(options).map((item, index) => {
          return (
            <MenuItem key={index} value={item}>
              {options[item]}
            </MenuItem>
          );
        })}
      {children && children}
    </TextField>
  );
}
