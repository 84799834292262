import React, { ReactNode, useEffect, useRef, useState } from "react";

import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  Rating,
  Snackbar,
  Stack,
  Typography,
  Slide,
  SlideProps,
} from "@mui/material";

import {
  DataGrid,
  GridFilterModel,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";

import {
  AutoAwesomeOutlined,
  CheckBoxOutlined,
  QuestionAnswerOutlined,
  ShareOutlined,
} from "@mui/icons-material";

import { parsePhoneNumber } from "libphonenumber-js";

import { useParams } from "react-router-dom";
import {
  LiveAnswerDocument,
  LiveAnswerPageDocument,
  LiveResponseDcoument,
} from "../types/Responses";
import { LivePublishedSurveyDocument } from "../types/Forms";
import { useProtectedApi } from "../hooks/useProtectedApi";
import { FRONTEND_BASE_URL, RESPONSE_URL } from "../data/constants";
import { AxiosError } from "axios";

import { format, parseISO } from "date-fns";

import {
  CheckboxValue,
  FormSubItemTypes,
  ImageChoiceValue,
  LiveAddressAnswer,
  LiveFormSubItemDocument,
  LivePageDocument,
  LiveQuestionDocument,
  QuestionTypes,
  StarRatingIcons,
} from "../types/Blocks";
import QuestionTypeIcon from "../components/QuestionTypeIcon";
import { STAR_RATING_ICON_SHAPES } from "../data/BlockFields";
import ResponseImageChoiceCard from "../components/SurveyComponents/ResponseImageChoiceCard";
import { buildDateFormatString } from "../utils/buildDateFormatString";

import getCountryFlag from "country-flag-icons/unicode";
import EmptyMailBoxIcon from "../components/Icons/EmptyMailBoxIcon";
import EmptyPlaceHolder from "../components/Layout/EmptyPlaceHolder";

export type ResponsesResult = {
  responses: LiveResponseDcoument[];
  publishedSurvey: LivePublishedSurveyDocument;
};

export enum ResponseSortFilterModes {
  QUESTIONS = "questions",
  PAGES = "pages ",
  RESPONSES = "responses",
}

export type SortOptions = {
  sortField: string;
  sortValue: string;
  sortMode: ResponseSortFilterModes;
};

export type FilterOptions = {
  filterField: string;
  filterOperator: string;
  filterValue: string;
  filterMode: ResponseSortFilterModes;
};

const RESPONSE_SORTABLE_FILTERABLE_FIELDS = ["createdAt", "submissionDate"];

function TransitionRight(props: SlideProps) {
  return <Slide {...props} direction="right" />;
}

type ResponsesProps = {};

export default function Responses({}: ResponsesProps) {
  const { surveyId } = useParams();

  const isInitialMount = useRef(true);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const pageNumber = useRef(1);
  const [totalNumOfResponses, setTotalNumOfResponses] = useState(0);

  const [responsesResult, setResponsesResult] = useState<
    LiveResponseDcoument[]
  >([]);

  const [publishedSurveyResult, setPublishedSurveyResult] =
    useState<LivePublishedSurveyDocument>();

  const [dataTableCols, setDataTableCols] = useState<any[]>();

  const [dataTableRows, setDataTableRows] = useState<any[]>();

  const [sortOptions, setSortOptions] = useState<SortOptions>();
  const [filterOptions, setFilterOptions] = useState<FilterOptions>();

  const [publishedSurveyIdentifier, setPublishedSurveyIdentifier] =
    useState("");

  const ProtectedApi = useProtectedApi();

  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleResponseQuery = async () => {
    const response = await ProtectedApi.get(
      `${RESPONSE_URL}/survey/${surveyId}`,
      {
        params: {
          page: pageNumber.current,
          // page: 1,
          limit: 10,
          // If we have sort sort options then spread them here
          ...(sortOptions && sortOptions),
          ...(filterOptions && filterOptions),
        },
      }
    );

    if (response.data.success.data.responses) {
      setResponsesResult((prevResults: LiveResponseDcoument[]) => {
        return [...prevResults, ...response.data.success.data.responses];
      });

      if (response.data.success.data.numOfResponses) {
        setTotalNumOfResponses(response.data.success.data.numOfResponses);
      } else {
        setTotalNumOfResponses(response.data.success.data.responses.length);
      }
    }

    if (response.data.success.data.publishedSurvey) {
      setDataTableCols(
        prepareTableCols(response.data.success.data.publishedSurvey.pages)
      );
      setPublishedSurveyIdentifier(
        response.data.success.data.publishedSurvey.identifier
      );
    }

    if (
      response.data.success.data.responses &&
      response.data.success.data.publishedSurvey
    ) {
      setDataTableRows(
        prepareTableRows(
          response.data.success.data.responses,
          response.data.success.data.publishedSurvey.pages
        )
      );
    }
  };

  const getResponses = async () => {
    try {
      setLoading(true);
      setError("");
      setResponsesResult([]);
      setPublishedSurveyResult(undefined);
      await handleResponseQuery();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error instanceof AxiosError && !error?.response) {
        setError("No response from server.");
      } else if (error instanceof AxiosError && error?.response?.data) {
        setError(error.response.data.error.message);
      } else {
        setError("Fialed to get forms.");
      }
    }
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      getResponses();
    }
  }, []);

  const prepareTableCols = (surveyPages: LivePageDocument[]) => {
    return [
      {
        field: "createdAt",
        headerName: "Start Date",
        width: 125,
        renderHeader: () => {
          return <Typography>Start Date</Typography>;
        },
      },
      {
        field: "submissionDate",
        headerName: "Submitted",
        width: 125,
        renderHeader: () => {
          return <Typography>Submitted Date</Typography>;
        },
      },
      ...surveyPages.reduce(
        (
          previousValue: any,
          currentValue: LivePageDocument,
          currentIndex: number
        ) => {
          return [
            ...previousValue,
            /*
              NOTE : We want to biuld address columns seperately for each field of the address
            */
            ...currentValue.subItems
              .filter((subItem: LiveFormSubItemDocument) => {
                return (
                  subItem.subItemType === FormSubItemTypes.QUESTION &&
                  (subItem as LiveQuestionDocument).questionType ===
                    QuestionTypes.ADDRESS
                );
              })
              .reduce(
                (
                  previousValue: any,
                  currentValue: LiveFormSubItemDocument,
                  currentIndex: number
                ) => {
                  return [
                    ...previousValue,
                    ...((currentValue as LiveQuestionDocument).addressLine1
                      .isVisible
                      ? [
                          {
                            field: currentValue.subItemId + ".addressLine1",
                            headerName: (currentValue as LiveQuestionDocument)
                              .question,
                            width: 300,
                            isDynamicQuestion: (
                              currentValue as LiveQuestionDocument
                            ).isDynamicQuestion,
                            questionType: (currentValue as LiveQuestionDocument)
                              .questionType,
                            renderHeader: () => {
                              return headerColumnBuilder(
                                (currentValue as LiveQuestionDocument)
                                  .questionType,
                                (currentValue as LiveQuestionDocument).question,
                                (currentValue as LiveQuestionDocument)
                                  .isDynamicQuestion,
                                (currentValue as LiveQuestionDocument)
                                  .addressLine1.label ?? "Address LIne 1"
                              );
                            },
                          },
                        ]
                      : []),
                    ...((currentValue as LiveQuestionDocument).addressLine2
                      .isVisible
                      ? [
                          {
                            field: currentValue.subItemId + ".addressLine2",
                            headerName: (currentValue as LiveQuestionDocument)
                              .question,
                            width: 300,
                            isDynamicQuestion: (
                              currentValue as LiveQuestionDocument
                            ).isDynamicQuestion,
                            questionType: (currentValue as LiveQuestionDocument)
                              .questionType,
                            renderHeader: () => {
                              return headerColumnBuilder(
                                (currentValue as LiveQuestionDocument)
                                  .questionType,
                                (currentValue as LiveQuestionDocument).question,
                                (currentValue as LiveQuestionDocument)
                                  .isDynamicQuestion,
                                (currentValue as LiveQuestionDocument)
                                  .addressLine2.label ?? "Address LIne 2"
                              );
                            },
                          },
                        ]
                      : []),
                    ...((currentValue as LiveQuestionDocument).addressTown
                      .isVisible
                      ? [
                          {
                            field: currentValue.subItemId + ".addressTown",
                            headerName: (currentValue as LiveQuestionDocument)
                              .question,
                            width: 300,
                            isDynamicQuestion: (
                              currentValue as LiveQuestionDocument
                            ).isDynamicQuestion,
                            questionType: (currentValue as LiveQuestionDocument)
                              .questionType,
                            renderHeader: () => {
                              return headerColumnBuilder(
                                (currentValue as LiveQuestionDocument)
                                  .questionType,
                                (currentValue as LiveQuestionDocument).question,
                                (currentValue as LiveQuestionDocument)
                                  .isDynamicQuestion,
                                (currentValue as LiveQuestionDocument)
                                  .addressTown.label ?? "Town/ City"
                              );
                            },
                          },
                        ]
                      : []),
                    ...((currentValue as LiveQuestionDocument).addressState
                      .isVisible
                      ? [
                          {
                            field: currentValue.subItemId + ".addressState",
                            headerName: (currentValue as LiveQuestionDocument)
                              .question,
                            width: 300,
                            isDynamicQuestion: (
                              currentValue as LiveQuestionDocument
                            ).isDynamicQuestion,
                            questionType: (currentValue as LiveQuestionDocument)
                              .questionType,
                            renderHeader: () => {
                              return headerColumnBuilder(
                                (currentValue as LiveQuestionDocument)
                                  .questionType,
                                (currentValue as LiveQuestionDocument).question,
                                (currentValue as LiveQuestionDocument)
                                  .isDynamicQuestion,
                                (currentValue as LiveQuestionDocument)
                                  .addressState.label ?? "State/ County"
                              );
                            },
                          },
                        ]
                      : []),
                    ...((currentValue as LiveQuestionDocument).addressPostCode
                      .isVisible
                      ? [
                          {
                            field: currentValue.subItemId + ".addressPostCode",
                            headerName: (currentValue as LiveQuestionDocument)
                              .question,
                            width: 300,
                            isDynamicQuestion: (
                              currentValue as LiveQuestionDocument
                            ).isDynamicQuestion,
                            questionType: (currentValue as LiveQuestionDocument)
                              .questionType,
                            renderHeader: () => {
                              return headerColumnBuilder(
                                (currentValue as LiveQuestionDocument)
                                  .questionType,
                                (currentValue as LiveQuestionDocument).question,
                                (currentValue as LiveQuestionDocument)
                                  .isDynamicQuestion,
                                (currentValue as LiveQuestionDocument)
                                  .addressPostCode.label ?? "ZIP/ Post Code"
                              );
                            },
                          },
                        ]
                      : []),
                    ...((currentValue as LiveQuestionDocument).addressCountry
                      .isVisible
                      ? [
                          {
                            field: currentValue.subItemId + ".addressCountry",
                            headerName: (currentValue as LiveQuestionDocument)
                              .question,
                            width: 300,
                            isDynamicQuestion: (
                              currentValue as LiveQuestionDocument
                            ).isDynamicQuestion,
                            questionType: (currentValue as LiveQuestionDocument)
                              .questionType,
                            renderHeader: () => {
                              return headerColumnBuilder(
                                (currentValue as LiveQuestionDocument)
                                  .questionType,
                                (currentValue as LiveQuestionDocument).question,
                                (currentValue as LiveQuestionDocument)
                                  .isDynamicQuestion,
                                (currentValue as LiveQuestionDocument)
                                  .addressCountry.label ?? "Country"
                              );
                            },
                          },
                        ]
                      : []),
                  ];
                },
                []
              ),

            ...currentValue.subItems
              .filter((subItem: LiveFormSubItemDocument) => {
                return (
                  subItem.subItemType === FormSubItemTypes.QUESTION &&
                  (subItem as LiveQuestionDocument).questionType !==
                    QuestionTypes.ADDRESS
                );
              })
              .map((question: LiveFormSubItemDocument) => {
                return {
                  field: question.subItemId,
                  headerName: (question as LiveQuestionDocument).question,
                  width: 300,
                  isDynamicQuestion: (question as LiveQuestionDocument)
                    .isDynamicQuestion,
                  questionType: (question as LiveQuestionDocument).questionType,
                  ...((question as LiveQuestionDocument).questionType ===
                    QuestionTypes.STAR_RATING && {
                    ratingScale: (question as LiveQuestionDocument).scale,
                    ratingIcon: (question as LiveQuestionDocument).icon,
                  }),
                  renderHeader: () => {
                    return headerColumnBuilder(
                      (question as LiveQuestionDocument).questionType,
                      (question as LiveQuestionDocument).question,
                      (question as LiveQuestionDocument).isDynamicQuestion
                    );
                  },
                  renderCell: (params: any) => {
                    if (params.colDef.isDynamicQuestion) {
                      return (
                        <Box
                          sx={{
                            width: "100%",
                            py: 1,
                          }}
                        >
                          <Card
                            sx={{
                              width: "100%",
                            }}
                          >
                            <CardContent>
                              <Box sx={{ mb: 1, display: "flex" }}>
                                <AutoAwesomeOutlined
                                  color="primary"
                                  fontSize="small"
                                  sx={{ mr: 1 }}
                                />
                                <Typography variant="body2">
                                  {params.row[
                                    `${params.field}_dynamicQuestionText`
                                  ] ?? "N/A"}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                }}
                              >
                                <QuestionAnswerOutlined
                                  color="success"
                                  fontSize="small"
                                  sx={{ mr: 1 }}
                                />
                                <Typography variant="body2">
                                  {params.value ?? ""}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Box>
                      );
                    }

                    if (
                      params.colDef.questionType === QuestionTypes.CHECKBOXES
                    ) {
                      return (
                        <Box
                          sx={{
                            width: "100%",
                            mb: 1,
                          }}
                        >
                          {params.value.map((checkbox: CheckboxValue) => {
                            return (
                              <Box
                                key={checkbox.checkboxId}
                                sx={{ display: "flex", mt: 1 }}
                              >
                                <CheckBoxOutlined
                                  sx={{ mr: 1 }}
                                  fontSize="small"
                                />
                                <Typography>{checkbox.value}</Typography>
                              </Box>
                            );
                          })}
                        </Box>
                      );
                    }

                    if (
                      params.colDef.questionType === QuestionTypes.STAR_RATING
                    ) {
                      return (
                        <Box
                          sx={{
                            width: "100%",
                            my: 1,
                          }}
                        >
                          <Rating
                            // TODO - Fix the type of the scale value that is set. Make sure it is a number when set
                            value={parseInt(params.value) ?? 0}
                            max={parseInt(params.colDef.ratingScale)}
                            readOnly
                            icon={React.cloneElement(
                              STAR_RATING_ICON_SHAPES[
                                params.colDef.ratingIcon as StarRatingIcons
                              ].icon,
                              {
                                sx: {
                                  fontSize: "25px",
                                  color: (question as LiveQuestionDocument)
                                    .color,
                                },
                              }
                            )}
                            emptyIcon={React.cloneElement(
                              STAR_RATING_ICON_SHAPES[
                                params.colDef.ratingIcon as StarRatingIcons
                              ].emptyIcon,
                              {
                                sx: {
                                  fontSize: "25px",
                                  color: (question as LiveQuestionDocument)
                                    .color,
                                },
                              }
                            )}
                          />
                        </Box>
                      );
                    }

                    if (params.colDef.questionType === QuestionTypes.YESNO) {
                      return (
                        <Box
                          sx={{
                            my: 2,
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderRadius: "5px",
                            minWidth: "75px",
                            textAlign: "center",
                            // px: 2,
                            py: 0.5,
                            // backgroundColor: "blue",
                          }}
                        >
                          <Typography>{params.value.toUpperCase()}</Typography>
                        </Box>
                      );
                    }

                    if (params.colDef.questionType === QuestionTypes.RANKING) {
                      return (
                        <Box
                          sx={{
                            py: 1,
                          }}
                        >
                          {params.value &&
                            params.value.map((value: string, index: number) => {
                              return (
                                <Typography key={index}>
                                  {index + 1}. {value}
                                </Typography>
                              );
                            })}
                        </Box>
                      );
                    }

                    if (
                      params.colDef.questionType === QuestionTypes.IMAGE_CHOICE
                    ) {
                      return (
                        <Box
                          sx={{
                            py: 1,
                          }}
                        >
                          {params.value &&
                            params.value.map(
                              (
                                imageChoiceValue: ImageChoiceValue,
                                imageChoiceValueIndex: number
                              ) => {
                                return (
                                  <ResponseImageChoiceCard
                                    key={imageChoiceValue.imageChoiceId}
                                    imageUrl={imageChoiceValue.imageUrl}
                                    imageCaption={imageChoiceValue.imageCaption}
                                    sx={{
                                      width: "100%",
                                      ...(imageChoiceValueIndex !== 0 && {
                                        mt: 1,
                                      }),
                                    }}
                                  />
                                );
                              }
                            )}
                        </Box>
                      );
                    }

                    if (
                      params.colDef.questionType === QuestionTypes.DATE &&
                      params.value
                    ) {
                      const parsedDate = parseISO(params.value);

                      const formattedDate = format(
                        parsedDate,
                        buildDateFormatString(
                          (question as LiveQuestionDocument).dateFormat,
                          (question as LiveQuestionDocument).dateSeperator
                        )
                      );

                      return (
                        <Box
                          sx={{
                            py: 1,
                          }}
                        >
                          {formattedDate}
                        </Box>
                      );
                    }

                    if (
                      params.colDef.questionType === QuestionTypes.PHONE &&
                      params.value
                    ) {
                      try {
                        // TODO - ===========================================
                        // TODO - Decide whether we save numbers wit ha plus always or just append + here
                        // TODO - ===========================================

                        const phoneNumberParsed = parsePhoneNumber(
                          "+" + params.value
                        );

                        if (phoneNumberParsed) {
                          const country = phoneNumberParsed.country;
                          return (
                            <Box
                              sx={{
                                py: 1,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  mr: 1,
                                  fontSize: 25,
                                }}
                              >
                                {country && getCountryFlag(country)}
                              </Box>
                              <Typography>+{params.value}</Typography>
                            </Box>
                          );
                        }
                      } catch (error) {
                        return undefined;
                      }
                    }

                    return undefined;
                  },
                };
              }),
          ];
        },
        []
      ),
    ];
  };

  const prepareTableRows = (
    responses: LiveResponseDcoument[],
    surveyPages: LivePageDocument[]
  ) => {
    return [
      ...responses.map((response: LiveResponseDcoument) => {
        return {
          id: response._id,
          createdAt: format(new Date(response.createdAt), "dd-MM-yyyy"),
          submissionDate:
            response.submissionDate &&
            format(new Date(response.submissionDate), "dd-MM-yyyy"),
          ...response.answerPages.reduce(
            (
              previousAnswerPage: any,
              currentAnswerPage: LiveAnswerPageDocument
            ) => {
              return {
                ...previousAnswerPage,
                ...currentAnswerPage.answers.reduce(
                  (previousAnswer: any, currentAnswer: LiveAnswerDocument) => {
                    return {
                      ...previousAnswer,
                      ...(currentAnswer.dynamicQuestionText && {
                        [`${currentAnswer.questionId}_dynamicQuestionText`]:
                          currentAnswer.dynamicQuestionText,
                      }),

                      ...(currentAnswer.questionType ===
                        QuestionTypes.ADDRESS && {
                        [`${currentAnswer.questionId}.addressLine1`]:
                          (currentAnswer.answerValue as LiveAddressAnswer)
                            .addressLine1 ?? "",
                        [`${currentAnswer.questionId}.addressLine2`]:
                          (currentAnswer.answerValue as LiveAddressAnswer)
                            .addressLine2 ?? "",
                        [`${currentAnswer.questionId}.addressTown`]:
                          (currentAnswer.answerValue as LiveAddressAnswer)
                            .addressTown ?? "",
                        [`${currentAnswer.questionId}.addressState`]:
                          (currentAnswer.answerValue as LiveAddressAnswer)
                            .addressState ?? "",
                        [`${currentAnswer.questionId}.addressPostCode`]:
                          (currentAnswer.answerValue as LiveAddressAnswer)
                            .addressPostCode ?? "",
                        [`${currentAnswer.questionId}.addressCountry`]:
                          (currentAnswer.answerValue as LiveAddressAnswer)
                            .addressCountry ?? "",
                      }),

                      ...(currentAnswer.questionType !==
                        QuestionTypes.ADDRESS && {
                        [currentAnswer.questionId]: currentAnswer.answerValue,
                      }),
                    };
                  },
                  {}
                ),
              };
            },
            {}
          ),
        };
      }),
    ];
  };

  const headerColumnBuilder = (
    questionType: QuestionTypes,
    questionText: string,
    isDynamicQuestion: boolean,
    subtitle?: string
  ): ReactNode => (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <QuestionTypeIcon
        questionType={questionType}
        sx={{
          mr: 1,
        }}
      />
      <Stack>
        {isDynamicQuestion && (
          <Chip
            icon={<AutoAwesomeOutlined />}
            label="Dynamic Question"
            color="primary"
          />
        )}
        {!isDynamicQuestion && (
          <Typography
            sx={{
              whiteSpace: "initial",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
            }}
          >
            {questionText}
          </Typography>
        )}

        {subtitle && <Typography variant="subtitle1">{subtitle}</Typography>}
      </Stack>
    </Box>
  );

  useEffect(() => {
    if (
      sortOptions?.sortField &&
      sortOptions.sortValue &&
      sortOptions.sortMode
    ) {
      pageNumber.current = 1;
      getResponses();
    }
  }, [sortOptions]);

  useEffect(() => {
    if (filterOptions?.filterField && filterOptions.filterOperator) {
      pageNumber.current = 1;
      getResponses();
    }
  }, [filterOptions]);

  const copyFormLink = () => {
    navigator.clipboard.writeText(
      `${FRONTEND_BASE_URL}/${publishedSurveyIdentifier}`
    );
    handleShowSnackbar();
  };
  const handleShowSnackbar = () => {
    setShowSnackbar(true);
  };

  const handleHideSnackbar = () => {
    setShowSnackbar(false);
  };

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    handleHideSnackbar();
  };

  return (
    <Box
      sx={{
        pt: 4,
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" fontWeight="bold">
            Responses
          </Typography>
          {publishedSurveyIdentifier && (
            <Button
              variant="contained"
              startIcon={<ShareOutlined />}
              onClick={copyFormLink}
            >
              Get Responses
            </Button>
          )}
        </Box>
        <Divider sx={{ my: 2 }} />

        {!loading && error && (
          <Alert sx={{ my: 3 }} severity="error">
            {error}
          </Alert>
        )}

        {!loading && !error && totalNumOfResponses === 0 && (
          <EmptyPlaceHolder
            sx={{
              mt: 10,
            }}
            title="No Responses yet."
            description="You have not got any responses yet. Share a link to your form
              and view your responses here."
            svgIcon={<EmptyMailBoxIcon />}
            showButton
            buttonContent={"Get Responses"}
            buttonProps={{
              startIcon: <ShareOutlined />,
              onClick: copyFormLink,
            }}
          />
        )}

        {dataTableCols && dataTableRows && totalNumOfResponses > 0 && (
          <DataGrid
            density="comfortable"
            rows={dataTableRows}
            columns={dataTableCols}
            loading={loading}
            pageSizeOptions={[10]}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            paginationMode="server"
            onPaginationModelChange={(mode: GridPaginationModel) => {
              pageNumber.current = mode.page + 1;
              getResponses();
            }}
            getRowHeight={() => "auto"}
            rowCount={totalNumOfResponses}
            checkboxSelection
            // hideFooterPagination
            // TODO - This would have to be conditional if we find that the database has a loft of responses or not
            filterMode="server"
            slots={{}}
            onFilterModelChange={(filterMode: GridFilterModel) => {
              if (
                filterMode.items.length > 0 &&
                filterMode.items[0].field &&
                filterMode.items[0].operator
              ) {
                // TODO - Will need to make this lodash debounce to limit number of requests as the fitler value is typed
                setFilterOptions({
                  filterField: filterMode.items[0].field,
                  filterOperator: filterMode.items[0].operator,
                  filterValue: filterMode.items[0].value,
                  filterMode: RESPONSE_SORTABLE_FILTERABLE_FIELDS.includes(
                    filterMode.items[0].field
                  )
                    ? ResponseSortFilterModes.RESPONSES
                    : ResponseSortFilterModes.QUESTIONS,
                });
              } else {
                setFilterOptions(undefined);
              }
            }}
            sortingMode="server"
            onSortModelChange={(sortModel: GridSortModel) => {
              if (
                sortModel.length > 0 &&
                sortModel[0].field &&
                sortModel[0].sort
              ) {
                setSortOptions({
                  sortField: sortModel[0].field,
                  sortValue: sortModel[0].sort,
                  sortMode: RESPONSE_SORTABLE_FILTERABLE_FIELDS.includes(
                    sortModel[0].field
                  )
                    ? ResponseSortFilterModes.RESPONSES
                    : ResponseSortFilterModes.QUESTIONS,
                });
              } else {
                setSortOptions(undefined);
              }
            }}
          />
        )}
      </Container>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        TransitionComponent={TransitionRight}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Form link copied to clipboard.
        </Alert>
      </Snackbar>
    </Box>
  );
}
