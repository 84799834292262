import React, { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Add } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
  Snackbar,
  Slide,
  SlideProps,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";

import FormListCard from "../components/FormListCard";
import { useProtectedApi } from "../hooks/useProtectedApi";
import { FRONTEND_BASE_URL, SURVEY_DRAFTS_URL } from "../data/constants";
import { FormFields } from "../types/Forms";
import { AxiosError } from "axios";
import { InView } from "react-intersection-observer";
import {
  useUpdateSavedSurveyTheme,
  useUpdateSurveyTheme,
} from "../providers/surveyTheme";
import NoFormsIcon from "../components/Icons/NoFormsIcon";
import EmptyPlaceHolder from "../components/Layout/EmptyPlaceHolder";

type HomeProps = {};

function TransitionRight(props: SlideProps) {
  return <Slide {...props} direction="right" />;
}

export default function Home({}: HomeProps) {
  const theme = useTheme();
  const isInitialMount = useRef(true);

  const updateSurveyTheme = useUpdateSurveyTheme();
  const updateSavedSurveyTheme = useUpdateSavedSurveyTheme();

  const navigate = useNavigate();

  const [showSnackbar, setShowSnackbar] = useState(false);

  const [createNewFormLoading, setCreateNewFormLoading] = useState(false);
  const [createNewFormError, setCreateNewFormError] = useState("");

  const [initialLoading, setInitialLoading] = useState(true);
  const [initialError, setInitialError] = useState("");

  const [paginationLoading, setPaginationLoading] = useState(false);
  const [paginationError, setPaginationError] = useState("");

  const [showPaginationSpinnger, setShowPaginationSpinner] = useState(true);

  const pageNumber = useRef(1);
  const [results, setResults] = useState<FormFields[]>([]);

  const ProtectedApi = useProtectedApi();

  const getForms = async () => {
    const response = await ProtectedApi.get(SURVEY_DRAFTS_URL, {
      params: { page: pageNumber.current, limit: 10 },
    });

    setResults((prevResults: FormFields[]) => {
      return [...prevResults, ...response.data.success.data.surveys];
    });

    if (response.data.success.data.next) {
      pageNumber.current = response.data.success.data.next.page;
      setShowPaginationSpinner(true);
    } else {
      setShowPaginationSpinner(false);
    }
  };

  const getInitialForms = async () => {
    try {
      setInitialLoading(true);
      setInitialError("");
      pageNumber.current = 1;
      setResults([]);
      await getForms();
      setInitialLoading(false);
    } catch (error) {
      setInitialLoading(false);
      if (error instanceof AxiosError && !error?.response) {
        setInitialError("No response from server.");
      } else if (error instanceof AxiosError && error?.response?.data) {
        setInitialError(error.response.data.error.message);
      } else {
        setInitialError("Fialed to get forms.");
      }
    }
  };

  const getPaginationForms = async () => {
    try {
      setPaginationLoading(true);
      setPaginationError("");
      await getForms();
      setPaginationLoading(false);
    } catch (error) {
      setPaginationLoading(false);
      if (error instanceof AxiosError && !error?.response) {
        setPaginationError("No response from server.");
      } else if (error instanceof AxiosError && error?.response?.data) {
        setPaginationError(error.response.data.error.message);
      } else {
        setPaginationError("Failed to get next page of forms");
      }
    }
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      getInitialForms();
    }
  }, []);

  const createNewForm = async () => {
    try {
      setCreateNewFormLoading(true);
      setCreateNewFormError("");

      const response = await ProtectedApi.post(
        SURVEY_DRAFTS_URL,
        {
          title: "My New Form",
        },
        {
          params: { page: pageNumber.current, limit: 10 },
        }
      );

      setCreateNewFormLoading(false);
      navigate(`/builder/${response.data.success.data.survey.id}`);
    } catch (error) {
      setCreateNewFormLoading(false);
      if (error instanceof AxiosError && !error?.response) {
        setCreateNewFormError("No response from server.");
      } else if (error instanceof AxiosError && error?.response?.data) {
        setCreateNewFormError(error.response.data.error.message);
      } else {
        setCreateNewFormError("Unable to create new form");
      }
    }
  };

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [formToDeleteId, setFormToDeleteId] = useState("");

  const handleDeleteDialogOpen = () => {
    setShowDeleteDialog(true);
  };

  const handleDeleteDialogClose = () => {
    if (!deleteLoading) {
      setShowDeleteDialog(false);
    }
  };

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteError, setDeleteError] = useState("");

  const deleteForm = async () => {
    try {
      setDeleteLoading(true);
      setDeleteError("");

      const response = await ProtectedApi.delete(
        `${SURVEY_DRAFTS_URL}/${formToDeleteId}`
      );

      setDeleteLoading(false);
      setDeleteError("");
      setShowDeleteDialog(false);
      getInitialForms();
    } catch (error) {
      setDeleteLoading(false);
      if (error instanceof AxiosError && !error?.response) {
        setDeleteError("No response from server.");
      } else if (error instanceof AxiosError && error?.response?.data) {
        setDeleteError(error.response.data.error.message);
      } else {
        setDeleteError("Unable to delete form");
      }
    }
  };

  const handleShowSnackbar = () => {
    setShowSnackbar(true);
  };

  const handleHideSnackbar = () => {
    setShowSnackbar(false);
  };

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    handleHideSnackbar();
  };

  return (
    <Box
      sx={{
        pt: 4,
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" fontWeight="bold">
            My Forms
          </Typography>
          <Button
            variant="contained"
            startIcon={<Add />}
            disabled={createNewFormLoading}
            onClick={createNewForm}
          >
            {createNewFormLoading ? (
              <CircularProgress color="primary" />
            ) : (
              "New Form"
            )}
          </Button>
        </Box>
        <Divider sx={{ my: 2 }} />

        {initialError && (
          <Alert sx={{ my: 3 }} variant="filled" severity="error">
            {initialError}
          </Alert>
        )}

        {initialLoading && !initialError && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 5,
            }}
          >
            <CircularProgress></CircularProgress>
          </Box>
        )}

        {!initialLoading && !initialError && results.length === 0 && (
          <EmptyPlaceHolder
            sx={{
              mt: 10,
            }}
            title="Let's build a Form!"
            description="Looks like you don't have any forms yet. It's super easy to
              build something awesome. Give it a shot!"
            svgIcon={<NoFormsIcon />}
            showButton
            buttonContent={
              createNewFormLoading ? (
                <CircularProgress color="primary" />
              ) : (
                "Let's Do It!"
              )
            }
            buttonProps={{
              startIcon: <Add />,
              onClick: createNewForm,
              disabled: createNewFormLoading,
            }}
          />
        )}

        {results.length > 0 && !initialLoading && !initialError && (
          <>
            {results.map((formFields: FormFields) => {
              return (
                <FormListCard
                  key={formFields._id}
                  formTitle={formFields.title}
                  isFormPublished={Boolean(formFields.publishedSurvey)}
                  numOfResponses={formFields.numOfResponses ?? 0}
                  numOfQuestions={formFields.numOfQuestions ?? 0}
                  updatedAt={
                    formFields.updatedAt ? new Date(formFields.updatedAt) : null
                  }
                  editClickHandler={() => {
                    updateSurveyTheme && updateSurveyTheme(null);
                    updateSavedSurveyTheme && updateSavedSurveyTheme(null);
                    navigate(`/builder/${formFields._id}`);
                  }}
                  deleteFormHandler={() => {
                    setDeleteError("");
                    setFormToDeleteId(formFields._id);
                    handleDeleteDialogOpen();
                  }}
                  copyPublishedLinkHandler={() => {
                    if (formFields.publishedSurvey) {
                      navigator.clipboard.writeText(
                        `${FRONTEND_BASE_URL}/${formFields.publishedSurvey.identifier}`
                      );
                      handleShowSnackbar();
                    }
                  }}
                  viewResponsesHandler={() => {
                    if (formFields.publishedSurvey) {
                      navigate(
                        `/response/survey/${formFields.publishedSurvey._id}`
                      );
                    }
                  }}
                />
              );
            })}
            <Box
              sx={{
                my: 3,
                display: "flex",
                justifyContent: "center",
              }}
            >
              {showPaginationSpinnger && (
                <InView
                  as="div"
                  onChange={(inView, entry) => {
                    if (inView) {
                      getPaginationForms();
                    }
                  }}
                >
                  <CircularProgress size={50} thickness={5} />
                </InView>
              )}
            </Box>
          </>
        )}
      </Container>
      <Dialog
        open={showDeleteDialog}
        onClose={handleDeleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Form</DialogTitle>
        <DialogContent>
          {deleteError && (
            <Alert sx={{ my: 3 }} variant="filled" severity="error">
              {deleteError}
            </Alert>
          )}

          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the form? All the associated
            Responses will also be lost. This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} disabled={deleteLoading}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              deleteForm();
            }}
            autoFocus
            color="error"
            disabled={deleteLoading}
          >
            {deleteLoading ? <CircularProgress color="error" /> : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        TransitionComponent={TransitionRight}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Form link copied to clipboard.
        </Alert>
      </Snackbar>
    </Box>
  );
}
