import React from "react";

import {
  Box,
  Typography,
  FormControl,
  FormGroup,
  IconButton,
  Button,
} from "@mui/material";

import {
  DropdownBlockFields,
  DropdownValue,
  QuestionTypes,
} from "../../../../types/Blocks";

import { v4 as uuid } from "uuid";

import NewQuestionRequiredOption from "../../Options/QuestionRequiredOption";
import NewConditionalVisibilityOptions from "../../Options/ConditionalVisibilityOptions";
import DynamicQuestionOption from "../../Options/DynamicQuestionOption";
import { useField } from "formik";
import ControlledTextField from "../../../Inputs/ControlledTextField";
import { Add, Close } from "@mui/icons-material";
import TabsLayout from "../../../Layout/TabsLayout";
import InfoTooltip from "../../../InfoTooltip";

type DropdownBlockSettingsPanelProps = {
  name: string;
  pageIndex: number;
  blockIndex: number;
};

export default function DropdownBlockSettingsPanel({
  name,
  pageIndex,
  blockIndex,
}: DropdownBlockSettingsPanelProps) {
  const [field, meta, helpers] = useField<DropdownBlockFields>(name);

  // TODO - We must add validatioin to prevent them from entering
  // TODO - the same value for a dropdown twice.

  // TODO - Maybe show some placeholder in the settings panel if they have not added any dropdown options yet
  return (
    <TabsLayout
      tabOptions={{
        options: {
          label: "Options",
          tabPanel: (
            <>
              <Box sx={{ mt: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      mr: 1,
                    }}
                  >
                    Dropdown Options
                  </Typography>
                  <InfoTooltip title="Add options for the Dropdown by clicking the `Add New Option` button below." />
                </Box>
                <Box>
                  {field.value.dropdownValues && (
                    <FormControl
                      component="fieldset"
                      variant="standard"
                      fullWidth
                    >
                      <FormGroup>
                        {field.value.dropdownValues.map(
                          (
                            dropdownValue: DropdownValue,
                            dropdownValueIndex
                          ) => {
                            return (
                              <ControlledTextField
                                key={dropdownValue.dropdownValueId}
                                sx={{
                                  ...(dropdownValueIndex === 0 && {
                                    mt: 3,
                                  }),
                                  mb: 2,
                                }}
                                name={`${name}.dropdownValues[${dropdownValueIndex}].value`}
                                id={`${name}.dropdownValues[${dropdownValueIndex}].value`}
                                variant="outlined"
                                size="small"
                                multiline
                                required
                                placeholder="Type choice value here."
                                autoComplete="off"
                                InputProps={{
                                  endAdornment: (
                                    <Box>
                                      {field.value.dropdownValues.length >
                                        1 && (
                                        <IconButton
                                          onClick={() => {
                                            // @ts-ignore
                                            helpers.setTouched({
                                              // @ts-ignore
                                              ...(meta.touched as FormikTouched<CheckboxBlockFields>),
                                              dropdownValue:
                                                // @ts-ignore
                                                meta.touched?.dropdownValues
                                                  // @ts-ignore
                                                  ?.filter(
                                                    (
                                                      _: string,
                                                      currentDropdownValueIndex: number
                                                    ) => {
                                                      return (
                                                        dropdownValueIndex !==
                                                        currentDropdownValueIndex
                                                      );
                                                    }
                                                  ),
                                            });

                                            helpers.setValue({
                                              ...field.value,
                                              dropdownValues:
                                                field.value.dropdownValues.filter(
                                                  (
                                                    _,
                                                    currentDropdownValueIndex: number
                                                  ) => {
                                                    return (
                                                      dropdownValueIndex !==
                                                      currentDropdownValueIndex
                                                    );
                                                  }
                                                ),
                                            });
                                          }}
                                        >
                                          <Close
                                            fontSize="small"
                                            color="error"
                                          />
                                        </IconButton>
                                      )}
                                    </Box>
                                  ),
                                }}
                              />
                            );
                          }
                        )}
                      </FormGroup>
                    </FormControl>
                  )}
                  <Button
                    sx={{
                      my: 2,
                    }}
                    variant="outlined"
                    fullWidth
                    startIcon={<Add />}
                    onClick={() => {
                      helpers.setValue({
                        ...field.value,
                        dropdownValues: [
                          ...(field.value.dropdownValues
                            ? [...field.value.dropdownValues]
                            : []),
                          {
                            dropdownValueId: uuid(),
                            value: "",
                          },
                        ],
                      });
                    }}
                  >
                    Add New Option
                  </Button>
                </Box>
              </Box>

              <Box sx={{ mt: 1 }}>
                <NewQuestionRequiredOption
                  name={name}
                ></NewQuestionRequiredOption>
              </Box>
              <Box sx={{ mt: 3 }}>
                <DynamicQuestionOption
                  name={name}
                  pageIndex={pageIndex}
                  blockIndex={blockIndex}
                ></DynamicQuestionOption>
              </Box>
            </>
          ),
        },
        visibility: {
          label: "Visibility",
          tabPanel: (
            <NewConditionalVisibilityOptions
              name={name}
              itemType={QuestionTypes.DROPDOWN}
              itemTitle="question"
              pageIndex={pageIndex}
              blockIndex={blockIndex}
            ></NewConditionalVisibilityOptions>
          ),
        },
      }}
    />
  );
}
