import React from "react";

import { Box, BoxProps, Typography } from "@mui/material";
import ControlledFontPicker from "../Inputs/ControlledFontPicker";
import {
  AVAILABLE_FONTS,
  FONT_WEIGHT_OPTIONS,
} from "../../data/SurveyThemeData";
import ControlledTextField from "../Inputs/ControlledTextField";
import ControlledSelect from "../Inputs/ControlledSelect";

type FontSettingsMiniFormProps = BoxProps & {
  name: string;
  label?: string;
};

export default function FontSettingsMiniForm({
  name,
  label,
  sx,
}: FontSettingsMiniFormProps) {
  return (
    <Box sx={{ ...sx }}>
      <Typography>{label ?? "Font Settings"}</Typography>
      <ControlledFontPicker
        sx={{ mt: 2 }}
        name={`${name}.fontFamily`}
        label="Font"
        options={AVAILABLE_FONTS}
      />

      <Box
        sx={{
          display: "flex ",
        }}
      >
        <ControlledTextField
          sx={{ mt: 3, mr: 1 }}
          name={`${name}.fontSize`}
          label="Font Size"
        />

        <ControlledSelect
          sx={{ mt: 3, ml: 1 }}
          name={`${name}.fontWeight`}
          label="Font Weight"
          options={FONT_WEIGHT_OPTIONS}
        />
      </Box>
    </Box>
  );
}
