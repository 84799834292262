import React, { useEffect } from "react";

import { useFormikContext } from "formik";
import { useUpdateSurveyTheme } from "../providers/surveyTheme";
import { SurveyThemeFormFields } from "../types/SurveyTheme";

type SurveyThemeUpdaterProps = {};

export default function SurveyThemeUpdater({}: SurveyThemeUpdaterProps) {
  const formik = useFormikContext<SurveyThemeFormFields>();

  const updateSurveyTheme = useUpdateSurveyTheme();

  useEffect(() => {
    // @ts-ignore
    updateSurveyTheme && updateSurveyTheme(formik.values);
  }, [formik.values]);

  return <></>;
}
