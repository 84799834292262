import React from "react";

import { FormSubBlockTypes } from "../../../../types/Blocks";
import NewConditionalVisibilityOptions from "../../Options/ConditionalVisibilityOptions";
import TabsLayout from "../../../Layout/TabsLayout";

type ParagraphBlockSettingsPanelProps = {
  name: string;
  pageIndex: number;
  blockIndex: number;
};

export default function ParagraphBlockSettingsPanel({
  name,
  pageIndex,
  blockIndex,
}: ParagraphBlockSettingsPanelProps) {
  return (
    <TabsLayout
      tabOptions={{
        // options: {
        //   label: "Options",
        //   tabPanel: <></>,
        // },
        visibility: {
          label: "Visibility",
          tabPanel: (
            <NewConditionalVisibilityOptions
              name={name}
              itemType={FormSubBlockTypes.PARAGRAPH}
              itemTitle="paragraph"
              pageIndex={pageIndex}
              blockIndex={blockIndex}
            ></NewConditionalVisibilityOptions>
          ),
        },
      }}
    />
  );
}
