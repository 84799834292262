import React from "react";

import { Box } from "@mui/material";

import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

import { useSurveyTheme } from "../../../../providers/surveyTheme";
import TransparentSurveyButton from "../../../SurveyComponents/TransparentSurveyButton";
import SurveyBlockLayout from "../../../SurveyComponents/SurveyBlockLayout";
import SurveyQuestionBlockHeader from "../../../SurveyComponents/SurveyQuestionBlockHeader";

interface YesNoBlockProps {
  // formik: FormikContextType<IFormCanvasBlocksFields>;
  name: string;
  // index: number;
  pageIndex: number;
  blockIndex: number;
  settingsHandler: () => void;
  deleteHandler: () => void;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  settingsPanelActive: boolean;
}

export interface INewTextFieldBlockFormFields {
  question: string;
  isQuestionRequired: boolean;
  requiredText: string;
}

export default function YesNoBlock({
  // formik,
  name,
  // index,
  pageIndex,
  blockIndex,
  deleteHandler,
  settingsHandler,
  dragHandleProps,
  settingsPanelActive,
}: YesNoBlockProps) {
  const surveyTheme = useSurveyTheme();

  return (
    <SurveyBlockLayout
      name={name}
      settingsHandler={settingsHandler}
      deleteHandler={deleteHandler}
      settingsPanelActive={settingsPanelActive}
      dragHandleProps={dragHandleProps}
    >
      <SurveyQuestionBlockHeader name={name} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          ...(surveyTheme && {
            ...(surveyTheme.contentTextAlignment && {
              textAlign: surveyTheme.contentTextAlignment,
            }),
          }),
        }}
      >
        <Box sx={{ mb: 3 }}>
          <TransparentSurveyButton
            surveyTheme={surveyTheme}
            sx={{
              px: 10,
            }}
          >
            YES
          </TransparentSurveyButton>
        </Box>
        <Box>
          <TransparentSurveyButton
            surveyTheme={surveyTheme}
            sx={{
              px: 10,
            }}
          >
            NO
          </TransparentSurveyButton>
        </Box>
      </Box>
    </SurveyBlockLayout>
  );
}
