import { useState, createContext } from "react";

export type AuthContextType = {
  auth: any;
  setAuth: React.Dispatch<React.SetStateAction<AuthState>>;
};

export const AuthContext = createContext<AuthContextType>({
  auth: {}, // Initial authentication state
  setAuth: () => {}, // Initial state setter function
});

export type AuthProviderProps = {
  children: React.ReactNode;
};

export type AuthState = {
  user: any;
  accessToken: string;
};

export const INITIAL_AUTH_STATE = {
  user: null,
  accessToken: "",
};

export function AuthPRovider({ children }: AuthProviderProps) {
  const [auth, setAuth] = useState<AuthState>(INITIAL_AUTH_STATE);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
}
