import React from "react";

import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import ControlledTextField from "../../../Inputs/ControlledTextField";
import { useSurveyTheme } from "../../../../providers/surveyTheme";
import SurveyBlockLayout from "../../../SurveyComponents/SurveyBlockLayout";

type HeadingBlockProps = {
  name: string;
  pageIndex: number;
  blockIndex: number;
  settingsHandler: () => void;
  deleteHandler: () => void;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  settingsPanelActive: boolean;
};

export default function HeadingBlock({
  // formik,
  name,
  // index,
  pageIndex,
  blockIndex,
  deleteHandler,
  settingsHandler,
  dragHandleProps,
  settingsPanelActive,
}: HeadingBlockProps) {
  const surveyTheme = useSurveyTheme();
  const theme = useTheme();

  return (
    <SurveyBlockLayout
      name={name}
      settingsHandler={settingsHandler}
      deleteHandler={deleteHandler}
      settingsPanelActive={settingsPanelActive}
      dragHandleProps={dragHandleProps}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Box sx={{ display: "flex", flexGrow: 1 }}>
            <ControlledTextField
              name={`${name}.heading`}
              id={`${name}.heading`}
              variant="standard"
              size="small"
              multiline
              required
              placeholder="Heading"
              autoComplete="off"
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{
                style: {
                  ...theme.typography.h4,
                  ...(surveyTheme && {
                    color: surveyTheme.colorSettings.questionTextColor,

                    ...(surveyTheme.contentTextAlignment && {
                      textAlign: surveyTheme.contentTextAlignment,
                    }),

                    ...(surveyTheme.globalFont &&
                      !surveyTheme.hasGranularFontSettings && {
                        fontFamily: surveyTheme.globalFont,
                      }),

                    ...(surveyTheme.hasGranularFontSettings &&
                      surveyTheme.granularFontSettings.questionText && {
                        fontFamily:
                          surveyTheme.granularFontSettings.questionText
                            .fontFamily,
                      }),
                  }),
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </SurveyBlockLayout>
  );
}
