import React from "react";

import { Box, Slider, Typography } from "@mui/material";

import { useTheme } from "@mui/material/styles";

import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

import ControlledTextField from "../../../Inputs/ControlledTextField";
import { useField } from "formik";
import { SliderBlockFields } from "../../../../types/Blocks";
import { useSurveyTheme } from "../../../../providers/surveyTheme";
import { generateSliderStyling } from "../../../../utils/styles/generateSliderStyling";
import { generateAnswerTextStyle } from "../../../../utils/styles/generateAnswerTextStyle";
import SurveyBlockLayout from "../../../SurveyComponents/SurveyBlockLayout";
import SurveyQuestionBlockHeader from "../../../SurveyComponents/SurveyQuestionBlockHeader";

interface SliderBlockProps {
  // formik: FormikContextType<IFormCanvasBlocksFields>;
  name: string;
  // index: number;
  pageIndex: number;
  blockIndex: number;
  settingsHandler: () => void;
  deleteHandler: () => void;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  settingsPanelActive: boolean;
}

export default function SliderBlock({
  // formik,
  name,
  // index,
  pageIndex,
  blockIndex,
  deleteHandler,
  settingsHandler,
  dragHandleProps,
  settingsPanelActive,
}: SliderBlockProps) {
  const surveyTheme = useSurveyTheme();
  const [field] = useField<SliderBlockFields>(name);
  const theme = useTheme();

  return (
    <SurveyBlockLayout
      name={name}
      settingsHandler={settingsHandler}
      deleteHandler={deleteHandler}
      settingsPanelActive={settingsPanelActive}
      dragHandleProps={dragHandleProps}
    >
      <SurveyQuestionBlockHeader name={name} />

      <Slider
        value={(field.value.sliderMaxNumber ?? 100) / 2}
        valueLabelDisplay="auto"
        min={field.value.sliderMinNumber ?? 0}
        max={field.value.sliderMaxNumber ?? 100}
        step={field.value.sliderStep ?? 1}
        sx={{
          ...(surveyTheme && generateSliderStyling(surveyTheme)),
        }}
      />

      {!field.value.sliderHasCustomLabels && (
        <Box
          sx={{
            mt: 1.5,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="caption"
            sx={{
              ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
              ...(surveyTheme && {
                // We are using this override certain aspects genrated by
                // answer text style
                fontSize: theme.typography.caption.fontSize,
                fontWeight: theme.typography.caption.fontWeight,
              }),
            }}
          >
            {field.value.sliderMinNumber ?? 0}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
              ...(surveyTheme && {
                // We are using this override certain aspects genrated by
                // answer text style
                fontSize: theme.typography.caption.fontSize,
                fontWeight: theme.typography.caption.fontWeight,
              }),
            }}
          >
            {field.value.sliderMaxNumber ?? 100}
          </Typography>
        </Box>
      )}

      {field.value.sliderHasCustomLabels && (
        <Box
          sx={{
            mt: 1.5,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <ControlledTextField
            name={`${name}.sliderMinLabel`}
            id={`${name}.sliderMinLabel`}
            variant="standard"
            size="small"
            multiline
            required
            placeholder="Add min label (optional)"
            autoComplete="off"
            InputProps={{
              disableUnderline: true,
            }}
            inputProps={{
              style: {
                ...theme.typography.caption,
                ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
                ...(surveyTheme && {
                  // We are using this override certain aspects genrated by
                  // answer text style
                  fontSize: theme.typography.caption.fontSize,
                  fontWeight: theme.typography.caption.fontWeight,
                }),
              },
            }}
          />
          <ControlledTextField
            name={`${name}.sliderMidLabel`}
            id={`${name}.sliderMidLabel`}
            variant="standard"
            size="small"
            multiline
            required
            placeholder="Add mid label (optional)"
            autoComplete="off"
            InputProps={{
              disableUnderline: true,
            }}
            inputProps={{
              style: {
                ...theme.typography.caption,
                ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
                ...(surveyTheme && {
                  // We are using this override certain aspects genrated by
                  // answer text style
                  fontSize: theme.typography.caption.fontSize,
                  fontWeight: theme.typography.caption.fontWeight,
                }),
                textAlign: "center",
              },
            }}
          />
          <ControlledTextField
            name={`${name}.sliderMaxLabel`}
            id={`${name}.sliderMaxLabel`}
            variant="standard"
            size="small"
            multiline
            required
            placeholder="Add max label (optional)"
            autoComplete="off"
            InputProps={{
              disableUnderline: true,
            }}
            inputProps={{
              style: {
                ...theme.typography.caption,
                ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
                ...(surveyTheme && {
                  // We are using this override certain aspects genrated by
                  // answer text style
                  fontSize: theme.typography.caption.fontSize,
                  fontWeight: theme.typography.caption.fontWeight,
                }),
                textAlign: "end",
              },
            }}
          />
        </Box>
      )}
    </SurveyBlockLayout>
  );
}
