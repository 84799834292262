import React from "react";

import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  Button,
  Radio,
  RadioGroup,
} from "@mui/material";

import { useTheme, lighten } from "@mui/material/styles";

import { v4 as uuid } from "uuid";

import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

import { useSurveyTheme } from "../../../../providers/surveyTheme";

import { generateAnswerTextStyle } from "../../../../utils/styles/generateAnswerTextStyle";
import SurveyQuestionBlockHeader from "../../../SurveyComponents/SurveyQuestionBlockHeader";
import { useField } from "formik";
import {
  RadioGroupBlockFields,
  RadioGroupValue,
} from "../../../../types/Blocks";
import ControlledTextField from "../../../Inputs/ControlledTextField";
import { Add, Close } from "@mui/icons-material";
import SurveyBlockLayout from "../../../SurveyComponents/SurveyBlockLayout";

interface RadioGroupBlockProps {
  // formik: FormikContextType<IFormCanvasBlocksFields>;
  name: string;
  // index: number;
  pageIndex: number;
  blockIndex: number;
  settingsHandler: () => void;
  deleteHandler: () => void;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  settingsPanelActive: boolean;
}

export default function RadioGroupBlock({
  // formik,
  name,
  // index,
  pageIndex,
  blockIndex,
  deleteHandler,
  settingsHandler,
  dragHandleProps,
  settingsPanelActive,
}: RadioGroupBlockProps) {
  const [field, meta, helpers] = useField<RadioGroupBlockFields>(name);

  const surveyTheme = useSurveyTheme();
  const theme = useTheme();

  return (
    <SurveyBlockLayout
      name={name}
      settingsHandler={settingsHandler}
      deleteHandler={deleteHandler}
      settingsPanelActive={settingsPanelActive}
      dragHandleProps={dragHandleProps}
    >
      <SurveyQuestionBlockHeader name={name} />

      <Box>
        {field.value.radioGroupValues && (
          <FormControl component="fieldset" variant="standard" fullWidth>
            <RadioGroup aria-disabled>
              {field.value.radioGroupValues.map(
                (radioGroupValue: RadioGroupValue, radioGroupValueIndex) => {
                  return (
                    <FormControlLabel
                      value={radioGroupValue.value}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          width: "100%",
                        },
                      }}
                      key={radioGroupValue.radioGroupValueId}
                      control={
                        <Radio
                          readOnly
                          checked={false}
                          sx={{
                            ...(surveyTheme && {
                              color: surveyTheme.colorSettings.answerTextColor,
                              "&.Mui-checked": {
                                color: lighten(
                                  surveyTheme.colorSettings.answerTextColor,
                                  0.25
                                ),
                              },
                            }),
                          }}
                        />
                      }
                      label={
                        <ControlledTextField
                          name={`${name}.radioGroupValues[${radioGroupValueIndex}].value`}
                          id={`${name}.radioGroupValues[${radioGroupValueIndex}].value`}
                          variant="standard"
                          size="small"
                          multiline
                          required
                          placeholder="Type choice value here."
                          autoComplete="off"
                          InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                              <Box sx={{ mt: 1 }}>
                                {field.value.radioGroupValues.length > 1 && (
                                  <IconButton
                                    onClick={() => {
                                      // @ts-ignore
                                      helpers.setTouched({
                                        // @ts-ignore
                                        ...(meta.touched as FormikTouched<CheckboxBlockFields>),
                                        radioGroupValue:
                                          // @ts-ignore
                                          meta.touched?.radioGroupValue
                                            // @ts-ignore
                                            ?.filter(
                                              (
                                                _: string,
                                                currentRadioGroupValueIndex: number
                                              ) => {
                                                return (
                                                  radioGroupValueIndex !==
                                                  currentRadioGroupValueIndex
                                                );
                                              }
                                            ),
                                      });

                                      helpers.setValue({
                                        ...field.value,
                                        radioGroupValues:
                                          field.value.radioGroupValues.filter(
                                            (
                                              _,
                                              currentRadioGroupValueIndex
                                            ) => {
                                              return (
                                                radioGroupValueIndex !==
                                                currentRadioGroupValueIndex
                                              );
                                            }
                                          ),
                                      });
                                    }}
                                  >
                                    <Close color="error" />
                                  </IconButton>
                                )}
                              </Box>
                            ),
                          }}
                          inputProps={{
                            style: {
                              ...theme.typography.h6,
                              ...(surveyTheme &&
                                generateAnswerTextStyle(surveyTheme)),
                            },
                          }}
                        />
                      }
                    />
                  );
                }
              )}
            </RadioGroup>
          </FormControl>
        )}
        <Button
          sx={{
            my: 2,
          }}
          variant="outlined"
          startIcon={<Add />}
          onClick={() => {
            helpers.setValue({
              ...field.value,
              radioGroupValues: [
                ...(field.value.radioGroupValues
                  ? [...field.value.radioGroupValues]
                  : []),
                {
                  radioGroupValueId: uuid(),
                  value: "",
                },
              ],
            });
          }}
        >
          Add New Choice
        </Button>
      </Box>
    </SurveyBlockLayout>
  );
}
