import React from "react";

import { Blocks } from "../../../../types/Blocks";
import ConditionalVisibilityOptions from "../../Options/ConditionalVisibilityOptions";
import CustomBackgroundSettingsOption from "../../Options/CustomBackgroundSettingsOption";
import TabsLayout from "../../../Layout/TabsLayout";

type PageBlockSettingsPanelProps = {
  name: string;
  pageIndex: number;
};

export default function PageBlockSettingsPanel({
  name,
  pageIndex,
}: PageBlockSettingsPanelProps) {
  return (
    <TabsLayout
      tabOptions={{
        style: {
          label: "Style",
          tabPanel: (
            <>
              <CustomBackgroundSettingsOption
                name={name}
              ></CustomBackgroundSettingsOption>
            </>
          ),
        },
        visibility: {
          label: "Visibility",
          tabPanel: (
            <ConditionalVisibilityOptions
              name={name}
              itemType={Blocks.PAGE}
              itemTitle="page"
              pageIndex={pageIndex}
            ></ConditionalVisibilityOptions>
          ),
        },
      }}
    />
  );
}
