import React, { useEffect, useRef, useState } from "react";
import { useProtectedApi } from "../../hooks/useProtectedApi";
import { IMAGE_URL, API_BASE_URL } from "../../data/constants";
import { LiveImageDocument } from "../../types/Blocks";
import { AxiosError } from "axios";
import {
  Alert,
  Box,
  ButtonBase,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { InView } from "react-intersection-observer";

import { useTheme } from "@mui/material/styles";

type ImageGallerListProps = {
  selectImageHandler: (imageUrl: string) => void;
};

export default function ImagesGalleryList({
  selectImageHandler,
}: ImageGallerListProps) {
  const theme = useTheme();
  const isInitialMount = useRef(true);

  const [initialLoading, setInitialLoading] = useState(true);
  const [initialError, setInitialError] = useState("");

  const [paginationLoading, setPaginationLoading] = useState(false);
  const [paginationError, setPaginationError] = useState("");

  const [showPaginationSpinnger, setShowPaginationSpinner] = useState(true);

  const pageNumber = useRef(1);
  const [results, setResults] = useState<LiveImageDocument[]>([]);

  const ProtectedApi = useProtectedApi();

  const getImages = async () => {
    const response = await ProtectedApi.get(IMAGE_URL, {
      params: { page: pageNumber.current, limit: 10 },
    });

    setResults((prevResults: LiveImageDocument[]) => {
      return [...prevResults, ...response.data.success.data.images];
    });

    if (response.data.success.data.next) {
      pageNumber.current = response.data.success.data.next.page;
      setShowPaginationSpinner(true);
    } else {
      setShowPaginationSpinner(false);
    }
  };

  const getInitialImages = async () => {
    try {
      setInitialLoading(true);
      setInitialError("");
      pageNumber.current = 1;
      setResults([]);
      await getImages();
      setInitialLoading(false);
    } catch (error) {
      setInitialLoading(false);
      if (error instanceof AxiosError && !error?.response) {
        setInitialError("No response from server.");
      } else if (error instanceof AxiosError && error?.response?.data) {
        setInitialError(error.response.data.error.message);
      } else {
        setInitialError("Fialed to get forms.");
      }
    }
  };

  const getPaginationImages = async () => {
    try {
      setPaginationLoading(true);
      setPaginationError("");
      await getImages();
      setPaginationLoading(false);
    } catch (error) {
      setPaginationLoading(false);
      if (error instanceof AxiosError && !error?.response) {
        setPaginationError("No response from server.");
      } else if (error instanceof AxiosError && error?.response?.data) {
        setPaginationError(error.response.data.error.message);
      } else {
        setPaginationError("Failed to get next page of forms");
      }
    }
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      getInitialImages();
    }
  }, []);

  return (
    <Box
      sx={{
        pb: 0.5,
      }}
    >
      {initialError && (
        <Alert sx={{ my: 3 }} severity="error">
          {initialError}
        </Alert>
      )}

      {initialLoading && !initialError && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 5,
          }}
        >
          <CircularProgress></CircularProgress>
        </Box>
      )}

      {results.length > 0 && !initialLoading && !initialError && (
        <>
          <Grid container spacing={2}>
            {results.map((imageFields: LiveImageDocument) => {
              return (
                <Grid item xs={6} key={imageFields._id}>
                  <ButtonBase
                    onClick={() =>
                      selectImageHandler(
                        `${API_BASE_URL}/uploads/${imageFields.savedFileName}`
                      )
                    }
                    sx={{
                      position: "relative",

                      width: 200,
                      height: 200,
                      overflow: "hidden",

                      "&:hover, &.Mui-focusVisible": {
                        zIndex: 1,
                        "& span": {
                          background: "rgba(0, 0, 0,0.1)",
                        },
                        "& .MuiTypography-root": {
                          opacity: 1,
                          border: `4px solid ${theme.palette.primary.light}`,
                          background: theme.palette.primary.main,

                          // border: "4px solid currentColor",
                          // background: "rgba(0, 0, 0,0.6)",
                        },
                      },
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        display: "block",
                        margin: "0 auto",

                        position: "absolute",
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                      }}
                      src={`${API_BASE_URL}/uploads/${imageFields.savedFileName}`}
                      alt="My Gallery"
                    />
                    <span
                      style={{
                        position: "absolute",
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: theme.palette.common.white,
                        background: "rgba(0, 0, 0, 0)",
                      }}
                    >
                      <Typography
                        component="span"
                        variant="subtitle1"
                        color="inherit"
                        sx={{
                          opacity: 0,
                          position: "relative",
                          p: 4,
                          pt: 2,
                          pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                        }}
                      >
                        Select Image
                      </Typography>
                    </span>
                  </ButtonBase>
                </Grid>
              );
            })}
          </Grid>
          <Box
            sx={{
              my: 3,
              display: "flex",
              justifyContent: "center",
            }}
          >
            {showPaginationSpinnger && (
              <InView
                as="div"
                onChange={(inView, entry) => {
                  if (inView) {
                    getPaginationImages();
                  }
                }}
              >
                <CircularProgress size={50} thickness={5} />
              </InView>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}
