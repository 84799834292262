import React, { useRef, useEffect, useState } from "react";

import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";

import { BuilderDrawerPanels } from "../../types/Drawers";

import { MiniDrawerButton } from "../../types/Drawers";

interface IBuilderDrawerProps {
  hideMainDrawer: boolean;
  children: React.ReactNode;
  miniDrawerButtons: MiniDrawerButton[];
  currentMainDrawerPanel: BuilderDrawerPanels;
  updateMainDrawerPanel: (newPanel: BuilderDrawerPanels) => void;
}

export const MINI_DRAWER_WIDTH = 80;
export const MAIN_DRAWER_WIDTH = 200;

export default function BuilderDrawer({
  hideMainDrawer,
  children,
  miniDrawerButtons,
  currentMainDrawerPanel,
  updateMainDrawerPanel,
}: IBuilderDrawerProps) {
  const [mainDrawerMarginLeft, setMainDrawerMarginLeft] = useState(0);
  const miniDrawerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (miniDrawerRef.current) {
        setMainDrawerMarginLeft(miniDrawerRef.current.clientWidth);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          width: MINI_DRAWER_WIDTH,
          "& .MuiDrawer-paper": {
            width: MINI_DRAWER_WIDTH,
            boxSizing: "border-box",
          },
          flexShrink: 0,
        }}
        PaperProps={{
          ref: miniDrawerRef,
        }}
      >
        <Toolbar />

        <List>
          {miniDrawerButtons.map((miniDrawerButton, index) => (
            <ListItem
              key={miniDrawerButton.label}
              disablePadding
              sx={{ display: "block" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  // px: 2.5,
                }}
                onClick={() => {
                  updateMainDrawerPanel(miniDrawerButton.panel);
                }}
                selected={currentMainDrawerPanel === miniDrawerButton.panel}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    justifyContent: "center",
                  }}
                >
                  {miniDrawerButton.icon}
                </ListItemIcon>
                <ListItemText
                  primary={miniDrawerButton.label}
                  primaryTypographyProps={{ variant: "caption" }}
                  sx={{ opacity: 1 }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Drawer
        hidden={hideMainDrawer}
        variant="permanent"
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          width: MAIN_DRAWER_WIDTH,
          "& .MuiDrawer-paper": {
            marginLeft: `${MINI_DRAWER_WIDTH}px`,
            boxSizing: "border-box",
            width: MAIN_DRAWER_WIDTH,
          },
          flexShrink: 0,
        }}
      >
        <Toolbar />

        {children}
      </Drawer>
    </Box>
  );
}

// </Box>
