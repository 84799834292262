import React from "react";

import { useField } from "formik";

import { useTheme } from "@mui/material/styles";

import {
  FormControlLabel,
  FormControlLabelProps,
  Switch,
  SwitchProps,
} from "@mui/material";

type ControlledSwitchProps = SwitchProps & {
  name: string;
};

export default function ControlledSwitch({
  name,
  ...otherPRops
}: ControlledSwitchProps) {
  const [field] = useField(name);

  const switchConfig: SwitchProps = {
    checked: field.value,
    ...field,
    ...otherPRops,
  };

  return <Switch {...switchConfig} />;
}
