import { ApiProtected } from "../api/api";
import { REFRESH_TOKEN_URL } from "../data/constants";
import { useAuth } from "./useAuth";

export const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    const response = await ApiProtected.get(REFRESH_TOKEN_URL, {
      withCredentials: true,
    });

    setAuth((prev) => {
      return {
        ...prev,
        user: response.data.success.data.user,
        accessToken: response.data.success.data.token,
      };
    });

    return response.data.accessToken;
  };
  return refresh;
};
