import {
  ActionFields,
  ApiRequestActionFields,
  FileActionFields,
  RedirectActionFields,
  Actions,
  ActionPathFields,
  HeadingActionFields,
  ParagraphActionFields,
} from "../types/Actions";

import {
  VisibilityOption,
  VisibilityLogicalOperators,
  VisibilitySettings,
  BackgroundTypes,
} from "../types/Blocks";

// const INITIAL_VISIBILITY_SETTINGS = {
//   isVisible: VisibilityOption.SHOW,
//   union: VisibilityLogicalUnions.AND,
//   conditions: [
//     {
//       questionId: "",
//       logicalOperator: VisibilityLogicalOperators.IS,
//       answerValue: "",
//     },
//   ],
// };

const INITIAL_VISIBILITY_SETTINGS: VisibilitySettings = {
  isVisible: VisibilityOption.SHOW,
  primaryCondition: {
    questionId: "",
    logicalOperator: VisibilityLogicalOperators.IS,
    answerValue: "",
  },
  secondaryConditions: [
    // {
    //   logicalUnion: VisibilityLogicalUnions.AND,
    //   questionId: "",
    //   logicalOperator: VisibilityLogicalOperators.IS,
    //   answerValue: "",
    // },
  ],
};

export const INITIAL_ACTION_FIELDS: ActionFields = {
  actionId: "",
  actionType: Actions.API_REQUEST,
  hasConditionalVisibility: false,
  visibilitySettings: INITIAL_VISIBILITY_SETTINGS,
};

export const INITIAL_REDIRECT_ACTION_FIELDS: RedirectActionFields = {
  ...INITIAL_ACTION_FIELDS,
  actionType: Actions.REDIRECT,
  redirectionUrl: "",
};

export const INITIAL_FILE_ACTION_FIELDS: FileActionFields = {
  ...INITIAL_ACTION_FIELDS,
  actionType: Actions.FILE,
};

export const INITIAL_API_REQUEST_ACTION_FIELDS: ApiRequestActionFields = {
  ...INITIAL_ACTION_FIELDS,
  actionType: Actions.API_REQUEST,
};

export const INITIAL_HEADING_ACTION_FIELDS: HeadingActionFields = {
  ...INITIAL_ACTION_FIELDS,
  actionType: Actions.HEADING,
  heading: "",
};

export const INITIAL_PARAGRAPH_ACTION_FIELDS: ParagraphActionFields = {
  ...INITIAL_ACTION_FIELDS,
  actionType: Actions.PARAGRAPH,
  paragraph: "",
};

export const INITIAL_ACTION_PATH_ACTION_FIELDS: ActionPathFields = {
  actionPathId: "",
  hasConditionalVisibility: false,
  visibilitySettings: INITIAL_VISIBILITY_SETTINGS,
  hasCustomBackground: false,
  customBackgroundSettings: {
    backgroundType: BackgroundTypes.IMAGE,
    imageBackgroundSettings: {
      imageUrl: "",
    },
    colorBackgroundSettings: {
      color: "",
    },
  },
  actions: [],
};
