import React, { useEffect, useRef, useState } from "react";

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Popover,
  Slide,
  SlideProps,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";

import Header from "./Header";
import {
  CheckCircleOutlineOutlined,
  CloudDoneOutlined,
  CloudOutlined,
  Code,
  ContentCopy,
  WarningAmberRounded,
} from "@mui/icons-material";

import { useFormikContext } from "formik";

import debounce from "lodash.debounce";

import { useProtectedApi } from "../../hooks/useProtectedApi";
import { AxiosError } from "axios";
import { SURVEY_DRAFTS_URL } from "../../data/constants";
import { IFormCanvasBlocksFields } from "../FormBuilder";
import ControlledTextField from "../Inputs/ControlledTextField";

function TransitionRight(props: SlideProps) {
  return <Slide {...props} direction="right" />;
}

type BuilderHeaderProps = {
  currentFormId: string;
  publishedSurveyIdentifier?: string;
  formTitleName: string;
};

const generateEmbedCodeSnippet = (publishedSurveyIdentifier: string) => {
  return `<iframe
  src="//app.livelyforms.io/${publishedSurveyIdentifier}"
  width="100%"
  height="100%"
></iframe>
`;
};

export default function BuilderHeader({
  currentFormId,
  publishedSurveyIdentifier,
  formTitleName,
}: BuilderHeaderProps) {
  const theme = useTheme();
  const ProtectedApi = useProtectedApi();
  const formik = useFormikContext();

  const [showSnackbar, setShowSnackbar] = useState(false);

  const [showEmbedOption, setShowEmbedOption] = useState(
    Boolean(publishedSurveyIdentifier)
  );

  const handleShowSnackbar = () => {
    setShowSnackbar(true);
  };

  const handleHideSnackbar = () => {
    setShowSnackbar(false);
  };

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    handleHideSnackbar();
  };

  const [
    currentPublishedSurveyIdentifier,
    setCurrentPublishedSurveyIdentifier,
  ] = useState(publishedSurveyIdentifier);

  const [popoverAnchorEl, setPopoverAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const [saveFormLoading, setSaveFormLoading] = useState(false);
  const [saveFormError, setSaveFormError] = useState("");
  const [isFormSaved, setIsFormSaved] = useState(true);

  const [publishFormLoading, setPublishFormLoading] = useState(false);
  const [publishFormError, setPublishFormError] = useState("");
  const [isFormPublishedSuccess, setIsFormPublishedSuccess] = useState(false);

  const handleShowPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setPopoverAnchorEl(null);
  };

  const saveForm = async (values: IFormCanvasBlocksFields) => {
    try {
      setSaveFormLoading(true);
      setSaveFormError("");

      /* 
        NOTE : Formik submit form will try to validate the form 
        and it is not valid it will not run the submit function.
        However, we do not validation in order to save a draft 
        anyway as it will likely be dirt. Also onSubmit formik
        makes every field "touched"
      */
      // The submit form function will contain the api call to save the form data
      // await formik.submitForm();

      await ProtectedApi.patch(
        `${SURVEY_DRAFTS_URL}/${currentFormId}`,
        {
          ...(values.surveyTheme && { surveyTheme: values.surveyTheme }),
          pages: values.pages,
          actionPaths: values.actionPaths,
          title: values.title,
        }
        /* 
          IF we just use formik.values it keeps the intial state
          saved and does set the new values
        */
        // formik.values
      );

      setSaveFormLoading(false);
      setIsFormSaved(true);
    } catch (error) {
      setSaveFormLoading(false);
      if (error instanceof AxiosError && !error?.response) {
        setSaveFormError("No response from server.");
      } else if (error instanceof AxiosError && error?.response?.data) {
        setSaveFormError(error.response.data.error.message);
      } else {
        setSaveFormError("Unable to save form");
      }
    }
  };

  const debouncedSubmit = useRef(debounce(saveForm, 5000));

  // const debouncedSubmit = debounce(saveForm, 1000);

  // TODO - Figure out how to prevent this from running on first load
  useEffect(() => {
    setIsFormSaved(false);
    debouncedSubmit.current(formik.values as IFormCanvasBlocksFields);
  }, [formik.values]);

  const publishForm = async () => {
    try {
      setPublishFormLoading(true);
      setPublishFormError("");
      setIsFormPublishedSuccess(false);

      const response = await ProtectedApi.patch(
        `${SURVEY_DRAFTS_URL}/${currentFormId}/publish`
      );

      setIsFormPublishedSuccess(true);

      if (response.data.success.data.identifier) {
        setCurrentPublishedSurveyIdentifier(
          response.data.success.data.identifier
        );
        setShowEmbedOption(true);
      }

      triggerHidePublishSuccess();
      setPublishFormLoading(false);
    } catch (error) {
      setIsFormPublishedSuccess(false);
      setPublishFormLoading(false);
      if (error instanceof AxiosError && !error?.response) {
        setPublishFormError("No response from server.");
      } else if (error instanceof AxiosError && error?.response?.data) {
        setPublishFormError(error.response.data.error.message);
      } else {
        setPublishFormError("Unable to publish form");
      }
    }
  };

  const triggerHidePublishSuccess = () => {
    setTimeout(() => {
      setIsFormPublishedSuccess(false);
    }, 6000);
  };

  return (
    <Header>
      {/* {formTitle} */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <ControlledTextField
          sx={{
            ml: 2,
          }}
          name={formTitleName}
          id={formTitleName}
          variant="standard"
          size="small"
          required
          placeholder="Form Title"
          autoComplete="off"
          InputProps={{
            disableUnderline: true,
          }}
          inputProps={{
            style: {
              ...theme.typography.h6,
            },
          }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {saveFormError && (
            <Tooltip title="Failed to save form">
              <WarningAmberRounded
                color="error"
                sx={{
                  mr: 2,
                }}
              />
            </Tooltip>
          )}

          <Button
            sx={{
              mr: 2,
              ":disabled": {
                color: "green",
              },
            }}
            startIcon={isFormSaved ? <CloudDoneOutlined /> : <CloudOutlined />}
            disabled={saveFormLoading || isFormSaved}
            onClick={
              () => {
                /* 
                Cancel if we have triggered a save event with our 
                useEffect
              */
                if (debouncedSubmit.current) {
                  debouncedSubmit.current.cancel();
                }
                saveForm(formik.values as IFormCanvasBlocksFields);
              }
              /* 
              We should not use this as we should not want the
              uder to wait if they click this button
            */
              // debouncedSubmit.current(formik.values as IFormCanvasBlocksFields)
            }
          >
            {saveFormLoading ? (
              <CircularProgress color="primary" />
            ) : isFormSaved ? (
              "Saved"
            ) : (
              "Save"
            )}
          </Button>

          {showEmbedOption && (
            <Button
              sx={{
                mr: 2,
              }}
              startIcon={<Code />}
              onClick={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) => {
                handleShowPopover(event);
              }}
            >
              Embed
            </Button>
          )}

          {showEmbedOption && (
            <Popover
              open={Boolean(popoverAnchorEl)}
              onClose={() => {
                handleClosePopover();
              }}
              anchorEl={popoverAnchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              slotProps={{
                paper: {
                  sx: {
                    minWidth: "300px",
                    maxWidth: "325px",
                  },
                },
              }}
            >
              <Box
                sx={{
                  p: 2,
                }}
              >
                <Typography variant="body1" fontWeight={500}>
                  Embed your Lively Form on your own Website
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    my: 1,
                  }}
                >
                  Simple Copy and Paste the code snippet below into your
                  website's HTML and you are good to go!
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    size="small"
                    startIcon={<ContentCopy />}
                    onClick={(
                      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => {
                      if (currentPublishedSurveyIdentifier) {
                        navigator.clipboard.writeText(
                          generateEmbedCodeSnippet(
                            currentPublishedSurveyIdentifier
                          )
                        );
                        handleShowSnackbar();
                      }
                    }}
                  >
                    Copy
                  </Button>
                </Box>

                <Typography
                  variant="body1"
                  component="pre"
                  color="text.secondary"
                  sx={{
                    mt: 1,
                    background: "#444",
                    // border: "1px solid #222",
                    borderRadius: "10px",
                    padding: "0.75rem 1rem",
                    color: "#f2f2f2",
                    overflow: "auto",
                  }}
                >
                  {`<iframe`}
                  <br></br>
                  {`    src="//app.livelyforms.io/${currentPublishedSurveyIdentifier}"`}
                  <br></br>

                  {`    width="100%"`}
                  <br></br>

                  {`    height="100%"`}
                  <br></br>

                  {`    style="border: none;"`}
                  <br></br>

                  {`></iframe>`}
                </Typography>

                {/* <pre>
                  <code
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      padding: "10px",
                    }}
                    className="language-html"
                  >
                    {embedCodeSnippet}
                  </code>
                </pre> */}

                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    mt: 2,
                  }}
                >
                  NOTE : You only need to add the Code Snippet to your website
                  once. All changes you make to your form will be reflected on
                  your webstie.
                </Typography>
              </Box>
            </Popover>
          )}

          {publishFormError && !isFormPublishedSuccess && (
            <Tooltip title={publishFormError}>
              <WarningAmberRounded
                color="error"
                sx={{
                  mr: 2,
                }}
              />
            </Tooltip>
          )}

          {isFormPublishedSuccess && !publishFormError && (
            <Tooltip title="Your form has been published successfully!">
              <CheckCircleOutlineOutlined
                color="success"
                sx={{
                  mr: 2,
                }}
              />
            </Tooltip>
          )}

          <Button
            variant="contained"
            sx={{
              mr: 2,
            }}
            disabled={
              publishFormLoading ||
              isFormPublishedSuccess ||
              saveFormLoading ||
              !isFormSaved
            }
            onClick={publishForm}
          >
            Publish
          </Button>
        </Box>
      </Box>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        TransitionComponent={TransitionRight}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Code Snippet copied to Clipboard.
        </Alert>
      </Snackbar>
    </Header>
  );
}
