import React from "react";

import { Box, Typography } from "@mui/material";

import { AddressBlockFields, QuestionTypes } from "../../../../types/Blocks";
import NewQuestionRequiredOption from "../../Options/QuestionRequiredOption";
import NewConditionalVisibilityOptions from "../../Options/ConditionalVisibilityOptions";
import DynamicQuestionOption from "../../Options/DynamicQuestionOption";
import ControlledSwitch from "../../../Inputs/ControlledSwitch";
import { useField } from "formik";
import TabsLayout from "../../../Layout/TabsLayout";

type AddressBlockSettingsPanelProps = {
  name: string;
  pageIndex: number;
  blockIndex: number;
};

export default function AddressBlockSettingsPanel({
  name,
  pageIndex,
  blockIndex,
}: AddressBlockSettingsPanelProps) {
  const [field] = useField<AddressBlockFields>(name);

  return (
    <TabsLayout
      tabOptions={{
        options: {
          label: "Options",
          tabPanel: (
            <>
              <Box
                sx={{
                  mt: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1">Address Line 1</Typography>
                  <ControlledSwitch
                    name={`${name}.addressLine1.isVisible`}
                    id={`${name}.addressLine1.isVisible`}
                    size="small"
                  />
                </Box>
              </Box>

              {field.value.addressLine1.isVisible && (
                <Box
                  sx={{
                    mt: 3,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">
                      Address Line 1 Required
                    </Typography>
                    <ControlledSwitch
                      name={`${name}.addressLine1.isRequired`}
                      id={`${name}.addressLine1.isRequired`}
                      size="small"
                    />
                  </Box>
                </Box>
              )}

              <Box
                sx={{
                  mt: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1">Address Line 2</Typography>
                  <ControlledSwitch
                    name={`${name}.addressLine2.isVisible`}
                    id={`${name}.addressLine2.isVisible`}
                    size="small"
                  />
                </Box>
              </Box>

              {field.value.addressLine2.isVisible && (
                <Box
                  sx={{
                    mt: 3,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">
                      Address Line 2 Required
                    </Typography>
                    <ControlledSwitch
                      name={`${name}.addressLine2.isRequired`}
                      id={`${name}.addressLine2.isRequired`}
                      size="small"
                    />
                  </Box>
                </Box>
              )}

              <Box
                sx={{
                  mt: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1">Town/ City</Typography>
                  <ControlledSwitch
                    name={`${name}.addressTown.isVisible`}
                    id={`${name}.addressTown.isVisible`}
                    size="small"
                  />
                </Box>
              </Box>

              {field.value.addressTown.isVisible && (
                <Box
                  sx={{
                    mt: 3,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">Town/ City Required</Typography>
                    <ControlledSwitch
                      name={`${name}.addressTown.isRequired`}
                      id={`${name}.addressTown.isRequired`}
                      size="small"
                    />
                  </Box>
                </Box>
              )}

              <Box
                sx={{
                  mt: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1">State/ County</Typography>
                  <ControlledSwitch
                    name={`${name}.addressState.isVisible`}
                    id={`${name}.addressState.isVisible`}
                    size="small"
                  />
                </Box>
              </Box>

              {field.value.addressState.isVisible && (
                <Box
                  sx={{
                    mt: 3,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">
                      State/ County Required
                    </Typography>
                    <ControlledSwitch
                      name={`${name}.addressState.isRequired`}
                      id={`${name}.addressState.isRequired`}
                      size="small"
                    />
                  </Box>
                </Box>
              )}

              <Box
                sx={{
                  mt: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1">ZIP/ Post Code</Typography>
                  <ControlledSwitch
                    name={`${name}.addressPostCode.isVisible`}
                    id={`${name}.addressPostCode.isVisible`}
                    size="small"
                  />
                </Box>
              </Box>

              {field.value.addressPostCode.isVisible && (
                <Box
                  sx={{
                    mt: 3,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">
                      ZIP/ Post Code Required
                    </Typography>
                    <ControlledSwitch
                      name={`${name}.addressPostCode.isRequired`}
                      id={`${name}.addressPostCode.isRequired`}
                      size="small"
                    />
                  </Box>
                </Box>
              )}

              <Box
                sx={{
                  mt: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1">Country</Typography>
                  <ControlledSwitch
                    name={`${name}.addressCountry.isVisible`}
                    id={`${name}.addressCountry.isVisible`}
                    size="small"
                  />
                </Box>
              </Box>

              {field.value.addressCountry.isVisible && (
                <Box
                  sx={{
                    mt: 3,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">Country Required</Typography>
                    <ControlledSwitch
                      name={`${name}.addressCountry.isRequired`}
                      id={`${name}.addressCountry.isRequired`}
                      size="small"
                    />
                  </Box>
                </Box>
              )}

              <Box sx={{ mt: 1 }}>
                <NewQuestionRequiredOption
                  name={name}
                ></NewQuestionRequiredOption>
              </Box>
              <Box sx={{ mt: 3 }}>
                <DynamicQuestionOption
                  name={name}
                  pageIndex={pageIndex}
                  blockIndex={blockIndex}
                ></DynamicQuestionOption>
              </Box>
            </>
          ),
        },
        visibility: {
          label: "Visibility",
          tabPanel: (
            <NewConditionalVisibilityOptions
              name={name}
              itemType={QuestionTypes.ADDRESS}
              itemTitle="question"
              pageIndex={pageIndex}
              blockIndex={blockIndex}
            ></NewConditionalVisibilityOptions>
          ),
        },
      }}
    />
  );
}
