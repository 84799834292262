import React from "react";

import { Box, BoxProps, Grid, Typography } from "@mui/material";

import { LiveSurveyThemeDocument } from "../../../../types/SurveyTheme";

import LiveSurveyQuestionText from "../../../SurveyComponents/LiveSurveyQuestionText";
import { useField } from "formik";
import TransparentSurveyButton from "../../../SurveyComponents/TransparentSurveyButton";
import { generateAnswerTextStyle } from "../../../../utils/styles/generateAnswerTextStyle";
import LiveSurveyCaptionText from "../../../SurveyComponents/LiveSurveyCaptionText";

type LiveScaleBlockProps = BoxProps & {
  name: string;
  questionText: string;
  questionDescription?: string;
  isQuestionRequired: boolean;
  surveyTheme: LiveSurveyThemeDocument | null;
  fromNumber: number;
  toNumber: number;
  minLabel?: string;
  midLabel?: string;
  maxLabel?: string;
};

export default function LiveScaleBlock({
  name,
  isQuestionRequired,
  questionText,
  questionDescription,
  surveyTheme,
  fromNumber,
  toNumber,
  minLabel,
  midLabel,
  maxLabel,
  sx,
}: LiveScaleBlockProps) {
  const [field, meta, helpers] = useField<number>(name);

  const updateValue = (newValue: number) => {
    helpers.setValue(newValue);
  };

  const scaleValues: number[] = [];
  for (let i = fromNumber; i <= toNumber; i++) {
    scaleValues.push(i);
  }

  return (
    <Box sx={{ mb: 3, ...sx }}>
      <LiveSurveyQuestionText
        questionText={questionText}
        questionDescription={questionDescription}
        isQuestionRequired={isQuestionRequired}
        surveyTheme={surveyTheme}
        sx={{
          mb: 3,
        }}
      />

      {/* // TODO - Need to also handle how the split view of the survey will effect the responsiveness values for ALL components*/}

      <Box
        sx={{
          my: 1.5,
          display: { xs: "flex", md: "none" },
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <LiveSurveyCaptionText color="GrayText" surveyTheme={surveyTheme}>
          {scaleValues[0]} - {minLabel}
        </LiveSurveyCaptionText>
        <LiveSurveyCaptionText color="GrayText" surveyTheme={surveyTheme}>
          {scaleValues.length % 2 === 0
            ? scaleValues.length / 2
            : Math.round(scaleValues.length / 2)}{" "}
          - {midLabel}
        </LiveSurveyCaptionText>
        <LiveSurveyCaptionText color="GrayText" surveyTheme={surveyTheme}>
          {scaleValues.at(-1)} - {maxLabel}
        </LiveSurveyCaptionText>
      </Box>

      <Grid
        container
        rowSpacing={1}
        columnSpacing={0}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {scaleValues.map((value, index) => {
          return (
            <Grid
              key={index}
              item
              xs={4}
              sm={2}
              md={12 / scaleValues.length}
              sx={{
                px: 0.5,
              }}
            >
              <TransparentSurveyButton
                surveyTheme={surveyTheme}
                isSelected={field.value === value}
                variant="outlined"
                size="large"
                sx={{
                  width: "100%",
                  // flexGrow: 1,
                  ...(value !== scaleValues[0] &&
                    {
                      // ml: 1,
                      // mx: 2,
                    }),
                  py: 2,
                }}
                onClick={() => {
                  updateValue(value);
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
                  }}
                >
                  {value}
                </Typography>
              </TransparentSurveyButton>
            </Grid>
          );
        })}
      </Grid>

      <Box
        sx={{
          mt: 1.5,
          display: { xs: "none", md: "flex" },
          justifyContent: "space-between",
        }}
      >
        <LiveSurveyCaptionText color="GrayText" surveyTheme={surveyTheme}>
          {minLabel}
        </LiveSurveyCaptionText>
        <LiveSurveyCaptionText color="GrayText" surveyTheme={surveyTheme}>
          {midLabel}
        </LiveSurveyCaptionText>
        <LiveSurveyCaptionText color="GrayText" surveyTheme={surveyTheme}>
          {maxLabel}
        </LiveSurveyCaptionText>
      </Box>
    </Box>
  );
}
