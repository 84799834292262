import React, { useEffect, useRef, useState } from "react";

import { Alert, Box, CircularProgress, IconButton } from "@mui/material";

import { useProtectedApi } from "../hooks/useProtectedApi";
import { SURVEY_THEME_URL } from "../data/constants";
import { AxiosError } from "axios";
import { InView } from "react-intersection-observer";
import { LiveSurveyThemeDocument } from "../types/SurveyTheme";
import SurveyThemePreviewCard from "./SurveyThemePreviewCard";
import { Add } from "@mui/icons-material";
import {
  useUpdateSavedSurveyTheme,
  useUpdateSurveyTheme,
} from "../providers/surveyTheme";

type MySurveyThemesListProps = {
  addClickHandler: () => void;
  editSurveyClickHandler: (surveyThemeToSetId: string) => void;
};

export default function MySurveyThemesList({
  addClickHandler,
  editSurveyClickHandler,
}: MySurveyThemesListProps) {
  const isInitialMount = useRef(true);

  const updateSurveyTheme = useUpdateSurveyTheme();
  const updateSavedSurveyTheme = useUpdateSavedSurveyTheme();

  const [initialLoading, setInitialLoading] = useState(true);
  const [initialError, setInitialError] = useState("");

  const [paginationLoading, setPaginationLoading] = useState(false);
  const [paginationError, setPaginationError] = useState("");

  const [showPaginationSpinnger, setShowPaginationSpinner] = useState(true);

  const pageNumber = useRef(1);
  const [results, setResults] = useState<LiveSurveyThemeDocument[]>([]);

  const ProtectedApi = useProtectedApi();

  const getSurveyThemes = async () => {
    const response = await ProtectedApi.get(SURVEY_THEME_URL, {
      params: { page: pageNumber.current, limit: 10 },
    });

    setResults((prevResults: LiveSurveyThemeDocument[]) => {
      return [...prevResults, ...response.data.success.data.surveyThemes];
    });

    if (response.data.success.data.next) {
      pageNumber.current = response.data.success.data.next.page;
      setShowPaginationSpinner(true);
    } else {
      setShowPaginationSpinner(false);
    }
  };

  const getInitialSurveyThemes = async () => {
    try {
      setInitialLoading(true);
      setInitialError("");
      pageNumber.current = 1;
      setResults([]);
      await getSurveyThemes();
      setInitialLoading(false);
    } catch (error) {
      setInitialLoading(false);
      if (error instanceof AxiosError && !error?.response) {
        setInitialError("No response from server.");
      } else if (error instanceof AxiosError && error?.response?.data) {
        setInitialError(error.response.data.error.message);
      } else {
        setInitialError("Fialed to get forms.");
      }
    }
  };

  const getPaginationSurveyThemes = async () => {
    try {
      setPaginationLoading(true);
      setPaginationError("");
      await getSurveyThemes();
      setPaginationLoading(false);
    } catch (error) {
      setPaginationLoading(false);
      if (error instanceof AxiosError && !error?.response) {
        setPaginationError("No response from server.");
      } else if (error instanceof AxiosError && error?.response?.data) {
        setPaginationError(error.response.data.error.message);
      } else {
        setPaginationError("Failed to get next page of forms");
      }
    }
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      getInitialSurveyThemes();
    }
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <IconButton
          onClick={() => {
            addClickHandler();
          }}
        >
          <Add fontSize="small" />
        </IconButton>
      </Box>
      {initialError && (
        <Alert sx={{ my: 3 }} variant="filled" severity="error">
          {initialError}
        </Alert>
      )}

      {initialLoading && !initialError && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 5,
          }}
        >
          <CircularProgress></CircularProgress>
        </Box>
      )}

      {results.length > 0 && !initialLoading && !initialError && (
        <>
          {results.map((surveyThemes: LiveSurveyThemeDocument) => {
            return (
              <SurveyThemePreviewCard
                surveyThemeSettings={surveyThemes}
                sx={{
                  mt: 1,
                }}
                key={surveyThemes._id}
                title={surveyThemes.title ?? "No Title"}
                editClickHandler={() => {
                  updateSurveyTheme && updateSurveyTheme(surveyThemes);
                  updateSavedSurveyTheme &&
                    updateSavedSurveyTheme(surveyThemes);

                  editSurveyClickHandler(surveyThemes._id);
                }}
              />
            );
          })}
          <Box
            sx={{
              my: 3,
              display: "flex",
              justifyContent: "center",
            }}
          >
            {showPaginationSpinnger && (
              <InView
                as="div"
                onChange={(inView, entry) => {
                  if (inView) {
                    getPaginationSurveyThemes();
                  }
                }}
              >
                <CircularProgress size={50} thickness={5} />
              </InView>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}
