import { AuthCallbackErrors } from "../types/Auth";

export const API_BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://www.app.livelyforms.io/api"
    : "http://localhost:5000/api";

export const FRONTEND_BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://app.livelyforms.io"
    : "http://localhost:3000";

export const LANDING_PAGE_URL = "https://livelyforms.io";

export const LOGIN_URL = "/auth/login";
export const LOGOUT_URL = "/auth/logout";
export const REFRESH_TOKEN_URL = "/auth/refresh";
export const FORGOT_PASSWORD_URL = "/auth/forgot-password";
export const RESET_PASSWORD_URL = "/auth/reset-password";

export const SET_FIRST_PASSWORD_URL = "/auth/set-password";

export const CHANGE_PASSWORD_URL = "/auth/change-password";
export const REQUEST_SET_PASSWORD_URL = "/auth/request-set-password";

export const SIGNUP_URL = "/users";
export const VERIFY_EMAIL_URL = "/users/verify";

export const ACCOUNT_URL = "/users/account";
export const DELETE_ACCOUNT_URL = "/users/account/delete";


export const SURVEY_DRAFTS_URL = "/survey/drafts";
export const SURVEY_PUBLISHED_URL = "/survey/published";

export const RESPONSE_URL = "/response";

export const IMAGE_URL = "/image";

export const SURVEY_THEME_URL = "/surveyTheme";


//  Callback Errors
export const AuthCallbackErrorMessages = {
  [AuthCallbackErrors.GOOGLE_AUTH_ERROR]: "OOPS! there was a problem whilst trying to get you in using Google.",
  [AuthCallbackErrors.MICROSOFT_AUTH_ERROR]: "OOPS! there was a problem whilst trying to get you in using Microsoft.",
  [AuthCallbackErrors.AUTH_ERROR_STATUS_BLOCKED]: "Sorry, your account is blocked from using Lively Forms.",
  [AuthCallbackErrors.AUTH_ERROR_STATUS_PENDING]: "Hi, your account is still pending approval. We will approve it shortly."
}