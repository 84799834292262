import React, { useEffect } from "react";

import { Box, Divider } from "@mui/material";

import { useTheme } from "@mui/material/styles";

import { FormikTouched, useField, useFormikContext } from "formik";

import { Draggable } from "react-beautiful-dnd";

import { StrictModeDroppable } from "./Helpers/StrictModeDroppable";

import {
  Blocks,
  FormPage,
  FormSubBlockFields,
  FormSubBlockTypes,
  FormSubItemFields,
  FormSubItemTypes,
  PageBlockFields,
  QuestionFields,
  QuestionTypes,
} from "../types/Blocks";
import { IFormCanvasBlocksFields } from "./FormBuilder";

import EmptyFormPagePlaceHolder from "./EmptyFormPagePlaceHolder";
import { ActionFields, ActionPathFields, Actions } from "../types/Actions";

import EmptyPageIcon from "./Icons/EmptyPageIcon";
import StartBuildingFormIcon from "./Icons/StartBuildingFormIcon";
import EmptyActionPathIcon from "./Icons/EmptyActionPathIcon";
import StartBuildingActionsIcon from "./Icons/StartBuildingActionPathsIcon";

import TextFieldBlock from "./Blocks/FormBlocks/TextFieldBlock/TextFieldBlock";
import CheckboxesBlock from "./Blocks/FormBlocks/CheckboxesBlock/CheckboxesBlock";
import StarRatingBlock from "./Blocks/FormBlocks/StarRatingBlock/StarRatingBlock";
import PageBlock from "./Blocks/FormBlocks/PageBlock/PageBlock";
import ActionPathBlock from "./Blocks/ActionBlocks/ActionPathBlock/ActionPathBlock";
import RedirectActionBlock from "./Blocks/ActionBlocks/RedirectActionBlock/RedirectActionBlock";
import { SettingsDrawerPAnel } from "../types/Drawers";
import HeadingBlock from "./Blocks/FormBlocks/HeadingBlock/HeadingBlock";
import ParagraphBlock from "./Blocks/FormBlocks/ParagraphBlock/ParagraphBlock";
import YesNoBlock from "./Blocks/FormBlocks/YesNoBlock/YesNoBlock";
import ScaleBlock from "./Blocks/FormBlocks/ScaleBlock/ScaleBlock";
import SliderBlock from "./Blocks/FormBlocks/SliderBlock/SliderBlock";
import RankingBlock from "./Blocks/FormBlocks/RankingBlock/RankingBlock";
import ImageChoiceBlock from "./Blocks/FormBlocks/ImageChoiceBlock/ImageChoiceBlock";
import DateBlock from "./Blocks/FormBlocks/DateBlock/DateBlock";
import RadioGroupBlock from "./Blocks/FormBlocks/RadioGroupBlock/RadioGroupBlock";
import DropdownBlock from "./Blocks/FormBlocks/DropdownBlock/DropdownBlock";
import EmailBlock from "./Blocks/FormBlocks/EmailBlock/EmailBlock";
import PhoneBlock from "./Blocks/FormBlocks/PhoneBlock/PhoneBlock";
import LinkBlock from "./Blocks/FormBlocks/LinkBlock/LinkBlock";
import AddressBlock from "./Blocks/FormBlocks/AddressBlock/AddressBlock";
import { useSurveyTheme } from "../providers/surveyTheme";

interface IFormBuilderCanvasProps {
  fieldName: string;
  settingsClickHandler: (
    type: QuestionTypes | Blocks | Actions | FormSubBlockTypes,
    pageIndex: number,
    blockIndex?: number
  ) => void;
  currentDrawerPanel: SettingsDrawerPAnel | undefined;
  resetSettingsDrawerPanel: () => void;
  pagesRef: React.RefObject<HTMLDivElement | null>;
  actionPathsRef: React.RefObject<HTMLDivElement | null>;
  showSettingsDrawer: boolean;
  showMainDrawer: boolean;
}

export default function FormBuilderCanvas({
  fieldName,
  settingsClickHandler,
  currentDrawerPanel,
  resetSettingsDrawerPanel,
  pagesRef,
  actionPathsRef,
  showSettingsDrawer,
  showMainDrawer,
}: IFormBuilderCanvasProps) {
  const surveyTheme = useSurveyTheme();
  const theme = useTheme();

  const {
    values: formValues,
    setFieldValue,
    touched,
    validateForm,
    setFieldTouched,
  } = useFormikContext<IFormCanvasBlocksFields>();
  const [field] = useField<PageBlockFields[]>(fieldName);

  const deleteItemHandler = (
    fieldName: string,
    values:
      | PageBlockFields[]
      | ActionPathFields[]
      | FormSubItemFields[]
      | ActionFields[],
    valuesTouched:
      | FormikTouched<
          FormPage | ActionPathFields | QuestionFields | ActionFields
        >[]
      | undefined,
    itemIndexToDelete: number
  ) => {
    if (valuesTouched && Array.isArray(valuesTouched)) {
      setFieldTouched(
        fieldName,
        // @ts-ignore
        valuesTouched.filter(
          (
            _: FormikTouched<
              FormPage | ActionPathFields | QuestionFields | ActionFields
            >,
            itemIndex: number
          ) => {
            return itemIndex !== itemIndexToDelete;
          }
        )
      );
    }

    setFieldValue(
      fieldName,
      // @ts-ignore
      values.filter(
        (
          _:
            | PageBlockFields
            | ActionPathFields
            | FormSubItemFields
            | ActionFields,
          itemIndex: number
        ) => {
          return itemIndex !== itemIndexToDelete;
        }
      )
    );
  };

  const triggerValidation = async () => {
    await validateForm();
  };

  useEffect(() => {
    triggerValidation();
  }, [formValues]);

  return (
    <Box>
      <StrictModeDroppable
        droppableId="form-blocks-droppable"
        type="droppableItem"
      >
        {(provided) => {
          return (
            <Box {...provided.droppableProps} ref={provided.innerRef}>
              {field.value.length === 0 && (
                <Box sx={{ my: 5 }}>
                  <EmptyFormPagePlaceHolder
                    title="Let's start building a form."
                    description="Drag and Drop different types of form blocks from the left drawer to start building your form."
                    icon={<StartBuildingFormIcon />}
                    sx={{
                      minHeight: "85vh",
                      maxHeight: "85vh",
                    }}
                  />
                </Box>
              )}
              <Box ref={pagesRef}>
                {field.value.map((page: PageBlockFields, pageIndex: number) => {
                  return (
                    <Draggable
                      key={page.pageId}
                      draggableId={page.pageId}
                      index={pageIndex}
                    >
                      {(provided) => {
                        return (
                          <Box
                            {...provided.draggableProps}
                            // {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            sx={{ mb: 2 }}
                          >
                            <PageBlock
                              formScreenContentStyle={{
                                ...((!showSettingsDrawer ||
                                  !showMainDrawer) && {
                                  px:
                                    (showSettingsDrawer ? 0 : 4) +
                                    (showMainDrawer ? 0 : 4),
                                }),
                              }}
                              settingsHandler={() => {
                                settingsClickHandler(Blocks.PAGE, pageIndex);
                              }}
                              name={`pages[${pageIndex}]`}
                              pageIndex={pageIndex}
                              numOfPages={field.value.length}
                              dragHandleProps={provided.dragHandleProps}
                              deleteHandler={() => {
                                if (
                                  currentDrawerPanel?.pageIndex === pageIndex
                                ) {
                                  resetSettingsDrawerPanel();
                                }

                                deleteItemHandler(
                                  "pages",
                                  formValues.pages,
                                  touched.pages,
                                  pageIndex
                                );
                              }}
                            >
                              <StrictModeDroppable
                                droppableId={page.pageId}
                                type={`droppableSubItem`}
                              >
                                {(provided) => {
                                  return (
                                    <Box
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                    >
                                      {field.value[pageIndex]?.subItems
                                        .length === 0 && (
                                        <EmptyFormPagePlaceHolder
                                          title="Add a Question"
                                          description="You can add fields to this form page by from the sidebar on the lef ."
                                          icon={<EmptyPageIcon />}
                                          iconStyle={{
                                            ...(surveyTheme && {
                                              fill: surveyTheme.colorSettings
                                                .questionTextColor,
                                            }),
                                          }}
                                          surveyThemeStyling={true}
                                        />
                                      )}
                                      {field.value[pageIndex].subItems.map(
                                        (
                                          formBlock: FormSubItemFields,
                                          blockIndex: number
                                        ) => {
                                          return (
                                            <Draggable
                                              key={formBlock.subItemId}
                                              draggableId={formBlock.subItemId}
                                              index={blockIndex}
                                            >
                                              {(provided, snapshot) => {
                                                return (
                                                  <Box
                                                    {...provided.draggableProps}
                                                    // {...provided.dragHandleProps}
                                                    ref={provided.innerRef}
                                                  >
                                                    {formBlock.subItemType ===
                                                      FormSubItemTypes.QUESTION &&
                                                      (
                                                        formBlock as QuestionFields
                                                      ).questionType ===
                                                        QuestionTypes.TEXT && (
                                                        <TextFieldBlock
                                                          settingsHandler={() => {
                                                            settingsClickHandler(
                                                              QuestionTypes.TEXT,
                                                              pageIndex,
                                                              blockIndex
                                                            );
                                                          }}
                                                          settingsPanelActive={
                                                            currentDrawerPanel?.blockIndex ===
                                                              blockIndex &&
                                                            currentDrawerPanel?.pageIndex ===
                                                              pageIndex
                                                          }
                                                          dragHandleProps={
                                                            provided.dragHandleProps
                                                          }
                                                          key={
                                                            formBlock.subItemId
                                                          }
                                                          name={`pages[${pageIndex}].subItems[${blockIndex}]`}
                                                          pageIndex={pageIndex}
                                                          blockIndex={
                                                            blockIndex
                                                          }
                                                          deleteHandler={() => {
                                                            if (
                                                              currentDrawerPanel?.blockIndex ===
                                                                blockIndex &&
                                                              currentDrawerPanel?.pageIndex ===
                                                                pageIndex
                                                            ) {
                                                              resetSettingsDrawerPanel();
                                                            }

                                                            deleteItemHandler(
                                                              `pages[${pageIndex}].subItems`,
                                                              formValues.pages[
                                                                pageIndex
                                                              ].subItems,
                                                              // TODO - This might be cause the error. When no questions have been touched in a page  and we try to delete it then it throws an error
                                                              touched.pages?.[
                                                                pageIndex
                                                              ].subItems,
                                                              blockIndex
                                                            );
                                                          }}
                                                        ></TextFieldBlock>
                                                      )}

                                                    {formBlock.subItemType ===
                                                      FormSubItemTypes.QUESTION &&
                                                      (
                                                        formBlock as QuestionFields
                                                      ).questionType ===
                                                        QuestionTypes.CHECKBOXES && (
                                                        <CheckboxesBlock
                                                          settingsHandler={() => {
                                                            settingsClickHandler(
                                                              QuestionTypes.CHECKBOXES,
                                                              pageIndex,
                                                              blockIndex
                                                            );
                                                          }}
                                                          settingsPanelActive={
                                                            currentDrawerPanel?.blockIndex ===
                                                              blockIndex &&
                                                            currentDrawerPanel?.pageIndex ===
                                                              pageIndex
                                                          }
                                                          name={`pages[${pageIndex}].subItems[${blockIndex}]`}
                                                          dragHandleProps={
                                                            provided.dragHandleProps
                                                          }
                                                          pageIndex={pageIndex}
                                                          blockIndex={
                                                            blockIndex
                                                          }
                                                          deleteHandler={() => {
                                                            if (
                                                              currentDrawerPanel?.blockIndex ===
                                                                blockIndex &&
                                                              currentDrawerPanel?.pageIndex ===
                                                                pageIndex
                                                            ) {
                                                              resetSettingsDrawerPanel();
                                                            }

                                                            deleteItemHandler(
                                                              `pages[${pageIndex}].subItems`,
                                                              formValues.pages[
                                                                pageIndex
                                                              ].subItems,
                                                              touched.pages?.[
                                                                pageIndex
                                                              ].subItems,
                                                              blockIndex
                                                            );
                                                          }}
                                                        ></CheckboxesBlock>
                                                      )}

                                                    {formBlock.subItemType ===
                                                      FormSubItemTypes.QUESTION &&
                                                      (
                                                        formBlock as QuestionFields
                                                      ).questionType ===
                                                        QuestionTypes.STAR_RATING && (
                                                        <StarRatingBlock
                                                          settingsPanelActive={
                                                            currentDrawerPanel?.blockIndex ===
                                                              blockIndex &&
                                                            currentDrawerPanel?.pageIndex ===
                                                              pageIndex
                                                          }
                                                          settingsHandler={() => {
                                                            settingsClickHandler(
                                                              QuestionTypes.STAR_RATING,
                                                              pageIndex,
                                                              blockIndex
                                                            );
                                                          }}
                                                          dragHandleProps={
                                                            provided.dragHandleProps
                                                          }
                                                          key={
                                                            formBlock.subItemId
                                                          }
                                                          name={`pages[${pageIndex}].subItems[${blockIndex}]`}
                                                          pageIndex={pageIndex}
                                                          blockIndex={
                                                            blockIndex
                                                          }
                                                          deleteHandler={() => {
                                                            if (
                                                              currentDrawerPanel?.blockIndex ===
                                                                blockIndex &&
                                                              currentDrawerPanel?.pageIndex ===
                                                                pageIndex
                                                            ) {
                                                              resetSettingsDrawerPanel();
                                                            }

                                                            deleteItemHandler(
                                                              `pages[${pageIndex}].subItems`,
                                                              formValues.pages[
                                                                pageIndex
                                                              ].subItems,
                                                              touched.pages?.[
                                                                pageIndex
                                                              ].subItems,
                                                              blockIndex
                                                            );
                                                          }}
                                                        ></StarRatingBlock>
                                                      )}

                                                    {formBlock.subItemType ===
                                                      FormSubItemTypes.QUESTION &&
                                                      (
                                                        formBlock as QuestionFields
                                                      ).questionType ===
                                                        QuestionTypes.YESNO && (
                                                        <YesNoBlock
                                                          settingsHandler={() => {
                                                            settingsClickHandler(
                                                              QuestionTypes.YESNO,
                                                              pageIndex,
                                                              blockIndex
                                                            );
                                                          }}
                                                          settingsPanelActive={
                                                            currentDrawerPanel?.blockIndex ===
                                                              blockIndex &&
                                                            currentDrawerPanel?.pageIndex ===
                                                              pageIndex
                                                          }
                                                          dragHandleProps={
                                                            provided.dragHandleProps
                                                          }
                                                          key={
                                                            formBlock.subItemId
                                                          }
                                                          name={`pages[${pageIndex}].subItems[${blockIndex}]`}
                                                          pageIndex={pageIndex}
                                                          blockIndex={
                                                            blockIndex
                                                          }
                                                          deleteHandler={() => {
                                                            if (
                                                              currentDrawerPanel?.blockIndex ===
                                                                blockIndex &&
                                                              currentDrawerPanel?.pageIndex ===
                                                                pageIndex
                                                            ) {
                                                              resetSettingsDrawerPanel();
                                                            }

                                                            deleteItemHandler(
                                                              `pages[${pageIndex}].subItems`,
                                                              formValues.pages[
                                                                pageIndex
                                                              ].subItems,
                                                              touched.pages?.[
                                                                pageIndex
                                                              ].subItems,
                                                              blockIndex
                                                            );
                                                          }}
                                                        ></YesNoBlock>
                                                      )}

                                                    {formBlock.subItemType ===
                                                      FormSubItemTypes.QUESTION &&
                                                      (
                                                        formBlock as QuestionFields
                                                      ).questionType ===
                                                        QuestionTypes.SCALE && (
                                                        <ScaleBlock
                                                          settingsHandler={() => {
                                                            settingsClickHandler(
                                                              QuestionTypes.SCALE,
                                                              pageIndex,
                                                              blockIndex
                                                            );
                                                          }}
                                                          settingsPanelActive={
                                                            currentDrawerPanel?.blockIndex ===
                                                              blockIndex &&
                                                            currentDrawerPanel?.pageIndex ===
                                                              pageIndex
                                                          }
                                                          dragHandleProps={
                                                            provided.dragHandleProps
                                                          }
                                                          key={
                                                            formBlock.subItemId
                                                          }
                                                          name={`pages[${pageIndex}].subItems[${blockIndex}]`}
                                                          pageIndex={pageIndex}
                                                          blockIndex={
                                                            blockIndex
                                                          }
                                                          deleteHandler={() => {
                                                            if (
                                                              currentDrawerPanel?.blockIndex ===
                                                                blockIndex &&
                                                              currentDrawerPanel?.pageIndex ===
                                                                pageIndex
                                                            ) {
                                                              resetSettingsDrawerPanel();
                                                            }

                                                            deleteItemHandler(
                                                              `pages[${pageIndex}].subItems`,
                                                              formValues.pages[
                                                                pageIndex
                                                              ].subItems,
                                                              touched.pages?.[
                                                                pageIndex
                                                              ].subItems,
                                                              blockIndex
                                                            );
                                                          }}
                                                        ></ScaleBlock>
                                                      )}

                                                    {formBlock.subItemType ===
                                                      FormSubItemTypes.QUESTION &&
                                                      (
                                                        formBlock as QuestionFields
                                                      ).questionType ===
                                                        QuestionTypes.SLIDER && (
                                                        <SliderBlock
                                                          settingsHandler={() => {
                                                            settingsClickHandler(
                                                              QuestionTypes.SLIDER,
                                                              pageIndex,
                                                              blockIndex
                                                            );
                                                          }}
                                                          settingsPanelActive={
                                                            currentDrawerPanel?.blockIndex ===
                                                              blockIndex &&
                                                            currentDrawerPanel?.pageIndex ===
                                                              pageIndex
                                                          }
                                                          dragHandleProps={
                                                            provided.dragHandleProps
                                                          }
                                                          key={
                                                            formBlock.subItemId
                                                          }
                                                          name={`pages[${pageIndex}].subItems[${blockIndex}]`}
                                                          pageIndex={pageIndex}
                                                          blockIndex={
                                                            blockIndex
                                                          }
                                                          deleteHandler={() => {
                                                            if (
                                                              currentDrawerPanel?.blockIndex ===
                                                                blockIndex &&
                                                              currentDrawerPanel?.pageIndex ===
                                                                pageIndex
                                                            ) {
                                                              resetSettingsDrawerPanel();
                                                            }

                                                            deleteItemHandler(
                                                              `pages[${pageIndex}].subItems`,
                                                              formValues.pages[
                                                                pageIndex
                                                              ].subItems,
                                                              touched.pages?.[
                                                                pageIndex
                                                              ].subItems,
                                                              blockIndex
                                                            );
                                                          }}
                                                        ></SliderBlock>
                                                      )}

                                                    {formBlock.subItemType ===
                                                      FormSubItemTypes.QUESTION &&
                                                      (
                                                        formBlock as QuestionFields
                                                      ).questionType ===
                                                        QuestionTypes.RANKING && (
                                                        <RankingBlock
                                                          settingsHandler={() => {
                                                            settingsClickHandler(
                                                              QuestionTypes.RANKING,
                                                              pageIndex,
                                                              blockIndex
                                                            );
                                                          }}
                                                          settingsPanelActive={
                                                            currentDrawerPanel?.blockIndex ===
                                                              blockIndex &&
                                                            currentDrawerPanel?.pageIndex ===
                                                              pageIndex
                                                          }
                                                          dragHandleProps={
                                                            provided.dragHandleProps
                                                          }
                                                          key={
                                                            formBlock.subItemId
                                                          }
                                                          name={`pages[${pageIndex}].subItems[${blockIndex}]`}
                                                          pageIndex={pageIndex}
                                                          blockIndex={
                                                            blockIndex
                                                          }
                                                          deleteHandler={() => {
                                                            if (
                                                              currentDrawerPanel?.blockIndex ===
                                                                blockIndex &&
                                                              currentDrawerPanel?.pageIndex ===
                                                                pageIndex
                                                            ) {
                                                              resetSettingsDrawerPanel();
                                                            }

                                                            deleteItemHandler(
                                                              `pages[${pageIndex}].subItems`,
                                                              formValues.pages[
                                                                pageIndex
                                                              ].subItems,
                                                              touched.pages?.[
                                                                pageIndex
                                                              ].subItems,
                                                              blockIndex
                                                            );
                                                          }}
                                                        ></RankingBlock>
                                                      )}

                                                    {formBlock.subItemType ===
                                                      FormSubItemTypes.QUESTION &&
                                                      (
                                                        formBlock as QuestionFields
                                                      ).questionType ===
                                                        QuestionTypes.IMAGE_CHOICE && (
                                                        <ImageChoiceBlock
                                                          settingsHandler={() => {
                                                            settingsClickHandler(
                                                              QuestionTypes.IMAGE_CHOICE,
                                                              pageIndex,
                                                              blockIndex
                                                            );
                                                          }}
                                                          settingsPanelActive={
                                                            currentDrawerPanel?.blockIndex ===
                                                              blockIndex &&
                                                            currentDrawerPanel?.pageIndex ===
                                                              pageIndex
                                                          }
                                                          dragHandleProps={
                                                            provided.dragHandleProps
                                                          }
                                                          key={
                                                            formBlock.subItemId
                                                          }
                                                          name={`pages[${pageIndex}].subItems[${blockIndex}]`}
                                                          pageIndex={pageIndex}
                                                          blockIndex={
                                                            blockIndex
                                                          }
                                                          deleteHandler={() => {
                                                            if (
                                                              currentDrawerPanel?.blockIndex ===
                                                                blockIndex &&
                                                              currentDrawerPanel?.pageIndex ===
                                                                pageIndex
                                                            ) {
                                                              resetSettingsDrawerPanel();
                                                            }

                                                            deleteItemHandler(
                                                              `pages[${pageIndex}].subItems`,
                                                              formValues.pages[
                                                                pageIndex
                                                              ].subItems,
                                                              touched.pages?.[
                                                                pageIndex
                                                              ].subItems,
                                                              blockIndex
                                                            );
                                                          }}
                                                        ></ImageChoiceBlock>
                                                      )}

                                                    {formBlock.subItemType ===
                                                      FormSubItemTypes.QUESTION &&
                                                      (
                                                        formBlock as QuestionFields
                                                      ).questionType ===
                                                        QuestionTypes.DATE && (
                                                        <DateBlock
                                                          settingsHandler={() => {
                                                            settingsClickHandler(
                                                              QuestionTypes.DATE,
                                                              pageIndex,
                                                              blockIndex
                                                            );
                                                          }}
                                                          settingsPanelActive={
                                                            currentDrawerPanel?.blockIndex ===
                                                              blockIndex &&
                                                            currentDrawerPanel?.pageIndex ===
                                                              pageIndex
                                                          }
                                                          dragHandleProps={
                                                            provided.dragHandleProps
                                                          }
                                                          key={
                                                            formBlock.subItemId
                                                          }
                                                          name={`pages[${pageIndex}].subItems[${blockIndex}]`}
                                                          pageIndex={pageIndex}
                                                          blockIndex={
                                                            blockIndex
                                                          }
                                                          deleteHandler={() => {
                                                            if (
                                                              currentDrawerPanel?.blockIndex ===
                                                                blockIndex &&
                                                              currentDrawerPanel?.pageIndex ===
                                                                pageIndex
                                                            ) {
                                                              resetSettingsDrawerPanel();
                                                            }

                                                            deleteItemHandler(
                                                              `pages[${pageIndex}].subItems`,
                                                              formValues.pages[
                                                                pageIndex
                                                              ].subItems,
                                                              touched.pages?.[
                                                                pageIndex
                                                              ].subItems,
                                                              blockIndex
                                                            );
                                                          }}
                                                        ></DateBlock>
                                                      )}

                                                    {formBlock.subItemType ===
                                                      FormSubItemTypes.QUESTION &&
                                                      (
                                                        formBlock as QuestionFields
                                                      ).questionType ===
                                                        QuestionTypes.RADIO_GROUP && (
                                                        <RadioGroupBlock
                                                          settingsHandler={() => {
                                                            settingsClickHandler(
                                                              QuestionTypes.RADIO_GROUP,
                                                              pageIndex,
                                                              blockIndex
                                                            );
                                                          }}
                                                          settingsPanelActive={
                                                            currentDrawerPanel?.blockIndex ===
                                                              blockIndex &&
                                                            currentDrawerPanel?.pageIndex ===
                                                              pageIndex
                                                          }
                                                          dragHandleProps={
                                                            provided.dragHandleProps
                                                          }
                                                          key={
                                                            formBlock.subItemId
                                                          }
                                                          name={`pages[${pageIndex}].subItems[${blockIndex}]`}
                                                          pageIndex={pageIndex}
                                                          blockIndex={
                                                            blockIndex
                                                          }
                                                          deleteHandler={() => {
                                                            if (
                                                              currentDrawerPanel?.blockIndex ===
                                                                blockIndex &&
                                                              currentDrawerPanel?.pageIndex ===
                                                                pageIndex
                                                            ) {
                                                              resetSettingsDrawerPanel();
                                                            }

                                                            deleteItemHandler(
                                                              `pages[${pageIndex}].subItems`,
                                                              formValues.pages[
                                                                pageIndex
                                                              ].subItems,
                                                              touched.pages?.[
                                                                pageIndex
                                                              ].subItems,
                                                              blockIndex
                                                            );
                                                          }}
                                                        ></RadioGroupBlock>
                                                      )}

                                                    {formBlock.subItemType ===
                                                      FormSubItemTypes.QUESTION &&
                                                      (
                                                        formBlock as QuestionFields
                                                      ).questionType ===
                                                        QuestionTypes.DROPDOWN && (
                                                        <DropdownBlock
                                                          settingsHandler={() => {
                                                            settingsClickHandler(
                                                              QuestionTypes.DROPDOWN,
                                                              pageIndex,
                                                              blockIndex
                                                            );
                                                          }}
                                                          settingsPanelActive={
                                                            currentDrawerPanel?.blockIndex ===
                                                              blockIndex &&
                                                            currentDrawerPanel?.pageIndex ===
                                                              pageIndex
                                                          }
                                                          dragHandleProps={
                                                            provided.dragHandleProps
                                                          }
                                                          key={
                                                            formBlock.subItemId
                                                          }
                                                          name={`pages[${pageIndex}].subItems[${blockIndex}]`}
                                                          pageIndex={pageIndex}
                                                          blockIndex={
                                                            blockIndex
                                                          }
                                                          deleteHandler={() => {
                                                            if (
                                                              currentDrawerPanel?.blockIndex ===
                                                                blockIndex &&
                                                              currentDrawerPanel?.pageIndex ===
                                                                pageIndex
                                                            ) {
                                                              resetSettingsDrawerPanel();
                                                            }

                                                            deleteItemHandler(
                                                              `pages[${pageIndex}].subItems`,
                                                              formValues.pages[
                                                                pageIndex
                                                              ].subItems,
                                                              touched.pages?.[
                                                                pageIndex
                                                              ].subItems,
                                                              blockIndex
                                                            );
                                                          }}
                                                        ></DropdownBlock>
                                                      )}

                                                    {formBlock.subItemType ===
                                                      FormSubItemTypes.QUESTION &&
                                                      (
                                                        formBlock as QuestionFields
                                                      ).questionType ===
                                                        QuestionTypes.EMAIL && (
                                                        <EmailBlock
                                                          settingsHandler={() => {
                                                            settingsClickHandler(
                                                              QuestionTypes.EMAIL,
                                                              pageIndex,
                                                              blockIndex
                                                            );
                                                          }}
                                                          settingsPanelActive={
                                                            currentDrawerPanel?.blockIndex ===
                                                              blockIndex &&
                                                            currentDrawerPanel?.pageIndex ===
                                                              pageIndex
                                                          }
                                                          dragHandleProps={
                                                            provided.dragHandleProps
                                                          }
                                                          key={
                                                            formBlock.subItemId
                                                          }
                                                          name={`pages[${pageIndex}].subItems[${blockIndex}]`}
                                                          pageIndex={pageIndex}
                                                          blockIndex={
                                                            blockIndex
                                                          }
                                                          deleteHandler={() => {
                                                            if (
                                                              currentDrawerPanel?.blockIndex ===
                                                                blockIndex &&
                                                              currentDrawerPanel?.pageIndex ===
                                                                pageIndex
                                                            ) {
                                                              resetSettingsDrawerPanel();
                                                            }

                                                            deleteItemHandler(
                                                              `pages[${pageIndex}].subItems`,
                                                              formValues.pages[
                                                                pageIndex
                                                              ].subItems,
                                                              touched.pages?.[
                                                                pageIndex
                                                              ].subItems,
                                                              blockIndex
                                                            );
                                                          }}
                                                        ></EmailBlock>
                                                      )}

                                                    {formBlock.subItemType ===
                                                      FormSubItemTypes.QUESTION &&
                                                      (
                                                        formBlock as QuestionFields
                                                      ).questionType ===
                                                        QuestionTypes.PHONE && (
                                                        <PhoneBlock
                                                          settingsHandler={() => {
                                                            settingsClickHandler(
                                                              QuestionTypes.PHONE,
                                                              pageIndex,
                                                              blockIndex
                                                            );
                                                          }}
                                                          settingsPanelActive={
                                                            currentDrawerPanel?.blockIndex ===
                                                              blockIndex &&
                                                            currentDrawerPanel?.pageIndex ===
                                                              pageIndex
                                                          }
                                                          dragHandleProps={
                                                            provided.dragHandleProps
                                                          }
                                                          key={
                                                            formBlock.subItemId
                                                          }
                                                          name={`pages[${pageIndex}].subItems[${blockIndex}]`}
                                                          pageIndex={pageIndex}
                                                          blockIndex={
                                                            blockIndex
                                                          }
                                                          deleteHandler={() => {
                                                            if (
                                                              currentDrawerPanel?.blockIndex ===
                                                                blockIndex &&
                                                              currentDrawerPanel?.pageIndex ===
                                                                pageIndex
                                                            ) {
                                                              resetSettingsDrawerPanel();
                                                            }

                                                            deleteItemHandler(
                                                              `pages[${pageIndex}].subItems`,
                                                              formValues.pages[
                                                                pageIndex
                                                              ].subItems,
                                                              touched.pages?.[
                                                                pageIndex
                                                              ].subItems,
                                                              blockIndex
                                                            );
                                                          }}
                                                        ></PhoneBlock>
                                                      )}

                                                    {formBlock.subItemType ===
                                                      FormSubItemTypes.QUESTION &&
                                                      (
                                                        formBlock as QuestionFields
                                                      ).questionType ===
                                                        QuestionTypes.LINK && (
                                                        <LinkBlock
                                                          settingsHandler={() => {
                                                            settingsClickHandler(
                                                              QuestionTypes.LINK,
                                                              pageIndex,
                                                              blockIndex
                                                            );
                                                          }}
                                                          settingsPanelActive={
                                                            currentDrawerPanel?.blockIndex ===
                                                              blockIndex &&
                                                            currentDrawerPanel?.pageIndex ===
                                                              pageIndex
                                                          }
                                                          dragHandleProps={
                                                            provided.dragHandleProps
                                                          }
                                                          key={
                                                            formBlock.subItemId
                                                          }
                                                          name={`pages[${pageIndex}].subItems[${blockIndex}]`}
                                                          pageIndex={pageIndex}
                                                          blockIndex={
                                                            blockIndex
                                                          }
                                                          deleteHandler={() => {
                                                            if (
                                                              currentDrawerPanel?.blockIndex ===
                                                                blockIndex &&
                                                              currentDrawerPanel?.pageIndex ===
                                                                pageIndex
                                                            ) {
                                                              resetSettingsDrawerPanel();
                                                            }

                                                            deleteItemHandler(
                                                              `pages[${pageIndex}].subItems`,
                                                              formValues.pages[
                                                                pageIndex
                                                              ].subItems,
                                                              touched.pages?.[
                                                                pageIndex
                                                              ].subItems,
                                                              blockIndex
                                                            );
                                                          }}
                                                        ></LinkBlock>
                                                      )}

                                                    {formBlock.subItemType ===
                                                      FormSubItemTypes.QUESTION &&
                                                      (
                                                        formBlock as QuestionFields
                                                      ).questionType ===
                                                        QuestionTypes.ADDRESS && (
                                                        <AddressBlock
                                                          settingsHandler={() => {
                                                            settingsClickHandler(
                                                              QuestionTypes.ADDRESS,
                                                              pageIndex,
                                                              blockIndex
                                                            );
                                                          }}
                                                          settingsPanelActive={
                                                            currentDrawerPanel?.blockIndex ===
                                                              blockIndex &&
                                                            currentDrawerPanel?.pageIndex ===
                                                              pageIndex
                                                          }
                                                          dragHandleProps={
                                                            provided.dragHandleProps
                                                          }
                                                          key={
                                                            formBlock.subItemId
                                                          }
                                                          name={`pages[${pageIndex}].subItems[${blockIndex}]`}
                                                          pageIndex={pageIndex}
                                                          blockIndex={
                                                            blockIndex
                                                          }
                                                          deleteHandler={() => {
                                                            if (
                                                              currentDrawerPanel?.blockIndex ===
                                                                blockIndex &&
                                                              currentDrawerPanel?.pageIndex ===
                                                                pageIndex
                                                            ) {
                                                              resetSettingsDrawerPanel();
                                                            }

                                                            deleteItemHandler(
                                                              `pages[${pageIndex}].subItems`,
                                                              formValues.pages[
                                                                pageIndex
                                                              ].subItems,
                                                              touched.pages?.[
                                                                pageIndex
                                                              ].subItems,
                                                              blockIndex
                                                            );
                                                          }}
                                                        ></AddressBlock>
                                                      )}

                                                    {formBlock.subItemType ===
                                                      FormSubItemTypes.BLOCK &&
                                                      (
                                                        formBlock as FormSubBlockFields
                                                      ).blockType ===
                                                        FormSubBlockTypes.HEADING && (
                                                        <HeadingBlock
                                                          settingsHandler={() => {
                                                            settingsClickHandler(
                                                              FormSubBlockTypes.HEADING,
                                                              pageIndex,
                                                              blockIndex
                                                            );
                                                          }}
                                                          settingsPanelActive={
                                                            currentDrawerPanel?.blockIndex ===
                                                              blockIndex &&
                                                            currentDrawerPanel?.pageIndex ===
                                                              pageIndex
                                                          }
                                                          dragHandleProps={
                                                            provided.dragHandleProps
                                                          }
                                                          key={
                                                            formBlock.subItemId
                                                          }
                                                          name={`pages[${pageIndex}].subItems[${blockIndex}]`}
                                                          pageIndex={pageIndex}
                                                          blockIndex={
                                                            blockIndex
                                                          }
                                                          deleteHandler={() => {
                                                            if (
                                                              currentDrawerPanel?.blockIndex ===
                                                                blockIndex &&
                                                              currentDrawerPanel?.pageIndex ===
                                                                pageIndex
                                                            ) {
                                                              resetSettingsDrawerPanel();
                                                            }

                                                            deleteItemHandler(
                                                              `pages[${pageIndex}].subItems`,
                                                              formValues.pages[
                                                                pageIndex
                                                              ].subItems,
                                                              touched.pages?.[
                                                                pageIndex
                                                              ].subItems,
                                                              blockIndex
                                                            );
                                                          }}
                                                        ></HeadingBlock>
                                                      )}

                                                    {formBlock.subItemType ===
                                                      FormSubItemTypes.BLOCK &&
                                                      (
                                                        formBlock as FormSubBlockFields
                                                      ).blockType ===
                                                        FormSubBlockTypes.PARAGRAPH && (
                                                        <ParagraphBlock
                                                          settingsHandler={() => {
                                                            settingsClickHandler(
                                                              FormSubBlockTypes.PARAGRAPH,
                                                              pageIndex,
                                                              blockIndex
                                                            );
                                                          }}
                                                          settingsPanelActive={
                                                            currentDrawerPanel?.blockIndex ===
                                                              blockIndex &&
                                                            currentDrawerPanel?.pageIndex ===
                                                              pageIndex
                                                          }
                                                          dragHandleProps={
                                                            provided.dragHandleProps
                                                          }
                                                          key={
                                                            formBlock.subItemId
                                                          }
                                                          name={`pages[${pageIndex}].subItems[${blockIndex}]`}
                                                          pageIndex={pageIndex}
                                                          blockIndex={
                                                            blockIndex
                                                          }
                                                          deleteHandler={() => {
                                                            if (
                                                              currentDrawerPanel?.blockIndex ===
                                                                blockIndex &&
                                                              currentDrawerPanel?.pageIndex ===
                                                                pageIndex
                                                            ) {
                                                              resetSettingsDrawerPanel();
                                                            }

                                                            deleteItemHandler(
                                                              `pages[${pageIndex}].subItems`,
                                                              formValues.pages[
                                                                pageIndex
                                                              ].subItems,
                                                              touched.pages?.[
                                                                pageIndex
                                                              ].subItems,
                                                              blockIndex
                                                            );
                                                          }}
                                                        ></ParagraphBlock>
                                                      )}
                                                  </Box>
                                                );
                                              }}
                                            </Draggable>
                                          );
                                        }
                                      )}
                                      {provided.placeholder}
                                    </Box>
                                  );
                                }}
                              </StrictModeDroppable>
                            </PageBlock>
                          </Box>
                        );
                      }}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </Box>
            </Box>
          );
        }}
      </StrictModeDroppable>

      <Divider sx={{ my: 5, borderStyle: "dashed" }} />

      <StrictModeDroppable
        droppableId="action-blocks-droppable"
        type="droppableActionPath"
      >
        {(provided) => {
          return (
            <Box {...provided.droppableProps} ref={provided.innerRef}>
              {formValues.actionPaths.length === 0 && (
                <Box sx={{ my: 5 }}>
                  <EmptyFormPagePlaceHolder
                    title="Build different journeys for different users."
                    description="Drag and Drop Actions to build different action paths for users based on the answer they provide to your questions."
                    icon={<StartBuildingActionsIcon />}
                    sx={{
                      minHeight: "85vh",
                      maxHeight: "85vh",
                    }}
                  />
                </Box>
              )}
              <Box ref={actionPathsRef}>
                {formValues.actionPaths.map(
                  (actionPath: ActionPathFields, actionPathIndex: number) => {
                    return (
                      <Draggable
                        key={actionPath.actionPathId}
                        draggableId={actionPath.actionPathId}
                        index={actionPathIndex}
                      >
                        {(provided) => {
                          return (
                            <Box
                              {...provided.draggableProps}
                              // {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              sx={{ mb: 2 }}
                            >
                              <ActionPathBlock
                                formScreenContentStyle={{
                                  ...((!showSettingsDrawer ||
                                    !showMainDrawer) && {
                                    px:
                                      (showSettingsDrawer ? 0 : 4) +
                                      (showMainDrawer ? 0 : 4),
                                  }),
                                }}
                                settingsHandler={() => {
                                  settingsClickHandler(
                                    Actions.ACTION_PATH,
                                    actionPathIndex
                                  );
                                }}
                                name={`actionPaths[${actionPathIndex}]`}
                                actionPathIndex={actionPathIndex}
                                numOfActionPaths={formValues.actionPaths.length}
                                dragHandleProps={provided.dragHandleProps}
                                deleteHandler={() => {
                                  if (
                                    currentDrawerPanel?.pageIndex ===
                                    actionPathIndex
                                  ) {
                                    resetSettingsDrawerPanel();
                                  }

                                  deleteItemHandler(
                                    "actionPaths",
                                    formValues.actionPaths,
                                    touched.actionPaths,
                                    actionPathIndex
                                  );
                                }}
                              >
                                <StrictModeDroppable
                                  droppableId={actionPath.actionPathId}
                                  type={`droppableAction`}
                                >
                                  {(provided) => {
                                    return (
                                      <Box
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                      >
                                        {formValues.actionPaths[actionPathIndex]
                                          .actions.length === 0 && (
                                          <EmptyFormPagePlaceHolder
                                            title="Add an Action"
                                            description="You can add actions to this action path which you want to occur after the form is submitted.."
                                            icon={<EmptyActionPathIcon />}
                                            iconStyle={{
                                              ...(surveyTheme && {
                                                fill: surveyTheme.colorSettings
                                                  .questionTextColor,
                                              }),
                                            }}
                                            surveyThemeStyling={true}
                                          />
                                        )}
                                        {formValues.actionPaths[
                                          actionPathIndex
                                        ].actions.map(
                                          (
                                            action: ActionFields,
                                            actionIndex: number
                                          ) => {
                                            return (
                                              <Draggable
                                                key={action.actionId}
                                                draggableId={action.actionId}
                                                index={actionIndex}
                                              >
                                                {(provided) => {
                                                  return (
                                                    <Box
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                      ref={provided.innerRef}
                                                    >
                                                      {/* <Card>
                                                      <CardContent>
                                                        HELLO {actionIndex}
                                                      </CardContent>
                                                    </Card> */}

                                                      {action.actionType ===
                                                        Actions.REDIRECT && (
                                                        <RedirectActionBlock
                                                          key={action.actionId}
                                                          settingsHandler={() => {
                                                            settingsClickHandler(
                                                              Actions.REDIRECT,
                                                              actionPathIndex,
                                                              actionIndex
                                                            );
                                                          }}
                                                          settingsPanelActive={
                                                            currentDrawerPanel?.blockIndex ===
                                                              actionIndex &&
                                                            currentDrawerPanel?.pageIndex ===
                                                              actionPathIndex
                                                          }
                                                          deleteHandler={() => {
                                                            if (
                                                              currentDrawerPanel?.blockIndex ===
                                                                actionIndex &&
                                                              currentDrawerPanel?.pageIndex ===
                                                                actionPathIndex
                                                            ) {
                                                              resetSettingsDrawerPanel();
                                                            }

                                                            deleteItemHandler(
                                                              `actionPaths[${actionPathIndex}].actions`,
                                                              formValues
                                                                .actionPaths[
                                                                actionPathIndex
                                                              ].actions,
                                                              touched
                                                                .actionPaths?.[
                                                                actionPathIndex
                                                              ].actions,
                                                              actionIndex
                                                            );
                                                          }}
                                                          dragHandleProps={
                                                            provided.dragHandleProps
                                                          }
                                                          name={`actionPaths[${actionPathIndex}].actions[${actionIndex}]`}
                                                        ></RedirectActionBlock>
                                                      )}

                                                      {action.actionType ===
                                                        Actions.HEADING && (
                                                        <HeadingBlock
                                                          settingsHandler={() => {
                                                            settingsClickHandler(
                                                              Actions.HEADING,
                                                              actionPathIndex,
                                                              actionIndex
                                                            );
                                                          }}
                                                          settingsPanelActive={
                                                            currentDrawerPanel?.blockIndex ===
                                                              actionIndex &&
                                                            currentDrawerPanel?.pageIndex ===
                                                              actionPathIndex
                                                          }
                                                          dragHandleProps={
                                                            provided.dragHandleProps
                                                          }
                                                          key={action.actionId}
                                                          name={`actionPaths[${actionPathIndex}].actions[${actionIndex}]`}
                                                          pageIndex={
                                                            actionPathIndex
                                                          }
                                                          blockIndex={
                                                            actionIndex
                                                          }
                                                          deleteHandler={() => {
                                                            if (
                                                              currentDrawerPanel?.blockIndex ===
                                                                actionIndex &&
                                                              currentDrawerPanel?.pageIndex ===
                                                                actionPathIndex
                                                            ) {
                                                              resetSettingsDrawerPanel();
                                                            }

                                                            deleteItemHandler(
                                                              `actionPaths[${actionPathIndex}].actions`,
                                                              formValues
                                                                .actionPaths[
                                                                actionPathIndex
                                                              ].actions,
                                                              touched
                                                                .actionPaths?.[
                                                                actionPathIndex
                                                              ].actions,
                                                              actionIndex
                                                            );
                                                          }}
                                                        ></HeadingBlock>
                                                      )}

                                                      {action.actionType ===
                                                        Actions.PARAGRAPH && (
                                                        <ParagraphBlock
                                                          settingsHandler={() => {
                                                            settingsClickHandler(
                                                              Actions.PARAGRAPH,
                                                              actionPathIndex,
                                                              actionIndex
                                                            );
                                                          }}
                                                          settingsPanelActive={
                                                            currentDrawerPanel?.blockIndex ===
                                                              actionIndex &&
                                                            currentDrawerPanel?.pageIndex ===
                                                              actionPathIndex
                                                          }
                                                          dragHandleProps={
                                                            provided.dragHandleProps
                                                          }
                                                          key={action.actionId}
                                                          name={`actionPaths[${actionPathIndex}].actions[${actionIndex}]`}
                                                          pageIndex={
                                                            actionPathIndex
                                                          }
                                                          blockIndex={
                                                            actionIndex
                                                          }
                                                          deleteHandler={() => {
                                                            if (
                                                              currentDrawerPanel?.blockIndex ===
                                                                actionIndex &&
                                                              currentDrawerPanel?.pageIndex ===
                                                                actionPathIndex
                                                            ) {
                                                              resetSettingsDrawerPanel();
                                                            }

                                                            deleteItemHandler(
                                                              `actionPaths[${actionPathIndex}].actions`,
                                                              formValues
                                                                .actionPaths[
                                                                actionPathIndex
                                                              ].actions,
                                                              touched
                                                                .actionPaths?.[
                                                                actionPathIndex
                                                              ].actions,
                                                              actionIndex
                                                            );
                                                          }}
                                                        ></ParagraphBlock>
                                                      )}
                                                    </Box>
                                                  );
                                                }}
                                              </Draggable>
                                            );
                                          }
                                        )}
                                        {provided.placeholder}
                                      </Box>
                                    );
                                  }}
                                </StrictModeDroppable>
                              </ActionPathBlock>
                            </Box>
                          );
                        }}
                      </Draggable>
                    );
                  }
                )}
                {provided.placeholder}
              </Box>
            </Box>
          );
        }}
      </StrictModeDroppable>
    </Box>
  );
}
