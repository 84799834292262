import React, { useState } from "react";

import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Tooltip,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { ViewAgendaOutlined } from "@mui/icons-material";

// import { lighten } from "@mui/material/styles";
import { Link } from "react-router-dom";
import generateColorFromString from "../../utils/generateColorFromString";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useLogout } from "../../hooks/useLogout";
import { Logout, PersonOutlined } from "@mui/icons-material";

import LivelyFormsLogo from "../../assets/Lively_Forms_Logo_Blue.svg";

type HeaderProps = {
  children?: React.ReactNode;
};

export default function Header({ children }: HeaderProps) {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const logout = useLogout();

  const handleLogoutClick = async () => {
    await logout();
    navigate("/login");
  };

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenMyForms = () => {
    navigate("/");
    handleCloseUserMenu();
  };

  const handleMyAccountClick = () => {
    navigate("/account");
    handleCloseUserMenu();
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        elevation={0}
        variant="outlined"
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          background: (theme) => theme.palette.background.paper,
        }}
      >
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Link to="/">
                <img
                  src={LivelyFormsLogo}
                  alt="Lively Forms Logo"
                  height={30}
                  width={200}
                />
              </Link>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                }}
              >
                {children}
              </Box>

              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      sx={{
                        backgroundColor: generateColorFromString(
                          auth?.user?.displayName ?? "NO USER"
                        ),
                      }}
                    >
                      {(auth?.user?.displayName ?? "NO USER")[0].toUpperCase()}
                    </Avatar>
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={handleOpenMyForms}>
                    <ListItemIcon>
                      <ViewAgendaOutlined color="primary" fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>My Forms</ListItemText>
                  </MenuItem>

                  <MenuItem onClick={handleMyAccountClick}>
                    <ListItemIcon>
                      <PersonOutlined color="primary" fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>My Account</ListItemText>
                  </MenuItem>

                  <MenuItem onClick={handleLogoutClick}>
                    <ListItemIcon>
                      <Logout color="primary" fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Log Out</ListItemText>
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Box>
  );
}
