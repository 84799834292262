import { SurveyThemeFormFields } from "../../types/SurveyTheme";

export const generateAnswerTextStyle = (surveyTheme: SurveyThemeFormFields) => {
  return {
    ...(surveyTheme && {
      ...(surveyTheme.colorSettings.answerTextColor && {
        color: surveyTheme.colorSettings.answerTextColor,
      }),
      ...(surveyTheme.globalFont &&
        !surveyTheme.hasGranularFontSettings && {
          fontFamily: surveyTheme.globalFont,
        }),
      ...(surveyTheme.hasGranularFontSettings &&
        surveyTheme.granularFontSettings.answerText && {
          fontFamily: surveyTheme.granularFontSettings.answerText.fontFamily,
          fontSize: parseInt(
            // @ts-ignore
            surveyTheme.granularFontSettings.answerText.fontSize
          ),
          fontWeight: surveyTheme.granularFontSettings.answerText.fontWeight,
        }),
    }),
  };
};
