import React from "react";

import { Card, Box, Typography, IconButton } from "@mui/material";

import { Add } from "@mui/icons-material";

import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

type IDrawerListCardProps = {
  title: string;
  icon: React.ReactNode;
  addHandler: () => void;
  dragHandleProps?: DraggableProvidedDragHandleProps | null | undefined;
};

export default function BlockListCard({
  title,
  icon,
  addHandler,
  dragHandleProps,
}: IDrawerListCardProps) {
  return (
    <Card
      sx={{
        m: 0.5,
        py: 0.5,
      }}
      {...dragHandleProps}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Box sx={{ mr: 1, mt: 0.5 }}>{icon}</Box>
          <Typography variant="body2">{title}</Typography>
        </Box>
        <Box>
          <IconButton onClick={addHandler}>
            <Add fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    </Card>
  );
}
