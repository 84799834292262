import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useAuth } from "../hooks/useAuth";
import generateColorFromString from "../utils/generateColorFromString";
import EditDisplayNameForm from "../components/SettingsForms/EditDisplayNameForm";
import EditPasswordForm from "../components/SettingsForms/EditPasswordForm";
import DeleteAccountForm from "../components/SettingsForms/DeleteAccountForm";
import RequestToSetPassword from "../components/SettingsForms/RequestToSetPassword";

type AccountProps = {};

export default function Account({}: AccountProps) {
  const { auth } = useAuth();

  const [showEditDisplayNameDialog, setShowEditDisplayNameDialog] =
    useState(false);

  const [showEditPasswordDialog, setShowEditPasswordDialog] = useState(false);

  const [showSetPasswordDialog, setShowSetPasswordDialog] = useState(false);

  const [showDeleteAccountDialog, setShowDeleteAccountDialog] = useState(false);

  const handleEditDisplayNameeDialogOpen = () => {
    setShowEditDisplayNameDialog(true);
  };

  const handleEditDisplayNameDialogClose = () => {
    // if (!deleteLoading) {
    setShowEditDisplayNameDialog(false);
    // }
  };

  const handleEditPasswordDialogOpen = () => {
    setShowEditPasswordDialog(true);
  };

  const handleEditPasswordDialogClose = () => {
    // if (!deleteLoading) {
    setShowEditPasswordDialog(false);
    // }
  };
  const handleSetPasswordDialogOpen = () => {
    setShowSetPasswordDialog(true);
  };

  const handleSetPasswordDialogClose = () => {
    // if (!deleteLoading) {
    setShowSetPasswordDialog(false);
    // }
  };

  const handleDeleteAccountDialogOpen = () => {
    setShowDeleteAccountDialog(true);
  };

  const handleDeleteAccountDialogClose = () => {
    // if (!deleteLoading) {
    setShowDeleteAccountDialog(false);
    // }
  };

  const handleDeleteMyAccountClick = () => {
    handleDeleteAccountDialogOpen();
  };

  const handleEditPasswordClick = () => {
    handleEditPasswordDialogOpen();
  };

  const handleEditDisplayNameClick = () => {
    handleEditDisplayNameeDialogOpen();
  };

  const handleSetPasswordClick = () => {
    handleSetPasswordDialogOpen();
  };

  return (
    <Box
      sx={{
        pt: 4,
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" fontWeight="bold">
            My Account
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{
              width: 65,
              height: 65,
              backgroundColor: generateColorFromString(
                auth?.user?.displayName ?? "NO USER"
              ),
              mr: 3,
            }}
          >
            {(auth?.user?.displayName ?? "NO USER")[0].toUpperCase()}
          </Avatar>
          <Box>
            <Typography variant="h6">
              {auth?.user?.displayName ?? "NO DISPLAY NAME"}
            </Typography>
            <Typography color="text.secondary">
              @{auth?.user?.username ?? "NO USERNAME"}
            </Typography>
          </Box>
        </Box>

        <Button
          variant="outlined"
          sx={{
            mt: 2,
          }}
          onClick={handleEditDisplayNameClick}
        >
          Edit Display Name
        </Button>

        <Typography
          variant="h6"
          sx={{
            mt: 3,
          }}
        >
          Email
        </Typography>

        <Typography
          sx={{
            mt: 1,
          }}
        >
          {auth.user?.email ?? "No Email Found"}
        </Typography>

        <Typography
          variant="h6"
          sx={{
            mt: 3,
          }}
        >
          Password
        </Typography>

        {auth?.user?.hasPassword && (
          <Button
            variant="outlined"
            sx={{
              mt: 1,
            }}
            onClick={handleEditPasswordClick}
          >
            Edit Password
          </Button>
        )}

        {!auth?.user?.hasPassword && (
          <Button
            variant="outlined"
            sx={{
              mt: 1,
            }}
            onClick={handleSetPasswordClick}
          >
            Set Password
          </Button>
        )}

        <Divider sx={{ my: 3 }} />

        <Typography variant="h6">DANGER ZONE</Typography>

        <Typography
          variant="body1"
          sx={{
            mt: 1,
          }}
        >
          Please Note : Deleting your account will result in the deletion of all
          your Lively Forms, any responses collected and any Themes you created.
          This can not be undone.
        </Typography>

        {!auth?.user?.hasPassword && (
          <Alert
            sx={{
              my: 1,
            }}
            severity="info"
          >
            You signed up to Lively Forms using your social media profile. In
            order to delete your account we will need you to set a password for
            your account for security purposes.
          </Alert>
        )}
        <Button
          color="error"
          variant="contained"
          sx={{
            mt: 3,
          }}
          onClick={handleDeleteMyAccountClick}
          disabled={!auth?.user?.hasPassword}
        >
          Delete my Account
        </Button>
      </Container>

      <Dialog
        open={showEditDisplayNameDialog}
        // onClose={handleEditDisplayNameDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Edit Display Name</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              mt: 2,
            }}
          >
            <EditDisplayNameForm
              onSuccess={() => {
                handleEditDisplayNameDialogClose();
              }}
              initialDisplayName={auth?.user?.displayName}
              submitButton={(editDisplayNameLoading: boolean) => (
                <DialogActions
                  sx={{
                    mt: 3,
                    mr: -3,
                    mb: -2,
                  }}
                >
                  <Button
                    onClick={handleEditDisplayNameDialogClose}
                    disabled={editDisplayNameLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="error"
                    disabled={editDisplayNameLoading}
                  >
                    {editDisplayNameLoading ? (
                      <CircularProgress color="error" />
                    ) : (
                      "Update"
                    )}
                  </Button>
                </DialogActions>
              )}
            />
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showEditPasswordDialog}
        // onClose={handleEditPasswordDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Edit Password</DialogTitle>
        <DialogContent>
          <Box>
            <EditPasswordForm
              submitButton={(editPasswordLoading: boolean) => (
                <DialogActions
                  sx={{
                    mt: 3,
                    mr: -3,
                    mb: -2,
                  }}
                >
                  <Button
                    onClick={handleEditPasswordDialogClose}
                    disabled={editPasswordLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="error"
                    disabled={editPasswordLoading}
                  >
                    {editPasswordLoading ? (
                      <CircularProgress color="error" />
                    ) : (
                      "Update"
                    )}
                  </Button>
                </DialogActions>
              )}
            />
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showSetPasswordDialog}
        // onClose={handleEditPasswordDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Set Password</DialogTitle>
        <DialogContent>
          <Box>
            <RequestToSetPassword
              submitButton={(
                requestSetFirstPassword,
                setPasswordLoading: boolean,
                setPasswordSuccess: string
              ) => (
                <DialogActions
                  sx={{
                    mt: 3,
                    mr: -3,
                    mb: -2,
                  }}
                >
                  <Button
                    onClick={handleSetPasswordDialogClose}
                    disabled={setPasswordLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="error"
                    onClick={requestSetFirstPassword}
                    disabled={setPasswordLoading || Boolean(setPasswordSuccess)}
                  >
                    {setPasswordLoading ? (
                      <CircularProgress color="error" />
                    ) : (
                      "Request Link"
                    )}
                  </Button>
                </DialogActions>
              )}
            />
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showDeleteAccountDialog}
        // onClose={handleEditPasswordDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Delete My Account</DialogTitle>
        <DialogContent>
          <Box>
            <DeleteAccountForm
              submitButton={(deleteAccountLoading: boolean) => (
                <DialogActions
                  sx={{
                    mt: 3,
                    mr: -3,
                    mb: -2,
                  }}
                >
                  <Button
                    onClick={handleDeleteAccountDialogClose}
                    disabled={deleteAccountLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="error"
                    disabled={deleteAccountLoading}
                  >
                    {deleteAccountLoading ? (
                      <CircularProgress color="error" />
                    ) : (
                      "Confirm"
                    )}
                  </Button>
                </DialogActions>
              )}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
