import React from "react";

import { Box } from "@mui/material";
import { lighten } from "@mui/material/styles";

import ControlledCheckboxes from "../../../Inputs/ControlledCheckboxes";
import { CheckboxValue } from "../../../../types/Blocks";
import { LiveSurveyThemeDocument } from "../../../../types/SurveyTheme";

import { generateAnswerTextStyle } from "../../../../utils/styles/generateAnswerTextStyle";
import LiveSurveyQuestionText from "../../../SurveyComponents/LiveSurveyQuestionText";

type LiveCheckboxesBlockProps = {
  name: string;
  questionText: string;
  questionDescription?: string;
  isQuestionRequired: boolean;
  checkboxes: CheckboxValue[];
  surveyTheme: LiveSurveyThemeDocument | null;
};

export default function LiveCheckboxesBlock({
  name,
  questionText,
  questionDescription,
  isQuestionRequired,
  checkboxes,
  surveyTheme,
}: LiveCheckboxesBlockProps) {
  return (
    <Box sx={{ mb: 3 }}>
      <LiveSurveyQuestionText
        questionText={questionText}
        questionDescription={questionDescription}
        isQuestionRequired={isQuestionRequired}
        surveyTheme={surveyTheme}
        sx={{
          mb: 3,
        }}
      />

      <ControlledCheckboxes
        name={name}
        checkboxes={checkboxes}
        checkboxStyle={{
          ...(surveyTheme && {
            color: surveyTheme.colorSettings.answerTextColor,
            "&.Mui-checked": {
              color: lighten(surveyTheme.colorSettings.answerTextColor, 0.25),
            },
          }),
        }}
        checkboxLabelStyles={{
          ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
        }}
      />
    </Box>
  );
}
