import React from "react";

import { Route, Routes } from "react-router-dom";

// Theming
import { ThemeProvider } from "@emotion/react";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { theme } from "./theme";

// Components
import PrivateRoutes from "./components/Auth/PrivateRoutes";
import AutoLogin from "./components/Auth/AutoLogin";

// Pages
import Builder from "./pages/Builder";
import Login from "./pages/Login";
import AppLayout from "./components/Layout/AppLayout";
import Home from "./pages/Home";
import PublishedForm from "./pages/LiveForm";
import Responses from "./pages/Responses";
import VerifyEmail from "./pages/VerifyEmail";
import SignUp from "./pages/SignUp";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Account from "./pages/Account";
import SetFirstPassword from "./pages/SetFirstPassword";

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route element={<AutoLogin />}>
            <Route element={<PrivateRoutes />}>
              <Route element={<AppLayout />}>
                <Route path="/" element={<Home />} />
                <Route path="/account" element={<Account />} />
                <Route
                  path="/response/survey/:surveyId"
                  element={<Responses />}
                />
              </Route>
              {/* Builder will have it's own custom header */}
              <Route path="/builder/:identifier" element={<Builder />} />
            </Route>
          </Route>

          <Route
            path="/verify/:emailVerificationToken"
            element={<VerifyEmail />}
          />

          <Route
            path="/reset-password/:passwordResetToken"
            element={<ResetPassword />}
          />

          <Route
            path="/set-password/:setPasswordToken"
            element={<SetFirstPassword />}
          />

          <Route path="/forgot-password" element={<ForgotPassword />} />

          <Route path="/signup" element={<SignUp />} />

          <Route path="/login" element={<Login />} />

          {/* 
            It will first try all other routes which 
            if they match it will go there but if not 
            it will reac hhere. 
            // TODO - Need to make surre we do not generate identifiers which collide with our reserved rotues
           */}
          <Route path="/:identifier" element={<PublishedForm />} />
        </Routes>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
