import React, { useState } from "react";

import * as yup from "yup";
import { Form, Formik, FormikProps } from "formik";
import ControlledTextField from "../Inputs/ControlledTextField";
import {
  ACCOUNT_URL,
  CHANGE_PASSWORD_URL,
  DELETE_ACCOUNT_URL,
} from "../../data/constants";
import { useProtectedApi } from "../../hooks/useProtectedApi";
import { useAuth } from "../../hooks/useAuth";
import { Alert, Typography } from "@mui/material";

const FORM_VALIDATION = yup.object().shape({
  password: yup.string().required("Password is required."),
});

const INITIAL_FORM_STATE = {
  password: "",
};

type DeleteAccountFormFields = {
  password: string;
};

type DeleteAccountFormProps = {
  submitButton: (loading: boolean) => React.ReactNode;
};

export default function DeleteAccountForm({
  submitButton,
}: DeleteAccountFormProps) {
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const { auth, setAuth } = useAuth();

  const ProtectedApi = useProtectedApi();

  const handleSubmit = async (values: DeleteAccountFormFields) => {
    try {
      setError("");
      setIsLoading(true);

      await ProtectedApi.post(DELETE_ACCOUNT_URL, values);

      setAuth({
        user: "",
        accessToken: "",
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // @ts-ignore
      if (!error?.response) {
        setError("No response from server.");
        // @ts-ignore
      } else if (error?.response) {
        // @ts-ignore
        setError(error.response.data.error.message);
      } else {
        setError("Unable to login.");
      }
    }
  };

  return (
    <Formik
      initialValues={INITIAL_FORM_STATE as DeleteAccountFormFields}
      validationSchema={FORM_VALIDATION}
      onSubmit={async (values: any) => {
        await handleSubmit(values);
      }}
      validateOnChange
      validateOnBlur
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
      }: FormikProps<DeleteAccountFormFields>) => {
        return (
          <Form>
            <Alert sx={{ mb: 2 }} severity="warning">
              WARNING : All of your account data will be deleted immediately.
              You will no longer be able to access your account or it's data if
              you proceed.
            </Alert>

            <Typography
              sx={{
                mb: 2,
              }}
            >
              Please provide your password we can confirm it's you.
            </Typography>

            {error && (
              <Alert sx={{ my: 3 }} severity="error">
                {error}
              </Alert>
            )}

            <ControlledTextField
              name="password"
              label="Password"
              required
              type="password"
            ></ControlledTextField>

            {submitButton(loading)}
          </Form>
        );
      }}
    </Formik>
  );
}
