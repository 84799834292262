import React from "react";

import { Box, BoxProps } from "@mui/material";
import ControlledTextField from "../../../Inputs/ControlledTextField";
import { LiveSurveyThemeDocument } from "../../../../types/SurveyTheme";

import { generateAnswerTextStyle } from "../../../../utils/styles/generateAnswerTextStyle";
import LiveSurveyQuestionText from "../../../SurveyComponents/LiveSurveyQuestionText";
import { generateTextFieldStyle } from "../../../../utils/styles/generateTextFieldStyle";

type LiveEmailBlockProps = BoxProps & {
  name: string;
  questionText: string;
  questionDescription?: string;
  isQuestionRequired: boolean;
  surveyTheme: LiveSurveyThemeDocument | null;
};

export default function LiveEmailBlock({
  name,
  isQuestionRequired,
  questionText,
  questionDescription,
  surveyTheme,
  sx,
}: LiveEmailBlockProps) {
  return (
    <Box sx={{ mb: 3, ...sx }}>
      <LiveSurveyQuestionText
        questionText={questionText}
        questionDescription={questionDescription}
        isQuestionRequired={isQuestionRequired}
        surveyTheme={surveyTheme}
        sx={{
          mb: 3,
        }}
      />

      <ControlledTextField
        type="email"
        name={name}
        required={isQuestionRequired}
        placeholder="email@example.com"
        variant={(surveyTheme && surveyTheme.fieldVariant) ?? "outlined"}
        size="small"
        fullWidth
        sx={{
          ...generateTextFieldStyle(surveyTheme),
        }}
        inputProps={{
          style: {
            ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
          },
        }}
      ></ControlledTextField>
    </Box>
  );
}
