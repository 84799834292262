import React, { useState } from "react";

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";

import TabPanel from "../Helpers/TabPanel";
import { hexToRGBA } from "../../utils/hexToRGBA";
import {
  CloudUploadOutlined,
  DeleteOutlineOutlined,
  InsertPhotoOutlined,
} from "@mui/icons-material";
import { useProtectedApi } from "../../hooks/useProtectedApi";
import { IMAGE_URL } from "../../data/constants";

import ImagesGalleryList from "./ImagesGalleryList";

export enum SelectImageTabs {
  UPLOAD = "Upload",
  UNSPLASH = "Unsplash",
  MY_IMAGES = "My Images",
}

type SelectImageDialogProps = {
  show: boolean;
  closeHandler: () => void;
  setImageHandler: (imageUrl: string) => void;
};

export default function SelectImageDialog({
  show,
  closeHandler,
  setImageHandler,
}: SelectImageDialogProps) {
  const theme = useTheme();

  // const [field, meta, helpers] = useField<ImageBackgroundSettings>(name);

  const [selectImageTab, setSelectImageTab] = useState(SelectImageTabs.UPLOAD);

  const [uploadedImageFile, setUploadedImageFile] = useState<File>();
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");

  const [uploadImageError, setUploadImageError] = useState("");
  const [uploadImageLoading, setUploadImageLoading] = useState(false);
  const [uploadImageSuccess, setUploadImageSuccess] = useState(false);

  const ProtectedApi = useProtectedApi();

  const handleSelectImageTabChange = (
    event: React.SyntheticEvent,
    newValue: SelectImageTabs
  ) => {
    setSelectImageTab(newValue);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];
    if (file) {
      setUploadedImageUrl(URL.createObjectURL(file));
      setUploadedImageFile(file);
    }
  };

  const handleDeleteUploadedImage = () => {
    setUploadedImageUrl("");
    setUploadedImageFile(undefined);
    setUploadImageError("");
  };

  // const setBackgroundImageUrl = (newImageUrl: string) => {
  //   helpers.setValue({
  //     imageUrl: newImageUrl,
  //   });
  // };

  const handleUploadImage = async () => {
    try {
      setUploadImageError("");
      setUploadImageLoading(true);
      setUploadImageSuccess(false);

      if (!uploadedImageFile) {
        throw new Error("You must first select a file");
      }

      const formData = new FormData();
      formData.append("upload", uploadedImageFile);

      const response = await ProtectedApi.post(IMAGE_URL, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setImageHandler(response.data.success.data.imageUrl);

      setUploadImageSuccess(true);
      triggerHideImageUploadSuccess();
      setUploadImageLoading(false);
      // Delete local file in memory
      handleDeleteUploadedImage();
    } catch (error) {
      setUploadImageSuccess(false);
      setUploadImageLoading(false);
      // @ts-ignore
      if (!error?.response) {
        setUploadImageError("No response from server.");
        // @ts-ignore
      } else if (error?.response) {
        // @ts-ignore
        // TODO - Need to handle in cases message is undefined as we can get other type of errors
        setUploadImageError(error.response.data.error.message);
      } else {
        setUploadImageError("Unable to login.");
      }
    }
  };

  const triggerHideImageUploadSuccess = () => {
    setTimeout(() => {
      setUploadImageSuccess(false);
    }, 6000);
  };

  return (
    <Dialog open={show} onClose={closeHandler} maxWidth="sm" fullWidth>
      <DialogTitle id="alert-dialog-title">Select an Image</DialogTitle>
      <DialogContent>
        <Tabs
          value={selectImageTab}
          onChange={handleSelectImageTabChange}
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab
            sx={{ p: 1 }}
            value={SelectImageTabs.UPLOAD}
            label={SelectImageTabs.UPLOAD}
          />
          <Tab
            sx={{ p: 1 }}
            value={SelectImageTabs.MY_IMAGES}
            label={SelectImageTabs.MY_IMAGES}
          />
          <Tab
            sx={{ p: 1 }}
            value={SelectImageTabs.UNSPLASH}
            label={SelectImageTabs.UNSPLASH}
          />
        </Tabs>

        <TabPanel value={selectImageTab} index={SelectImageTabs.UPLOAD}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: 300,
            }}
          >
            <Box
              sx={{
                borderWidth: "1px",
                borderStyle: "dashed",
                borderColor: hexToRGBA(theme.palette.primary.light, 0.25),
                borderRadius: "6px",
                backgroundColor: hexToRGBA(theme.palette.primary.light, 0.075),
                p: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
                height: "100%",
              }}
            >
              {uploadedImageUrl && (
                <img
                  style={{
                    display: "block",
                    margin: "0 auto",
                    // width: "100%",
                    // height: "100%",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    //   objectFit: "contain",
                    //   overflow: "hidden",
                  }}
                  src={uploadedImageUrl}
                  alt="Uploaded Background"
                />
              )}
              {!uploadedImageUrl && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <CloudUploadOutlined
                    color="primary"
                    sx={{ mb: 1 }}
                    fontSize="large"
                  />
                  <Typography>Upload an Image from your Device</Typography>
                  <Button sx={{ mt: 3 }} variant="contained" component="label">
                    Select Image
                    <input type="file" hidden onChange={handleImageChange} />
                  </Button>
                </Box>
              )}
            </Box>

            {uploadImageError && (
              <Alert sx={{ mt: 0.5 }} severity="error">
                {uploadImageError}
              </Alert>
            )}

            {uploadImageSuccess && (
              <Alert sx={{ mt: 0.5 }} severity="success">
                Image uploaded successfully!
              </Alert>
            )}

            {uploadedImageUrl && (
              <Box sx={{ display: "flex", mt: 1 }}>
                <Button
                  fullWidth
                  startIcon={<InsertPhotoOutlined />}
                  onClick={handleUploadImage}
                  disabled={uploadImageLoading}
                >
                  {uploadImageLoading ? (
                    <CircularProgress color="primary" />
                  ) : (
                    "Upload"
                  )}
                </Button>
                <Button
                  fullWidth
                  component="label"
                  color="error"
                  startIcon={<DeleteOutlineOutlined />}
                  onClick={handleDeleteUploadedImage}
                  disabled={uploadImageLoading}
                >
                  Delete
                </Button>
              </Box>
            )}
          </Box>
        </TabPanel>
        <TabPanel value={selectImageTab} index={SelectImageTabs.MY_IMAGES}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: 300,
            }}
          >
            <ImagesGalleryList
              selectImageHandler={(imageUrl: string) => {
                setImageHandler(imageUrl);
                closeHandler();
              }}
            />
          </Box>
        </TabPanel>
        <TabPanel value={selectImageTab} index={SelectImageTabs.UNSPLASH}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: 300,
            }}
          ></Box>
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
}
