import React from "react";

import { Typography, TypographyProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { LiveSurveyThemeDocument } from "../../../../types/SurveyTheme";

type LiveHeadingBlockProps = TypographyProps & {
  heading: string;
  surveyTheme: LiveSurveyThemeDocument | null;
};

export default function LiveHeadingBlock({
  heading,
  surveyTheme,
  sx,
}: LiveHeadingBlockProps) {
  const theme = useTheme();

  return (
    <Typography
      sx={{
        mb: 3,
        ...theme.typography.h4,
        ...(surveyTheme && {
          color: surveyTheme.colorSettings.questionTextColor,

          ...(surveyTheme.contentTextAlignment && {
            textAlign: surveyTheme.contentTextAlignment,
          }),

          ...(surveyTheme.globalFont &&
            !surveyTheme.hasGranularFontSettings && {
              fontFamily: surveyTheme.globalFont,
            }),

          ...(surveyTheme.hasGranularFontSettings &&
            surveyTheme.granularFontSettings.questionText && {
              fontFamily:
                surveyTheme.granularFontSettings.questionText.fontFamily,
            }),
        }),
        ...sx,
      }}
    >
      {heading ?? "No Heading Provided"}
    </Typography>
  );
}
