import React from "react";

import {
  Box,
  IconButton,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import { useTheme, lighten } from "@mui/material/styles";

import { v4 as uuid } from "uuid";

import { Add, Close } from "@mui/icons-material";

import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

import { useField } from "formik";

import { CheckboxBlockFields, CheckboxValue } from "../../../../types/Blocks";
import ControlledTextField from "../../../Inputs/ControlledTextField";
import { useSurveyTheme } from "../../../../providers/surveyTheme";
import { generateAnswerTextStyle } from "../../../../utils/styles/generateAnswerTextStyle";
import SurveyQuestionBlockHeader from "../../../SurveyComponents/SurveyQuestionBlockHeader";
import SurveyBlockLayout from "../../../SurveyComponents/SurveyBlockLayout";

interface ICheckboxesBlockProps {
  // formik: FormikContextType<IFormCanvasBlocksFields>;
  name: string;
  // index: number;
  pageIndex: number;
  blockIndex: number;
  settingsHandler: () => void;
  deleteHandler: () => void;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  settingsPanelActive: boolean;
}

export interface ICheckboxesBlockFormFields {
  question: string;
  isQuestionRequired: boolean;
  requiredText: string;
}

export default function CheckboxesBlock({
  // formik,
  name,
  // index,
  pageIndex,
  blockIndex,
  deleteHandler,
  settingsHandler,
  dragHandleProps,
  settingsPanelActive,
}: ICheckboxesBlockProps) {
  const [field, meta, helpers] = useField<CheckboxBlockFields>(name);
  const theme = useTheme();

  const surveyTheme = useSurveyTheme();

  return (
    <SurveyBlockLayout
      name={name}
      settingsHandler={settingsHandler}
      deleteHandler={deleteHandler}
      settingsPanelActive={settingsPanelActive}
      dragHandleProps={dragHandleProps}
    >
      <SurveyQuestionBlockHeader name={name} />
      <Box>
        <FormControl component="fieldset" variant="standard" fullWidth>
          <FormGroup>
            {field.value.checkboxes.map(
              (checkbox: CheckboxValue, checkboxIndex) => {
                return (
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        width: "100%",
                      },
                    }}
                    key={checkbox.checkboxId}
                    control={
                      <Checkbox
                        readOnly
                        checked={false}
                        name={checkbox.value}
                        sx={{
                          ...(surveyTheme && {
                            color: surveyTheme.colorSettings.answerTextColor,
                            "&.Mui-checked": {
                              color: lighten(
                                surveyTheme.colorSettings.answerTextColor,
                                0.25
                              ),
                            },
                          }),
                        }}
                      />
                    }
                    label={
                      <ControlledTextField
                        name={`${name}.checkboxes[${checkboxIndex}].value`}
                        id={`${name}.checkboxes[${checkboxIndex}].value`}
                        variant="standard"
                        size="small"
                        multiline
                        required
                        placeholder="Type checkbox value here."
                        autoComplete="off"
                        InputProps={{
                          disableUnderline: true,
                          endAdornment: (
                            <Box sx={{ mt: 1 }}>
                              {field.value.checkboxes.length > 1 && (
                                <IconButton
                                  onClick={() => {
                                    // @ts-ignore
                                    helpers.setTouched({
                                      // @ts-ignore
                                      ...(meta.touched as FormikTouched<CheckboxBlockFields>),
                                      // @ts-ignore
                                      checkboxes: meta.touched?.checkboxes
                                        // @ts-ignore
                                        ?.filter(
                                          (
                                            _: string,
                                            currentCheckboxIndex: number
                                          ) => {
                                            return (
                                              checkboxIndex !==
                                              currentCheckboxIndex
                                            );
                                          }
                                        ),
                                    });

                                    helpers.setValue({
                                      ...field.value,
                                      checkboxes: field.value.checkboxes.filter(
                                        (_, currentCheckboxIndex) => {
                                          return (
                                            checkboxIndex !==
                                            currentCheckboxIndex
                                          );
                                        }
                                      ),
                                    });
                                  }}
                                >
                                  <Close color="error" />
                                </IconButton>
                              )}
                            </Box>
                          ),
                        }}
                        inputProps={{
                          style: {
                            ...theme.typography.h6,
                            ...(surveyTheme &&
                              generateAnswerTextStyle(surveyTheme)),
                          },
                        }}
                      />
                    }
                  />
                );
              }
            )}
          </FormGroup>
        </FormControl>
        <Button
          sx={{
            my: 2,
          }}
          variant="outlined"
          startIcon={<Add />}
          onClick={() => {
            helpers.setValue({
              ...field.value,
              checkboxes: [
                ...(field.value.checkboxes ? [...field.value.checkboxes] : []),
                {
                  checkboxId: uuid(),
                  value: "",
                },
              ],
            });
          }}
        >
          Add New Choice
        </Button>
      </Box>
    </SurveyBlockLayout>
  );
}
