import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { LiveSurveyThemeDocument } from "../types/SurveyTheme";

/*
  We will use this context for storing a saved version of the theme.
  While the user is editting the theme the changes will be saved to
  the live version which components can get the theme from. If they 
  decide not to save their changes then we will revert live theme to 
  the saved theme
*/
export const SavedSurveyThemeContext =
  createContext<LiveSurveyThemeDocument | null>(null);
export const SavedSurveyThemeUpdateContext = createContext<Dispatch<
  SetStateAction<LiveSurveyThemeDocument | null>
> | null>(null);

export const SurveyThemeContext = createContext<LiveSurveyThemeDocument | null>(
  null
);
export const SurveyThemeUpdateContext = createContext<Dispatch<
  SetStateAction<LiveSurveyThemeDocument | null>
> | null>(null);

interface SurveyThemeProviderProps {
  children: React.ReactNode;
}

export function useSavedSurveyTheme() {
  return useContext(SavedSurveyThemeContext);
}

export function useUpdateSavedSurveyTheme() {
  return useContext(SavedSurveyThemeUpdateContext);
}

export function useSurveyTheme() {
  return useContext(SurveyThemeContext);
}

export function useUpdateSurveyTheme() {
  return useContext(SurveyThemeUpdateContext);
}

export const SurveyThemeProvider: React.FC<SurveyThemeProviderProps> = ({
  children,
}) => {
  const [savedSurveyTheme, setSavedSurveyTheme] =
    useState<LiveSurveyThemeDocument | null>(null);

  const [currentSurveyTheme, setCurrentSurveyTheme] =
    useState<LiveSurveyThemeDocument | null>(null);

  return (
    <SavedSurveyThemeContext.Provider value={savedSurveyTheme}>
      <SavedSurveyThemeUpdateContext.Provider value={setSavedSurveyTheme}>
        <SurveyThemeContext.Provider value={currentSurveyTheme}>
          <SurveyThemeUpdateContext.Provider value={setCurrentSurveyTheme}>
            {children}
          </SurveyThemeUpdateContext.Provider>
        </SurveyThemeContext.Provider>
      </SavedSurveyThemeUpdateContext.Provider>
    </SavedSurveyThemeContext.Provider>
  );
};
