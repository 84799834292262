import React, { useState, useEffect } from "react";

import { useField } from "formik";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { Box, IconButton } from "@mui/material";

import { DeleteOutlineOutlined } from "@mui/icons-material";

import { buildDateFormatString } from "../../../utils/buildDateFormatString";

import ControlledSelect from "../../Inputs/ControlledSelect";
import ControlledTextField from "../../Inputs/ControlledTextField";

import {
  AllowedDateFormats,
  AllowedDateSeperators,
  CheckboxBlockFields,
  CheckboxValue,
  DropdownBlockFields,
  ImageChoiceBlockFields,
  QuestionFields,
  QuestionTypes,
  RadioGroupBlockFields,
  ScaleBlockFields,
  SecondaryVisibilityCondition,
  StarRatingBlockBlockFields,
  VisibilityConditionAnswerFields,
  VisibilityLogicalOperators,
} from "../../../types/Blocks";
import {
  BASE_VISIBILITY_LOGICAL_OPERATORS,
  VISIBILITY_LOGICAL_OPERATORS_PER_BLOCK_TYPE,
  VISIBILITY_CONDITION_ANSWER_FIELD_PER_BLOCK_TYPE,
  SENTIMENT_OPTIONS,
  VISIBILITY_UNION_OPTIONS,
} from "../../../data/BlockFields";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

export type QuestionOptions = {
  [key: string]: string;
};

type DropDownOptions = {
  [key: string]: string;
};

type NewVisibilityConditionRowProps = {
  name: string;
  deleteHandler?: () => void;
  questions: QuestionFields[];
  isSecondaryCondition?: boolean;
};

export default function NewVisibilityConditionRow({
  name,
  deleteHandler,
  questions,
  isSecondaryCondition = false,
}: NewVisibilityConditionRowProps) {
  const [selectedQuestion, setSelectedQuestion] = useState<QuestionFields>();
  const [selectedQuestionAnswers, setSelectedQuestionAnswers] =
    useState<DropDownOptions>();

  const [selectedLogicalOperator, setSelectedLogicalOperator] =
    useState<VisibilityLogicalOperators>();
  const [selectedLogicalOperatorAnswers, setSelectedLogicalOperatorAnswers] =
    useState<string[]>([]);

  const [field, meta, helpers] = useField<SecondaryVisibilityCondition>(name);

  const questionOptions = questions.reduce(
    (previousValue: QuestionOptions, currentValue: QuestionFields) => {
      previousValue[currentValue.subItemId] = currentValue.question;
      return previousValue;
    },
    {}
  );

  useEffect(() => {
    if (field.value.questionId) {
      const foundQuestion = questions.find((question) => {
        return question.subItemId === field.value.questionId;
      });

      if (foundQuestion) {
        if (
          // foundQuestion.questionType !== Blocks.PAGE &&
          VISIBILITY_CONDITION_ANSWER_FIELD_PER_BLOCK_TYPE[
            foundQuestion?.questionType
          ] === VisibilityConditionAnswerFields.DROPDOWN
        ) {
          if (foundQuestion.questionType === QuestionTypes.CHECKBOXES) {
            setSelectedQuestionAnswers(() => {
              const checkboxAnswers = (
                foundQuestion as CheckboxBlockFields
              ).checkboxes.reduce(
                (
                  previousValue: DropDownOptions,
                  currentValue: CheckboxValue
                ) => {
                  previousValue[currentValue.checkboxId] = currentValue.value;
                  return previousValue;
                },
                {}
              );

              return checkboxAnswers;
            });
          } else if (foundQuestion.questionType === QuestionTypes.STAR_RATING) {
            setSelectedQuestionAnswers(() => {
              let answers: DropDownOptions = {};

              for (
                let i = 1;
                i <
                // @ts-ignore
                parseInt((foundQuestion as StarRatingBlockBlockFields).scale) +
                  1;
                i++
              ) {
                answers[String(i)] = String(i);
              }

              return answers;
            });
          } else if (foundQuestion.questionType === QuestionTypes.YESNO) {
            setSelectedQuestionAnswers(() => {
              let answers: DropDownOptions = {
                yes: "Yes",
                no: "No",
              };

              return answers;
            });
          } else if (foundQuestion.questionType === QuestionTypes.SCALE) {
            setSelectedQuestionAnswers(() => {
              let answers: DropDownOptions = {};

              for (
                let i =
                  // @ts-ignore
                  parseInt((foundQuestion as ScaleBlockFields).fromNumber);
                i <
                // @ts-ignore
                parseInt((foundQuestion as ScaleBlockFields).toNumber) + 1;
                i++
              ) {
                answers[String(i)] = String(i);
              }

              return answers;
            });
          } else if (
            foundQuestion.questionType === QuestionTypes.IMAGE_CHOICE
          ) {
            setSelectedQuestionAnswers(() => {
              let answers: DropDownOptions = {};

              for (
                let i = 0;
                i <
                parseInt(
                  // @ts-ignore
                  (foundQuestion as ImageChoiceBlockFields).imageChoiceValues
                    .length
                );
                i++
              ) {
                answers[
                  String(
                    (foundQuestion as ImageChoiceBlockFields).imageChoiceValues[
                      i
                    ].imageChoiceId
                  )
                ] = String(
                  `${i + 1}  ${
                    (foundQuestion as ImageChoiceBlockFields).imageChoiceValues[
                      i
                    ].imageCaption ?? ""
                  }`
                );
              }

              return answers;
            });
          } else if (foundQuestion.questionType === QuestionTypes.DROPDOWN) {
            setSelectedQuestionAnswers(() => {
              let answers: DropDownOptions = {};

              for (
                let i = 0;
                i <
                parseInt(
                  // @ts-ignore
                  (foundQuestion as DropdownBlockFields).dropdownValues.length
                );
                i++
              ) {
                answers[
                  String(
                    (foundQuestion as DropdownBlockFields).dropdownValues[i]
                      .value
                  )
                ] = String(
                  `${
                    (foundQuestion as DropdownBlockFields).dropdownValues[i]
                      .value ?? ""
                  }`
                );
              }

              return answers;
            });
          } else if (foundQuestion.questionType === QuestionTypes.RADIO_GROUP) {
            setSelectedQuestionAnswers(() => {
              let answers: DropDownOptions = {};

              for (
                let i = 0;
                i <
                parseInt(
                  // @ts-ignore
                  (foundQuestion as RadioGroupBlockFields).radioGroupValues
                    .length
                );
                i++
              ) {
                answers[
                  String(
                    (foundQuestion as RadioGroupBlockFields).radioGroupValues[i]
                      .value
                  )
                ] = String(
                  `${
                    (foundQuestion as RadioGroupBlockFields).radioGroupValues[i]
                      .value ?? ""
                  }`
                );
              }

              return answers;
            });
          }
        }
        setSelectedQuestion(foundQuestion);
      }
    }
  }, [field.value, questions]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mt: 2,
      }}
    >
      {isSecondaryCondition && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <ControlledSelect
            name={`${name}.logicalUnion`}
            id={`${name}.logicalUnion`}
            options={VISIBILITY_UNION_OPTIONS}
            required
            fullWidth={false}
            sx={{ width: 80 }}
          />
          <IconButton onClick={deleteHandler}>
            <DeleteOutlineOutlined fontSize="small" color="error" />
          </IconButton>
        </Box>
      )}
      <ControlledSelect
        sx={{ mb: 2 }}
        name={`${name}.questionId`}
        id={`${name}.questionId`}
        label="Question"
        options={questionOptions}
        required
        disabled={Object.keys(questionOptions).length <= 0}
      />
      <ControlledSelect
        sx={{ mb: 2 }}
        name={`${name}.logicalOperator`}
        id={`${name}].logicalOperator`}
        options={
          // selectedQuestion && selectedQuestion.questionType !== Blocks.PAGE
          selectedQuestion
            ? VISIBILITY_LOGICAL_OPERATORS_PER_BLOCK_TYPE[
                selectedQuestion?.questionType
              ]
            : BASE_VISIBILITY_LOGICAL_OPERATORS
        }
        required
        disabled={!Boolean(field.value.questionId)}
      />
      {selectedQuestion &&
        // selectedQuestion.question !== Blocks.PAGE &&
        VISIBILITY_CONDITION_ANSWER_FIELD_PER_BLOCK_TYPE[
          selectedQuestion?.questionType
        ] === VisibilityConditionAnswerFields.TEXT_FIELD &&
        field.value.logicalOperator &&
        field.value.logicalOperator !==
          VisibilityLogicalOperators.HAS_SENTIMENT &&
        field.value.logicalOperator !==
          VisibilityLogicalOperators.HAS_NOT_SENTIMENT && (
          <ControlledTextField
            sx={{ mb: 2 }}
            name={`${name}.answerValue`}
            id={`${name}.answerValue`}
            label="Answer"
            required
          />
        )}

      {selectedQuestion &&
        // selectedQuestion.question !== Blocks.PAGE &&
        VISIBILITY_CONDITION_ANSWER_FIELD_PER_BLOCK_TYPE[
          selectedQuestion?.questionType
        ] === VisibilityConditionAnswerFields.NUMBER &&
        field.value.logicalOperator && (
          <ControlledTextField
            sx={{ mb: 2 }}
            name={`${name}.answerValue`}
            id={`${name}.answerValue`}
            label="Answer"
            required
            type="number"
          />
        )}

      {selectedQuestion &&
        // selectedQuestion.type !== Blocks.PAGE &&
        VISIBILITY_CONDITION_ANSWER_FIELD_PER_BLOCK_TYPE[
          selectedQuestion?.questionType
        ] === VisibilityConditionAnswerFields.DROPDOWN &&
        field.value.logicalOperator &&
        field.value.logicalOperator !==
          VisibilityLogicalOperators.HAS_SENTIMENT &&
        field.value.logicalOperator !==
          VisibilityLogicalOperators.HAS_NOT_SENTIMENT && (
          <ControlledSelect
            sx={{ mb: 2 }}
            name={`${name}.answerValue`}
            id={`${name}.answerValue`}
            label="Answer"
            options={selectedQuestionAnswers ?? {}}
            required
            disabled={
              selectedQuestionAnswers &&
              Object.keys(selectedQuestionAnswers).length <= 0
            }
          />
        )}

      {selectedQuestion &&
        // selectedQuestion.type !== Blocks.PAGE &&
        VISIBILITY_CONDITION_ANSWER_FIELD_PER_BLOCK_TYPE[
          selectedQuestion?.questionType
        ] === VisibilityConditionAnswerFields.DATE &&
        field.value.logicalOperator &&
        field.value.logicalOperator !==
          VisibilityLogicalOperators.HAS_SENTIMENT &&
        field.value.logicalOperator !==
          VisibilityLogicalOperators.HAS_NOT_SENTIMENT && (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={field.value.answerValue}
              onChange={(value) => {
                if (value) {
                  helpers.setValue({
                    ...field.value,
                    answerValue: value,
                  });
                }
              }}
              sx={{}}
              slotProps={{
                textField: {
                  fullWidth: true,
                  size: "small",
                  name: name,
                  id: name,
                },
              }}
              format={buildDateFormatString(
                AllowedDateFormats.DDMMYYYY,
                AllowedDateSeperators.SLASH
              )}
            />
          </LocalizationProvider>
        )}

      {selectedQuestion &&
        // selectedQuestion.type !== Blocks.PAGE &&
        field.value.logicalOperator &&
        (field.value.logicalOperator ===
          VisibilityLogicalOperators.HAS_SENTIMENT ||
          field.value.logicalOperator ===
            VisibilityLogicalOperators.HAS_NOT_SENTIMENT) && (
          <ControlledSelect
            sx={{ mb: 2 }}
            name={`${name}.answerValue`}
            id={`${name}.answerValue`}
            label="Answer"
            options={SENTIMENT_OPTIONS}
            required
            disabled={
              selectedQuestionAnswers &&
              Object.keys(selectedQuestionAnswers).length <= 0
            }
          />
        )}

      {(!selectedQuestion || !field.value.logicalOperator) && (
        <ControlledTextField
          sx={{ mb: 2 }}
          name={`${name}.answerValue`}
          id={`${name}.answerValue`}
          label="Answer"
          required
          disabled
        />
      )}
    </Box>
  );
}
