import React, { useState } from "react";

import { Box } from "@mui/material";

import {
  Actions,
  LiveActionDocument,
  LiveActionPathDocument,
  LiveHeadingActionDocument,
  LiveParagraphActionDocument,
  LiveRedirectActionDocument,
} from "../types/Actions";
import FormScreenLAyout from "./Layout/FormScreenLayout";
import { useSurveyTheme } from "../providers/surveyTheme";
import LiveHeadingBlock from "./Blocks/FormBlocks/HeadingBlock/LiveHeadingBlock";
import LiveParagraphBlock from "./Blocks/FormBlocks/ParagraphBlock/LiveParagraphBlock";
import SurveyButton from "./SurveyComponents/SurveyButton";
import LiveRedirectActionBlock from "./Blocks/ActionBlocks/RedirectActionBlock/LiveRedirectActionBlock";

type LiveFormPageProps = {
  actionPaths: LiveActionPathDocument[];
};

export type FormValues = {
  [key: string]: any;
};

export default function LiveActionPaths({ actionPaths }: LiveFormPageProps) {
  const surveyTheme = useSurveyTheme();
  const [actionPathsList, setActionPathsList] = useState(actionPaths);

  const [currentActionPathIndex, setCurrentActionPathIndex] = useState(0);

  const incrementACtionPathIndex = () => {
    setCurrentActionPathIndex((prevActionPathIndex) => {
      return prevActionPathIndex + 1;
    });
  };

  const decrementACtionPathIndex = () => {
    setCurrentActionPathIndex((prevActionPathIndex) => {
      return prevActionPathIndex - 1;
    });
  };

  return (
    <FormScreenLAyout
      sx={{
        height: "100vh",
      }}
      surveyTheme={surveyTheme}
    >
      {actionPathsList &&
        actionPathsList[currentActionPathIndex].actions.map(
          (action: LiveActionDocument) => {
            if (action.actionType === Actions.REDIRECT) {
              return (
                <LiveRedirectActionBlock
                  key={action._id}
                  redirectionUrl={
                    (action as LiveRedirectActionDocument).redirectionUrl
                  }
                  surveyTheme={surveyTheme}
                />
              );
            } else if (action.actionType === Actions.HEADING) {
              return (
                <LiveHeadingBlock
                  key={action._id}
                  heading={(action as LiveHeadingActionDocument).heading}
                  surveyTheme={surveyTheme}
                />
              );
            } else if (action.actionType === Actions.PARAGRAPH) {
              return (
                <LiveParagraphBlock
                  key={action._id}
                  paragraphText={
                    (action as LiveParagraphActionDocument).paragraph
                  }
                  surveyTheme={surveyTheme}
                />
              );
            }
          }
        )}

      <Box
        sx={{
          display: "flex",
          ...(surveyTheme && {
            ...(surveyTheme.contentTextAlignment && {
              justifyContent: surveyTheme.contentTextAlignment,
            }),
          }),
        }}
      >
        {currentActionPathIndex === 0 &&
          currentActionPathIndex !== actionPathsList.length - 1 && (
            <SurveyButton onClick={incrementACtionPathIndex}>Next</SurveyButton>
          )}

        {/* Back button on Last Action Path  */}
        {currentActionPathIndex !== 0 &&
          currentActionPathIndex === actionPathsList.length - 1 && (
            <SurveyButton onClick={decrementACtionPathIndex}>Back</SurveyButton>
          )}

        {/*  Previouse and Next Buttons */}

        {currentActionPathIndex !== 0 &&
          currentActionPathIndex < actionPathsList.length - 1 && (
            <SurveyButton sx={{ mx: 2 }} onClick={decrementACtionPathIndex}>
              Back
            </SurveyButton>
          )}

        {currentActionPathIndex !== 0 &&
          currentActionPathIndex < actionPathsList.length - 1 && (
            <SurveyButton sx={{ mx: 2 }} onClick={incrementACtionPathIndex}>
              Next
            </SurveyButton>
          )}
      </Box>
    </FormScreenLAyout>
  );
}
