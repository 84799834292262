import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { hexToRGBA } from "../../utils/hexToRGBA";

type ResponseImageChoiceCardProps = BoxProps & {
  imageUrl: string;
  imageCaption: string;
  sx?: BoxProps["sx"];
};

export default function ResponseImageChoiceCard({
  imageUrl,
  imageCaption,
  sx,
}: ResponseImageChoiceCardProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "25%",
        // minHeight: 200,
        mx: 1,
        // mb: 2,
        ...sx,
      }}
    >
      <Box
        sx={{
          backgroundColor: hexToRGBA(theme.palette.primary.light, 0.075),
          borderWidth: "1px",
          borderColor: hexToRGBA(theme.palette.primary.light, 0.25),
          borderRadius: "6px",
          width: "100%",
          height: "100%",
          px: 1,
          py: 1,
          display: "flex",
          flexDirection: "row",
          // alignItems: "center",
          // justifyContent: "center",
          overflow: "hidden",
        }}
      >
        {imageUrl && (
          <Box
            sx={{
              maxWidth: 100,
              height: 100,
              display: "flex",
            }}
          >
            <img
              style={{
                display: "block",
                margin: "auto",
                width: "100%",
                // height: "100%",
              }}
              src={imageUrl}
              alt="Uploaded Choice "
            />
          </Box>
        )}

        <Typography
          textAlign="start"
          sx={{
            ml: 2,
          }}
        >
          {imageCaption}
        </Typography>
      </Box>
    </Box>
  );
}
