import React from "react";

import FormScreenLAyout from "./Layout/FormScreenLayout";
import { Typography } from "@mui/material";
import { useSurveyTheme } from "../providers/surveyTheme";

type LiveGenericCompletionPageProps = {};

export default function LiveGenericCompletionPage({}: LiveGenericCompletionPageProps) {
  const surveyTheme = useSurveyTheme();
  return (
    <FormScreenLAyout
      sx={{
        height: "100vh",
      }}
      contentBoxStyling={{
        py: 7,
        px: {
          xs: 3,
          sm: 5,
          md: 10,
          lg: 15,
        },
      }}
      surveyTheme={surveyTheme}
    >
      <Typography
        variant="h4"
        sx={{
          mb: 2,
        }}
      >
        Thank You!
      </Typography>
      <Typography>We really appreciate you filling out our form.</Typography>
    </FormScreenLAyout>
  );
}
