import React from "react";

import { Box, BoxProps, Card, CardContent, Divider } from "@mui/material";

import { RouteOutlined } from "@mui/icons-material";

import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

import NewBlockCardTopHeader from "../../BlockTopHeader";
import { useField } from "formik";
import { ActionPathFields } from "../../../../types/Actions";
import { useSurveyTheme } from "../../../../providers/surveyTheme";

import FormScreenLAyout from "../../../Layout/FormScreenLayout";
import SurveyButton from "../../../SurveyComponents/SurveyButton";
import { BackgroundTypes } from "../../../../types/Blocks";

type NewActionPathBlockProps = {
  name: string;
  formScreenContentStyle?: BoxProps["sx"];
  children: React.ReactNode;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  actionPathIndex: number;
  numOfActionPaths: number;
  deleteHandler: () => void;
  settingsHandler: () => void;
};

export default function NewActionPathBlock({
  name,
  formScreenContentStyle = {},
  children,
  dragHandleProps,
  actionPathIndex,
  numOfActionPaths,
  deleteHandler,
  settingsHandler,
}: NewActionPathBlockProps) {
  const surveyTheme = useSurveyTheme();

  const [field] = useField<ActionPathFields>(name);

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        my: 2,
        minHeight: "90vh",
        maxHeight: "90vh",
      }}
    >
      <CardContent {...dragHandleProps}>
        <NewBlockCardTopHeader
          icon={<RouteOutlined />}
          title="Action Path"
          settingsClickHandler={settingsHandler}
          deleteHandler={deleteHandler}
        ></NewBlockCardTopHeader>
      </CardContent>

      <Divider />

      <FormScreenLAyout
        contentBoxStyling={{
          ...formScreenContentStyle,
        }}
        surveyTheme={surveyTheme}
        sx={{
          ...(field.value.hasCustomBackground &&
            field.value.customBackgroundSettings.backgroundType ===
              BackgroundTypes.IMAGE &&
            field.value.customBackgroundSettings.imageBackgroundSettings
              .imageUrl && {
              backgroundImage: `url(${field.value.customBackgroundSettings.imageBackgroundSettings.imageUrl})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }),

          ...(field.value.hasCustomBackground &&
            field.value.customBackgroundSettings.backgroundType ===
              BackgroundTypes.COLOR &&
            field.value.customBackgroundSettings.colorBackgroundSettings
              .color && {
              backgroundColor:
                field.value.customBackgroundSettings.colorBackgroundSettings
                  .color,
            }),
        }}
      >
        {children}
        <CardContent>
          <Box
            sx={{
              display: "flex",
              ...(surveyTheme && {
                ...(surveyTheme.contentTextAlignment && {
                  justifyContent: surveyTheme.contentTextAlignment,
                }),
              }),
            }}
          >
            {actionPathIndex === 0 &&
              actionPathIndex !== numOfActionPaths - 1 && (
                <SurveyButton>Next</SurveyButton>
              )}

            {/* Back button on Last Action Path  */}
            {actionPathIndex !== 0 &&
              actionPathIndex === numOfActionPaths - 1 && (
                <SurveyButton sx={{ mx: 2 }}>Back</SurveyButton>
              )}

            {/*  Previouse and Next Buttons */}

            {actionPathIndex !== 0 &&
              actionPathIndex < numOfActionPaths - 1 && (
                <SurveyButton sx={{ mx: 2 }}>Back</SurveyButton>
              )}

            {actionPathIndex !== 0 &&
              actionPathIndex < numOfActionPaths - 1 && (
                <SurveyButton sx={{ mx: 2 }}>Next</SurveyButton>
              )}
          </Box>
        </CardContent>
      </FormScreenLAyout>
    </Card>
  );
}
