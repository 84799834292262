import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import { Formik, Form, FormikProps } from "formik";
import * as yup from "yup";

import {
  Typography,
  Card,
  Container,
  CardContent,
  Button,
  Box,
  CircularProgress,
  Alert,
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";

import ControlledTextField from "../components/Inputs/ControlledTextField";

import { API_BASE_URL, SIGNUP_URL } from "../data/constants";
import { LANDING_PAGE_URL } from "../data/constants";

import Api from "../api/api";

import LivelyFormsLogo from "../assets/Lively_Forms_Logo_Blue.svg";
import GoogleLetterIcon from "../components/Icons/GoogleLetterIcon";
import MicrosoftLogoIcon from "../components/Icons/MicrosoftLogoIcon";

const INITIAL_FORM_STATE = {
  displayName: "",
  username: "",
  email: "",
  password: "",
  agreement: false,
};

const FORM_VALIDATION = yup.object().shape({
  displayName: yup.string().required("Fist Name is reuqired "),
  username: yup.string().required("Username is reuqired "),
  email: yup.string().required("Email/Username is requried"),
  // TODO - Add validatio nto password
  password: yup.string().required("Password is required"),
  agreement: yup
    .boolean()
    .required("You must agree to the Privacy Policy.")
    .oneOf([true], "You must agree to the Privacy Policy."),
});

export interface LoginFormFields {
  displayName: string;
  username: string;
  email: string;
  password: string;
  agreement: boolean;
}

type SignUpProps = {};

export default function SignUp({}: SignUpProps) {
  const navigate = useNavigate();

  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isSuccess, setIsSuccess] = useState("");

  const handleSubmit = async (values: LoginFormFields) => {
    try {
      setError("");
      setIsSuccess("");
      setIsLoading(true);

      await Api.post(SIGNUP_URL, values, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });

      setIsSuccess(
        `Awesome! One last step. You will need to verify your email. We have sent an email to ${values.email} with a verification link. Click the link in the email to get started.`
      );
      setIsLoading(false);
    } catch (error) {
      setIsSuccess("");
      setIsLoading(false);
      // @ts-ignore
      if (!error?.response) {
        setError("No response from server.");
        // @ts-ignore
      } else if (error?.response) {
        // @ts-ignore
        setError(error.response.data.error.message);
      } else {
        setError("Unable to Sign Up.");
      }
    }
  };

  const attemptGoogleLogin = () => {
    // window.open(`${API_BASE_URL}/auth/google/callback`);

    window.location.href = `${API_BASE_URL}/auth/google/callback`;
  };

  const attemptMicrosoftLogin = () => {
    // window.open(`${API_BASE_URL}/auth/google/callback`);

    window.location.href = `${API_BASE_URL}/auth/microsoft/callback`;
  };

  return (
    <Box
      sx={{
        pt: 7,
      }}
    >
      <Container maxWidth="xs">
        <Card
          sx={{
            py: 5,
            px: 2,
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: 3,
              }}
            >
              <img
                src={LivelyFormsLogo}
                alt="Lively Forms Logo"
                height={40}
                width={240}
              />
            </Box>

            <Typography sx={{ mb: 5 }} variant="body1" align="center">
              Hello, Come on in!
            </Typography>

            {!isSuccess && (
              <Formik
                initialValues={INITIAL_FORM_STATE as LoginFormFields}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values: any) => {
                  handleSubmit(values);
                }}
                validateOnChange
                validateOnBlur
              >
                {({
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  setFieldTouched,
                  handleChange,
                }: FormikProps<LoginFormFields>) => {
                  return (
                    <Form>
                      {error && (
                        <Alert sx={{ my: 3 }} severity="error">
                          {error}
                        </Alert>
                      )}

                      <ControlledTextField
                        name="displayName"
                        label="Display Name"
                        required
                        sx={{ mb: 2 }}
                        autoFocus
                      ></ControlledTextField>

                      <ControlledTextField
                        name="username"
                        label="Username"
                        required
                        sx={{ mb: 2 }}
                      ></ControlledTextField>

                      <ControlledTextField
                        name="email"
                        label="Email"
                        type="email"
                        required
                        sx={{ mb: 2 }}
                      ></ControlledTextField>

                      <ControlledTextField
                        name="password"
                        label="Password"
                        type="password"
                        sx={{ mb: 2 }}
                        required
                      ></ControlledTextField>

                      <FormControl
                        sx={{ mt: 2, mb: 4 }}
                        error={touched.agreement && Boolean(errors.agreement)}
                      >
                        <FormGroup>
                          <FormControlLabel
                            onChange={handleChange}
                            label={
                              <Typography>
                                I confirm I have read and I agree to Lively
                                Forms'{" "}
                                <Button
                                  href={`${LANDING_PAGE_URL}/documents/Privacy-Policy.pdf`}
                                  // href="http://www.google.com/"
                                  size="small"
                                  target="_blank"
                                >
                                  Privacy Policy
                                </Button>
                              </Typography>
                            }
                            name="agreement"
                            control={<Checkbox checked={values.agreement} />}
                          ></FormControlLabel>
                        </FormGroup>
                        <FormHelperText error>
                          {errors.agreement}
                        </FormHelperText>
                      </FormControl>

                      {/* <ControlledCheckbox
                        name="agreement"
                        label={
                          <Typography>
                            I aree to Lively Forms'{" "}
                            <Button
                              href={`${LANDING_PAGE_URL}/documents/Privacy-Policy.pdf`}
                              // href="http://www.google.com/"
                              size="small"
                              target="_blank"
                            >
                              Privacy Policy
                            </Button>
                          </Typography>
                        }
                        control={<Checkbox></Checkbox>}
                        sx={{ mb: 4 }}
                      ></ControlledCheckbox> */}

                      <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        size="large"
                        disabled={loading}
                      >
                        {loading ? (
                          <CircularProgress color="primary" />
                        ) : (
                          "Join Waiting List"
                        )}
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            )}

            {isSuccess && !loading && !error && (
              <>
                <Alert severity="success" variant="standard">
                  {isSuccess}
                </Alert>
              </>
            )}

            <Button
              sx={{
                mt: 3,
                textTransform: "none",
              }}
              fullWidth
              size="large"
              disabled={loading}
              onClick={() => {
                navigate("/login");
              }}
            >
              Already have an account? Log In
            </Button>

            <Button
              variant="outlined"
              fullWidth
              size="large"
              disabled={loading}
              sx={{
                mt: 3,
                color: "#1f1f1f",
                borderColor: "#747775",
                "&:hover": {
                  borderColor: "#747775",
                },
              }}
              onClick={() => {
                attemptGoogleLogin();
              }}
              startIcon={
                <Box>
                  <GoogleLetterIcon
                    style={{
                      height: "20px",
                      marginRight: "12px",
                      minWidth: "20px",
                      width: "20px",
                    }}
                  />
                </Box>
              }
            >
              Continue with Google
            </Button>

            <Button
              variant="outlined"
              fullWidth
              size="large"
              disabled={loading}
              sx={{
                mt: 3,
                color: "#1f1f1f",
                borderColor: "#747775",
                "&:hover": {
                  borderColor: "#747775",
                },
              }}
              onClick={() => {
                attemptMicrosoftLogin();
              }}
              startIcon={
                <Box>
                  <MicrosoftLogoIcon
                    style={{
                      height: "20px",
                      marginRight: "12px",
                      minWidth: "20px",
                      width: "20px",
                    }}
                  />
                </Box>
              }
            >
              Continue with Microsoft
            </Button>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}
