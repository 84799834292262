import {
  AltRouteOutlined,
  RouteOutlined,
  Title,
  FontDownloadOutlined,
} from "@mui/icons-material";

import { ActionCard, Actions } from "../types/Actions";

export const ACTIONS_LIST: ActionCard[] = [
  {
    actionType: Actions.REDIRECT,
    label: "Redirect",
    icon: <AltRouteOutlined color="primary" fontSize="small" />,
  },
  // {
  //   actionType: Actions.FILE,
  //   label: "File",
  //   icon: <CloudDownloadOutlined color="primary" fontSize="small" />,
  // },
  // {
  //   actionType: Actions.API_REQUEST,
  //   label: "API Request",
  //   icon: <DnsOutlined color="primary" fontSize="small" />,
  // },
];

export const TYPOGRAPHY_ACTIONS_LIST: ActionCard[] = [
  {
    actionType: Actions.HEADING,
    label: "Heading",
    icon: <Title color="primary" fontSize="small" />,
  },
  {
    actionType: Actions.PARAGRAPH,
    label: "Paragraph",
    icon: <FontDownloadOutlined color="primary" fontSize="small" />,
  },
];

export const ACTION_PATH_LIST: ActionCard[] = [
  {
    actionType: Actions.ACTION_PATH,
    label: "Action Path",
    icon: <RouteOutlined color="primary" fontSize="small" />,
  },
];
