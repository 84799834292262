import { getContrastRatio } from "@mui/material/styles";

export function getTextColorForBackground(backgroundColor: string) {
  // Calculate the contrast ratio with white and black
  const contrastWithWhite = getContrastRatio(backgroundColor, "#FFFFFF");
  const contrastWithBlack = getContrastRatio(backgroundColor, "#000000");

  // Determine the best text color based on the contrast ratio
  if (contrastWithWhite > contrastWithBlack) {
    return "#FFFFFF"; // Use white text
  } else {
    return "#000000"; // Use black text
  }
}
