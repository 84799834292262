import {
  Star,
  StarBorder,
  Favorite,
  FavoriteBorder,
  ThumbUp,
  ThumbUpOffAlt,
} from "@mui/icons-material";

import {
  TextBlockFields,
  CheckboxBlockFields,
  VisibilityOption,
  VisibilityLogicalUnions,
  VisibilityLogicalOperators,
  StarRatingBlockBlockFields,
  StarRatingIcons,
  PageBlockFields,
  VisibilityConditionAnswerFields,
  BackgroundTypes,
  QuestionTypes,
  QuestionFields,
  FormSubItemTypes,
  FormSubItemFields,
  FormSubBlockFields,
  FormSubBlockTypes,
  ParagraphBlockFields,
  HeadingBlockFields,
  YesNoBlockFields,
  ScaleBlockFields,
  SliderBlockFields,
  RankingBlockFields,
  ImageChoiceBlockFields,
  DateBlockFields,
  AllowedDateFormats,
  AllowedDateSeperators,
  RadioGroupBlockFields,
  DropdownBlockFields,
  EmailBlockFields,
  PhoneBlockFields,
  LinkBlockFields,
  AddressBlockFields,
} from "../types/Blocks";

const DEFAULT_REQUIRED_TEXT = "This question is required.";

// export const VISIBILITY_OPTIONS = [
//   {
//     label: "Show",
//     value: VisibilityOption.SHOW,
//   },
//   {
//     label: "Hide",
//     value: VisibilityOption.HIDE,
//   },
// ];

export const VISIBILITY_OPTIONS = {
  [VisibilityOption.SHOW]: "Show",
  [VisibilityOption.HIDE]: "Hide",
};

// export const VISIBILITY_UNION_OPTIONS = [
//   {
//     label: "All",
//     value: VisibilityLogicalUnions.AND,
//   },
//   {
//     label: "Any",
//     value: VisibilityLogicalUnions.OR,
//   },
// ];

export const VISIBILITY_UNION_OPTIONS = {
  [VisibilityLogicalUnions.AND]: "And",
  [VisibilityLogicalUnions.OR]: "Or",
};

// export const VISIBILITY_LOGICAL_OPERATORS = [
//   {
//     label: "Is",
//     value: VisibilityLogicalOperators.IS,
//   },
//   {
//     label: "Is Not",
//     value: VisibilityLogicalOperators.IS_NOT,
//   },
//   {
//     label: "Is Greater than",
//     value: VisibilityLogicalOperators.GREATER_THAN,
//   },
//   {
//     label: "Is Greater than or Equal to",
//     value: VisibilityLogicalOperators.GREATER_THAN_OR_EQUAL_TO,
//   },
//   {
//     label: "Is Less than",
//     value: VisibilityLogicalOperators.LESS_THAN,
//   },
//   {
//     label: "Is Less than or Equal to",
//     value: VisibilityLogicalOperators.LESS_THAN_OR_EQUAL_TO,
//   },
// ];

export const VISIBILITY_LOGICAL_OPERATORS = {
  [VisibilityLogicalOperators.IS]: "Is",
  [VisibilityLogicalOperators.IS_NOT]: "Is Not",
  [VisibilityLogicalOperators.GREATER_THAN]: "Is Greater than",
  [VisibilityLogicalOperators.GREATER_THAN_OR_EQUAL_TO]:
    "Is Greater than or Equal to",
  [VisibilityLogicalOperators.LESS_THAN]: "Is Less than",
  [VisibilityLogicalOperators.LESS_THAN_OR_EQUAL_TO]:
    "Is Less than or Equal to",
};

export const STAR_RATING_SCALE = {
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  10: "10",
};

export const FROM_NUMBER_SCALE = {
  0: "0",
  1: "1",
};

export const TO_NUMBER_SCALE = {
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  10: "10",
};

export const STAR_RATING_ICONS = {
  [StarRatingIcons.STAR]: "Star",
  [StarRatingIcons.HEART]: "Heart",
  [StarRatingIcons.THUMBS_UP]: "Thumbs Up",
};

export const STAR_RATING_ICON_SHAPES = {
  [StarRatingIcons.STAR]: {
    icon: <Star />,
    emptyIcon: <StarBorder />,
  },
  [StarRatingIcons.HEART]: {
    icon: <Favorite />,
    emptyIcon: <FavoriteBorder />,
  },
  [StarRatingIcons.THUMBS_UP]: {
    icon: <ThumbUp />,
    emptyIcon: <ThumbUpOffAlt />,
  },
};

// export const INITIAL_BLOCK_FIELDS: BlockFields = {
//   id: "",
//   type: Blocks.TEXT,
//   question: "",
//   isQuestionRequried: false,
//   requiredText: DEFAULT_REQUIRED_TEXT,
//   hasConditionalVisibility: false,
//   visibilitySettings: {
//     isVisible: VisibilityOption.SHOW,
//     union: VisibilityLogicalUnions.AND,
//     conditions: [
//       {
//         questionId: "",
//         logicalOperator: VisibilityLogicalOperators.IS,
//         answerValue: "",
//       },
//     ],
//   },
// };

export const INITIAL_FORM_SUB_ITEM_FIELDS: FormSubItemFields = {
  subItemId: "",
  subItemType: FormSubItemTypes.QUESTION,
  hasConditionalVisibility: false,
  visibilitySettings: {
    isVisible: VisibilityOption.SHOW,
    primaryCondition: {
      questionId: "",
      logicalOperator: VisibilityLogicalOperators.IS,
      answerValue: "",
    },
    secondaryConditions: [
      // {
      //   logicalUnion: VisibilityLogicalUnions.AND,
      //   questionId: "",
      //   logicalOperator: VisibilityLogicalOperators.IS,
      //   answerValue: "",
      // },
    ],
  },
};

export const INITIAL_FORM_SUB_BLOCK_FIELDS: FormSubBlockFields = {
  ...INITIAL_FORM_SUB_ITEM_FIELDS,
  subItemType: FormSubItemTypes.BLOCK,
  blockType: FormSubBlockTypes.PARAGRAPH,
};

export const INITIAL_HEADING_BLOCK_FIELDS: HeadingBlockFields = {
  ...INITIAL_FORM_SUB_BLOCK_FIELDS,
  blockType: FormSubBlockTypes.HEADING,
  heading: "",
};

export const INITIAL_PARAGRAPH_BLOCK_FIELDS: ParagraphBlockFields = {
  ...INITIAL_FORM_SUB_BLOCK_FIELDS,
  blockType: FormSubBlockTypes.PARAGRAPH,
  paragraph: "",
};

export const INITIAL_BLOCK_FIELDS: QuestionFields = {
  ...INITIAL_FORM_SUB_ITEM_FIELDS,
  subItemType: FormSubItemTypes.QUESTION,
  questionType: QuestionTypes.TEXT,
  question: "",
  questionDescription: "",
  isQuestionRequired: false,
  requiredText: DEFAULT_REQUIRED_TEXT,
  isDynamicQuestion: false,
  dynamicQuestionSettings: {
    questionId: "",
    dynamicQuestionContext: "",
  },
};

export const INITIAL_TEXT_BLOCK_FIELDS: TextBlockFields = {
  ...INITIAL_BLOCK_FIELDS,
  questionType: QuestionTypes.TEXT,
};

export const INITIAL_CHECKBOXES_BLOCK_FIELDS: CheckboxBlockFields = {
  ...INITIAL_BLOCK_FIELDS,
  questionType: QuestionTypes.CHECKBOXES,
  checkboxes: [
    {
      checkboxId: "1",
      value: "",
    },
  ],
};

export const INITIAL_STAR_RATING_BLOCK_FIELDS: StarRatingBlockBlockFields = {
  ...INITIAL_BLOCK_FIELDS,
  questionType: QuestionTypes.STAR_RATING,
  scale: 5,
  icon: StarRatingIcons.STAR,
  color: "#ffd700",
};

export const INITIAL_YES_NO_BLOCK_FIELDS: YesNoBlockFields = {
  ...INITIAL_BLOCK_FIELDS,
  questionType: QuestionTypes.YESNO,
};

export const INITIAL_SCALE_BLOCK_FIELDS: ScaleBlockFields = {
  ...INITIAL_BLOCK_FIELDS,
  questionType: QuestionTypes.SCALE,
  fromNumber: 0,
  toNumber: 5,
  minLabel: "",
  middleLabel: "",
  maxLabel: "",
};

export const INITIAL_SLIDER_BLOCK_FIELDS: SliderBlockFields = {
  ...INITIAL_BLOCK_FIELDS,
  questionType: QuestionTypes.SLIDER,
  sliderMinNumber: 0,
  sliderMaxNumber: 5,
  sliderStep: 1,
  sliderHasCustomLabels: false,
  sliderMnLabel: "",
  sliderMiddleLabel: "",
  sliderMaxLabel: "",
};

export const INITIAL_RANKING_BLOCK_FIELDS: RankingBlockFields = {
  ...INITIAL_BLOCK_FIELDS,
  questionType: QuestionTypes.RANKING,
  rankingValues: [],
};

export const INITIAL_IMAGE_CHOICE_BLOCK_FIELDS: ImageChoiceBlockFields = {
  ...INITIAL_BLOCK_FIELDS,
  questionType: QuestionTypes.IMAGE_CHOICE,
  imageChoiceValues: [],
  imageChoiceHasMultipleChoices: false,
};

export const INITIAL_DATE_BLOCK_FIELDS: DateBlockFields = {
  ...INITIAL_BLOCK_FIELDS,
  questionType: QuestionTypes.DATE,
  dateFormat: AllowedDateFormats.DDMMYYYY,
  dateSeperator: AllowedDateSeperators.SLASH,
};

export const INITIAL_RADIO_GROUP_BLOCK_FIELDS: RadioGroupBlockFields = {
  ...INITIAL_BLOCK_FIELDS,
  questionType: QuestionTypes.RADIO_GROUP,
  radioGroupValues: [],
};

export const INITIAL_DROPDOWN_BLOCK_FIELDS: DropdownBlockFields = {
  ...INITIAL_BLOCK_FIELDS,
  questionType: QuestionTypes.DROPDOWN,
  dropdownValues: [],
};

export const INITIAL_EMAIL_BLOCK_FIELDS: EmailBlockFields = {
  ...INITIAL_BLOCK_FIELDS,
  questionType: QuestionTypes.EMAIL,
};

export const INITIAL_PHONE_BLOCK_FIELDS: PhoneBlockFields = {
  ...INITIAL_BLOCK_FIELDS,
  questionType: QuestionTypes.PHONE,
  phoneDefaultCountry: "GB",
};

export const INITIAL_LINK_BLOCK_FIELDS: LinkBlockFields = {
  ...INITIAL_BLOCK_FIELDS,
  questionType: QuestionTypes.LINK,
};

export const INITIAL_ADDRESS_BLOCK_FIELDS: AddressBlockFields = {
  ...INITIAL_BLOCK_FIELDS,
  questionType: QuestionTypes.ADDRESS,
  addressLine1: {
    isVisible: true,
    label: "Address Line 1",
    isRequired: false,
  },
  addressLine2: {
    isVisible: true,
    label: "Address Line 2",
    isRequired: false,
  },
  addressTown: {
    isVisible: true,
    label: "Town/ City",
    isRequired: false,
  },
  addressState: {
    isVisible: true,
    label: "State/ Province",
    isRequired: false,
  },
  addressPostCode: {
    isVisible: true,
    label: "ZIP / Post Code",
    isRequired: false,
  },
  addressCountry: {
    isVisible: true,
    label: "Country",
    isRequired: false,
  },
};

export const INITIAL_PAGE_BLOCK_FIELDS: PageBlockFields = {
  pageId: "",
  hasConditionalVisibility: false,
  visibilitySettings: {
    isVisible: VisibilityOption.SHOW,
    primaryCondition: {
      questionId: "",
      logicalOperator: VisibilityLogicalOperators.IS,
      answerValue: "",
    },
    secondaryConditions: [
      // {
      //   logicalUnion: VisibilityLogicalUnions.AND,
      //   questionId: "",
      //   logicalOperator: VisibilityLogicalOperators.IS,
      //   answerValue: "",
      // },
    ],
  },
  hasCustomBackground: false,
  customBackgroundSettings: {
    backgroundType: BackgroundTypes.IMAGE,
    imageBackgroundSettings: {
      imageUrl: "",
    },
    colorBackgroundSettings: {
      color: "",
    },
  },
  subItems: [],
};

/*  Visibility Logical Operators */
export const BASE_VISIBILITY_LOGICAL_OPERATORS = {
  [VisibilityLogicalOperators.IS]: "Is",
  [VisibilityLogicalOperators.IS_NOT]: "Is Not",
};

export const TEXT_VISIBILITY_LOGICAL_OPERATORS = {
  [VisibilityLogicalOperators.CONTAINS]: "Has",
  [VisibilityLogicalOperators.CONTAINS_NOT]: "Has Not",
  [VisibilityLogicalOperators.STARTS_WITH]: "Starts With",
  [VisibilityLogicalOperators.ENDS_WITH]: "Ends With",
};

export const SENTIMENT_VISIBILITY_LOGICAL_OPERATORS = {
  [VisibilityLogicalOperators.HAS_SENTIMENT]: "Has Sentiment",
  [VisibilityLogicalOperators.HAS_NOT_SENTIMENT]: "Has Not Sentiment",
};

export const NUMERIC_VISIBILITY_LOGICAL_OPERATORS = {
  [VisibilityLogicalOperators.GREATER_THAN]: "Is Greater than",
  [VisibilityLogicalOperators.GREATER_THAN_OR_EQUAL_TO]:
    "Is Greater than or Equal to",
  [VisibilityLogicalOperators.LESS_THAN]: "Is Less than",
  [VisibilityLogicalOperators.LESS_THAN_OR_EQUAL_TO]:
    "Is Less than or Equal to",
};

export const LIST_VISIBILITY_LOGICAL_OPERATORS = {
  [VisibilityLogicalOperators.CONTAINS]: "Has",
  [VisibilityLogicalOperators.CONTAINS_NOT]: "Has Not",
};

export const DATE_VISIBILITY_LOGICAL_OPERATORS = {
  [VisibilityLogicalOperators.IS_ON]: "Is On",
  [VisibilityLogicalOperators.IS_NOT_ON]: "Is Not Ont",
  [VisibilityLogicalOperators.IS_BEFORE]: "Is Before",
  [VisibilityLogicalOperators.IS_AFTER]: "Is After",
  [VisibilityLogicalOperators.IS_BEFORE_OR_ON]: "Is Before Or On",
  [VisibilityLogicalOperators.IS_AFTER_OR_ON]: "Is After Or On",
};

export const VISIBILITY_LOGICAL_OPERATORS_PER_BLOCK_TYPE = {
  [QuestionTypes.TEXT]: {
    ...BASE_VISIBILITY_LOGICAL_OPERATORS,
    ...TEXT_VISIBILITY_LOGICAL_OPERATORS,
    ...SENTIMENT_VISIBILITY_LOGICAL_OPERATORS,
  },
  [QuestionTypes.STAR_RATING]: {
    ...BASE_VISIBILITY_LOGICAL_OPERATORS,
    ...NUMERIC_VISIBILITY_LOGICAL_OPERATORS,
  },
  [QuestionTypes.CHECKBOXES]: {
    ...LIST_VISIBILITY_LOGICAL_OPERATORS,
  },
  [QuestionTypes.YESNO]: {
    ...BASE_VISIBILITY_LOGICAL_OPERATORS,
  },
  [QuestionTypes.SCALE]: {
    ...BASE_VISIBILITY_LOGICAL_OPERATORS,
    ...NUMERIC_VISIBILITY_LOGICAL_OPERATORS,
  },
  [QuestionTypes.SLIDER]: {
    ...BASE_VISIBILITY_LOGICAL_OPERATORS,
    ...NUMERIC_VISIBILITY_LOGICAL_OPERATORS,
  },
  [QuestionTypes.RANKING]: {
    ...BASE_VISIBILITY_LOGICAL_OPERATORS,
  },
  [QuestionTypes.IMAGE_CHOICE]: {
    ...BASE_VISIBILITY_LOGICAL_OPERATORS,
    ...LIST_VISIBILITY_LOGICAL_OPERATORS,
  },
  [QuestionTypes.DATE]: {
    ...DATE_VISIBILITY_LOGICAL_OPERATORS,
  },
  [QuestionTypes.RADIO_GROUP]: {
    ...BASE_VISIBILITY_LOGICAL_OPERATORS,
  },
  [QuestionTypes.DROPDOWN]: {
    ...BASE_VISIBILITY_LOGICAL_OPERATORS,
  },
  [QuestionTypes.EMAIL]: {
    ...BASE_VISIBILITY_LOGICAL_OPERATORS,
    ...TEXT_VISIBILITY_LOGICAL_OPERATORS,
  },
  // TODO - NOTE : A limitation we have for the phone field is that the answer submitted by the user will be formatted with spaces etc. The user should some how be made aware of this
  [QuestionTypes.PHONE]: {
    ...BASE_VISIBILITY_LOGICAL_OPERATORS,
    ...TEXT_VISIBILITY_LOGICAL_OPERATORS,
  },
  [QuestionTypes.LINK]: {
    ...BASE_VISIBILITY_LOGICAL_OPERATORS,
    ...TEXT_VISIBILITY_LOGICAL_OPERATORS,
  },
  [QuestionTypes.ADDRESS]: {
    ...BASE_VISIBILITY_LOGICAL_OPERATORS,
  },
};

export const VISIBILITY_CONDITION_ANSWER_FIELD_PER_BLOCK_TYPE = {
  [QuestionTypes.TEXT]: VisibilityConditionAnswerFields.TEXT_FIELD,
  [QuestionTypes.CHECKBOXES]: VisibilityConditionAnswerFields.DROPDOWN,
  [QuestionTypes.STAR_RATING]: VisibilityConditionAnswerFields.DROPDOWN,
  [QuestionTypes.YESNO]: VisibilityConditionAnswerFields.DROPDOWN,
  [QuestionTypes.SCALE]: VisibilityConditionAnswerFields.DROPDOWN,
  [QuestionTypes.SLIDER]: VisibilityConditionAnswerFields.NUMBER,
  [QuestionTypes.RANKING]: VisibilityConditionAnswerFields.DROPDOWN,
  [QuestionTypes.IMAGE_CHOICE]: VisibilityConditionAnswerFields.DROPDOWN,
  [QuestionTypes.DATE]: VisibilityConditionAnswerFields.DATE,
  [QuestionTypes.RADIO_GROUP]: VisibilityConditionAnswerFields.DROPDOWN,
  [QuestionTypes.DROPDOWN]: VisibilityConditionAnswerFields.DROPDOWN,
  [QuestionTypes.EMAIL]: VisibilityConditionAnswerFields.TEXT_FIELD,
  [QuestionTypes.PHONE]: VisibilityConditionAnswerFields.TEXT_FIELD,
  [QuestionTypes.LINK]: VisibilityConditionAnswerFields.TEXT_FIELD,
  [QuestionTypes.ADDRESS]: VisibilityConditionAnswerFields.TEXT_FIELD,
};

export enum Sentiments {
  POSITIVE = "positive",
  NEGATIVE = "negative",
  MEDIUM = "medium",
}

export const SENTIMENT_OPTIONS = {
  [Sentiments.POSITIVE]: "Positive",
  [Sentiments.NEGATIVE]: "Negative",
  [Sentiments.MEDIUM]: "Medium",
};

export const BACKGROUND_TYPES_LIST = {
  [BackgroundTypes.IMAGE]: "Image",
  [BackgroundTypes.COLOR]: "Color",
};

export const ALLOWED_DATE_FORMATS_OPTIONS = {
  [AllowedDateFormats.DDMMYYYY]: "DDMMYYYY",
  [AllowedDateFormats.MMDDYYYY]: "MMDDYYYY",
  [AllowedDateFormats.YYYYMMDD]: "YYYYMMDD",
};

export const ALLOWED_DATE_SEPERATORS_OPTIONS = {
  [AllowedDateSeperators.SLASH]: "/",
  [AllowedDateSeperators.DASH]: "-",
  [AllowedDateSeperators.PERIOD]: ".",
};
