import React from "react";

import { useTheme } from "@mui/material/styles";

import { Box, BoxProps, Typography } from "@mui/material";

import { generateQuestionTextStyle } from "../../utils/styles/generateQuestionTextStyle";
import { LiveSurveyThemeDocument } from "../../types/SurveyTheme";
import { generateQuestionDescriptionTextStyle } from "../../utils/styles/generateQuestionDescriptionTextStyle";

type LiveSurveyQuestionTextProps = BoxProps & {
  questionText: string;
  questionDescription?: string;
  isQuestionRequired: boolean;
  surveyTheme: LiveSurveyThemeDocument | null;
};

export default function LiveSurveyQuestionText({
  questionText,
  questionDescription,
  surveyTheme,
  isQuestionRequired,
  sx,
}: LiveSurveyQuestionTextProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        ...sx,
      }}
    >
      <Box
        sx={{
          ...(questionDescription && { mb: 0.5 }),
        }}
      >
        <Typography
          sx={{
            ...theme.typography.h6,
            ...(surveyTheme && {
              ...generateQuestionTextStyle(surveyTheme),
            }),
          }}
        >
          {questionText} {isQuestionRequired && "*"}
        </Typography>
      </Box>
      {questionDescription && (
        <Typography
          sx={{
            ...theme.typography.body2,
            ...(surveyTheme && {
              ...generateQuestionDescriptionTextStyle(surveyTheme),
            }),
          }}
        >
          {questionDescription}
        </Typography>
      )}
    </Box>
  );
}
