import React from "react";

import { Box } from "@mui/material";

import { QuestionTypes } from "../../../../types/Blocks";
import NewQuestionRequiredOption from "../../Options/QuestionRequiredOption";
import NewConditionalVisibilityOptions from "../../Options/ConditionalVisibilityOptions";
import DynamicQuestionOption from "../../Options/DynamicQuestionOption";
import TabsLayout from "../../../Layout/TabsLayout";

type TextFieldBlockSettingsPanelProps = {
  name: string;
  pageIndex: number;
  blockIndex: number;
};

export default function TextFieldBlockSettingsPanel({
  name,
  pageIndex,
  blockIndex,
}: TextFieldBlockSettingsPanelProps) {
  return (
    <>
      <TabsLayout
        tabOptions={{
          options: {
            label: "Options",
            tabPanel: (
              <>
                <Box sx={{ mt: 1 }}>
                  <NewQuestionRequiredOption
                    name={name}
                  ></NewQuestionRequiredOption>
                </Box>
                <Box sx={{ mt: 3 }}>
                  <DynamicQuestionOption
                    name={name}
                    pageIndex={pageIndex}
                    blockIndex={blockIndex}
                  ></DynamicQuestionOption>
                </Box>
              </>
            ),
          },
          visibility: {
            label: "Visibility",
            tabPanel: (
              <NewConditionalVisibilityOptions
                name={name}
                itemType={QuestionTypes.TEXT}
                itemTitle="question"
                pageIndex={pageIndex}
                blockIndex={blockIndex}
              ></NewConditionalVisibilityOptions>
            ),
          },
        }}
      />
    </>
  );
}
