import {
  AlignVerticalBottomOutlined,
  AlignVerticalCenterOutlined,
  AlignVerticalTopOutlined,
  CheckBoxOutlineBlank,
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  MinimizeOutlined,
} from "@mui/icons-material";
import {
  ContentSectionPlacements,
  FieldVariant,
  FontWeights,
  HorizontalLogoPosition,
  LogoRelativeToOptions,
  SurveyThemeFormFields,
  TextAlignmentPositions,
  VerticalAlignmentPositions,
  VerticalLogoPositions,
} from "../types/SurveyTheme";

import PageContentLeftAlign from "../assets/PageContentLeftAlign.png";
import PageContentCenterAlign from "../assets/PageContentCenterAlign.png";
import PageContentRightAlign from "../assets/PageContentRightAlign.png";
import { BackgroundTypes } from "../types/Blocks";

export const AVAILABLE_FONTS = {
  Roboto: "Roboto",
  "Open Sans": "Open Sans",
  Lato: "Lato",
  Montserrat: "Montserrat",
  Oswald: "Oswald",
  "Source Sans Pro": "Source Sans Pro",
  "Slabo 27px": "Slabo 27px",
  Raleway: "Raleway",
  "PT Sans": "PT Sans",
  Merriweather: "Merriweather",
  "Nunito Sans": "Nunito Sans",
  "Concert One": "Concert One",
  Prompt: "Prompt",
  "Work Sans": "Work Sans",
};

export const FIELD_VARIANT_OPTIONS = {
  [FieldVariant.OUTLINED]: <CheckBoxOutlineBlank />,
  [FieldVariant.UNDERLINE]: <MinimizeOutlined />,
};

export const TEXT_ALIGNMENT_OPTIONS = {
  [TextAlignmentPositions.LEFT]: <FormatAlignLeft />,
  [TextAlignmentPositions.CENTER]: <FormatAlignCenter />,
  [TextAlignmentPositions.END]: <FormatAlignRight />,
};

export const VERTICAL_ALIGNMENT_OPTIONS = {
  [VerticalAlignmentPositions.TOP]: <AlignVerticalTopOutlined />,
  [VerticalAlignmentPositions.CENTER]: <AlignVerticalCenterOutlined />,
  [VerticalAlignmentPositions.BOTTOM]: <AlignVerticalBottomOutlined />,
};

export const FONT_WEIGHT_OPTIONS = {
  [FontWeights.LIGHT]: "Light",
  [FontWeights.REGULAR]: "regular",
  [FontWeights.MEDIUM]: "medium",
  [FontWeights.BOLD]: "bold",
};

export const VERTICAL_LOGO_POSITION_OPTIONS = {
  [VerticalLogoPositions.BOTTOM]: "Bottom",
  [VerticalLogoPositions.TOP]: "Top",
};

export const HORIZONTAL_LOGO_POSITION_OPTIONS = {
  [HorizontalLogoPosition.LEFT]: "Left",
  [HorizontalLogoPosition.CENTER]: "Center",
  [HorizontalLogoPosition.RIGHT]: "Right",
};

export const LOGO_RELATIVE_TO_OPTIONS = {
  [LogoRelativeToOptions.PAGE]: "Page",
  [LogoRelativeToOptions.CONTENT]: "Content",
};

export const SURVEY_CONTENT_LAYOUT_OPTIONS = {
  [ContentSectionPlacements.LEFT]: (
    <img
      src={PageContentLeftAlign}
      alt="Page Content Left Align"
      style={{
        display: "block",
        margin: "auto",
        width: "100%",
        height: "100%",
      }}
    />
  ),
  [ContentSectionPlacements.CENTER]: (
    <img
      src={PageContentCenterAlign}
      alt="Page Content Center Align"
      style={{
        display: "block",
        margin: "auto",
        width: "100%",
        height: "100%",
      }}
    />
  ),
  [ContentSectionPlacements.RIGHT]: (
    <img
      src={PageContentRightAlign}
      alt="Page Content Right Align"
      style={{
        display: "block",
        margin: "auto",
        width: "100%",
        height: "100%",
      }}
    />
  ),
};

export const INITIAL_FORM_STATE: SurveyThemeFormFields = {
  title: "",
  colorSettings: {
    questionTextColor: "#ffffff",
    answerTextColor: "#ffffff",
    buttonBackgroundColor: "#ffffff",
    buttonTextColor: "#ffffff",
  },
  logoSettings: {
    hasSurveyLogo: false,
    logoAltText: "",
    logoUrl: "",
    relativeTo: LogoRelativeToOptions.PAGE,
    verticalPosition: VerticalLogoPositions.TOP,
    horizontalPosition: HorizontalLogoPosition.LEFT,
  },
  mainBackgroundSettings: {
    hasCustomBackground: false,
    customBackgroundSettings: {
      backgroundType: BackgroundTypes.COLOR,
      imageBackgroundSettings: { imageUrl: "" },
      colorBackgroundSettings: { color: "#ffffff" },
    },
  },
  contentBackgroundSettings: {
    hasCustomBackground: false,
    customBackgroundSettings: {
      backgroundType: BackgroundTypes.COLOR,
      imageBackgroundSettings: { imageUrl: "" },
      colorBackgroundSettings: { color: "#ffffff" },
    },
  },
  globalFont: "Roboto",
  hasGranularFontSettings: false,
  granularFontSettings: {
    questionText: {
      fontFamily: "Roboto",
      fontSize: 15,
      fontWeight: FontWeights.REGULAR,
    },
    answerText: {
      fontFamily: "Roboto",
      fontSize: 15,
      fontWeight: FontWeights.REGULAR,
    },
  },
  contentSectionPlacement: ContentSectionPlacements.CENTER,
  contentTextAlignment: TextAlignmentPositions.LEFT,
  contentVerticalAlignment: VerticalAlignmentPositions.TOP,
  fieldVariant: FieldVariant.OUTLINED,
};
