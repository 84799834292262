import React, { useEffect, useState } from "react";
import FormBuilder, {
  IFormCanvasBlocksFields,
} from "../components/FormBuilder";

import { useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { useProtectedApi } from "../hooks/useProtectedApi";
import { SURVEY_DRAFTS_URL } from "../data/constants";
import { Alert, Box, CircularProgress, Container } from "@mui/material";

import {
  useUpdateSavedSurveyTheme,
  useUpdateSurveyTheme,
} from "../providers/surveyTheme";
import { ArrowBack } from "@mui/icons-material";
import UnderConstructionIcon from "../components/Icons/UnderConstructionIcon";
import Header from "../components/Layout/Header";
import EmptyPlaceHolder from "../components/Layout/EmptyPlaceHolder";

type BuilderPageProps = {};

export default function Builder({}: BuilderPageProps) {
  const navigate = useNavigate();
  const { identifier } = useParams();

  const updateSurveyTheme = useUpdateSurveyTheme();
  const updateSavedSurveyTheme = useUpdateSavedSurveyTheme();

  const [loading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState<IFormCanvasBlocksFields>();

  const ProtectedApi = useProtectedApi();

  const getFormData = async () => {
    try {
      setIsLoading(true);
      setError("");
      const response = await ProtectedApi.get(
        `${SURVEY_DRAFTS_URL}/${identifier}`
      );

      setFormData(response.data.success.data.survey);

      if (response.data.success.data.survey.surveyTheme) {
        updateSurveyTheme &&
          updateSurveyTheme(response.data.success.data.survey.surveyTheme);

        updateSavedSurveyTheme &&
          updateSavedSurveyTheme(response.data.success.data.survey.surveyTheme);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error instanceof AxiosError && !error?.response) {
        setError("No response from server.");
      } else if (error instanceof AxiosError && error?.response?.data) {
        setError(error.response.data.error.message);
      } else {
        setError("Unable to get form data");
      }
    }
  };

  useEffect(() => {
    getFormData();
  }, []);

  return (
    <>
      <Container maxWidth="lg">
        {error && (
          <Alert sx={{ my: 3 }} severity="error">
            {error}
          </Alert>
        )}

        {loading && !error && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 5,
            }}
          >
            <CircularProgress></CircularProgress>
          </Box>
        )}
      </Container>

      {formData && identifier && !loading && !error && (
        <>
          <Box
            sx={{
              display: {
                xs: "none",
                md: "block",
              },
            }}
          >
            <FormBuilder
              initialValues={{
                title: formData.title,
                surveyTheme: formData.surveyTheme ?? "",
                pages: formData.pages,
                actionPaths: formData.actionPaths,
              }}
              currentFormId={identifier}
              // @ts-ignore
              publishedSurveyIdentifier={formData.publishedSurvey?.identifier}
            />
          </Box>
          <Box
            sx={{
              display: {
                xs: "block",
                md: "none",
              },
            }}
          >
            <Header />
            <EmptyPlaceHolder
              sx={{
                mt: 10,
              }}
              title="Use Form Builder on Desktop"
              description="We are currently working on creating our Form Builder for
                  mobile devices. For now we recommend using our Form Buidler on
                  desktop."
              svgIcon={<UnderConstructionIcon />}
              showButton
              buttonContent={"Back To My Forms"}
              buttonProps={{
                startIcon: <ArrowBack />,
                onClick: () => {
                  navigate(-1);
                },
              }}
            />
          </Box>
        </>
      )}
    </>
  );
}
