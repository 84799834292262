import React from "react";

import {
  Box,
  Card,
  CardContent,
  CardProps,
  IconButton,
  Typography,
} from "@mui/material";
import { EditOutlined } from "@mui/icons-material";

import { useTheme } from "@mui/material/styles";
import {
  ContentSectionPlacements,
  LiveSurveyThemeDocument,
} from "../types/SurveyTheme";
import { BackgroundTypes } from "../types/Blocks";

type SurveyThemePreviewCardProps = CardProps & {
  title: string;
  surveyThemeSettings: LiveSurveyThemeDocument;
  editClickHandler: () => void;
};

export default function SurveyThemePreviewCard({
  sx,
  title,
  surveyThemeSettings,
  editClickHandler,
}: SurveyThemePreviewCardProps) {
  const theme = useTheme();

  return (
    <Card sx={{ ...sx }}>
      <Box
        sx={{
          ...(surveyThemeSettings.mainBackgroundSettings
            .customBackgroundSettings.backgroundType ===
            BackgroundTypes.IMAGE &&
            surveyThemeSettings.mainBackgroundSettings.customBackgroundSettings
              .imageBackgroundSettings.imageUrl && {
              backgroundImage: `url(${surveyThemeSettings.mainBackgroundSettings.customBackgroundSettings.imageBackgroundSettings.imageUrl})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }),

          ...(surveyThemeSettings.mainBackgroundSettings
            .customBackgroundSettings.backgroundType ===
            BackgroundTypes.COLOR &&
            surveyThemeSettings.mainBackgroundSettings.customBackgroundSettings
              .colorBackgroundSettings.color && {
              backgroundImage: "",
              backgroundColor:
                surveyThemeSettings.mainBackgroundSettings
                  .customBackgroundSettings.colorBackgroundSettings.color,
            }),
        }}
      >
        <Box
          sx={{
            pl: 2,
            py: 3,
            ...(surveyThemeSettings.contentSectionPlacement && {
              ...(surveyThemeSettings.contentSectionPlacement ===
                ContentSectionPlacements.CENTER && {
                textAlign: "center",
              }),

              ...((surveyThemeSettings.contentSectionPlacement ===
                ContentSectionPlacements.LEFT ||
                surveyThemeSettings.contentSectionPlacement ===
                  ContentSectionPlacements.RIGHT) && {
                width: "50%",
              }),

              ...(surveyThemeSettings.contentSectionPlacement ===
                ContentSectionPlacements.RIGHT && {
                ml: "50%",
              }),

              ...(surveyThemeSettings.contentBackgroundSettings
                .hasCustomBackground && {
                ...(surveyThemeSettings.contentBackgroundSettings
                  .customBackgroundSettings.backgroundType ===
                  BackgroundTypes.IMAGE &&
                  surveyThemeSettings.contentBackgroundSettings
                    .customBackgroundSettings.imageBackgroundSettings
                    .imageUrl && {
                    backgroundImage: `url(${surveyThemeSettings.contentBackgroundSettings.customBackgroundSettings.imageBackgroundSettings.imageUrl})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }),

                ...(surveyThemeSettings.contentBackgroundSettings
                  .customBackgroundSettings.backgroundType ===
                  BackgroundTypes.COLOR &&
                  surveyThemeSettings.contentBackgroundSettings
                    .customBackgroundSettings.colorBackgroundSettings.color && {
                    backgroundImage: "",
                    backgroundColor:
                      surveyThemeSettings.contentBackgroundSettings
                        .customBackgroundSettings.colorBackgroundSettings.color,
                  }),
              }),
            }),
          }}
        >
          <Typography
            sx={{
              ...theme.typography.h6,
              ...(surveyThemeSettings.colorSettings.questionTextColor && {
                color: surveyThemeSettings.colorSettings.questionTextColor,
              }),

              ...(surveyThemeSettings.globalFont &&
                !surveyThemeSettings.hasGranularFontSettings && {
                  fontFamily: surveyThemeSettings.globalFont,
                }),
              ...(surveyThemeSettings.hasGranularFontSettings &&
                surveyThemeSettings.granularFontSettings.questionText && {
                  fontFamily:
                    surveyThemeSettings.granularFontSettings.questionText
                      .fontFamily,
                  fontWeight:
                    surveyThemeSettings.granularFontSettings.questionText
                      .fontWeight,
                }),
            }}
          >
            Question
          </Typography>
          <Typography
            sx={{
              ...(surveyThemeSettings.colorSettings.answerTextColor && {
                color: surveyThemeSettings.colorSettings.answerTextColor,
              }),

              ...(surveyThemeSettings.globalFont &&
                !surveyThemeSettings.hasGranularFontSettings && {
                  fontFamily: surveyThemeSettings.globalFont,
                }),

              ...(surveyThemeSettings.hasGranularFontSettings &&
                surveyThemeSettings.granularFontSettings.answerText && {
                  fontFamily:
                    surveyThemeSettings.granularFontSettings.answerText
                      .fontFamily,
                  fontWeight:
                    surveyThemeSettings.granularFontSettings.answerText
                      .fontWeight,
                }),
            }}
          >
            Answer
          </Typography>
          <Box
            sx={{
              my: 1,
              display: "inline-block",
              background: "pink",
              px: 0.5,
              py: 0.15,
              backgroundColor:
                surveyThemeSettings.colorSettings.buttonBackgroundColor,
              color: surveyThemeSettings.colorSettings.buttonTextColor,
            }}
          >
            <Typography
              sx={{
                fontSize: theme.typography.caption.fontSize,
              }}
            >
              Submit
            </Typography>
          </Box>
        </Box>
      </Box>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography>{title}</Typography>
          <IconButton onClick={editClickHandler}>
            <EditOutlined fontSize="small" />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
}
