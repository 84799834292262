import React, { useState } from "react";

import * as yup from "yup";
import { Form, Formik, FormikProps } from "formik";
import ControlledTextField from "../Inputs/ControlledTextField";
import { ACCOUNT_URL } from "../../data/constants";
import { useProtectedApi } from "../../hooks/useProtectedApi";
import { useAuth } from "../../hooks/useAuth";
import { Alert } from "@mui/material";

const FORM_VALIDATION = yup.object().shape({
  displayName: yup
    .string()
    .required("Display Name is requried")
    .test(
      "len",
      "Must not be greater than 20 characters",
      (val) => val.length <= 20
    ),
});

type EditDisplayNameFormFields = {
  displayName: string;
};

type EditDisplayNameFormProps = {
  submitButton: (loading: boolean) => React.ReactNode;
  initialDisplayName: string;
  onSuccess: () => void;
};

export default function EditDisplayNameForm({
  initialDisplayName,
  submitButton,
  onSuccess,
}: EditDisplayNameFormProps) {
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const { auth, setAuth } = useAuth();

  const ProtectedApi = useProtectedApi();

  const handleSubmit = async (values: EditDisplayNameFormFields) => {
    try {
      setError("");
      setIsLoading(true);

      const response = await ProtectedApi.patch(ACCOUNT_URL, {
        displayName: values.displayName,
      });

      setAuth({
        ...(auth && {
          ...auth,
        }),
        user: response.data.success.data.user,
      });

      setIsLoading(false);
      onSuccess();
    } catch (error) {
      setIsLoading(false);
      // @ts-ignore
      if (!error?.response) {
        setError("No response from server.");
        // @ts-ignore
      } else if (error?.response) {
        // @ts-ignore
        setError(error.response.data.error.message);
      } else {
        setError("Unable to login.");
      }
    }
  };

  return (
    <Formik
      initialValues={
        { displayName: initialDisplayName } as EditDisplayNameFormFields
      }
      validationSchema={FORM_VALIDATION}
      onSubmit={async (values: any) => {
        await handleSubmit(values);
      }}
      validateOnChange
      validateOnBlur
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
      }: FormikProps<EditDisplayNameFormFields>) => {
        return (
          <Form>
            {error && (
              <Alert sx={{ my: 3 }} severity="error">
                {error}
              </Alert>
            )}
            <ControlledTextField
              name="displayName"
              label="Display Name"
              required
            ></ControlledTextField>

            {submitButton(loading)}
          </Form>
        );
      }}
    </Formik>
  );
}
