import { lighten, alpha, Theme } from "@mui/material/styles";
import { LiveSurveyThemeDocument } from "../../types/SurveyTheme";
import { hexToRGBA } from "../hexToRGBA";

export const generateRankingBoxStyle = (
  isDragging: boolean,
  surveyTheme: LiveSurveyThemeDocument | null,
  theme: Theme
) => {
  return {
    display: "flex",
    alignItems: "center",
    minWidth: "250px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: hexToRGBA(theme.palette.primary.light, 0.25),
    borderRadius: "5px",
    px: 1,
    mb: 1,
    ...(isDragging && {
      borderWidth: "2px",
    }),

    ...(surveyTheme && {
      backgroundColor: alpha(
        lighten(surveyTheme.colorSettings.answerTextColor, 0.8),
        0.1
      ),
      borderColor: lighten(surveyTheme.colorSettings.answerTextColor, 0.15),

      ...(isDragging && {
        backgroundColor: alpha(
          lighten(surveyTheme.colorSettings.answerTextColor, 0.5),
          0.25
        ),
      }),

      "&:hover": {
        backgroundColor: alpha(
          lighten(surveyTheme.colorSettings.answerTextColor, 0.5),
          0.25
        ),
        borderColor: lighten(surveyTheme.colorSettings.answerTextColor, 0.15),
      },
    }),
  };
};
