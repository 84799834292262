import React, { useRef, useState } from "react";

import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";

import { Formik, FormikProps, Form, FormikTouched, FormikErrors } from "formik";

import { v4 as uuid } from "uuid";

import {
  DragDropContext,
  DropResult,
  ResponderProvided,
  Draggable,
} from "react-beautiful-dnd";

import { MINI_DRAWER_BUTTONS } from "../data/BuilderDrawerData";
import {
  BuilderDrawerPanels,
  SettingsDrawerPAnel,
  SettingsDrawers,
} from "../types/Drawers";

import {
  INITIAL_IMAGE_CHOICE_BLOCK_FIELDS,
  INITIAL_FORM_SUB_ITEM_FIELDS,
  INITIAL_FORM_SUB_BLOCK_FIELDS,
  INITIAL_TEXT_BLOCK_FIELDS,
  INITIAL_CHECKBOXES_BLOCK_FIELDS,
  INITIAL_STAR_RATING_BLOCK_FIELDS,
  INITIAL_PAGE_BLOCK_FIELDS,
  INITIAL_BLOCK_FIELDS,
  INITIAL_HEADING_BLOCK_FIELDS,
  INITIAL_PARAGRAPH_BLOCK_FIELDS,
  INITIAL_YES_NO_BLOCK_FIELDS,
  INITIAL_SCALE_BLOCK_FIELDS,
  INITIAL_SLIDER_BLOCK_FIELDS,
  INITIAL_RANKING_BLOCK_FIELDS,
  INITIAL_DATE_BLOCK_FIELDS,
  INITIAL_RADIO_GROUP_BLOCK_FIELDS,
  INITIAL_DROPDOWN_BLOCK_FIELDS,
  INITIAL_EMAIL_BLOCK_FIELDS,
  INITIAL_PHONE_BLOCK_FIELDS,
  INITIAL_LINK_BLOCK_FIELDS,
  INITIAL_ADDRESS_BLOCK_FIELDS,
} from "../data/BlockFields";

import {
  FORM_BLOCKS_LIST,
  FORM_PAGE_BLOCKS_LIST,
  TYPOGRAPHY_FORM_BLOCKS_LIST,
} from "../data/BlocksData";
import {
  BlockCard,
  Blocks,
  FormSubBlockTypes,
  FormSubItemFields,
  PageBlockFields,
  QuestionFields,
  QuestionTypes,
  RankingBlockFields,
} from "../types/Blocks";

import BuilderDrawer, {
  MAIN_DRAWER_WIDTH,
  MINI_DRAWER_WIDTH,
} from "./Layout/BuilderDrawer";
import DrawerListCard from "./Blocks/DrawerListCard";
import { StrictModeDroppable } from "./Helpers/StrictModeDroppable";

import FormBuilderCanvas from "./FormBuilderCanvas";
import {
  ACTIONS_LIST,
  ACTION_PATH_LIST,
  TYPOGRAPHY_ACTIONS_LIST,
} from "../data/ActionsData";
import {
  ActionCard,
  ActionFields,
  ActionPathFields,
  Actions,
} from "../types/Actions";
import {
  INITIAL_ACTION_PATH_ACTION_FIELDS,
  INITIAL_API_REQUEST_ACTION_FIELDS,
  INITIAL_FILE_ACTION_FIELDS,
  INITIAL_REDIRECT_ACTION_FIELDS,
  INITIAL_ACTION_FIELDS,
  INITIAL_HEADING_ACTION_FIELDS,
  INITIAL_PARAGRAPH_ACTION_FIELDS,
} from "../data/ActionFields";

import { FORM_VALIDATION } from "../data/FormValidation";
import SettingsDrawer, { SETTINGS_DRAWER_WIDTH } from "./Layout/SettingsDrawer";
import TextFieldBlockSettingsPanel from "./Blocks/FormBlocks/TextFieldBlock/TextFieldBlockSettingsPanel";
import NewCheckboxesBlockSettingsPanel from "./Blocks/FormBlocks/CheckboxesBlock/CheckboxesBlockSettingPanel";
import StarRatingBlockSettingsPanel from "./Blocks/FormBlocks/StarRatingBlock/StarRatingBlockSettingsPanel";
import PageBlockSettingsPanel from "./Blocks/FormBlocks/PageBlock/PageBlockSettingsPanel";
import ActionPathBlockSettingsPanel from "./Blocks/ActionBlocks/ActionPathBlock/ActionPathBlockSettingsPanel";

import BuilderHeader from "./Layout/BuilderHeader";
import RedirectActionBlockSettingsPanel from "./Blocks/ActionBlocks/RedirectActionBlock/RedirectActionBlockSettingsPanel";
import {
  Close,
  EditOutlined,
  InfoOutlined,
  KeyboardDoubleArrowLeftOutlined,
  KeyboardDoubleArrowRightOutlined,
} from "@mui/icons-material";
import SurveyThemeSettingsPanel from "./SurveyThemeSettingsPanel";
import SurveyThemeForm, { FORM_MODES } from "./SurveyThemeForm";
import HeadingBlockSettingsPanel from "./Blocks/FormBlocks/HeadingBlock/HeadingBlockSettingsPanel";
import ParagraphBlockSettingsPanel from "./Blocks/FormBlocks/ParagraphBlock/ParagraphBlockSettingsPanel";

import { delay } from "../utils/delay";
import YesNoBlockSettingsPanel from "./Blocks/FormBlocks/YesNoBlock/YesNoBlockSettingsPanel";
import ScaleBlockSettingsPanel from "./Blocks/FormBlocks/ScaleBlock/ScaleBlockSettingsPanel";
import SliderBlockSettingsPanel from "./Blocks/FormBlocks/SliderBlock/SliderBlockSettingsPanel";
import ImageChoiceBlockSettingsPanel from "./Blocks/FormBlocks/ImageChoiceBlock/ImageChoiceBlockSettingsPanel";
import DateBlockSettingsPanel from "./Blocks/FormBlocks/DateBlock/DateBlockSettingsPanel";
import RadioGroupBlockSettingsPanel from "./Blocks/FormBlocks/RadioGroupBlock/RadioGroupBlockSettingsPanel";
import DropdownBlockSettingsPanel from "./Blocks/FormBlocks/DropdownBlock/DropdownBlockSettingsPanel";
import EmailBlockSettingsPanel from "./Blocks/FormBlocks/EmailBlock/EmailBlockSettingsPanel";
import PhoneBlockSettingsPanel from "./Blocks/FormBlocks/PhoneBlock/PhoneBlockSettingsPanel";
import LinkBlockSettingsPanel from "./Blocks/FormBlocks/LinkBlock/LinkBlockSettingsPanel";
import AddressBlockSettingsPanel from "./Blocks/FormBlocks/AddressBlock/AddressBlockSettingsPanel";
import EmptyPlaceHolder from "./Layout/EmptyPlaceHolder";
import SettingsPanelIcon from "./Icons/SettingsPanelIcon";
import RankingBlockSettingsPanel from "./Blocks/FormBlocks/RankingBlock/RankingBlockSettingsPanel";
import InfoTooltip from "./InfoTooltip";

export interface IFormCanvasBlocksFields {
  title: string;
  surveyTheme: string;
  pages: PageBlockFields[];
  actionPaths: ActionPathFields[];
}

type FormBuilderProps = {
  currentFormId: string;
  publishedSurveyIdentifier?: string;
  initialValues: IFormCanvasBlocksFields;
};

// NOTE : If the endIndex is greater than the indexes present in the array then this will not work as expected as
// splice() does not insert empty spaces for indexes that exceed those available thus resulting in unexpected behaviour
// a little function to help us with reordering the result
export const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

export default function FormBuilder({
  currentFormId,
  publishedSurveyIdentifier,
  initialValues,
}: FormBuilderProps) {
  const pagesListRef = useRef<HTMLDivElement | null>(null);
  const actionPathsListRef = useRef<HTMLDivElement | null>(null);

  const scrollToLastFormPage = (index: number) => {
    if (pagesListRef.current) {
      const listItem = pagesListRef.current.children[index];
      if (listItem) {
        listItem.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const scrollToLastActionPath = (index: number) => {
    if (actionPathsListRef.current) {
      const listItem = actionPathsListRef.current.children[index];
      if (listItem) {
        listItem.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const [currentMainDrawerPanel, setMainDrawerPanel] =
    useState<BuilderDrawerPanels>(BuilderDrawerPanels.BLOCKS);

  const updateMainDrawerPanel = (newPanel: BuilderDrawerPanels) => {
    setMainDrawerPanel(newPanel);
    setShowMainDrawer(true);
  };

  const [showSettingsDrawer, setShowSettingsDrawer] = useState(false);

  const [showMainDrawer, setShowMainDrawer] = useState(true);

  const toggleSettingsDrawer = () => {
    setShowSettingsDrawer((prevSettingsDrawer: boolean) => {
      return !prevSettingsDrawer;
    });
  };

  const toggleMainDrawer = () => {
    setShowMainDrawer((prevShowMainDrawer: boolean) => {
      return !prevShowMainDrawer;
    });
  };

  const [currentSettingsDrawerPanel, setCurrentSettingsDrawerPanel] =
    useState<SettingsDrawerPAnel>();

  const updateSettingsDrawerPanel = (
    type:
      | QuestionTypes
      | Blocks
      | Actions
      | FormSubBlockTypes
      | SettingsDrawers,
    pageIndex?: number,
    blockIndex?: number
  ) => {
    setCurrentSettingsDrawerPanel({ type, pageIndex, blockIndex });
    setShowSettingsDrawer(true);
  };

  const resetSettingsDrawerPanel = () => {
    setCurrentSettingsDrawerPanel(undefined);
  };

  const onDragEndHandler = async (
    result: DropResult,
    provided: ResponderProvided,
    formValues: IFormCanvasBlocksFields,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<IFormCanvasBlocksFields>>,
    touched: FormikTouched<IFormCanvasBlocksFields>,
    setFieldTouched: (
      field: string,
      isTouched?: boolean | undefined,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    if (!result.destination) return;

    // If dragging an entire page
    if (
      result.type === "droppableRankingValue"
      // result.source.droppableId === "ranking-values-droppable"
    ) {
      // Find the index of the subItem
      const blockSubItemId = result.source.droppableId;

      let parentPageIndex = -1;
      let parentSubItemIndex = -1;

      formValues.pages.forEach(
        (pageValue: PageBlockFields, pageIndex: number) => {
          pageValue.subItems.forEach(
            (subItemValue: FormSubItemFields, subItemIndex: number) => {
              if (subItemValue.subItemId === blockSubItemId) {
                parentSubItemIndex = subItemIndex;
                parentPageIndex = pageIndex;
              }
            }
          );
        }
      );

      if (parentSubItemIndex !== -1 && parentPageIndex !== -1) {
        setFieldValue(
          `pages[${parentPageIndex}].subItems[${parentSubItemIndex}].rankingValues`,
          reorder(
            (
              formValues.pages[parentPageIndex]?.subItems[
                parentSubItemIndex
              ] as RankingBlockFields
            ).rankingValues,
            result.source.index,
            result.destination.index
          )
        );

        if (
          (
            touched.pages?.[parentPageIndex]?.subItems?.[
              parentSubItemIndex
            ] as FormikTouched<RankingBlockFields>
          )?.rankingValues &&
          Array.isArray(
            (
              touched.pages?.[parentPageIndex]?.subItems?.[
                parentSubItemIndex
              ] as FormikTouched<RankingBlockFields>
            )?.rankingValues
          )
        ) {
          let newRankedValuesTouchedCopy = [
            // @ts-ignore
            ...(
              touched.pages?.[parentPageIndex]?.subItems?.[
                parentSubItemIndex
              ] as FormikTouched<RankingBlockFields>
            )?.rankingValues,
          ];

          if (
            result.destination.index >
            newRankedValuesTouchedCopy.length - 1
          ) {
            // Add 1 to the index as index starts from 0
            const emptySlotsToAdd =
              result.destination.index + 1 - newRankedValuesTouchedCopy.length;

            for (let i = 0; i <= emptySlotsToAdd - 1; i++) {
              newRankedValuesTouchedCopy.push({});
            }
          }

          setFieldTouched(
            `pages[${parentPageIndex}].subItems[${parentSubItemIndex}]`,
            // @ts-ignore
            reorder(
              newRankedValuesTouchedCopy,
              result.source.index,
              result.destination.index
            )
          );
        }
      }

      // setFieldValue(
      //   "pages",
      //   reorder(formValues.pages, result.source.index, result.destination.index)
      // );
    } else if (
      result.type === "droppableItem" &&
      result.source.droppableId === "form-blocks-droppable"
    ) {
      setFieldValue(
        "pages",
        reorder(formValues.pages, result.source.index, result.destination.index)
      );

      if (touched.pages && Array.isArray(touched.pages)) {
        let newPagesTouchedCopy = [...touched.pages];
        if (result.destination.index > newPagesTouchedCopy.length - 1) {
          // Add 1 to the index as index starts from 0
          const emptySlotsToAdd =
            result.destination.index + 1 - newPagesTouchedCopy.length;

          for (let i = 0; i <= emptySlotsToAdd - 1; i++) {
            newPagesTouchedCopy.push({});
          }
        }

        setFieldTouched(
          "pages",
          // @ts-ignore
          reorder(
            newPagesTouchedCopy,
            result.source.index,
            result.destination.index
          )
        );
      }
    } else if (
      result.type === "droppableItem" &&
      result.source.droppableId === "blocks-left-drawer-page"
    ) {
      let newPageValuesCopy = [...formValues.pages];

      if (result.destination.index === newPageValuesCopy.length) {
        newPageValuesCopy.push({
          ...INITIAL_PAGE_BLOCK_FIELDS,
          pageId: uuid(),
        });
      } else if (result.destination.index < newPageValuesCopy.length) {
        newPageValuesCopy.splice(result.destination.index, 0, {
          ...INITIAL_PAGE_BLOCK_FIELDS,
          pageId: uuid(),
        });
      }

      setFieldValue("pages", newPageValuesCopy);

      if (touched.pages && Array.isArray(touched.pages)) {
        let newPagesTouchedCopy = [...touched.pages];
        if (result.destination.index > newPagesTouchedCopy.length - 1) {
          // Add 1 to the index as index starts from 0
          const emptySlotsToAdd =
            result.destination.index + 1 - newPagesTouchedCopy.length;

          for (let i = 0; i <= emptySlotsToAdd - 1; i++) {
            newPagesTouchedCopy.push({});
          }
        }
        newPagesTouchedCopy.splice(result.destination.index, 0, {});

        // @ts-ignore
        setFieldTouched("pages", newPagesTouchedCopy);
      }
    } else if (
      result.type === "droppableActionPath" &&
      result.source.droppableId === "action-path-left-drawer"
    ) {
      if (touched.actionPaths && Array.isArray(touched.actionPaths)) {
        let newActionPathsTouchedCopy = [...touched.actionPaths];
        if (result.destination.index > newActionPathsTouchedCopy.length - 1) {
          // Add 1 to the index as index starts from 0
          const emptySlotsToAdd =
            result.destination.index + 1 - newActionPathsTouchedCopy.length;

          for (let i = 0; i <= emptySlotsToAdd - 1; i++) {
            newActionPathsTouchedCopy.push({});
          }
        }
        newActionPathsTouchedCopy.splice(result.destination.index, 0, {});

        // @ts-ignore
        setFieldTouched("actionPaths", newActionPathsTouchedCopy);
      }

      let newActionPathsValuesCopy = [...formValues.actionPaths];

      if (result.destination.index === newActionPathsValuesCopy.length) {
        newActionPathsValuesCopy.push({
          ...INITIAL_ACTION_PATH_ACTION_FIELDS,
          actionPathId: uuid(),
        });
      } else if (result.destination.index < newActionPathsValuesCopy.length) {
        newActionPathsValuesCopy.splice(result.destination.index, 0, {
          ...INITIAL_ACTION_PATH_ACTION_FIELDS,
          actionPathId: uuid(),
        });
      }

      setFieldValue("actionPaths", newActionPathsValuesCopy);
    } else if (
      result.type === "droppableSubItem" &&
      (result.source.droppableId === "blocks-left-drawer" ||
        result.source.droppableId === "blocks-left-drawer-typography")
    ) {
      // Handle dragging a block fro mthe side drawer
      // Find the index of the destination page in list of pages
      const destParentId = result.destination.droppableId;

      const destPageIndex = formValues.pages.findIndex((value) => {
        return value.pageId === destParentId;
      });

      let newPageValues = [...formValues.pages[destPageIndex].subItems];

      // TODO - Need to add Heading and PAragraph here
      const fieldBlockToAdd = {
        ...INITIAL_FORM_SUB_ITEM_FIELDS,
        ...(Object.values(QuestionTypes).includes(
          result.draggableId as QuestionTypes
        ) && {
          ...INITIAL_BLOCK_FIELDS,
          ...(result.draggableId === QuestionTypes.TEXT &&
            INITIAL_TEXT_BLOCK_FIELDS),
          ...(result.draggableId === QuestionTypes.CHECKBOXES && {
            ...INITIAL_CHECKBOXES_BLOCK_FIELDS,
            checkboxes: [
              { checkboxId: uuid(), value: "" },
              { checkboxId: uuid(), value: "" },
              { checkboxId: uuid(), value: "" },
              { checkboxId: uuid(), value: "" },
            ],
          }),
          ...(result.draggableId === QuestionTypes.STAR_RATING &&
            INITIAL_STAR_RATING_BLOCK_FIELDS),

          ...(result.draggableId === QuestionTypes.YESNO &&
            INITIAL_YES_NO_BLOCK_FIELDS),

          ...(result.draggableId === QuestionTypes.SCALE &&
            INITIAL_SCALE_BLOCK_FIELDS),

          ...(result.draggableId === QuestionTypes.SLIDER &&
            INITIAL_SLIDER_BLOCK_FIELDS),

          ...(result.draggableId === QuestionTypes.RANKING && {
            ...INITIAL_RANKING_BLOCK_FIELDS,
            rankingValues: [
              {
                rankingId: uuid(),
                value: "",
              },
              {
                rankingId: uuid(),
                value: "",
              },
            ],
          }),

          ...(result.draggableId === QuestionTypes.IMAGE_CHOICE && {
            ...INITIAL_IMAGE_CHOICE_BLOCK_FIELDS,
            imageChoiceValues: [
              {
                imageChoiceId: uuid(),
                imageUrl: "",
              },
            ],
          }),

          ...(result.draggableId === QuestionTypes.DATE &&
            INITIAL_DATE_BLOCK_FIELDS),

          ...(result.draggableId === QuestionTypes.RADIO_GROUP &&
            INITIAL_RADIO_GROUP_BLOCK_FIELDS),

          ...(result.draggableId === QuestionTypes.DROPDOWN &&
            INITIAL_DROPDOWN_BLOCK_FIELDS),

          ...(result.draggableId === QuestionTypes.EMAIL &&
            INITIAL_EMAIL_BLOCK_FIELDS),

          ...(result.draggableId === QuestionTypes.PHONE &&
            INITIAL_PHONE_BLOCK_FIELDS),

          ...(result.draggableId === QuestionTypes.LINK &&
            INITIAL_LINK_BLOCK_FIELDS),

          ...(result.draggableId === QuestionTypes.ADDRESS &&
            INITIAL_ADDRESS_BLOCK_FIELDS),
        }),

        ...(Object.values(FormSubBlockTypes).includes(
          result.draggableId as FormSubBlockTypes
        ) && {
          ...INITIAL_FORM_SUB_BLOCK_FIELDS,

          ...(result.draggableId === FormSubBlockTypes.HEADING &&
            INITIAL_HEADING_BLOCK_FIELDS),

          ...(result.draggableId === FormSubBlockTypes.PARAGRAPH &&
            INITIAL_PARAGRAPH_BLOCK_FIELDS),
        }),

        subItemId: uuid(),
      };

      // Insert new form block at index the card was dropped
      // @ts-ignore
      newPageValues.splice(result.destination.index, 0, fieldBlockToAdd);

      setFieldValue(`pages[${destPageIndex}].subItems`, newPageValues);
      if (
        touched.pages &&
        Array.isArray(touched.pages) &&
        touched.pages[destPageIndex]?.subItems &&
        Array.isArray(touched.pages[destPageIndex]?.subItems)
      ) {
        let newTouchedValuesCopy = [
          // @ts-ignore
          ...touched.pages[destPageIndex]?.subItems,
        ];

        if (result.destination.index > newTouchedValuesCopy.length - 1) {
          // Add 1 to the index as index starts from 0
          const emptySlotsToAdd =
            result.destination.index + 1 - newTouchedValuesCopy.length;

          for (let i = 0; i <= emptySlotsToAdd - 1; i++) {
            newTouchedValuesCopy.push({});
          }
        }

        newTouchedValuesCopy.splice(result.destination.index, 0, {});

        setFieldTouched(
          `pages[${destPageIndex}].subItems`,
          // @ts-ignore
          newTouchedValuesCopy
        );
      }
    } else if (
      result.type === "droppableAction" &&
      (result.source.droppableId === "actions-left-drawer" ||
        result.source.droppableId === "actions-left-drawer-typography")
    ) {
      // if you are dragging action block fro mleft drawer
      // Find the index of the destination page in list of pages
      const destParentId = result.destination.droppableId;

      const destActionPathIndexIndex = formValues.actionPaths.findIndex(
        (value) => {
          return value.actionPathId === destParentId;
        }
      );

      let newActionPathValues = [
        ...formValues.actionPaths[destActionPathIndexIndex].actions,
      ];

      const actionFieldsToAdd: ActionFields = {
        ...INITIAL_ACTION_FIELDS,
        ...(result.draggableId === Actions.REDIRECT &&
          INITIAL_REDIRECT_ACTION_FIELDS),
        ...(result.draggableId === Actions.FILE && INITIAL_FILE_ACTION_FIELDS),
        ...(result.draggableId === Actions.API_REQUEST &&
          INITIAL_API_REQUEST_ACTION_FIELDS),

        ...(result.draggableId === Actions.HEADING &&
          INITIAL_HEADING_ACTION_FIELDS),
        ...(result.draggableId === Actions.PARAGRAPH &&
          INITIAL_PARAGRAPH_ACTION_FIELDS),

        actionId: uuid(),
      };

      // Insert new form block at index the card was dropped
      newActionPathValues.splice(
        result.destination.index,
        0,
        actionFieldsToAdd
      );

      setFieldValue(
        `actionPaths[${destActionPathIndexIndex}].actions`,
        newActionPathValues
      );
      if (
        touched.actionPaths &&
        Array.isArray(touched.actionPaths) &&
        touched.actionPaths[destActionPathIndexIndex]?.actions &&
        Array.isArray(touched.actionPaths[destActionPathIndexIndex]?.actions)
      ) {
        let newTouchedValuesCopy = [
          // @ts-ignore
          ...touched.actionPaths[destActionPathIndexIndex]?.actions,
        ];

        if (result.destination.index > newTouchedValuesCopy.length - 1) {
          // Add 1 to the index as index starts from 0
          const emptySlotsToAdd =
            result.destination.index + 1 - newTouchedValuesCopy.length;

          for (let i = 0; i <= emptySlotsToAdd - 1; i++) {
            newTouchedValuesCopy.push({});
          }
        }

        newTouchedValuesCopy.splice(result.destination.index, 0, {});

        setFieldTouched(
          `actionPaths[${destActionPathIndexIndex}].actions`,
          // @ts-ignore
          newTouchedValuesCopy
        );
      }
    } else if (result.type === "droppableSubItem") {
      // If you are dragging a form block

      // We set project.id to be the its droppable id aswell
      const sourceParentId = result.source.droppableId;
      const destParentId = result.destination.droppableId;

      // Find the index of the source page in list of pages
      const sourcePageIndex = formValues.pages.findIndex((value) => {
        return value.pageId === sourceParentId;
      });

      // Find the index of the destination page in list of pages
      const destPageIndex = formValues.pages.findIndex((value) => {
        return value.pageId === destParentId;
      });

      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        reorderValuesInSameItem(
          `pages[${sourcePageIndex}].subItems`,
          formValues.pages[sourcePageIndex]?.subItems,
          result.source.index,
          result.destination.index,
          setFieldValue
        );

        if (
          sourcePageIndex !== -1 &&
          touched.pages &&
          Array.isArray(touched.pages) &&
          touched.pages[sourcePageIndex]?.subItems &&
          Array.isArray(touched.pages[sourcePageIndex]?.subItems)
        ) {
          reorderTouchedInSameItem(
            `pages[${sourcePageIndex}].subItems`,
            touched.pages[sourcePageIndex].subItems!,
            result.source.index,
            result.destination.index,
            setFieldTouched
          );
        }
      } else {
        moveValueBetweenItems(
          `pages[${sourcePageIndex}].subItems`,
          `pages[${destPageIndex}].subItems`,
          formValues.pages[sourcePageIndex]?.subItems,
          formValues.pages[destPageIndex]?.subItems,
          result.source.index,
          result.destination.index,
          setFieldValue
        );

        if (
          sourcePageIndex !== -1 &&
          destPageIndex !== -1 &&
          touched.pages &&
          Array.isArray(touched.pages)
        ) {
          if (
            touched.pages[sourcePageIndex]?.subItems &&
            Array.isArray(touched.pages[sourcePageIndex]?.subItems) &&
            touched.pages[destPageIndex]?.subItems &&
            Array.isArray(touched.pages[destPageIndex]?.subItems)
          ) {
            moveTouchedBetweenExistingTouchedSubItems(
              `pages[${sourcePageIndex}].subItems`,
              `pages[${destPageIndex}].subItems`,
              touched.pages[sourcePageIndex]?.subItems!,
              touched.pages[destPageIndex]?.subItems!,
              result.source.index,
              result.destination.index,
              setFieldTouched
            );
          } else if (
            touched.pages[sourcePageIndex]?.subItems &&
            Array.isArray(touched.pages[sourcePageIndex]?.subItems) &&
            !touched.pages[destPageIndex]?.subItems &&
            !Array.isArray(touched.pages[destPageIndex]?.subItems)
          ) {
            moveTouchedToEmptyTouchedSubItem(
              `pages[${sourcePageIndex}].subItems`,
              `pages[${destPageIndex}].subItems`,
              touched.pages[sourcePageIndex]?.subItems!,
              result.source.index,
              result.destination.index,
              setFieldTouched
            );
          } else if (
            !touched.pages[sourcePageIndex]?.subItems &&
            !Array.isArray(touched.pages[sourcePageIndex]?.subItems) &&
            touched.pages[destPageIndex]?.subItems &&
            Array.isArray(touched.pages[destPageIndex]?.subItems)
          ) {
            addEmptyTouched(
              `pages[${destPageIndex}].subItems`,
              touched.pages[destPageIndex]?.subItems!,
              result.destination.index,
              setFieldTouched
            );
          }
        }
      }
    } // If dragging an entire action path
    else if (result.type === "droppableActionPath") {
      setFieldValue(
        "actionPaths",
        reorder(
          formValues.actionPaths,
          result.source.index,
          result.destination.index
        )
      );

      if (touched.actionPaths && Array.isArray(touched.actionPaths)) {
        let newActionPathsTouchedCopy = [...touched.actionPaths];
        if (result.destination.index > newActionPathsTouchedCopy.length - 1) {
          // Add 1 to the index as index starts from 0
          const emptySlotsToAdd =
            result.destination.index + 1 - newActionPathsTouchedCopy.length;

          for (let i = 0; i <= emptySlotsToAdd - 1; i++) {
            newActionPathsTouchedCopy.push({});
          }
        }

        setFieldTouched(
          "actionPaths",
          // @ts-ignore
          reorder(
            newActionPathsTouchedCopy,
            result.source.index,
            result.destination.index
          )
        );
      }
    } else if (result.type === "droppableAction") {
      // If you are dragging an action block

      // We set project.id to be the its droppable id aswell
      const sourceParentId = result.source.droppableId;
      const destParentId = result.destination.droppableId;

      // Find the index of the source page in list of pages
      const sourcePageIndex = formValues.actionPaths.findIndex((value) => {
        return value.actionPathId === sourceParentId;
      });

      // Find the index of the destination page in list of pages
      const destPageIndex = formValues.actionPaths.findIndex((value) => {
        return value.actionPathId === destParentId;
      });

      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        reorderValuesInSameItem(
          `actionPaths[${sourcePageIndex}].actions`,
          formValues.actionPaths[sourcePageIndex]?.actions,
          result.source.index,
          result.destination.index,
          setFieldValue
        );

        if (
          sourcePageIndex !== -1 &&
          touched.pages &&
          Array.isArray(touched.actionPaths) &&
          touched.actionPaths[sourcePageIndex]?.actions &&
          Array.isArray(touched.actionPaths[sourcePageIndex]?.actions)
        ) {
          reorderTouchedInSameItem(
            `actionPaths[${sourcePageIndex}].actions`,
            touched.actionPaths[sourcePageIndex].actions!,
            result.source.index,
            result.destination.index,
            setFieldTouched
          );
        }
      } else {
        moveValueBetweenItems(
          `actionPaths[${sourcePageIndex}].actions`,
          `actionPaths[${destPageIndex}].actions`,
          formValues.actionPaths[sourcePageIndex]?.actions,
          formValues.actionPaths[destPageIndex]?.actions,
          result.source.index,
          result.destination.index,
          setFieldValue
        );

        if (
          sourcePageIndex !== -1 &&
          destPageIndex !== -1 &&
          touched.actionPaths &&
          Array.isArray(touched.actionPaths)
        ) {
          if (
            touched.actionPaths[sourcePageIndex]?.actions &&
            Array.isArray(touched.actionPaths[sourcePageIndex]?.actions) &&
            touched.actionPaths[destPageIndex]?.actions &&
            Array.isArray(touched.actionPaths[destPageIndex]?.actions)
          ) {
            moveTouchedBetweenExistingTouchedSubItems(
              `actionPaths[${sourcePageIndex}].actions`,
              `actionPaths[${destPageIndex}].actions`,
              touched.actionPaths[sourcePageIndex]?.actions!,
              touched.actionPaths[destPageIndex]?.actions!,
              result.source.index,
              result.destination.index,
              setFieldTouched
            );
          } else if (
            touched.actionPaths[sourcePageIndex]?.actions &&
            Array.isArray(touched.actionPaths[sourcePageIndex]?.actions) &&
            !touched.actionPaths[destPageIndex]?.actions &&
            !Array.isArray(touched.actionPaths[destPageIndex]?.actions)
          ) {
            moveTouchedToEmptyTouchedSubItem(
              `actionPaths[${sourcePageIndex}].actions`,
              `actionPaths[${destPageIndex}].actions`,
              touched.actionPaths[sourcePageIndex]?.actions!,
              result.source.index,
              result.destination.index,
              setFieldTouched
            );
          } else if (
            !touched.actionPaths[sourcePageIndex]?.actions &&
            !Array.isArray(touched.actionPaths[sourcePageIndex]?.actions) &&
            touched.actionPaths[destPageIndex]?.actions &&
            Array.isArray(touched.actionPaths[destPageIndex]?.actions)
          ) {
            addEmptyTouched(
              `actionPaths[${destPageIndex}].actions`,
              touched.actionPaths[destPageIndex]?.actions!,
              result.destination.index,
              setFieldTouched
            );
          }
        }
      }
    } else if (result.type === "leftDrawerBlock") {
      const blockType = result.draggableId;
    }
  };

  // Add an empty touched object to array of sub item objects
  const addEmptyTouched = (
    fieldName: string,
    currentTouched: FormikTouched<QuestionFields | ActionFields>[],
    indexToInsertAt: number,
    setFieldTouched: (
      field: string,
      isTouched?: boolean | undefined,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    let currentTouchedCopy = [...currentTouched];

    // Setting by splice or index does not really matter as touched in source is empty anyway
    currentTouchedCopy.splice(indexToInsertAt, 0, {});

    setFieldTouched(
      fieldName,
      // @ts-ignore
      currentTouchedCopy
    );
  };

  // WORKS
  const moveTouchedToEmptyTouchedSubItem = (
    sourceFieldName: string,
    destinationFieldName: string,
    currentSourceTouched: FormikTouched<QuestionFields | ActionFields>[],
    indexToMoveFrom: number,
    indexToMoveTo: number,
    setFieldTouched: (
      field: string,
      isTouched?: boolean | undefined,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    let currentSourceTouchedCopy = [...currentSourceTouched];

    const [draggedTouchedSubItme] = currentSourceTouchedCopy.splice(
      indexToMoveFrom,
      1
    );

    let newDestinationTouched: FormikTouched<QuestionFields | ActionFields>[] =
      [];

    newDestinationTouched[indexToMoveTo] = draggedTouchedSubItme;

    setFieldTouched(
      sourceFieldName,
      // @ts-ignore
      currentSourceTouchedCopy
    );

    setFieldTouched(
      destinationFieldName,
      // @ts-ignore
      newDestinationTouched
    );
  };

  const moveTouchedBetweenExistingTouchedSubItems = (
    sourceFieldName: string,
    destinationFieldName: string,
    currentSourceTouched: FormikTouched<QuestionFields | ActionFields>[],
    currentDestinationTouched: FormikTouched<QuestionFields | ActionFields>[],
    indexToMoveFrom: number,
    indexToMoveTo: number,
    setFieldTouched: (
      field: string,
      isTouched?: boolean | undefined,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    let newSourceTocuhedCopy = [...currentSourceTouched];

    let newDestinationTocuhedCopy = [...currentDestinationTouched];

    const [draggedTouchedSubItme] = newSourceTocuhedCopy.splice(
      indexToMoveFrom,
      1
    );

    if (indexToMoveTo < newDestinationTocuhedCopy.length) {
      newDestinationTocuhedCopy.splice(indexToMoveTo, 0, draggedTouchedSubItme);
    } else {
      newDestinationTocuhedCopy[indexToMoveTo] = draggedTouchedSubItme;
    }

    setFieldTouched(
      sourceFieldName,
      // @ts-ignore
      newSourceTocuhedCopy
    );

    setFieldTouched(
      destinationFieldName,
      // @ts-ignore
      newDestinationTocuhedCopy
    );
  };

  const reorderTouchedInSameItem = (
    fieldName: string,
    currentSourceTouched: FormikTouched<QuestionFields | ActionFields>[],
    indexToMoveFrom: number,
    indexToMoveTo: number,
    setFieldTouched: (
      field: string,
      isTouched?: boolean | undefined,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    let newSourceTouchedCopy = [...currentSourceTouched];

    // Iif dragging to a destination for which a touched vlaue does not exist yet.
    if (indexToMoveTo > newSourceTouchedCopy.length - 1) {
      // Add 1 to the index as index starts from 0
      const emptySlotsToAdd = indexToMoveTo + 1 - newSourceTouchedCopy.length;

      for (let i = 0; i <= emptySlotsToAdd - 1; i++) {
        newSourceTouchedCopy.push({});
      }
    }

    setFieldTouched(
      fieldName,
      // @ts-ignore
      [...reorder(newSourceTouchedCopy, indexToMoveFrom, indexToMoveTo)]
    );
  };

  const reorderValuesInSameItem = (
    fieldName: string,
    currentSourceSubItemValues: FormSubItemFields[] | ActionFields[],
    indexToMoveFrom: number,
    indexToMoveTo: number,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<IFormCanvasBlocksFields>>
  ) => {
    let newSubItemValuesCopy = [...currentSourceSubItemValues];

    const reorderedSubItems = reorder(
      newSubItemValuesCopy,
      indexToMoveFrom,
      indexToMoveTo
    );

    setFieldValue(fieldName, reorderedSubItems);
  };

  const moveValueBetweenItems = (
    sourceFieldName: string,
    destinationFieldName: string,
    currentSourceItems: FormSubItemFields[] | ActionFields[],
    currentDestinationItems: FormSubItemFields[] | ActionFields[],
    indexToMoveFrom: number,
    indexToMoveTo: number,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<IFormCanvasBlocksFields>>
  ) => {
    let newSourceItemsCopy = [...currentSourceItems];

    const [draggedItem] = newSourceItemsCopy.splice(indexToMoveFrom, 1);

    let newDestinationItemsCopy = [...currentDestinationItems];

    newDestinationItemsCopy.splice(indexToMoveTo, 0, draggedItem);

    setFieldValue(sourceFieldName, newSourceItemsCopy);
    setFieldValue(destinationFieldName, newDestinationItemsCopy);
  };

  return (
    <>
      <div style={{ overflowY: "scroll", height: "100vh" }}>
        <Formik
          // initialValues={INITIAL_FORM_STATE as IFormCanvasBlocksFields}
          initialValues={{ ...initialValues } as IFormCanvasBlocksFields}
          validationSchema={FORM_VALIDATION}
          onSubmit={async (values: any) => {
            // await ProtectedApi.patch(
            //   `${SURVEY_DRAFTS_URL}/${currentFormId}`,
            //   values
            // );
          }}
          validateOnChange
          validateOnBlur
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
          }: FormikProps<IFormCanvasBlocksFields>) => {
            return (
              <Form>
                <BuilderHeader
                  formTitleName="title"
                  currentFormId={currentFormId}
                  publishedSurveyIdentifier={publishedSurveyIdentifier}
                />
                <DragDropContext
                  onDragEnd={(
                    result: DropResult,
                    provided: ResponderProvided
                  ) => {
                    onDragEndHandler(
                      result,
                      provided,
                      values,
                      setFieldValue,
                      touched,
                      setFieldTouched
                    );
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      height: "100%",
                      // minHeight: "100vh",
                      // backgroundColor: "#f6f6f6",
                    }}
                  >
                    <BuilderDrawer
                      hideMainDrawer={!showMainDrawer}
                      miniDrawerButtons={MINI_DRAWER_BUTTONS}
                      currentMainDrawerPanel={currentMainDrawerPanel}
                      updateMainDrawerPanel={updateMainDrawerPanel}
                    >
                      {currentMainDrawerPanel ===
                        BuilderDrawerPanels.BLOCKS && (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{ mx: 1, my: 1 }}
                              variant="body2"
                              color="GrayText"
                            >
                              Questions
                            </Typography>
                            <InfoTooltip title="Below are the types of questions you can ask respondants. You can drag a question onto a page or by pressing the 'Add' button to add the question to the last page." />
                          </Box>

                          <StrictModeDroppable
                            droppableId={"blocks-left-drawer"}
                            type={`droppableSubItem`}
                            isDropDisabled={true}
                          >
                            {(provided) => {
                              return (
                                <Box
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {FORM_BLOCKS_LIST.map(
                                    (
                                      blockCard: BlockCard,
                                      blockcardIndex: number
                                    ) => {
                                      return (
                                        <Draggable
                                          key={blockCard.type}
                                          draggableId={blockCard.type}
                                          index={blockcardIndex}
                                        >
                                          {(provided, snapshot) => {
                                            return (
                                              <>
                                                <Box
                                                  {...provided.draggableProps}
                                                  // {...provided.dragHandleProps}
                                                  ref={provided.innerRef}
                                                >
                                                  <DrawerListCard
                                                    dragHandleProps={
                                                      provided.dragHandleProps
                                                    }
                                                    title={blockCard.label}
                                                    icon={blockCard.icon}
                                                    addHandler={async () => {
                                                      const fieldBlockToAdd = {
                                                        ...(blockCard.type ===
                                                          QuestionTypes.TEXT &&
                                                          INITIAL_TEXT_BLOCK_FIELDS),
                                                        ...(blockCard.type ===
                                                          QuestionTypes.CHECKBOXES && {
                                                          ...INITIAL_CHECKBOXES_BLOCK_FIELDS,
                                                          checkboxes: [
                                                            {
                                                              checkboxId:
                                                                uuid(),
                                                              value: "",
                                                            },
                                                            {
                                                              checkboxId:
                                                                uuid(),
                                                              value: "",
                                                            },
                                                            {
                                                              checkboxId:
                                                                uuid(),
                                                              value: "",
                                                            },
                                                            {
                                                              checkboxId:
                                                                uuid(),
                                                              value: "",
                                                            },
                                                          ],
                                                        }),
                                                        ...(blockCard.type ===
                                                          QuestionTypes.STAR_RATING &&
                                                          INITIAL_STAR_RATING_BLOCK_FIELDS),

                                                        ...(blockCard.type ===
                                                          QuestionTypes.SCALE &&
                                                          INITIAL_SCALE_BLOCK_FIELDS),

                                                        ...(blockCard.type ===
                                                          QuestionTypes.YESNO &&
                                                          INITIAL_YES_NO_BLOCK_FIELDS),

                                                        ...(blockCard.type ===
                                                          QuestionTypes.SLIDER &&
                                                          INITIAL_SLIDER_BLOCK_FIELDS),

                                                        ...(blockCard.type ===
                                                          QuestionTypes.RANKING && {
                                                          ...INITIAL_RANKING_BLOCK_FIELDS,
                                                          rankingValues: [
                                                            {
                                                              rankingId: uuid(),
                                                              value: "",
                                                            },
                                                            {
                                                              rankingId: uuid(),
                                                              value: "",
                                                            },
                                                          ],
                                                        }),

                                                        ...(blockCard.type ===
                                                          QuestionTypes.IMAGE_CHOICE && {
                                                          ...INITIAL_IMAGE_CHOICE_BLOCK_FIELDS,
                                                          imageChoiceValues: [
                                                            {
                                                              imageChoiceId:
                                                                uuid(),
                                                              imageUrl: "",
                                                            },
                                                          ],
                                                        }),

                                                        ...(blockCard.type ===
                                                          QuestionTypes.DATE &&
                                                          INITIAL_DATE_BLOCK_FIELDS),

                                                        ...(blockCard.type ===
                                                          QuestionTypes.RADIO_GROUP &&
                                                          INITIAL_RADIO_GROUP_BLOCK_FIELDS),

                                                        ...(blockCard.type ===
                                                          QuestionTypes.DROPDOWN &&
                                                          INITIAL_DROPDOWN_BLOCK_FIELDS),

                                                        ...(blockCard.type ===
                                                          QuestionTypes.EMAIL &&
                                                          INITIAL_EMAIL_BLOCK_FIELDS),

                                                        ...(blockCard.type ===
                                                          QuestionTypes.PHONE &&
                                                          INITIAL_PHONE_BLOCK_FIELDS),

                                                        ...(blockCard.type ===
                                                          QuestionTypes.LINK &&
                                                          INITIAL_LINK_BLOCK_FIELDS),

                                                        ...(blockCard.type ===
                                                          QuestionTypes.ADDRESS &&
                                                          INITIAL_ADDRESS_BLOCK_FIELDS),

                                                        ...(blockCard.type ===
                                                          FormSubBlockTypes.HEADING &&
                                                          INITIAL_HEADING_BLOCK_FIELDS),

                                                        ...(blockCard.type ===
                                                          FormSubBlockTypes.PARAGRAPH &&
                                                          INITIAL_PARAGRAPH_BLOCK_FIELDS),

                                                        subItemId: uuid(),
                                                      };

                                                      if (
                                                        values.pages.length > 0
                                                      ) {
                                                        setFieldValue(
                                                          `pages[${
                                                            values.pages
                                                              .length - 1
                                                          }].subItems`,
                                                          [
                                                            ...values.pages[
                                                              values.pages
                                                                .length - 1
                                                            ].subItems,
                                                            fieldBlockToAdd,
                                                          ]
                                                        );
                                                        scrollToLastFormPage(
                                                          values.pages.length -
                                                            1
                                                        );
                                                      } else {
                                                        setFieldValue(`pages`, [
                                                          {
                                                            ...INITIAL_PAGE_BLOCK_FIELDS,
                                                            pageId: uuid(),
                                                            subItems: [
                                                              fieldBlockToAdd,
                                                            ],
                                                          },
                                                        ]);

                                                        await delay(10);

                                                        // Scroll to the first page
                                                        scrollToLastFormPage(0);
                                                      }
                                                    }}
                                                  />
                                                </Box>
                                                {snapshot.isDragging && (
                                                  <DrawerListCard
                                                    title={blockCard.label}
                                                    icon={blockCard.icon}
                                                    addHandler={() => {}}
                                                  />
                                                )}
                                              </>
                                            );
                                          }}
                                        </Draggable>
                                      );
                                    }
                                  )}
                                  {/* {provided.placeholder} */}
                                </Box>
                              );
                            }}
                          </StrictModeDroppable>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{ mx: 1, my: 1 }}
                              variant="body2"
                              color="GrayText"
                            >
                              Typography
                            </Typography>
                            <InfoTooltip title="Use 'Heading' and 'Paragraph' blocks to provide more information to respondants." />
                          </Box>
                          <StrictModeDroppable
                            droppableId={"blocks-left-drawer-typography"}
                            type={`droppableSubItem`}
                            isDropDisabled={true}
                          >
                            {(provided) => {
                              return (
                                <Box
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {TYPOGRAPHY_FORM_BLOCKS_LIST.map(
                                    (
                                      blockCard: BlockCard,
                                      blockcardIndex: number
                                    ) => {
                                      return (
                                        <Draggable
                                          key={blockCard.type}
                                          draggableId={blockCard.type}
                                          index={blockcardIndex}
                                        >
                                          {(provided, snapshot) => {
                                            return (
                                              <>
                                                <Box
                                                  {...provided.draggableProps}
                                                  // {...provided.dragHandleProps}
                                                  ref={provided.innerRef}
                                                >
                                                  <DrawerListCard
                                                    dragHandleProps={
                                                      provided.dragHandleProps
                                                    }
                                                    title={blockCard.label}
                                                    icon={blockCard.icon}
                                                    addHandler={async () => {
                                                      const fieldBlockToAdd = {
                                                        ...(blockCard.type ===
                                                          QuestionTypes.TEXT &&
                                                          INITIAL_TEXT_BLOCK_FIELDS),

                                                        ...(blockCard.type ===
                                                          FormSubBlockTypes.HEADING &&
                                                          INITIAL_HEADING_BLOCK_FIELDS),

                                                        ...(blockCard.type ===
                                                          FormSubBlockTypes.PARAGRAPH &&
                                                          INITIAL_PARAGRAPH_BLOCK_FIELDS),

                                                        subItemId: uuid(),
                                                      };

                                                      if (
                                                        values.pages.length > 0
                                                      ) {
                                                        setFieldValue(
                                                          `pages[${
                                                            values.pages
                                                              .length - 1
                                                          }].subItems`,
                                                          [
                                                            ...values.pages[
                                                              values.pages
                                                                .length - 1
                                                            ].subItems,
                                                            fieldBlockToAdd,
                                                          ]
                                                        );
                                                        scrollToLastFormPage(
                                                          values.pages.length -
                                                            1
                                                        );
                                                      } else {
                                                        setFieldValue(`pages`, [
                                                          {
                                                            ...INITIAL_PAGE_BLOCK_FIELDS,
                                                            pageId: uuid(),
                                                            subItems: [
                                                              fieldBlockToAdd,
                                                            ],
                                                          },
                                                        ]);

                                                        await delay(10);

                                                        // Scroll to the first page
                                                        scrollToLastFormPage(0);
                                                      }
                                                    }}
                                                  />
                                                </Box>
                                                {snapshot.isDragging && (
                                                  <DrawerListCard
                                                    title={blockCard.label}
                                                    icon={blockCard.icon}
                                                    addHandler={() => {}}
                                                  />
                                                )}
                                              </>
                                            );
                                          }}
                                        </Draggable>
                                      );
                                    }
                                  )}
                                  {/* {provided.placeholder} */}
                                </Box>
                              );
                            }}
                          </StrictModeDroppable>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{ mx: 1, my: 1 }}
                              variant="body2"
                              color="GrayText"
                            >
                              Structure
                            </Typography>
                            <InfoTooltip title="You can add different 'Pages' to your form and have different Questions on different Pages." />
                          </Box>
                          <StrictModeDroppable
                            droppableId={"blocks-left-drawer-page"}
                            type={`droppableItem`}
                            isDropDisabled={true}
                          >
                            {(provided) => {
                              return (
                                <Box
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {FORM_PAGE_BLOCKS_LIST.map(
                                    (
                                      blockCard: BlockCard,
                                      blockcardIndex: number
                                    ) => {
                                      return (
                                        <Draggable
                                          key={blockCard.type}
                                          draggableId={blockCard.type}
                                          index={blockcardIndex}
                                        >
                                          {(provided, snapshot) => {
                                            return (
                                              <>
                                                <Box
                                                  {...provided.draggableProps}
                                                  ref={provided.innerRef}
                                                >
                                                  <DrawerListCard
                                                    dragHandleProps={
                                                      provided.dragHandleProps
                                                    }
                                                    title={blockCard.label}
                                                    icon={blockCard.icon}
                                                    addHandler={async () => {
                                                      // Add to the last page
                                                      if (
                                                        blockCard.type ===
                                                        Blocks.PAGE
                                                      ) {
                                                        setFieldValue(`pages`, [
                                                          ...values.pages,
                                                          {
                                                            ...INITIAL_PAGE_BLOCK_FIELDS,
                                                            pageId: uuid(),
                                                          },
                                                        ]);

                                                        /*
                                                          NOTE : We are adding this delay becuase without it the state
                                                          does not update in time for there to actually be the right amount
                                                          of child components in ref for us to scroll to so by introducing a 
                                                          delay we insure that child compoenents will have the right length
                                                          Since we are adding one and it won't be ready yet we can provide the value
                                                          of the length rather than -1 of the length
                                                        */
                                                        await delay(10);

                                                        scrollToLastFormPage(
                                                          values.pages.length
                                                        );
                                                      }
                                                    }}
                                                  />
                                                </Box>
                                                {snapshot.isDragging && (
                                                  <DrawerListCard
                                                    title={blockCard.label}
                                                    icon={blockCard.icon}
                                                    addHandler={() => {}}
                                                  />
                                                )}
                                              </>
                                            );
                                          }}
                                        </Draggable>
                                      );
                                    }
                                  )}
                                  {/* {provided.placeholder} */}
                                </Box>
                              );
                            }}
                          </StrictModeDroppable>
                        </>
                      )}
                      {currentMainDrawerPanel ===
                        BuilderDrawerPanels.ACTIONS && (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{ mx: 1, my: 1 }}
                              variant="body2"
                              color="GrayText"
                            >
                              Actions
                            </Typography>
                            <InfoTooltip title="You can use 'Actions' to control the experience of respondants when they complete your form." />
                          </Box>

                          <StrictModeDroppable
                            droppableId={"actions-left-drawer"}
                            type={`droppableAction`}
                            isDropDisabled={true}
                          >
                            {(provided) => {
                              return (
                                <Box
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {ACTIONS_LIST.map(
                                    (
                                      actionCard: ActionCard,
                                      actionCardIndex: number
                                    ) => {
                                      return (
                                        <Draggable
                                          key={actionCard.actionType}
                                          draggableId={actionCard.actionType}
                                          index={actionCardIndex}
                                        >
                                          {(provided, snapshot) => {
                                            return (
                                              <>
                                                <Box
                                                  {...provided.draggableProps}
                                                  ref={provided.innerRef}
                                                >
                                                  <DrawerListCard
                                                    dragHandleProps={
                                                      provided.dragHandleProps
                                                    }
                                                    title={actionCard.label}
                                                    icon={actionCard.icon}
                                                    addHandler={async () => {
                                                      const actionFieldsToAdd =
                                                        {
                                                          ...(actionCard.actionType ===
                                                            Actions.REDIRECT &&
                                                            INITIAL_REDIRECT_ACTION_FIELDS),
                                                          ...(actionCard.actionType ===
                                                            Actions.FILE &&
                                                            INITIAL_FILE_ACTION_FIELDS),
                                                          ...(actionCard.actionType ===
                                                            Actions.API_REQUEST &&
                                                            INITIAL_API_REQUEST_ACTION_FIELDS),

                                                          ...(actionCard.actionType ===
                                                            Actions.HEADING &&
                                                            INITIAL_HEADING_ACTION_FIELDS),
                                                          ...(actionCard.actionType ===
                                                            Actions.PARAGRAPH &&
                                                            INITIAL_PARAGRAPH_ACTION_FIELDS),
                                                          actionId: uuid(),
                                                        };

                                                      if (
                                                        values.actionPaths
                                                          .length > 0
                                                      ) {
                                                        setFieldValue(
                                                          `actionPaths[${
                                                            values.actionPaths
                                                              .length - 1
                                                          }].actions`,
                                                          [
                                                            ...values
                                                              .actionPaths[
                                                              values.actionPaths
                                                                .length - 1
                                                            ].actions,
                                                            actionFieldsToAdd,
                                                          ]
                                                        );

                                                        scrollToLastActionPath(
                                                          values.actionPaths
                                                            .length - 1
                                                        );
                                                      } else {
                                                        setFieldValue(
                                                          `actionPaths`,
                                                          [
                                                            {
                                                              ...INITIAL_ACTION_PATH_ACTION_FIELDS,
                                                              actionPathId:
                                                                uuid(),
                                                              actions: [
                                                                actionFieldsToAdd,
                                                              ],
                                                            },
                                                          ]
                                                        );

                                                        await delay(10);

                                                        // Scroll to the first action path
                                                        scrollToLastActionPath(
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                </Box>
                                                {snapshot.isDragging && (
                                                  <DrawerListCard
                                                    title={actionCard.label}
                                                    icon={actionCard.icon}
                                                    addHandler={() => {}}
                                                  />
                                                )}
                                              </>
                                            );
                                          }}
                                        </Draggable>
                                      );
                                    }
                                  )}
                                  {/* {provided.placeholder} */}
                                </Box>
                              );
                            }}
                          </StrictModeDroppable>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{ mx: 1, my: 1 }}
                              variant="body2"
                              color="GrayText"
                            >
                              Typography
                            </Typography>
                            <InfoTooltip title="Use 'Heading' and 'Paragraph' blocks to provide more information to respondants when they compelte your form." />
                          </Box>

                          <StrictModeDroppable
                            droppableId={"actions-left-drawer-typography"}
                            type={`droppableAction`}
                            isDropDisabled={true}
                          >
                            {(provided) => {
                              return (
                                <Box
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {TYPOGRAPHY_ACTIONS_LIST.map(
                                    (
                                      actionCard: ActionCard,
                                      actionCardIndex: number
                                    ) => {
                                      return (
                                        <Draggable
                                          key={actionCard.actionType}
                                          draggableId={actionCard.actionType}
                                          index={actionCardIndex}
                                        >
                                          {(provided, snapshot) => {
                                            return (
                                              <>
                                                <Box
                                                  {...provided.draggableProps}
                                                  ref={provided.innerRef}
                                                >
                                                  <DrawerListCard
                                                    dragHandleProps={
                                                      provided.dragHandleProps
                                                    }
                                                    title={actionCard.label}
                                                    icon={actionCard.icon}
                                                    addHandler={async () => {
                                                      const actionFieldsToAdd =
                                                        {
                                                          ...(actionCard.actionType ===
                                                            Actions.HEADING &&
                                                            INITIAL_HEADING_ACTION_FIELDS),
                                                          ...(actionCard.actionType ===
                                                            Actions.PARAGRAPH &&
                                                            INITIAL_PARAGRAPH_ACTION_FIELDS),
                                                          actionId: uuid(),
                                                        };

                                                      if (
                                                        values.actionPaths
                                                          .length > 0
                                                      ) {
                                                        setFieldValue(
                                                          `actionPaths[${
                                                            values.actionPaths
                                                              .length - 1
                                                          }].actions`,
                                                          [
                                                            ...values
                                                              .actionPaths[
                                                              values.actionPaths
                                                                .length - 1
                                                            ].actions,
                                                            actionFieldsToAdd,
                                                          ]
                                                        );

                                                        scrollToLastActionPath(
                                                          values.actionPaths
                                                            .length - 1
                                                        );
                                                      } else {
                                                        setFieldValue(
                                                          `actionPaths`,
                                                          [
                                                            {
                                                              ...INITIAL_ACTION_PATH_ACTION_FIELDS,
                                                              actionPathId:
                                                                uuid(),
                                                              actions: [
                                                                actionFieldsToAdd,
                                                              ],
                                                            },
                                                          ]
                                                        );

                                                        await delay(10);

                                                        // Scroll to the first action path
                                                        scrollToLastActionPath(
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                </Box>
                                                {snapshot.isDragging && (
                                                  <DrawerListCard
                                                    title={actionCard.label}
                                                    icon={actionCard.icon}
                                                    addHandler={() => {}}
                                                  />
                                                )}
                                              </>
                                            );
                                          }}
                                        </Draggable>
                                      );
                                    }
                                  )}
                                  {/* {provided.placeholder} */}
                                </Box>
                              );
                            }}
                          </StrictModeDroppable>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{ mx: 1, my: 1 }}
                              variant="body2"
                              color="GrayText"
                            >
                              Structure
                            </Typography>
                            <InfoTooltip title="You can use 'Action Paths' as the different screens that are shown to respondants when they complete your form." />
                          </Box>

                          <StrictModeDroppable
                            droppableId={"action-path-left-drawer"}
                            type={`droppableActionPath`}
                            isDropDisabled={true}
                          >
                            {(provided) => {
                              return (
                                <Box
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {ACTION_PATH_LIST.map(
                                    (
                                      actionCard: ActionCard,
                                      actionCardIndex: number
                                    ) => {
                                      return (
                                        <Draggable
                                          key={actionCard.actionType}
                                          draggableId={actionCard.actionType}
                                          index={actionCardIndex}
                                        >
                                          {(provided, snapshot) => {
                                            return (
                                              <>
                                                <Box
                                                  {...provided.draggableProps}
                                                  ref={provided.innerRef}
                                                >
                                                  <DrawerListCard
                                                    dragHandleProps={
                                                      provided.dragHandleProps
                                                    }
                                                    title={actionCard.label}
                                                    icon={actionCard.icon}
                                                    addHandler={async () => {
                                                      setFieldValue(
                                                        `actionPaths`,
                                                        [
                                                          ...values.actionPaths,
                                                          {
                                                            ...INITIAL_ACTION_PATH_ACTION_FIELDS,
                                                            actionPathId:
                                                              uuid(),
                                                          },
                                                        ]
                                                      );

                                                      /*
                                                      NOTE : We are adding this delay becuase without it the state
                                                      does not update in time for there to actually be the right amount
                                                      of child components in ref for us to scroll to so by introducing a 
                                                      delay we insure that child compoenents will have the right length
                                                      Since we are adding one and it won't be ready yet we can provide the value
                                                      of the length rather than -1 of the length
                                                    */
                                                      await delay(10);

                                                      scrollToLastActionPath(
                                                        values.actionPaths
                                                          .length
                                                      );
                                                    }}
                                                  />
                                                </Box>
                                                {snapshot.isDragging && (
                                                  <DrawerListCard
                                                    title={actionCard.label}
                                                    icon={actionCard.icon}
                                                    addHandler={() => {}}
                                                  />
                                                )}
                                              </>
                                            );
                                          }}
                                        </Draggable>
                                      );
                                    }
                                  )}
                                  {/* {provided.placeholder} */}
                                </Box>
                              );
                            }}
                          </StrictModeDroppable>
                        </>
                      )}
                      {currentMainDrawerPanel ===
                        BuilderDrawerPanels.QUESTIONS && <h1>Questions</h1>}
                      {currentMainDrawerPanel ===
                        BuilderDrawerPanels.SETTINGS && (
                        <List>
                          <ListItem
                            secondaryAction={
                              <IconButton
                                edge="end"
                                onClick={() => {
                                  updateSettingsDrawerPanel(
                                    SettingsDrawers.THEME_SETTINGS
                                  );
                                }}
                              >
                                <EditOutlined fontSize="small" />
                              </IconButton>
                            }
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItem: "center",
                              }}
                            >
                              <Typography sx={{ mr: 1 }}>Theme</Typography>
                              <InfoTooltip title="You can apply `Themes` to your form to fit your own brand and identity." />
                            </Box>
                          </ListItem>
                        </List>
                      )}
                    </BuilderDrawer>
                    {/* <Container> */}
                    <Box
                      component="main"
                      sx={{
                        // height: "100%",
                        width: "100%",
                        flexGrow: 1,
                        px: 8,
                      }}
                    >
                      <FormBuilderCanvas
                        pagesRef={pagesListRef}
                        actionPathsRef={actionPathsListRef}
                        showSettingsDrawer={showSettingsDrawer}
                        resetSettingsDrawerPanel={resetSettingsDrawerPanel}
                        showMainDrawer={showMainDrawer}
                        fieldName="pages"
                        currentDrawerPanel={currentSettingsDrawerPanel}
                        settingsClickHandler={updateSettingsDrawerPanel}
                      />

                      <IconButton
                        sx={{
                          position: "absolute",
                          bottom: 16,
                          left:
                            (showMainDrawer
                              ? MINI_DRAWER_WIDTH + MAIN_DRAWER_WIDTH
                              : MINI_DRAWER_WIDTH) + 16,
                        }}
                        onClick={toggleMainDrawer}
                      >
                        {showMainDrawer ? (
                          <KeyboardDoubleArrowLeftOutlined />
                        ) : (
                          <KeyboardDoubleArrowRightOutlined />
                        )}
                      </IconButton>

                      <IconButton
                        sx={{
                          position: "absolute",
                          bottom: 16,
                          right:
                            (showSettingsDrawer ? SETTINGS_DRAWER_WIDTH : 0) +
                            16,
                        }}
                        onClick={toggleSettingsDrawer}
                      >
                        {showSettingsDrawer ? (
                          <KeyboardDoubleArrowRightOutlined />
                        ) : (
                          <KeyboardDoubleArrowLeftOutlined />
                        )}
                      </IconButton>
                      {/* <SpeedDial
                        ariaLabel="SpeedDial basic example"
                        sx={{
                          position: "absolute",
                          bottom: 16,
                          right: SETTINGS_DRAWER_WIDTH + 16,
                        }}
                        icon={<SpeedDialIcon />}
                      ></SpeedDial> */}
                    </Box>
                    {/* </Container> */}
                    <SettingsDrawer
                      hidden={!showSettingsDrawer}
                      onClose={() => {}}
                    >
                      {!currentSettingsDrawerPanel && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            height: "100%",
                          }}
                        >
                          <EmptyPlaceHolder
                            svgIcon={<SettingsPanelIcon />}
                            svgIconStyle={{
                              height: 50,
                            }}
                            title="Settings Panel"
                            description="You can edit different settings here. Select the settings for a Question, Page, Action , Action Path or a Theme."
                            titleProps={{
                              variant: "h6",
                            }}
                            descriptionProps={{
                              variant: "caption",
                            }}
                            // showButton
                            // buttonContent={"Close"}
                            // buttonProps={{
                            //   startIcon: <Close />,
                            //   onClick: toggleSettingsDrawer,
                            // }}
                          />
                        </Box>
                      )}
                      {currentSettingsDrawerPanel &&
                        currentSettingsDrawerPanel.type ===
                          QuestionTypes.TEXT && (
                          <TextFieldBlockSettingsPanel
                            name={`pages[${
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }].subItems[${
                              currentSettingsDrawerPanel.blockIndex
                            }]`}
                            pageIndex={
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }
                            blockIndex={
                              currentSettingsDrawerPanel.blockIndex ?? 0
                            }
                          ></TextFieldBlockSettingsPanel>
                        )}

                      {currentSettingsDrawerPanel &&
                        currentSettingsDrawerPanel.type ===
                          QuestionTypes.RANKING && (
                          <RankingBlockSettingsPanel
                            name={`pages[${
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }].subItems[${
                              currentSettingsDrawerPanel.blockIndex
                            }]`}
                            pageIndex={
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }
                            blockIndex={
                              currentSettingsDrawerPanel.blockIndex ?? 0
                            }
                          ></RankingBlockSettingsPanel>
                        )}

                      {currentSettingsDrawerPanel &&
                        currentSettingsDrawerPanel.type ===
                          QuestionTypes.CHECKBOXES && (
                          <NewCheckboxesBlockSettingsPanel
                            name={`pages[${
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }].subItems[${
                              currentSettingsDrawerPanel.blockIndex
                            }]`}
                            pageIndex={
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }
                            blockIndex={
                              currentSettingsDrawerPanel.blockIndex ?? 0
                            }
                          ></NewCheckboxesBlockSettingsPanel>
                        )}

                      {currentSettingsDrawerPanel &&
                        currentSettingsDrawerPanel.type ===
                          QuestionTypes.STAR_RATING && (
                          <StarRatingBlockSettingsPanel
                            name={`pages[${
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }].subItems[${
                              currentSettingsDrawerPanel.blockIndex
                            }]`}
                            pageIndex={
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }
                            blockIndex={
                              currentSettingsDrawerPanel.blockIndex ?? 0
                            }
                          ></StarRatingBlockSettingsPanel>
                        )}

                      {currentSettingsDrawerPanel &&
                        currentSettingsDrawerPanel.type ===
                          QuestionTypes.YESNO && (
                          <YesNoBlockSettingsPanel
                            name={`pages[${
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }].subItems[${
                              currentSettingsDrawerPanel.blockIndex
                            }]`}
                            pageIndex={
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }
                            blockIndex={
                              currentSettingsDrawerPanel.blockIndex ?? 0
                            }
                          ></YesNoBlockSettingsPanel>
                        )}

                      {currentSettingsDrawerPanel &&
                        currentSettingsDrawerPanel.type ===
                          QuestionTypes.SCALE && (
                          <ScaleBlockSettingsPanel
                            name={`pages[${
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }].subItems[${
                              currentSettingsDrawerPanel.blockIndex
                            }]`}
                            pageIndex={
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }
                            blockIndex={
                              currentSettingsDrawerPanel.blockIndex ?? 0
                            }
                          ></ScaleBlockSettingsPanel>
                        )}

                      {currentSettingsDrawerPanel &&
                        currentSettingsDrawerPanel.type ===
                          QuestionTypes.SLIDER && (
                          <SliderBlockSettingsPanel
                            name={`pages[${
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }].subItems[${
                              currentSettingsDrawerPanel.blockIndex
                            }]`}
                            pageIndex={
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }
                            blockIndex={
                              currentSettingsDrawerPanel.blockIndex ?? 0
                            }
                          ></SliderBlockSettingsPanel>
                        )}

                      {currentSettingsDrawerPanel &&
                        currentSettingsDrawerPanel.type ===
                          QuestionTypes.IMAGE_CHOICE && (
                          <ImageChoiceBlockSettingsPanel
                            name={`pages[${
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }].subItems[${
                              currentSettingsDrawerPanel.blockIndex
                            }]`}
                            pageIndex={
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }
                            blockIndex={
                              currentSettingsDrawerPanel.blockIndex ?? 0
                            }
                          ></ImageChoiceBlockSettingsPanel>
                        )}

                      {currentSettingsDrawerPanel &&
                        currentSettingsDrawerPanel.type ===
                          QuestionTypes.DATE && (
                          <DateBlockSettingsPanel
                            name={`pages[${
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }].subItems[${
                              currentSettingsDrawerPanel.blockIndex
                            }]`}
                            pageIndex={
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }
                            blockIndex={
                              currentSettingsDrawerPanel.blockIndex ?? 0
                            }
                          ></DateBlockSettingsPanel>
                        )}

                      {currentSettingsDrawerPanel &&
                        currentSettingsDrawerPanel.type ===
                          QuestionTypes.RADIO_GROUP && (
                          <RadioGroupBlockSettingsPanel
                            name={`pages[${
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }].subItems[${
                              currentSettingsDrawerPanel.blockIndex
                            }]`}
                            pageIndex={
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }
                            blockIndex={
                              currentSettingsDrawerPanel.blockIndex ?? 0
                            }
                          ></RadioGroupBlockSettingsPanel>
                        )}

                      {currentSettingsDrawerPanel &&
                        currentSettingsDrawerPanel.type ===
                          QuestionTypes.DROPDOWN && (
                          <DropdownBlockSettingsPanel
                            name={`pages[${
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }].subItems[${
                              currentSettingsDrawerPanel.blockIndex
                            }]`}
                            pageIndex={
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }
                            blockIndex={
                              currentSettingsDrawerPanel.blockIndex ?? 0
                            }
                          ></DropdownBlockSettingsPanel>
                        )}

                      {currentSettingsDrawerPanel &&
                        currentSettingsDrawerPanel.type ===
                          QuestionTypes.EMAIL && (
                          <EmailBlockSettingsPanel
                            name={`pages[${
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }].subItems[${
                              currentSettingsDrawerPanel.blockIndex
                            }]`}
                            pageIndex={
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }
                            blockIndex={
                              currentSettingsDrawerPanel.blockIndex ?? 0
                            }
                          ></EmailBlockSettingsPanel>
                        )}

                      {currentSettingsDrawerPanel &&
                        currentSettingsDrawerPanel.type ===
                          QuestionTypes.PHONE && (
                          <PhoneBlockSettingsPanel
                            name={`pages[${
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }].subItems[${
                              currentSettingsDrawerPanel.blockIndex
                            }]`}
                            pageIndex={
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }
                            blockIndex={
                              currentSettingsDrawerPanel.blockIndex ?? 0
                            }
                          ></PhoneBlockSettingsPanel>
                        )}

                      {currentSettingsDrawerPanel &&
                        currentSettingsDrawerPanel.type ===
                          QuestionTypes.LINK && (
                          <LinkBlockSettingsPanel
                            name={`pages[${
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }].subItems[${
                              currentSettingsDrawerPanel.blockIndex
                            }]`}
                            pageIndex={
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }
                            blockIndex={
                              currentSettingsDrawerPanel.blockIndex ?? 0
                            }
                          ></LinkBlockSettingsPanel>
                        )}

                      {currentSettingsDrawerPanel &&
                        currentSettingsDrawerPanel.type ===
                          QuestionTypes.ADDRESS && (
                          <AddressBlockSettingsPanel
                            name={`pages[${
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }].subItems[${
                              currentSettingsDrawerPanel.blockIndex
                            }]`}
                            pageIndex={
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }
                            blockIndex={
                              currentSettingsDrawerPanel.blockIndex ?? 0
                            }
                          ></AddressBlockSettingsPanel>
                        )}

                      {currentSettingsDrawerPanel &&
                        currentSettingsDrawerPanel.type ===
                          FormSubBlockTypes.HEADING && (
                          <HeadingBlockSettingsPanel
                            name={`pages[${
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }].subItems[${
                              currentSettingsDrawerPanel.blockIndex
                            }]`}
                            pageIndex={
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }
                            blockIndex={
                              currentSettingsDrawerPanel.blockIndex ?? 0
                            }
                          ></HeadingBlockSettingsPanel>
                        )}

                      {currentSettingsDrawerPanel &&
                        currentSettingsDrawerPanel.type ===
                          FormSubBlockTypes.PARAGRAPH && (
                          <ParagraphBlockSettingsPanel
                            name={`pages[${
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }].subItems[${
                              currentSettingsDrawerPanel.blockIndex
                            }]`}
                            pageIndex={
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }
                            blockIndex={
                              currentSettingsDrawerPanel.blockIndex ?? 0
                            }
                          ></ParagraphBlockSettingsPanel>
                        )}

                      {currentSettingsDrawerPanel &&
                        currentSettingsDrawerPanel.type === Blocks.PAGE && (
                          <PageBlockSettingsPanel
                            name={`pages[${
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }]`}
                            pageIndex={
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }
                          ></PageBlockSettingsPanel>
                        )}

                      {currentSettingsDrawerPanel &&
                        currentSettingsDrawerPanel.type ===
                          Actions.ACTION_PATH && (
                          <ActionPathBlockSettingsPanel
                            name={`actionPaths[${
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }]`}
                            actionPathIndex={
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }
                          ></ActionPathBlockSettingsPanel>
                        )}

                      {currentSettingsDrawerPanel &&
                        currentSettingsDrawerPanel.type ===
                          Actions.REDIRECT && (
                          <RedirectActionBlockSettingsPanel
                            name={`actionPaths[${
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }].actions[${
                              currentSettingsDrawerPanel.blockIndex
                            }]`}
                            pageIndex={
                              currentSettingsDrawerPanel.pageIndex ?? 0
                            }
                            blockIndex={
                              currentSettingsDrawerPanel.blockIndex ?? 0
                            }
                          ></RedirectActionBlockSettingsPanel>
                        )}

                      {currentSettingsDrawerPanel &&
                        currentSettingsDrawerPanel.type ===
                          SettingsDrawers.THEME_SETTINGS && (
                          <SurveyThemeSettingsPanel
                            name="surveyTheme"
                            addSurveyThemeClickHandler={() => {
                              setCurrentSettingsDrawerPanel({
                                type: SettingsDrawers.ADD_SURVEY_THEME,
                              });
                            }}
                            editSurveyClickHandler={() => {
                              setCurrentSettingsDrawerPanel({
                                type: SettingsDrawers.EDIT_SURVEY_THEME,
                              });
                            }}
                          />
                        )}

                      {currentSettingsDrawerPanel &&
                        currentSettingsDrawerPanel.type ===
                          SettingsDrawers.ADD_SURVEY_THEME && (
                          <SurveyThemeForm
                            currentFormMode={FORM_MODES.CREATE_NEW}
                            backClickHandler={() => {
                              setCurrentSettingsDrawerPanel({
                                type: SettingsDrawers.THEME_SETTINGS,
                              });
                            }}
                          />
                        )}

                      {currentSettingsDrawerPanel &&
                        currentSettingsDrawerPanel.type ===
                          SettingsDrawers.EDIT_SURVEY_THEME && (
                          <SurveyThemeForm
                            currentFormMode={FORM_MODES.EDIT}
                            backClickHandler={() => {
                              setCurrentSettingsDrawerPanel({
                                type: SettingsDrawers.THEME_SETTINGS,
                              });
                            }}
                          />
                        )}
                    </SettingsDrawer>
                  </Box>
                </DragDropContext>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
}
