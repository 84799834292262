import React from "react";

import { useField } from "formik";

import { TextField, TextFieldProps, MenuItem } from "@mui/material";

type ControlledFontPickerProps = TextFieldProps & {
  name: string;
  options: { [key: string]: any };
};

export default function ControlledFontPicker({
  name,
  options,
  ...otherPRops
}: ControlledFontPickerProps) {
  const [field, meta, helpers] = useField(name);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    helpers.setValue(value);
  };

  const textFieldConfig: TextFieldProps = {
    fullWidth: true,
    variant: "outlined",
    size: "small",
    ...field,
    ...otherPRops,
    select: true,
    onChange: handleChange,
  };

  if (meta && meta.touched && meta.error) {
    textFieldConfig.error = true;
    textFieldConfig.helperText = meta.error;
  }

  return (
    <TextField
      {...textFieldConfig}
      SelectProps={{ sx: { fontFamily: field.value ?? "roboto" } }}
    >
      {Object.keys(options).map((item, index) => {
        return (
          <MenuItem key={index} value={item} sx={{ fontFamily: item }}>
            {options[item]}
          </MenuItem>
        );
      })}
    </TextField>
  );
}
