import React from "react";

import {
  Box,
  BoxProps,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import { lighten, useTheme } from "@mui/material/styles";

import { LiveSurveyThemeDocument } from "../../../../types/SurveyTheme";

import LiveSurveyQuestionText from "../../../SurveyComponents/LiveSurveyQuestionText";
import { RadioGroupValue } from "../../../../types/Blocks";
import { useField } from "formik";
import { generateAnswerTextStyle } from "../../../../utils/styles/generateAnswerTextStyle";

type LiveRadioGroupBlockProps = BoxProps & {
  name: string;
  questionText: string;
  questionDescription?: string;
  isQuestionRequired: boolean;
  radioGroupValues: RadioGroupValue[];
  surveyTheme: LiveSurveyThemeDocument | null;
};

export default function LiveRadioGroupBlock({
  name,
  isQuestionRequired,
  questionText,
  questionDescription,
  radioGroupValues,
  surveyTheme,
  sx,
}: LiveRadioGroupBlockProps) {
  const theme = useTheme();
  const [field] = useField<string>(name);

  return (
    <Box sx={{ mb: 3, ...sx }}>
      <LiveSurveyQuestionText
        questionText={questionText}
        questionDescription={questionDescription}
        isQuestionRequired={isQuestionRequired}
        surveyTheme={surveyTheme}
        sx={{
          mb: 3,
        }}
      />

      {/* TODO - In the future add controlled Radio Group Component */}
      {radioGroupValues && (
        <FormControl component="fieldset" variant="standard" fullWidth>
          <RadioGroup
            name={name}
            value={field.value === undefined ? "" : field.value}
            onChange={field.onChange}
          >
            {radioGroupValues.map(
              (radioGroupValue: RadioGroupValue, radioGroupValueIndex) => {
                return (
                  <FormControlLabel
                    value={radioGroupValue.value}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        width: "100%",
                      },
                    }}
                    key={radioGroupValue.radioGroupValueId}
                    control={
                      <Radio
                        sx={{
                          ...(surveyTheme && {
                            color: surveyTheme.colorSettings.answerTextColor,
                            "&.Mui-checked": {
                              color: lighten(
                                surveyTheme.colorSettings.answerTextColor,
                                0.25
                              ),
                            },
                          }),
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          ...theme.typography.h6,
                          ...(surveyTheme &&
                            generateAnswerTextStyle(surveyTheme)),
                        }}
                      >
                        {radioGroupValue.value}
                      </Typography>
                    }
                  />
                );
              }
            )}
          </RadioGroup>
        </FormControl>
      )}
    </Box>
  );
}
