import React from "react";

import { Button, ButtonProps } from "@mui/material";
import { lighten, alpha } from "@mui/material/styles";
import { LiveSurveyThemeDocument } from "../../types/SurveyTheme";
import { generateAnswerTextStyle } from "../../utils/styles/generateAnswerTextStyle";

type TransparentSurveyButtonProps = ButtonProps & {
  surveyTheme: LiveSurveyThemeDocument | null;
  isSelected?: boolean;
  children: React.ReactNode;
  sx?: ButtonProps["sx"];
};

export default function TransparentSurveyButton({
  surveyTheme,
  isSelected = false,
  children,
  sx,
  ...otherProps
}: TransparentSurveyButtonProps) {
  return (
    <Button
      {...otherProps}
      variant="outlined"
      size="large"
      sx={{
        fontWeight: "bold",
        // Apple border width for selected even if there is not survey theme
        ...(isSelected && {
          borderWidth: "3px",
        }),
        "&:hover": {
          ...(isSelected && {
            borderWidth: "3px",
          }),
        },

        ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
        ...(surveyTheme && {
          backgroundColor: alpha(
            lighten(surveyTheme.colorSettings.answerTextColor, 0.8),
            0.1
          ),
          borderColor: lighten(surveyTheme.colorSettings.answerTextColor, 0.15),

          ...(isSelected && {
            borderWidth: "3px",
          }),

          "&:hover": {
            backgroundColor: alpha(
              lighten(surveyTheme.colorSettings.answerTextColor, 0.5),
              0.25
            ),
            borderColor: lighten(
              surveyTheme.colorSettings.answerTextColor,
              0.15
            ),
            ...(isSelected && {
              borderWidth: "3px",
            }),
          },
        }),
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </Button>
  );
}
