import React from "react";

import { IconProps } from "@mui/material";
import { QuestionTypes } from "../types/Blocks";
import {
  ArrowDropDownCircleOutlined,
  CalendarTodayOutlined,
  CallOutlined,
  CheckBoxOutlined,
  Close,
  Contrast,
  EmailOutlined,
  ErrorOutline,
  LinearScaleOutlined,
  LocationOnOutlined,
  LowPriorityOutlined,
  PhotoLibraryOutlined,
  PinOutlined,
  RadioButtonChecked,
  RadioButtonCheckedOutlined,
  StarOutlineOutlined,
  TextFieldsOutlined,
} from "@mui/icons-material";

type QuestionTypeIconProps = IconProps & {
  questionType: QuestionTypes;
};

export default function QuestionTypeIcon({
  questionType,
  sx,
}: QuestionTypeIconProps) {
  if (questionType === QuestionTypes.TEXT) {
    return <TextFieldsOutlined sx={sx} />;
  } else if (questionType === QuestionTypes.STAR_RATING) {
    return <StarOutlineOutlined sx={sx} />;
  } else if (questionType === QuestionTypes.CHECKBOXES) {
    return <CheckBoxOutlined sx={sx} />;
  } else if (questionType === QuestionTypes.YESNO) {
    return <Contrast sx={sx} />;
  } else if (questionType === QuestionTypes.SCALE) {
    return <PinOutlined sx={sx} />;
  } else if (questionType === QuestionTypes.SLIDER) {
    return <LinearScaleOutlined sx={sx} />;
  } else if (questionType === QuestionTypes.RANKING) {
    return <LowPriorityOutlined sx={sx} />;
  } else if (questionType === QuestionTypes.IMAGE_CHOICE) {
    return <PhotoLibraryOutlined sx={sx} />;
  } else if (questionType === QuestionTypes.DATE) {
    return <CalendarTodayOutlined sx={sx} />;
  } else if (questionType === QuestionTypes.RADIO_GROUP) {
    return <RadioButtonChecked sx={sx} />;
  } else if (questionType === QuestionTypes.DROPDOWN) {
    return <ArrowDropDownCircleOutlined sx={sx} />;
  } else if (questionType === QuestionTypes.EMAIL) {
    return <EmailOutlined sx={sx} />;
  } else if (questionType === QuestionTypes.PHONE) {
    return <CallOutlined sx={sx} />;
  } else if (questionType === QuestionTypes.ADDRESS) {
    return <LocationOnOutlined sx={sx} />;
  }

  // Show error icon if question type not found
  return <ErrorOutline sx={sx} />;
}
