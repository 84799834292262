import React, { useState } from "react";

import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
  DragStartEvent,
  DragEndEvent,
} from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";

import { Box, Button, Card, CardContent } from "@mui/material";

import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

import { v4 as uuid } from "uuid";

import SurveyQuestionBlockHeader from "../../../SurveyComponents/SurveyQuestionBlockHeader";
import { useField } from "formik";
import {
  ImageChoiceBlockFields,
  ImageChoiceValue,
} from "../../../../types/Blocks";
import {
  Add,
  HighlightAltOutlined,
  OpenWithOutlined,
} from "@mui/icons-material";
import SortableImageChoiceCard from "../../../SurveyComponents/SortableImageChoiceCard";
import { reorder } from "../../../FormBuilder";
import SelectImageDialog from "../../../Images/SelectImageDialog";
import { useSurveyTheme } from "../../../../providers/surveyTheme";
import SurveyBlockLayout from "../../../SurveyComponents/SurveyBlockLayout";

interface ImageChoiceBlockProps {
  // formik: FormikContextType<IFormCanvasBlocksFields>;
  name: string;
  // index: number;
  pageIndex: number;
  blockIndex: number;
  settingsHandler: () => void;
  deleteHandler: () => void;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  settingsPanelActive: boolean;
}

export default function ImageChoiceBlock({
  // formik,
  name,
  // index,
  pageIndex,
  blockIndex,
  deleteHandler,
  settingsHandler,
  dragHandleProps,
  settingsPanelActive,
}: ImageChoiceBlockProps) {
  const surveyTheme = useSurveyTheme();
  const [showSelectImageDialog, setShowSelectImageDialog] = useState(false);

  const [activeId, setActiveId] = useState<string | null>(null);
  const [selectedImageChoice, setSelectedImageChoice] = useState("");

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
    // useSensor(KeyboardSensor, {
    //   coordinateGetter: sortableKeyboardCoordinates,
    // })
  );

  const [field, meta, helpers] = useField<ImageChoiceBlockFields>(name);

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id as string);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    setActiveId(null);
    const { active, over } = event;

    if (active && over && active.data.current && active.id !== over.id) {
      const newImageChoiceValues = reorder(
        field.value.imageChoiceValues,
        active.data.current.sortable.index,
        // @ts-ignore
        over.data.current.sortable.index
      );

      helpers.setValue({
        ...field.value,
        imageChoiceValues: newImageChoiceValues,
      });
    }
  };

  const handleSelectImageDialogOpen = () => {
    setShowSelectImageDialog(true);
  };

  const handleSelectImageDialogClose = () => {
    setShowSelectImageDialog(false);
  };

  const setImageChoiceUrl = (newImageUrl: string) => {
    if (!selectedImageChoice) {
      return;
    }

    const newImageChoiceValuesList = field.value.imageChoiceValues.map(
      (imageChoiceValue: ImageChoiceValue) => {
        if (imageChoiceValue.imageChoiceId === selectedImageChoice) {
          return {
            ...imageChoiceValue,
            imageUrl: newImageUrl,
          };
        } else {
          return imageChoiceValue;
        }
      }
    );

    helpers.setValue({
      ...field.value,
      imageChoiceValues: newImageChoiceValuesList,
    });
  };

  const deleteImageChoice = (imageChoiceId: string) => {
    const selectedImageChoiceIndex = field.value.imageChoiceValues.findIndex(
      (imageChoiceValue: ImageChoiceValue) => {
        return imageChoiceValue.imageChoiceId === imageChoiceId;
      }
    );

    if (selectedImageChoiceIndex === -1) {
      return;
    }

    // @ts-ignore
    helpers.setTouched({
      // @ts-ignore
      ...(meta.touched as FormikTouched<ImageChoiceBlockFields>),
      // @ts-ignore
      imageChoiceValues:
        // @ts-ignore
        meta.touched?.imageChoiceValues
          // @ts-ignore
          ?.filter((_: string, currentImageChoiceValueIndex: number) => {
            return selectedImageChoiceIndex !== currentImageChoiceValueIndex;
          }),
    });

    helpers.setValue({
      ...field.value,
      imageChoiceValues: field.value.imageChoiceValues.filter(
        (_, currentImageChoiceValueIndex) => {
          return selectedImageChoiceIndex !== currentImageChoiceValueIndex;
        }
      ),
    });
  };

  return (
    <SurveyBlockLayout
      name={name}
      settingsHandler={settingsHandler}
      deleteHandler={deleteHandler}
      settingsPanelActive={settingsPanelActive}
      dragHandleProps={dragHandleProps}
    >
      <SurveyQuestionBlockHeader name={name} />

      {field.value.imageChoiceValues && (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
        >
          <Box
            sx={{
              display: "flex",
              ...(surveyTheme && {
                justifyContent: surveyTheme.contentTextAlignment,
              }),
              flexDirection: "row",
              flexWrap: "wrap",
              // background: "orange",
            }}
          >
            <SortableContext
              // items={field.value.imageChoiceValues}
              items={field.value.imageChoiceValues.map(
                (imageChoiceValue: ImageChoiceValue) => {
                  return imageChoiceValue.imageChoiceId;
                }
              )}
              strategy={rectSortingStrategy}
            >
              {field.value.imageChoiceValues.map(
                (
                  imageChoiceValue: ImageChoiceValue,
                  imageChoiceValueIndex: number
                ) => (
                  <SortableImageChoiceCard
                    name={`pages[${pageIndex}].subItems[${blockIndex}].imageChoiceValues[${imageChoiceValueIndex}]`}
                    key={imageChoiceValue.imageChoiceId}
                    id={imageChoiceValue.imageChoiceId}
                    surveyTheme={surveyTheme}
                    uploadImageClickHandler={() => {
                      setSelectedImageChoice(imageChoiceValue.imageChoiceId);
                      handleSelectImageDialogOpen();
                    }}
                    deleteImageChoiceClickHandler={() => {
                      setSelectedImageChoice(imageChoiceValue.imageChoiceId);
                      /* 
                          NOTE : Decided to pass the image choice id directly to the
                          delete function as the state does not update in time. However,
                          I think for the updateImage the approach aboce works as the
                          state is updated before the image url is set as we set after some
                          time as we have to show the select image dialog 
                        */
                      deleteImageChoice(imageChoiceValue.imageChoiceId);
                    }}
                    imageUrl={imageChoiceValue.imageUrl}
                  />
                )
              )}
              <DragOverlay>
                {activeId ? (
                  <Card>
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <OpenWithOutlined fontSize="large"></OpenWithOutlined>
                      </Box>
                    </CardContent>
                  </Card>
                ) : null}
              </DragOverlay>
            </SortableContext>
          </Box>
        </DndContext>
      )}

      <Box>
        <Button
          sx={{
            my: 2,
          }}
          variant="outlined"
          startIcon={<Add />}
          onClick={() => {
            helpers.setValue({
              ...field.value,
              imageChoiceValues: [
                ...(field.value.imageChoiceValues
                  ? [...field.value.imageChoiceValues]
                  : []),
                {
                  imageChoiceId: uuid(),
                  imageUrl: "",
                  imageCaption: "",
                },
              ],
            });
          }}
        >
          Add New Choice
        </Button>
      </Box>

      <SelectImageDialog
        show={showSelectImageDialog}
        closeHandler={handleSelectImageDialogClose}
        setImageHandler={setImageChoiceUrl}
      />
    </SurveyBlockLayout>
  );
}
