import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import { Formik, Form, FormikProps } from "formik";
import * as yup from "yup";

import {
  Typography,
  Card,
  Container,
  CardContent,
  Button,
  Box,
  CircularProgress,
  Alert,
} from "@mui/material";

import ControlledTextField from "../components/Inputs/ControlledTextField";

import { useAuth } from "../hooks/useAuth";
import { FORGOT_PASSWORD_URL } from "../data/constants";

import Api from "../api/api";

import LivelyFormsLogo from "../assets/Lively_Forms_Logo_Blue.svg";

const INITIAL_FORM_STATE = {
  email: "",
};

const FORM_VALIDATION = yup.object().shape({
  email: yup.string().email("Invalid Email").required("Email is requried"),
});

export interface ForgotPasswordFormFields {
  email: string;
}

type ForgotPasswordProps = {};

export default function ForgotPassword({}: ForgotPasswordProps) {
  const { setAuth } = useAuth();

  const navigate = useNavigate();

  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isSuccess, setIsSuccess] = useState("");

  const handleSubmit = async (values: ForgotPasswordFormFields) => {
    try {
      setError("");
      setIsSuccess("");
      setIsLoading(true);

      await Api.post(FORGOT_PASSWORD_URL, values, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });

      setIsSuccess(
        "We have sent you an email with a link to reset your password."
      );
      setIsLoading(false);
    } catch (error) {
      setIsSuccess("");
      setIsLoading(false);
      // @ts-ignore
      if (!error?.response) {
        setError("No response from server.");
        // @ts-ignore
      } else if (error?.response) {
        // @ts-ignore
        setError(error.response.data.error.message);
      } else {
        setError("Unable to login.");
      }
    }
  };

  return (
    <Box
      sx={{
        pt: 7,
      }}
    >
      <Container maxWidth="xs">
        <Card
          sx={{
            py: 5,
            px: 2,
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: 3,
              }}
            >
              <img
                src={LivelyFormsLogo}
                alt="Lively Forms Logo"
                height={40}
                width={240}
              />
            </Box>

            <Typography sx={{ mb: 3 }} variant="body1" align="center">
              Let's try and get you back in.
            </Typography>

            {!isSuccess && (
              <>
                <Typography sx={{ mb: 3 }} variant="body2">
                  Provide us your email and we will send you a link to reset
                  your password.
                </Typography>
                <Formik
                  initialValues={INITIAL_FORM_STATE as ForgotPasswordFormFields}
                  validationSchema={FORM_VALIDATION}
                  onSubmit={(values: any) => {
                    handleSubmit(values);
                  }}
                  validateOnChange
                  validateOnBlur
                >
                  {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }: FormikProps<ForgotPasswordFormFields>) => {
                    return (
                      <Form>
                        {error && (
                          <Alert sx={{ my: 3 }} severity="error">
                            {error}
                          </Alert>
                        )}

                        <ControlledTextField
                          name="email"
                          label="Email"
                          type="email"
                          required
                          sx={{ mb: 2 }}
                          autoFocus
                        ></ControlledTextField>

                        <Button
                          type="submit"
                          variant="contained"
                          fullWidth
                          size="large"
                          disabled={loading}
                        >
                          {loading ? (
                            <CircularProgress color="primary" />
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </Form>
                    );
                  }}
                </Formik>
              </>
            )}

            {isSuccess && !loading && !error && (
              <>
                <Alert severity="success" variant="standard">
                  {isSuccess}
                </Alert>
              </>
            )}

            <Button
              sx={{ mt: 2, textTransform: "none" }}
              onClick={() => {
                navigate("/login");
              }}
            >
              Back to Log In
            </Button>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}
