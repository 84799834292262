import React from "react";

import { Box, BoxProps } from "@mui/material";
import { LiveSurveyThemeDocument } from "../../../../types/SurveyTheme";

import LiveSurveyQuestionText from "../../../SurveyComponents/LiveSurveyQuestionText";
import { useField } from "formik";
import { YesNoValues } from "../../../../types/Blocks";
import { Check } from "@mui/icons-material";
import TransparentSurveyButton from "../../../SurveyComponents/TransparentSurveyButton";

type LiveYesNoBlockProps = BoxProps & {
  name: string;
  questionText: string;
  questionDescription?: string;
  isQuestionRequired: boolean;
  surveyTheme: LiveSurveyThemeDocument | null;
};

export default function LiveYesNoBlock({
  name,
  isQuestionRequired,
  questionText,
  questionDescription,
  surveyTheme,
  sx,
}: LiveYesNoBlockProps) {
  const [field, meta, helpers] = useField<YesNoValues>(name);

  const updateValues = (newValue: YesNoValues) => {
    helpers.setValue(newValue);
  };

  return (
    <Box sx={{ mb: 3, ...sx }}>
      <LiveSurveyQuestionText
        questionText={questionText}
        questionDescription={questionDescription}
        isQuestionRequired={isQuestionRequired}
        surveyTheme={surveyTheme}
        sx={{
          mb: 3,
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          ...(surveyTheme && {
            ...(surveyTheme.contentTextAlignment && {
              textAlign: surveyTheme.contentTextAlignment,
            }),
          }),
        }}
      >
        <Box
          sx={{
            mb: 3,
          }}
        >
          <TransparentSurveyButton
            variant="outlined"
            size="large"
            isSelected={field.value === YesNoValues.YES}
            sx={{
              minWidth: "200px",
              px: 10,
            }}
            onClick={() => {
              updateValues(YesNoValues.YES);
            }}
            surveyTheme={surveyTheme}
          >
            YES
          </TransparentSurveyButton>
        </Box>
        <Box>
          <TransparentSurveyButton
            variant="outlined"
            size="large"
            isSelected={field.value === YesNoValues.NO}
            sx={{
              minWidth: "200px",
              px: 10,
            }}
            onClick={() => {
              updateValues(YesNoValues.NO);
            }}
            surveyTheme={surveyTheme}
          >
            NO
          </TransparentSurveyButton>
        </Box>
      </Box>
    </Box>
  );
}
