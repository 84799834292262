import {
  CheckBoxOutlined,
  TextFieldsOutlined,
  StarOutlineOutlined,
  InsertDriveFileOutlined,
  Title,
  FontDownloadOutlined,
  Contrast,
  PinOutlined,
  LinearScaleOutlined,
  LowPriorityOutlined,
  PhotoLibraryOutlined,
  CalendarTodayOutlined,
  RadioButtonChecked,
  ArrowDropDownCircleOutlined,
  EmailOutlined,
  CallOutlined,
  Link,
  LocationOnOutlined,
} from "@mui/icons-material";

import {
  BlockCard,
  Blocks,
  FormSubBlockTypes,
  QuestionTypes,
} from "../types/Blocks";

export const FORM_BLOCKS_LIST: BlockCard[] = [
  {
    type: QuestionTypes.TEXT,
    label: "Text Field",
    icon: <TextFieldsOutlined color="primary" fontSize="small" />,
  },
  {
    type: QuestionTypes.CHECKBOXES,
    label: "Checkboxes",
    icon: <CheckBoxOutlined color="primary" fontSize="small" />,
  },
  {
    type: QuestionTypes.STAR_RATING,
    label: "Star Rating",
    icon: <StarOutlineOutlined color="primary" fontSize="small" />,
  },
  {
    type: QuestionTypes.YESNO,
    label: "Yes / No",
    icon: <Contrast color="primary" fontSize="small" />,
  },
  {
    type: QuestionTypes.SCALE,
    label: "Scale",
    icon: <PinOutlined color="primary" fontSize="small" />,
  },
  {
    type: QuestionTypes.SLIDER,
    label: "Slider",
    icon: <LinearScaleOutlined color="primary" fontSize="small" />,
  },
  {
    type: QuestionTypes.RANKING,
    label: "Ranking",
    icon: <LowPriorityOutlined color="primary" fontSize="small" />,
  },
  {
    type: QuestionTypes.IMAGE_CHOICE,
    label: "Image Choice",
    icon: <PhotoLibraryOutlined color="primary" fontSize="small" />,
  },
  {
    type: QuestionTypes.DATE,
    label: "Date",
    icon: <CalendarTodayOutlined color="primary" fontSize="small" />,
  },
  {
    type: QuestionTypes.RADIO_GROUP,
    label: "Radio Group",
    icon: <RadioButtonChecked color="primary" fontSize="small" />,
  },
  {
    type: QuestionTypes.DROPDOWN,
    label: "Dropdown",
    icon: <ArrowDropDownCircleOutlined color="primary" fontSize="small" />,
  },
  {
    type: QuestionTypes.EMAIL,
    label: "Email",
    icon: <EmailOutlined color="primary" fontSize="small" />,
  },
  {
    type: QuestionTypes.PHONE,
    label: "Phone",
    icon: <CallOutlined color="primary" fontSize="small" />,
  },
  {
    type: QuestionTypes.LINK,
    label: "Link",
    icon: <Link color="primary" fontSize="small" />,
  },
  {
    type: QuestionTypes.ADDRESS,
    label: "Address",
    icon: <LocationOnOutlined color="primary" fontSize="small" />,
  },
];

export const TYPOGRAPHY_FORM_BLOCKS_LIST: BlockCard[] = [
  {
    type: FormSubBlockTypes.HEADING,
    label: "Heading",
    icon: <Title color="primary" fontSize="small" />,
  },
  {
    type: FormSubBlockTypes.PARAGRAPH,
    label: "Paragraph",
    icon: <FontDownloadOutlined color="primary" fontSize="small" />,
  },
];

export const FORM_PAGE_BLOCKS_LIST: BlockCard[] = [
  {
    type: Blocks.PAGE,
    label: "Page",
    icon: <InsertDriveFileOutlined color="primary" fontSize="small" />,
  },
];

// export const FORM_SUB_BLOCKS_LIST: BlockCard[] = [
//   {
//     type: FormSubBlockTypes.HEADING,
//     label: "Heading",
//     icon: <Title color="primary" fontSize="small" />,
//   },
//   {
//     type: FormSubBlockTypes.PARAGRAPH,
//     label: "Paragraph",
//     icon: <FontDownloadOutlined color="primary" fontSize="small" />,
//   },
// ];
