import { Actions } from "./Actions";
import { Blocks, FormSubBlockTypes, QuestionTypes } from "./Blocks";

export enum BuilderDrawerPanels {
  BLOCKS,
  ACTIONS,
  QUESTIONS,
  SETTINGS,
}

export enum SettingsDrawers {
  THEME_SETTINGS = "theme_settings",
  ADD_SURVEY_THEME = "add_survey_theme",
  EDIT_SURVEY_THEME = "edit_survey_theme",
}

export enum EditSurveyThemeSettingsPanelTabs {
  MY_THEMES = "My Themes",
  THEME_TEMPLATES = "Templates",
}

export type MiniDrawerButton = {
  icon: React.ReactNode;
  label: string;
  panel: BuilderDrawerPanels;
};

export type SettingsDrawerPAnel = {
  type: QuestionTypes | Blocks | Actions | SettingsDrawers | FormSubBlockTypes;
  pageIndex?: number;
  blockIndex?: number;
};
