import React from "react";

import { Box, Typography } from "@mui/material";
import { AltRouteOutlined } from "@mui/icons-material";

import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import ControlledTextField from "../../../Inputs/ControlledTextField";
import { useSurveyTheme } from "../../../../providers/surveyTheme";
import SurveyBlockLayout from "../../../SurveyComponents/SurveyBlockLayout";

type RedirectActionBlockProps = {
  name: string;
  settingsHandler: () => void;
  deleteHandler: () => void;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  settingsPanelActive: boolean;
};

export default function RedirectActionBlock({
  name,
  deleteHandler,
  settingsHandler,
  dragHandleProps,
  settingsPanelActive,
}: RedirectActionBlockProps) {
  const surveyTheme = useSurveyTheme();

  return (
    <SurveyBlockLayout
      name={name}
      settingsHandler={settingsHandler}
      deleteHandler={deleteHandler}
      settingsPanelActive={settingsPanelActive}
      dragHandleProps={dragHandleProps}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
          }}
        >
          <AltRouteOutlined
            fontSize="large"
            color="primary"
            sx={{
              mr: 1,
              ...(surveyTheme && {
                color: surveyTheme.colorSettings.questionTextColor,
              }),
            }}
          />

          <Box
            sx={{
              width: "100%",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                ...(surveyTheme && {
                  color: surveyTheme.colorSettings.questionTextColor,
                }),
              }}
            >
              Redirect to:
            </Typography>
            <Typography
              variant="body2"
              sx={{
                ...(surveyTheme && {
                  color: surveyTheme.colorSettings.questionTextColor,
                }),
              }}
            >
              Enter the URL to redirect users to when they reach this Action
              PAth Screen
            </Typography>

            <ControlledTextField
              name={`${name}.redirectionUrl`}
              id={`${name}.redirectionUrl`}
              variant="standard"
              size="small"
              multiline
              required
              placeholder="Add a URL to redirect to."
              autoComplete="off"
              InputProps={{
                disableUnderline: true,
                sx: {
                  ...(surveyTheme && {
                    color: surveyTheme.colorSettings.questionTextColor,
                  }),
                },
              }}
              sx={{
                mt: 1.5,
              }}
            ></ControlledTextField>
          </Box>
        </Box>
      </Box>
    </SurveyBlockLayout>
  );
}
