import React from "react";

import { TextField, MenuItem } from "@mui/material";

import { useTheme, lighten, alpha } from "@mui/material/styles";

import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

import { useSurveyTheme } from "../../../../providers/surveyTheme";

import { generateAnswerTextStyle } from "../../../../utils/styles/generateAnswerTextStyle";
import SurveyQuestionBlockHeader from "../../../SurveyComponents/SurveyQuestionBlockHeader";
import { useField } from "formik";
import { DropdownBlockFields, DropdownValue } from "../../../../types/Blocks";
import { generateTextFieldStyle } from "../../../../utils/styles/generateTextFieldStyle";
import SurveyBlockLayout from "../../../SurveyComponents/SurveyBlockLayout";

interface DropdownBlockProps {
  // formik: FormikContextType<IFormCanvasBlocksFields>;
  name: string;
  // index: number;
  pageIndex: number;
  blockIndex: number;
  settingsHandler: () => void;
  deleteHandler: () => void;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  settingsPanelActive: boolean;
}

export default function DropdownBlock({
  // formik,
  name,
  // index,
  pageIndex,
  blockIndex,
  deleteHandler,
  settingsHandler,
  dragHandleProps,
  settingsPanelActive,
}: DropdownBlockProps) {
  const [field] = useField<DropdownBlockFields>(name);
  const surveyTheme = useSurveyTheme();
  const theme = useTheme();

  return (
    <SurveyBlockLayout
      name={name}
      settingsHandler={settingsHandler}
      deleteHandler={deleteHandler}
      settingsPanelActive={settingsPanelActive}
      dragHandleProps={dragHandleProps}
    >
      <SurveyQuestionBlockHeader name={name} />
      <TextField
        placeholder="Select an Option"
        value=""
        variant={(surveyTheme && surveyTheme.fieldVariant) ?? "outlined"}
        size="small"
        required
        fullWidth
        select
        sx={{
          ...generateTextFieldStyle(surveyTheme),
          ...(surveyTheme && {
            ".MuiSvgIcon-root ": {
              fill: surveyTheme.colorSettings.answerTextColor,
            },
          }),
        }}
        SelectProps={{
          MenuProps: {
            sx: {
              "& ul": {
                ...theme.typography.h6,
                ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
                ...(surveyTheme && {
                  "& .Mui-selected": {
                    backgroundColor: `${alpha(
                      lighten(surveyTheme.colorSettings.answerTextColor, 0.25),
                      0.15
                    )} !important`,
                  },
                }),
              },
            },
          },
        }}
      >
        {field.value.dropdownValues.map(
          (dropdownValue: DropdownValue, dropdownValueIndex) => {
            return (
              <MenuItem
                key={dropdownValue.dropdownValueId}
                value={dropdownValue.value}
              >
                {dropdownValue.value}
              </MenuItem>
            );
          }
        )}
      </TextField>
    </SurveyBlockLayout>
  );
}
