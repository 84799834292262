export default function generateColorFromString(
  str: string,
  s: number = 30,
  l: number = 60
) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 4) - hash);
  }
  const h = hash % 360;
  return "hsl(" + h + ", " + s + "%, " + l + "%)";
}
