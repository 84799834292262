import { Box } from "@mui/material";
import React from "react";
import {
  HorizontalLogoPosition,
  VerticalLogoPositions,
} from "../../types/SurveyTheme";

type SurveyLogoProps = {
  logoUrl: string;
  verticalPosition: VerticalLogoPositions;
  horizontalPosition: HorizontalLogoPosition;
};

export default function SurveyLogo({
  logoUrl,
  verticalPosition,
  horizontalPosition,
}: SurveyLogoProps) {
  return (
    <Box
      sx={{
        height: 60,
        width: 60,
        overflow: "hidden",
        position: "absolute",
        m: 2,
        ...(verticalPosition === VerticalLogoPositions.TOP && {
          top: 0,
        }),

        ...(verticalPosition === VerticalLogoPositions.BOTTOM && {
          bottom: 0,
        }),

        ...(horizontalPosition === HorizontalLogoPosition.LEFT && {
          left: 0,
        }),

        ...(horizontalPosition === HorizontalLogoPosition.CENTER && {
          left: "50%",
          transform: "translateX(-50%)",
        }),

        ...(horizontalPosition === HorizontalLogoPosition.RIGHT && {
          right: 0,
        }),
      }}
    >
      <img
        src={logoUrl}
        alt="Logo"
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          height: "auto",
          width: "auto",
        }}
      />
    </Box>
  );
}
