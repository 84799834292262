import { lighten, alpha } from "@mui/material/styles";

import { LiveSurveyThemeDocument } from "../../types/SurveyTheme";

export const generateSelectFieldStyle = (
  surveyTheme: LiveSurveyThemeDocument | null
) => {
  return {
    ...(surveyTheme && {
      ".MuiSvgIcon-root ": {
        fill: surveyTheme.colorSettings.answerTextColor,
      },
      "&:before": {
        borderColor: alpha(
          lighten(surveyTheme.colorSettings.answerTextColor, 0.25),
          0.6
        ),
      },
      "&:after": {
        borderColor: surveyTheme.colorSettings.answerTextColor,
      },
      "&:not(.Mui-disabled):hover::before": {
        borderColor: lighten(surveyTheme.colorSettings.answerTextColor, 0.5),
      },
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: alpha(
          lighten(surveyTheme.colorSettings.answerTextColor, 0.25),
          0.6
        ),
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: surveyTheme.colorSettings.answerTextColor,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: lighten(surveyTheme.colorSettings.answerTextColor, 0.5),
      },
    }),
  };
};
