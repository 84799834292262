import React from "react";

import { CardContent, Box, IconButton } from "@mui/material";

import { useTheme } from "@mui/material/styles";

import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

import { DeleteOutlineOutlined, SettingsOutlined } from "@mui/icons-material";

interface SurveyBlockLayoutProps {
  name: string;
  settingsHandler: () => void;
  deleteHandler: () => void;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  settingsPanelActive: boolean;
  children: React.ReactNode;
}

export default function SurveyBlockLayout({
  name,
  deleteHandler,
  settingsHandler,
  dragHandleProps,
  settingsPanelActive,
  children,
}: SurveyBlockLayoutProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        my: 1,
        ...(settingsPanelActive && {
          borderWidth: "2px",
          borderStyle: "solid",
          borderColor: theme.palette.primary.dark,
          borderRadius: "6px",
        }),
        // If already not active then allow hovering style
        ...(!settingsPanelActive && {
          "&:hover, &:active": {
            borderWidth: "2px",
            borderStyle: "dashed",
            borderColor: theme.palette.primary.dark,
            borderRadius: "6px",
          },
        }),
        ":hover .block-buttons": {
          display: "flex",
        },
        position: "relative",
      }}
      {...dragHandleProps}
    >
      <CardContent>
        {children}

        <Box
          className="block-buttons"
          sx={{ display: "none", position: "absolute", top: 15, right: 10 }}
        >
          <IconButton
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: "white",
              "&:hover": { backgroundColor: theme.palette.primary.main },
            }}
            onClick={settingsHandler}
            size="small"
          >
            <SettingsOutlined fontSize="small" />
          </IconButton>
          <IconButton
            sx={{
              ml: 0.75,
              backgroundColor: theme.palette.error.main,
              color: "white",
              "&:hover": { backgroundColor: theme.palette.error.main },
            }}
            onClick={deleteHandler}
            size="small"
          >
            <DeleteOutlineOutlined fontSize="small" />
          </IconButton>
        </Box>
      </CardContent>
    </Box>
  );
}
