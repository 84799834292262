import React from "react";

import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Typography,
  TypographyProps,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";

type EmptyPlaceHolderProps = {
  sx?: BoxProps["sx"];
  svgIcon: React.ReactElement;
  svgIconStyle?: React.CSSProperties;
  title?: string;
  titleProps?: TypographyProps;
  titleStyle?: BoxProps["sx"];
  description?: string;
  descriptionProps?: TypographyProps;
  descriptionStyle?: BoxProps["sx"];
  showButton?: boolean;
  buttonContent?: React.ReactElement | string;
  buttonProps?: ButtonProps;
};

export default function EmptyPlaceHolder({
  sx = {},
  svgIcon,
  svgIconStyle = {},
  title,
  titleProps = {},
  titleStyle = {},
  description,
  descriptionProps = {},
  descriptionStyle = {},
  showButton = false,
  buttonContent,
  buttonProps = {},
}: EmptyPlaceHolderProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        ...sx,
      }}
    >
      <Box sx={{ mt: 4, mb: 4 }}>
        {React.cloneElement(svgIcon as React.ReactElement, {
          ...{
            height: 75,
            style: {
              fill: theme.palette.primary.main,
              ...svgIconStyle,
            },
          },
        })}
      </Box>
      <Box
        sx={{
          mb: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          px: 2,
        }}
      >
        {title && (
          <Typography
            variant="h5"
            {...titleProps}
            sx={{
              ...titleStyle,
            }}
          >
            {title}
          </Typography>
        )}
        {description && (
          <Typography
            variant="body1"
            textAlign="center"
            {...descriptionProps}
            sx={{
              mt: 1,
              ...descriptionStyle,
            }}
          >
            {description}
          </Typography>
        )}
        {showButton && (
          <Button
            variant="outlined"
            size="large"
            sx={{
              mt: 5,
            }}
            {...buttonProps}
          >
            {buttonContent ?? "No Content"}
          </Button>
        )}
      </Box>
    </Box>
  );
}
