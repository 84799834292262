import React from "react";

import { Box, MenuItem } from "@mui/material";

import { getCountryCallingCode, CountryCode } from "libphonenumber-js";

import { QuestionTypes } from "../../../../types/Blocks";
import NewQuestionRequiredOption from "../../Options/QuestionRequiredOption";
import NewConditionalVisibilityOptions from "../../Options/ConditionalVisibilityOptions";
import DynamicQuestionOption from "../../Options/DynamicQuestionOption";
import getUnicodeFlagIcon from "country-flag-icons/unicode";

import countries from "i18n-iso-countries";
import ControlledSelect from "../../../Inputs/ControlledSelect";
import TabsLayout from "../../../Layout/TabsLayout";
import InfoTooltip from "../../../InfoTooltip";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));

type PhoneBlockSettingsPanelProps = {
  name: string;
  pageIndex: number;
  blockIndex: number;
};

export default function PhoneBlockSettingsPanel({
  name,
  pageIndex,
  blockIndex,
}: PhoneBlockSettingsPanelProps) {
  const countryOptions = Object.keys(countries.getNames("en"))
    .filter((countryCode) => {
      return !["AQ", "BV", "TF", "HM", "PN", "GS", "UM"].includes(countryCode);
    })
    .map((countryCode) => ({
      label: countries.getName(countryCode, "en"),
      value: countryCode,
      icon: getUnicodeFlagIcon(countryCode),
      callingCode: "+" + getCountryCallingCode(countryCode as CountryCode),
      id: countryCode,
    }));

  return (
    <TabsLayout
      tabOptions={{
        options: {
          label: "Options",
          tabPanel: (
            <>
              <ControlledSelect
                name={`${name}.phoneDefaultCountry`}
                label="Default Country"
                options={{}}
                sx={{
                  mt: 1,
                }}
              >
                {countryOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        <Box sx={{ mr: 1, fontSize: 25 }}>{option.icon}</Box>

                        {option.label}
                      </Box>
                    </Box>
                  </MenuItem>
                ))}
              </ControlledSelect>

              <Box sx={{ mt: 3 }}>
                <NewQuestionRequiredOption
                  name={name}
                ></NewQuestionRequiredOption>
              </Box>
              <Box sx={{ mt: 3 }}>
                <DynamicQuestionOption
                  name={name}
                  pageIndex={pageIndex}
                  blockIndex={blockIndex}
                ></DynamicQuestionOption>
              </Box>
            </>
          ),
        },
        visibility: {
          label: "Visibility",
          tabPanel: (
            <NewConditionalVisibilityOptions
              name={name}
              itemType={QuestionTypes.PHONE}
              itemTitle="question"
              pageIndex={pageIndex}
              blockIndex={blockIndex}
            ></NewConditionalVisibilityOptions>
          ),
        },
      }}
    />
  );
}
