import { darken } from "@mui/material";

import { SurveyThemeFormFields } from "../../types/SurveyTheme";

export const generateQuestionDescriptionTextStyle = (
  surveyTheme: SurveyThemeFormFields
) => {
  return {
    ...(surveyTheme && {
      ...(surveyTheme.colorSettings.questionTextColor && {
        color: darken(surveyTheme.colorSettings.questionTextColor, 0.3),
      }),
      ...(surveyTheme.globalFont &&
        !surveyTheme.hasGranularFontSettings && {
          fontFamily: surveyTheme.globalFont,
        }),
      ...(surveyTheme.hasGranularFontSettings &&
        surveyTheme.granularFontSettings.questionText && {
          fontFamily: surveyTheme.granularFontSettings.questionText.fontFamily,
          fontSize:
            parseInt(
              // @ts-ignore
              surveyTheme.granularFontSettings.questionText.fontSize
            ) * 0.6,
          fontWeight: surveyTheme.granularFontSettings.questionText.fontWeight,
        }),

      ...(surveyTheme.contentTextAlignment && {
        textAlign: surveyTheme.contentTextAlignment,
      }),
    }),
  };
};
