import React from "react";

import { Box, BoxProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { hexToRGBA } from "../utils/hexToRGBA";
import { useSurveyTheme } from "../providers/surveyTheme";
import EmptyPlaceHolder from "./Layout/EmptyPlaceHolder";

type EmptyFormPagePlaceHolderProps = {
  title: string;
  description: string;
  icon: React.ReactElement;
  iconStyle?: React.CSSProperties;
  surveyThemeStyling?: boolean;
  sx?: BoxProps["sx"];
};

export default function EmptyFormPagePlaceHolder({
  title,
  description,
  icon,
  iconStyle = {},
  surveyThemeStyling = false,
  sx = {},
}: EmptyFormPagePlaceHolderProps) {
  const surveyTheme = useSurveyTheme();
  const theme = useTheme();

  return (
    <Box
      sx={{
        borderWidth: "1px",
        borderStyle: "dashed",
        borderColor: hexToRGBA(theme.palette.primary.light, 0.25),
        borderRadius: "6px",
        backgroundColor: hexToRGBA(theme.palette.primary.light, 0.075),
        p: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        ...(surveyThemeStyling &&
          surveyTheme && {
            backgroundColor: hexToRGBA(
              surveyTheme.colorSettings.questionTextColor,
              0.075
            ),
            borderColor: hexToRGBA(
              surveyTheme.colorSettings.questionTextColor,
              0.25
            ),
          }),
        ...sx,
      }}
    >
      <EmptyPlaceHolder
        title={title}
        description={description}
        svgIcon={icon}
        svgIconStyle={{
          height: 50,
          fill: theme.palette.primary.main,
          ...iconStyle,
        }}
        showButton={false}
        titleProps={{
          variant: "h6",
        }}
        titleStyle={{
          ...(surveyThemeStyling &&
            surveyTheme && {
              color: surveyTheme.colorSettings.questionTextColor,
            }),
        }}
        descriptionStyle={{
          ...(surveyThemeStyling &&
            surveyTheme && {
              color: surveyTheme.colorSettings.questionTextColor,
            }),
        }}
        descriptionProps={{
          variant: "caption",
        }}
      />
    </Box>
  );
}
