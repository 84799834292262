import React from "react";

import { Box, BoxProps, Grid } from "@mui/material";
import { LiveSurveyThemeDocument } from "../../../../types/SurveyTheme";

import LiveSurveyQuestionText from "../../../SurveyComponents/LiveSurveyQuestionText";
import { ImageChoiceValue } from "../../../../types/Blocks";
import LiveSurveyImageChoiceCard from "../../../SurveyComponents/LiveSurveyImageChoiceCard";
import { useField } from "formik";

type LiveImageChoiceBlockProps = BoxProps & {
  name: string;
  questionText: string;
  questionDescription?: string;
  isQuestionRequired: boolean;
  surveyTheme: LiveSurveyThemeDocument | null;
  imageChoiceValues: ImageChoiceValue[];
  allowMultipleChoices: boolean;
};

export default function LiveImageChoiceBlock({
  name,
  isQuestionRequired,
  questionText,
  questionDescription,
  surveyTheme,
  sx,
  imageChoiceValues,
  allowMultipleChoices,
}: LiveImageChoiceBlockProps) {
  const [field, meta, helpers] = useField<ImageChoiceValue[]>(name);

  const checkIfImageChoiceSelected = (checkImageChoiceId: string) => {
    const findImageChoiceValueIndex = field.value?.findIndex(
      (imageChoiceValue: ImageChoiceValue, imageChoiceValueIndex: number) => {
        return imageChoiceValue.imageChoiceId === checkImageChoiceId;
      }
    );

    if (findImageChoiceValueIndex !== -1) {
      return true;
    } else {
      return false;
    }
  };

  const selectImageChoiceValue = (selectImageChoiceId: string) => {
    const findImageChoiceValue = imageChoiceValues.find(
      (imageChoiceValue: ImageChoiceValue, imageChoiceValueIndex: number) => {
        return imageChoiceValue.imageChoiceId === selectImageChoiceId;
      }
    );

    if (findImageChoiceValue) {
      helpers.setValue([
        ...(allowMultipleChoices ? [...field.value] : []),
        {
          ...findImageChoiceValue,
        },
      ]);
    }
  };

  const unselectImageChoiceValue = (unselectImageChoiceId: string) => {
    helpers.setValue(
      field.value.filter(
        (imageChoiceValue: ImageChoiceValue, imageChoiceValueIndex: number) => {
          return imageChoiceValue.imageChoiceId !== unselectImageChoiceId;
        }
      )
    );
  };

  return (
    <Box sx={{ mb: 3, ...sx }}>
      <LiveSurveyQuestionText
        questionText={questionText}
        questionDescription={questionDescription}
        isQuestionRequired={isQuestionRequired}
        surveyTheme={surveyTheme}
        sx={{
          mb: 3,
        }}
      />

      {imageChoiceValues && (
        <Grid
          container
          sx={{
            display: "flex",
            ...(surveyTheme && {
              justifyContent: surveyTheme.contentTextAlignment,
            }),
          }}
        >
          {imageChoiceValues.map(
            (
              imageChoiceValue: ImageChoiceValue,
              imageChoiceValueIndex: number
            ) => (
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                key={imageChoiceValue.imageChoiceId}
              >
                <LiveSurveyImageChoiceCard
                  surveyTheme={surveyTheme}
                  imageUrl={imageChoiceValue.imageUrl}
                  imageCaption={imageChoiceValue.imageCaption}
                  isSelected={checkIfImageChoiceSelected(
                    imageChoiceValue.imageChoiceId
                  )}
                  selectImageChoice={() => {
                    selectImageChoiceValue(imageChoiceValue.imageChoiceId);
                  }}
                  unselectImageChoice={() => {
                    unselectImageChoiceValue(imageChoiceValue.imageChoiceId);
                  }}
                />
              </Grid>
            )
          )}
        </Grid>
      )}
    </Box>
  );
}
