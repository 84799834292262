import React from "react";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { useTheme } from "@mui/material/styles";

import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

import SurveyQuestionBlockHeader from "../../../SurveyComponents/SurveyQuestionBlockHeader";
import { DateBlockFields } from "../../../../types/Blocks";
import { useField } from "formik";

import { buildDateFormatString } from "../../../../utils/buildDateFormatString";
import { generateTextFieldStyle } from "../../../../utils/styles/generateTextFieldStyle";
import { useSurveyTheme } from "../../../../providers/surveyTheme";
import { generateAnswerTextStyle } from "../../../../utils/styles/generateAnswerTextStyle";
import SurveyBlockLayout from "../../../SurveyComponents/SurveyBlockLayout";

interface DateBlockProps {
  // formik: FormikContextType<IFormCanvasBlocksFields>;
  name: string;
  // index: number;
  pageIndex: number;
  blockIndex: number;
  settingsHandler: () => void;
  deleteHandler: () => void;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  settingsPanelActive: boolean;
}

export default function DateBlock({
  // formik,
  name,
  // index,
  pageIndex,
  blockIndex,
  deleteHandler,
  settingsHandler,
  dragHandleProps,
  settingsPanelActive,
}: DateBlockProps) {
  const [field] = useField<DateBlockFields>(name);
  const surveyTheme = useSurveyTheme();
  const theme = useTheme();

  return (
    <SurveyBlockLayout
      name={name}
      settingsHandler={settingsHandler}
      deleteHandler={deleteHandler}
      settingsPanelActive={settingsPanelActive}
      dragHandleProps={dragHandleProps}
    >
      <SurveyQuestionBlockHeader name={name} />

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          sx={{
            "& .MuiIconButton-root": {
              color: "blue", // Customize the color here
            },
          }}
          slotProps={{
            textField: {
              variant: (surveyTheme && surveyTheme.fieldVariant) ?? "outlined",
              fullWidth: true,
              size: "small",
              sx: {
                ...generateTextFieldStyle(surveyTheme),
                ...(surveyTheme && {
                  "& .MuiIconButton-root": {
                    color: surveyTheme.colorSettings.answerTextColor, // Customize the color here
                  },
                }),
              },
              inputProps: {
                sx: {
                  ...theme.typography.h6,
                  ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
                },
              },
            },
          }}
          format={buildDateFormatString(
            field.value.dateFormat,
            field.value.dateSeperator
          )}
          readOnly
        />
      </LocalizationProvider>
    </SurveyBlockLayout>
  );
}
