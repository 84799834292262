import React from "react";
import { Box, ButtonBase, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { hexToRGBA } from "../../utils/hexToRGBA";
import { generateImageChoiceBoxStyle } from "../../utils/styles/generateImageChoiceBoxStyle";
import { LiveSurveyThemeDocument } from "../../types/SurveyTheme";
import { generateAnswerTextStyle } from "../../utils/styles/generateAnswerTextStyle";

type LiveSurveyImageChoiceCardProps = {
  imageUrl: string;
  imageCaption: string;
  isSelected: boolean;
  selectImageChoice: () => void;
  unselectImageChoice: () => void;
  surveyTheme: LiveSurveyThemeDocument | null;
};

export default function LiveSurveyImageChoiceCard({
  imageUrl,
  imageCaption,
  isSelected,
  selectImageChoice,
  unselectImageChoice,
  surveyTheme,
}: LiveSurveyImageChoiceCardProps) {
  const theme = useTheme();

  return (
    <ButtonBase
      onClick={() => {
        if (isSelected) {
          unselectImageChoice();
        } else {
          selectImageChoice();
        }
      }}
      sx={{
        width: "100%",
        minHeight: 200,
        px: 1,
        mb: 2,
      }}
    >
      <Box
        sx={{
          ...generateImageChoiceBoxStyle(surveyTheme, theme, isSelected),
        }}
      >
        {imageUrl && (
          <Box
            sx={{
              height: 175,
              display: "flex",
            }}
          >
            <img
              style={{
                display: "block",
                margin: "auto",
                width: "100%",
                // height: "100%",
              }}
              src={imageUrl}
              alt="Uploaded Choice "
            />
          </Box>
        )}

        <Box
          sx={{
            width: "100%",
          }}
        >
          <Typography
            sx={{
              my: 2,
              ...theme.typography.body1,
              ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
              ...(surveyTheme && {
                fontSize: theme.typography.body1.fontSize,
                fontWeight: theme.typography.body1.fontWeight,
              }),
              ...(surveyTheme && {
                textAlign: surveyTheme.contentTextAlignment,
              }),
            }}
          >
            {imageCaption}
          </Typography>
        </Box>
      </Box>
    </ButtonBase>
  );
}
