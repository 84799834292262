import { Form, Formik, FormikProps } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { SurveyThemeFormFields } from "../types/SurveyTheme";

import * as yup from "yup";
import ControlledTextField from "./Inputs/ControlledTextField";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import {
  AVAILABLE_FONTS,
  FIELD_VARIANT_OPTIONS,
  SURVEY_CONTENT_LAYOUT_OPTIONS,
  TEXT_ALIGNMENT_OPTIONS,
  VERTICAL_ALIGNMENT_OPTIONS,
} from "../data/SurveyThemeData";
import ControlledFontPicker from "./Inputs/ControlledFontPicker";
import ControlledSwitch from "./Inputs/ControlledSwitch";
import ControlledColorPickerTextField from "./Inputs/ControlledColorPickerTextField";
import CustomBackgroundSettingsOption from "./Blocks/Options/CustomBackgroundSettingsOption";

import ControlledToggleButtonGroup from "./Inputs/ControlledToggleButtonGroup";
import LogoSettingsOption from "./Blocks/Options/LogoSettingsOptions";
import FontSettingsMiniForm from "./MiniForms/FontSettingsMiniForm";
import { ArrowBack } from "@mui/icons-material";
import { useProtectedApi } from "../hooks/useProtectedApi";
import { SURVEY_THEME_URL } from "../data/constants";
import { AxiosError } from "axios";
import {
  useSavedSurveyTheme,
  useSurveyTheme,
  useUpdateSurveyTheme,
} from "../providers/surveyTheme";
import SurveyThemeUpdater from "./SurveyThemeUpdater";
import { INITIAL_FORM_STATE } from "../data/SurveyThemeData";

export enum FORM_MODES {
  EDIT = "edit",
  CREATE_NEW = "create_new",
}

const FORM_VALIDATION = yup.object().shape({});

type SurveyThemeFormProps = {
  currentFormMode: FORM_MODES;
  backClickHandler: () => void;
};

export default function SurveyThemeForm({
  currentFormMode,
  backClickHandler,
}: SurveyThemeFormProps) {
  const isInitialMount = useRef(true);

  const currentSurveyTheme = useSurveyTheme();
  const savedSurveyTheme = useSavedSurveyTheme();

  const updateSurveyTheme = useUpdateSurveyTheme();

  const ProtectedApi = useProtectedApi();

  const [initialLoading, setInitialLoading] = useState(false);
  const [initialError, setInitialError] = useState("");

  const [initialFormValues, setInitialFormValues] =
    useState<SurveyThemeFormFields>({
      ...INITIAL_FORM_STATE,
    });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (values: SurveyThemeFormFields) => {
    try {
      setError("");
      setLoading(true);

      if (currentFormMode === FORM_MODES.EDIT) {
        if (currentSurveyTheme?._id) {
          await ProtectedApi.patch(
            `${SURVEY_THEME_URL}/${currentSurveyTheme?._id}`,
            values
          );
        } else {
          throw new Error("Failed to update survey theme");
        }
      } else {
        await ProtectedApi.post(SURVEY_THEME_URL, values);
      }

      setLoading(false);
      backClickHandler();
    } catch (error) {
      setLoading(false);
      if (error instanceof AxiosError && !error?.response) {
        setError("No response from server.");
      } else if (error instanceof AxiosError && error?.response?.data) {
        setError(error.response.data.error.message);
      } else {
        setError("Fialed to save");
      }
    }
  };

  const getSurveyThemeDetails = async (surveyThemeId: string) => {
    try {
      setInitialError("");
      setInitialLoading(true);

      const response = await ProtectedApi.get(
        `${SURVEY_THEME_URL}/${surveyThemeId}`
      );

      if (response.data.success.data.surveyTheme) {
        updateSurveyTheme &&
          updateSurveyTheme(response.data.success.data.surveyTheme);

        setInitialFormValues(response.data.success.data.surveyTheme);
      }

      setInitialLoading(false);
    } catch (error) {
      setInitialLoading(false);
      if (error instanceof AxiosError && !error?.response) {
        setInitialError("No response from server.");
      } else if (error instanceof AxiosError && error?.response?.data) {
        setInitialError(error.response.data.error.message);
      } else {
        setInitialError("Fialed to get forms.");
      }
    }
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      if (currentFormMode === FORM_MODES.EDIT) {
        if (currentSurveyTheme?._id) {
          getSurveyThemeDetails(currentSurveyTheme?._id);
        } else {
          setInitialError("No Survey Theme Selected To Edit");
        }
      }
    }
  }, []);

  return (
    <>
      {initialLoading && !initialError && (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      )}
      {initialError && !initialLoading && (
        <Box
          sx={{
            dispaly: "flex",
            flexDirection: "column",
            px: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              pt: 1,
            }}
          >
            <IconButton
              onClick={() => {
                updateSurveyTheme && updateSurveyTheme(savedSurveyTheme);
                backClickHandler();
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography>Theme Settings</Typography>
          </Box>
          <Alert sx={{ mb: 3 }} severity="error">
            {initialError}
          </Alert>
        </Box>
      )}

      {!initialLoading && !initialError && (
        <Formik
          initialValues={{ ...initialFormValues } as SurveyThemeFormFields}
          validationSchema={FORM_VALIDATION}
          onSubmit={async (values: SurveyThemeFormFields) => {
            handleSubmit({
              title: values.title,
              colorSettings: values.colorSettings,
              logoSettings: values.logoSettings,
              mainBackgroundSettings: values.mainBackgroundSettings,
              contentBackgroundSettings: values.contentBackgroundSettings,
              globalFont: values.globalFont,
              hasGranularFontSettings: values.hasGranularFontSettings,
              granularFontSettings: values.granularFontSettings,
              contentSectionPlacement: values.contentSectionPlacement,
              contentTextAlignment: values.contentTextAlignment,
              contentVerticalAlignment: values.contentVerticalAlignment,
              fieldVariant: values.fieldVariant,
            });
          }}
          validateOnChange
          validateOnBlur
        >
          {({
            values,
            submitForm,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
          }: FormikProps<SurveyThemeFormFields>) => {
            return (
              <Form style={{ height: "100%" }}>
                <SurveyThemeUpdater />
                <Box
                  sx={{
                    height: "92vh",
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      dispaly: "flex",
                      flexDirection: "column",
                      px: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        pt: 1,
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          updateSurveyTheme &&
                            updateSurveyTheme(savedSurveyTheme);
                          backClickHandler();
                        }}
                      >
                        <ArrowBack />
                      </IconButton>
                      <Typography>Theme Settings</Typography>
                    </Box>
                  </Box>

                  {!initialLoading && !initialError && (
                    <>
                      <Box
                        sx={{
                          px: 2,
                          py: 3,
                          overflow: "scroll",
                          height: "100%",
                        }}
                      >
                        {error && (
                          <Alert sx={{ mb: 3 }} severity="error">
                            {error}
                          </Alert>
                        )}

                        <ControlledTextField
                          name="title"
                          label="Title"
                          id="title"
                        />

                        <Box
                          sx={{
                            mt: 3,
                          }}
                        >
                          <Typography sx={{ mb: 2 }}>Survey Layout</Typography>
                          <ControlledToggleButtonGroup
                            name="contentSectionPlacement"
                            label="Survey Layout"
                            options={SURVEY_CONTENT_LAYOUT_OPTIONS}
                            size="large"
                            fullWidth
                          />
                        </Box>

                        {!values.hasGranularFontSettings && (
                          <ControlledFontPicker
                            sx={{ mt: 3 }}
                            name="globalFont"
                            label="Global Font"
                            options={AVAILABLE_FONTS}
                          />
                        )}

                        <Box
                          sx={{
                            mt: 2,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="body1">
                            Custom Font Settings
                          </Typography>
                          <ControlledSwitch
                            name="hasGranularFontSettings"
                            id="hasGranularFontSettings"
                            size="small"
                          />
                        </Box>

                        {values.hasGranularFontSettings && (
                          <>
                            <FontSettingsMiniForm
                              name="granularFontSettings.questionText"
                              label="Question Text"
                              sx={{ mt: 3 }}
                            />

                            <FontSettingsMiniForm
                              name="granularFontSettings.answerText"
                              label="Answer Text"
                              sx={{ mt: 3 }}
                            />
                          </>
                        )}

                        <ControlledColorPickerTextField
                          sx={{ mt: 3 }}
                          label="Question Text Color"
                          name="colorSettings.questionTextColor"
                          id="colorSettings.questionTextColor"
                        />
                        <ControlledColorPickerTextField
                          sx={{ mt: 3 }}
                          label="Answer Text Color"
                          name="colorSettings.answerTextColor"
                          id="colorSettings.answerTextColor"
                        />
                        <ControlledColorPickerTextField
                          sx={{ mt: 3 }}
                          label="Button Text Color"
                          name="colorSettings.buttonTextColor"
                          id="colorSettings.buttonTextColor"
                        />
                        <ControlledColorPickerTextField
                          sx={{ mt: 3 }}
                          label="Button Background Color"
                          name="colorSettings.buttonBackgroundColor"
                          id="colorSettings.buttonBackgroundColor"
                        />

                        <Box
                          sx={{
                            mt: 3,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography>Field Variant</Typography>
                          <ControlledToggleButtonGroup
                            name="fieldVariant"
                            label="Field Variant"
                            options={FIELD_VARIANT_OPTIONS}
                            size="small"
                          />
                        </Box>

                        <Box
                          sx={{
                            mt: 3,
                          }}
                        >
                          <CustomBackgroundSettingsOption
                            name="mainBackgroundSettings"
                            label="Survey Background"
                          />
                        </Box>

                        <Box
                          sx={{
                            mt: 3,
                          }}
                        >
                          <CustomBackgroundSettingsOption
                            name="contentBackgroundSettings"
                            label="Content Background"
                          />
                        </Box>

                        <Box
                          sx={{
                            mt: 3,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>Content Alignment</Typography>
                          <ControlledToggleButtonGroup
                            name="contentTextAlignment"
                            label="Content Alignment"
                            options={TEXT_ALIGNMENT_OPTIONS}
                            size="small"
                          />
                        </Box>

                        <Box
                          sx={{
                            mt: 3,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>Content Vertical Alignment</Typography>
                          <ControlledToggleButtonGroup
                            name="contentVerticalAlignment"
                            label="Content Vertical Alignment"
                            options={VERTICAL_ALIGNMENT_OPTIONS}
                            size="small"
                          />
                        </Box>

                        <Box
                          sx={{
                            mt: 3,
                          }}
                        >
                          <LogoSettingsOption
                            name="logoSettings"
                            label="Logo"
                          />
                        </Box>
                      </Box>
                      <Box sx={{ px: 2, mt: 2 }}>
                        <Button
                          // Does not work because this form will be nested inside another form
                          // type="submit"
                          onClick={submitForm}
                          variant="contained"
                          fullWidth
                          disabled={loading}
                        >
                          {loading ? (
                            <CircularProgress color="primary" />
                          ) : (
                            "Submit"
                          )}{" "}
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
}
