import React, { useState } from "react";

import {
  Box,
  BoxProps,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";

import ControlledTextField from "../../../Inputs/ControlledTextField";
import {
  FieldVariant,
  LiveSurveyThemeDocument,
} from "../../../../types/SurveyTheme";

import { generateAnswerTextStyle } from "../../../../utils/styles/generateAnswerTextStyle";
import LiveSurveyQuestionText from "../../../SurveyComponents/LiveSurveyQuestionText";

import {
  format,
  parse,
  getCountryCallingCode,
  CountryCode,
  AsYouType,
  isValidPhoneNumber,
} from "libphonenumber-js";

import getUnicodeFlagIcon from "country-flag-icons/unicode";

import ExamplePhoneNumbers from "../../../../utils/examplePhoneNumbers.json";

import countries from "i18n-iso-countries";
import { useField } from "formik";
import { generateTextFieldStyle } from "../../../../utils/styles/generateTextFieldStyle";
import { generateSelectFieldStyle } from "../../../../utils/styles/generateSelectFieldStyle";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));

type LivePhoneBlockProps = BoxProps & {
  name: string;
  questionText: string;
  questionDescription?: string;
  isQuestionRequired: boolean;
  surveyTheme: LiveSurveyThemeDocument | null;
  defaultCountry: CountryCode;
};

export default function LivePhoneBlock({
  name,
  isQuestionRequired,
  questionText,
  questionDescription,
  surveyTheme,
  sx,
  defaultCountry,
}: LivePhoneBlockProps) {
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);

  const [field, meta, helpers] = useField<string>({
    name: name,
    validate: (value) => {
      let message;
      /* We have to define the validation here. 
         becuase the validation can be different
         based on the country selected
      */
      if (!isValidPhoneNumber(value, selectedCountry)) {
        message = "Invlaid Phone Number";
      }
      return message;
    },
  });

  const theme = useTheme();
  const countryOptions = Object.keys(countries.getNames("en"))
    .filter((countryCode) => {
      return !["AQ", "BV", "TF", "HM", "PN", "GS", "UM"].includes(countryCode);
    })
    .map((countryCode) => ({
      label: countries.getName(countryCode, "en"),
      value: countryCode,
      icon: getUnicodeFlagIcon(countryCode),
      callingCode: "+" + getCountryCallingCode(countryCode as CountryCode),
      id: countryCode,
    }));

  const getExamplePhoneNumberFormat = (
    countryCode: CountryCode
  ): string | undefined => {
    const findExampleNumber = ExamplePhoneNumbers.find((value: any) => {
      return value.id === countryCode;
    });

    if (findExampleNumber) {
      return format(
        parse(findExampleNumber.exampleNumber, countryCode),
        "INTERNATIONAL"
      ).slice(1);
    }
  };

  const handlePhoneNumberChange = (
    newValue: string,
    currentCountryCode: CountryCode
  ) => {
    // Remove any non-numeric characters from the input
    const inputValue = newValue.replace(/\D/g, "");

    const formattedInput = new AsYouType(currentCountryCode).input(inputValue);

    helpers.setValue(formattedInput);
  };

  return (
    <Box sx={{ mb: 3, ...sx }}>
      <LiveSurveyQuestionText
        questionText={questionText}
        questionDescription={questionDescription}
        isQuestionRequired={isQuestionRequired}
        surveyTheme={surveyTheme}
        sx={{
          mb: 3,
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
        }}
      >
        <FormControl
          sx={{
            mr: 1,
          }}
        >
          {/* // TODO - Make sure Select field follow survey Theme when selected and the field bordergets outlined  */}
          <Select
            size="small"
            value={selectedCountry ?? "GB"}
            variant={(surveyTheme && surveyTheme.fieldVariant) ?? "outlined"}
            onChange={(event) => {
              setSelectedCountry(event.target.value as CountryCode);
            }}
            sx={{
              ...generateSelectFieldStyle(surveyTheme),
            }}
            renderValue={(value: any) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    ...(surveyTheme && {
                      ...(surveyTheme.fieldVariant === FieldVariant.OUTLINED
                        ? {
                            mr: 1,
                          }
                        : {
                            mx: 1,
                          }),
                    }),
                  }}
                >
                  {getUnicodeFlagIcon(value)}
                </Box>
              );
            }}
            SelectDisplayProps={{
              style: {
                ...theme.typography.h6,
                ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
                lineHeight: undefined,
              },
            }}
          >
            {countryOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Box sx={{ mr: 1 }}>{option.icon}</Box>

                    {option.label}
                  </Box>
                  {option.callingCode}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <ControlledTextField
          name={name}
          required={isQuestionRequired}
          placeholder={getExamplePhoneNumberFormat(selectedCountry)}
          variant={(surveyTheme && surveyTheme.fieldVariant) ?? "outlined"}
          size="small"
          fullWidth
          sx={{
            ...generateTextFieldStyle(surveyTheme),
          }}
          InputProps={{
            // Make color of this dependant on answer textstyle
            startAdornment: <InputAdornment position="start">+</InputAdornment>,
          }}
          inputProps={{
            style: {
              ...theme.typography.h6,
              ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
            },
          }}
          onChange={(event) => {
            handlePhoneNumberChange(event?.target.value, selectedCountry);
          }}
        ></ControlledTextField>
      </Box>
    </Box>
  );
}

// {
//   "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
//   "fontWeight": 500,
//   "fontSize": "1.25rem",
//   "lineHeight": 1.6,
//   "letterSpacing": "0.0075em"
// }
