import { SurveyThemeFormFields } from "../../types/SurveyTheme";

export const generateQuestionTextStyle = (
  surveyTheme: SurveyThemeFormFields
) => {
  return {
    ...(surveyTheme && {
      ...(surveyTheme.colorSettings.questionTextColor && {
        color: surveyTheme.colorSettings.questionTextColor,
      }),
      ...(surveyTheme.globalFont &&
        !surveyTheme.hasGranularFontSettings && {
          fontFamily: surveyTheme.globalFont,
        }),
      ...(surveyTheme.hasGranularFontSettings &&
        surveyTheme.granularFontSettings.questionText && {
          fontFamily: surveyTheme.granularFontSettings.questionText.fontFamily,
          fontSize: parseInt(
            // @ts-ignore
            surveyTheme.granularFontSettings.questionText.fontSize
          ),
          fontWeight: surveyTheme.granularFontSettings.questionText.fontWeight,
        }),

      ...(surveyTheme.contentTextAlignment && {
        textAlign: surveyTheme.contentTextAlignment,
      }),
    }),
  };
};
