import React, { useState } from "react";

import { useField } from "formik";

import { Box, Button, Typography } from "@mui/material";

import { useTheme } from "@mui/material/styles";

import ControlledSwitch from "../../Inputs/ControlledSwitch";

import {
  InsertPhotoOutlined,
  PhotoSizeSelectLargeOutlined,
  DeleteOutlineOutlined,
} from "@mui/icons-material";
import { hexToRGBA } from "../../../utils/hexToRGBA";
import SelectImageDialog from "../../Images/SelectImageDialog";
import { SurveyThemeLogoSettings } from "../../../types/SurveyTheme";
import ControlledTextField from "../../Inputs/ControlledTextField";
import ControlledSelect from "../../Inputs/ControlledSelect";
import {
  HORIZONTAL_LOGO_POSITION_OPTIONS,
  LOGO_RELATIVE_TO_OPTIONS,
  VERTICAL_LOGO_POSITION_OPTIONS,
} from "../../../data/SurveyThemeData";

type LogoSettingsOptionProps = {
  // blockIndex: number;
  name: string;
  label?: string;
};

export default function LogoSettingsOption({
  // blockIndex,
  name,
  label,
}: LogoSettingsOptionProps) {
  const theme = useTheme();

  const [showSelectImageDialog, setShowSelectImageDialog] = useState(false);

  const [field, meta, helpers] = useField<SurveyThemeLogoSettings>(name);

  const handleDeleteImage = () => {
    helpers.setValue({
      ...field.value,
      logoUrl: "",
    });
  };

  const handleSelectImageDialogOpen = () => {
    setShowSelectImageDialog(true);
  };

  const handleSelectImageDialogClose = () => {
    setShowSelectImageDialog(false);
  };

  const setLogoImageUrl = (newImageUrl: string) => {
    helpers.setValue({
      ...field.value,
      logoUrl: newImageUrl,
    });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="body1">{label ?? "Background"}</Typography>
        <ControlledSwitch
          name={`${name}.hasSurveyLogo`}
          id={`${name}.hasSurveyLogo`}
          size="small"
        />
      </Box>

      {field.value.hasSurveyLogo && (
        <>
          <Box
            sx={{
              borderWidth: "1px",
              borderStyle: "dashed",
              borderColor: hexToRGBA(theme.palette.primary.light, 0.25),
              borderRadius: "6px",
              backgroundColor: hexToRGBA(theme.palette.primary.light, 0.075),
              p: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: 200,
              overflow: "hidden",
              mb: 2,
            }}
          >
            <Box sx={{}}>
              {field.value.logoUrl && (
                <img
                  style={{
                    display: "block",
                    margin: "auto",
                    width: "100%",
                    height: "100%",
                  }}
                  src={field.value.logoUrl}
                  alt="Uploaded Logo"
                />
              )}
              {!field.value.logoUrl && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <PhotoSizeSelectLargeOutlined
                    color="primary"
                    sx={{ mb: 1 }}
                    fontSize="large"
                  />
                  <Typography variant="body2" color="primary">
                    No Image Uploaded
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Button
              fullWidth
              component="label"
              startIcon={<InsertPhotoOutlined />}
              onClick={handleSelectImageDialogOpen}
            >
              Upload
            </Button>
            {field.value.logoUrl && (
              <Button
                fullWidth
                component="label"
                color="error"
                startIcon={<DeleteOutlineOutlined />}
                onClick={handleDeleteImage}
              >
                Delete
              </Button>
            )}
          </Box>
          <ControlledTextField
            sx={{
              mt: 3,
            }}
            name={`${name}.logoAltText`}
            label="Logo Alt Text"
          />

          <ControlledSelect
            sx={{ mt: 3 }}
            name={`${name}.relativeTo`}
            label="Relative To"
            options={LOGO_RELATIVE_TO_OPTIONS}
          />

          <ControlledSelect
            sx={{ mt: 3 }}
            name={`${name}.verticalPosition`}
            label="Vertical Position"
            options={VERTICAL_LOGO_POSITION_OPTIONS}
          />

          <ControlledSelect
            sx={{ mt: 3 }}
            name={`${name}.horizontalPosition`}
            label="Horizontal Position"
            options={HORIZONTAL_LOGO_POSITION_OPTIONS}
          />
        </>
      )}

      <SelectImageDialog
        show={showSelectImageDialog}
        closeHandler={handleSelectImageDialogClose}
        setImageHandler={setLogoImageUrl}
      />
    </>
  );
}
