import React from "react";

import { Box, BoxProps } from "@mui/material";
import { LiveSurveyThemeDocument } from "../../../../types/SurveyTheme";
import { useTheme, lighten, alpha } from "@mui/material/styles";
import LiveSurveyQuestionText from "../../../SurveyComponents/LiveSurveyQuestionText";
import { DropdownValue } from "../../../../types/Blocks";
import ControlledSelect from "../../../Inputs/ControlledSelect";
import { generateTextFieldStyle } from "../../../../utils/styles/generateTextFieldStyle";
import { generateAnswerTextStyle } from "../../../../utils/styles/generateAnswerTextStyle";
import { useField } from "formik";

type LiveDropdownBlockProps = BoxProps & {
  name: string;
  questionText: string;
  questionDescription?: string;
  isQuestionRequired: boolean;
  surveyTheme: LiveSurveyThemeDocument | null;
  dropdownValues: DropdownValue[];
};

export default function LiveDropdownBlock({
  name,
  isQuestionRequired,
  questionText,
  questionDescription,
  dropdownValues,
  surveyTheme,
  sx,
}: LiveDropdownBlockProps) {
  const [field] = useField(name);
  const theme = useTheme();
  const dropdownValueOptions = (dropdownValuesList: DropdownValue[]) => {
    return dropdownValuesList.reduce(
      (previousValue, currentValue, currentIndex) => {
        return {
          ...previousValue,
          [currentValue.value]: currentValue.value,
        };
      },
      {}
    );
  };
  return (
    <Box sx={{ mb: 3, ...sx }}>
      <LiveSurveyQuestionText
        questionText={questionText}
        questionDescription={questionDescription}
        isQuestionRequired={isQuestionRequired}
        surveyTheme={surveyTheme}
        sx={{
          mb: 3,
        }}
      />

      {/* // TODO - We will assume that the values each dropdown
          // TODO - value are unique in the list. We will need to add 
          // TODO - validation to make sure this is the case in the future.
          // TODO - when the user is entering the options 
      */}
      {/* // TODO - Add styling from the survey theme to the dropdown menu */}
      <ControlledSelect
        name={name}
        id={name}
        options={dropdownValueOptions(dropdownValues)}
        variant={(surveyTheme && surveyTheme.fieldVariant) ?? "outlined"}
        sx={{
          ...generateTextFieldStyle(surveyTheme),
          ...(surveyTheme && {
            ".MuiSvgIcon-root ": {
              fill: surveyTheme.colorSettings.answerTextColor,
            },
          }),
        }}
        // will need to investigate why sx is required inside inputProps in this case but in others it was style in inputProps
        inputProps={{
          sx: {
            ...theme.typography.h6,
            ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
          },
        }}
        SelectProps={{
          MenuProps: {
            sx: {
              "& ul": {
                ...theme.typography.h6,
                ...(surveyTheme && generateAnswerTextStyle(surveyTheme)),
                ...(surveyTheme && {
                  "& .Mui-selected": {
                    backgroundColor: `${alpha(
                      lighten(surveyTheme.colorSettings.answerTextColor, 0.25),
                      0.15
                    )} !important`,
                  },
                }),
              },
            },
          },
        }}
      />
    </Box>
  );
}
