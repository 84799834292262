import React from "react";

type EmptyPageIconProps = React.SVGProps<SVGSVGElement> & {
  style?: React.CSSProperties;
};

export default function EmptyPageIcon({
  style,
  ...otherProps
}: EmptyPageIconProps) {
  return (
    <svg
      //   height={height ?? "50"}
      viewBox="0 0 629 603"
      style={{
        ...style,
      }}
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path d="M36.5333 1.49751C22.8 5.89748 10.6667 16.6975 4.4 30.0308L0.8 37.6308L0.4 112.697L0 187.764L4 191.631C9.46667 197.097 16.1333 197.097 21.6 191.764L25.4667 187.897V165.764V143.631H236.8C464.8 143.631 453.733 143.897 457.467 137.097C459.2 133.764 459.2 126.831 457.467 123.497C453.733 116.697 464.8 116.964 236.8 116.964H25.3333L25.7333 80.5641C26.1333 49.4975 26.4 43.7641 28.4 40.1641C31.6 34.2974 39.2 28.0308 45.0667 26.4308C52.1333 24.4308 577.467 24.4308 584.533 26.4308C590.4 28.0308 598 34.2974 601.2 40.1641C603.2 43.7641 603.467 49.4975 603.867 80.5641L604.267 116.964H556.8C505.333 116.964 503.6 117.231 500.4 124.297C497.333 131.097 499.467 138.564 505.6 141.764C508.667 143.231 518.267 143.631 556.8 143.631H604.133V321.231C604.133 436.164 603.6 500.697 602.8 503.764C600.933 510.697 596.267 517.097 590.8 520.164C586.4 522.831 583.467 522.964 539.733 523.631L493.2 524.297L489.333 528.697C485.067 533.497 484.533 537.897 487.467 543.497C490.933 550.164 493.2 550.431 543.6 550.031L590.133 549.631L599.067 545.364C610.4 540.031 619.2 531.231 624.533 519.897L628.8 510.964V274.297V37.6308L624.533 28.8308C619.333 18.1641 609.733 8.83083 599.067 3.89748L591.467 0.297501L316.8 0.030838C92.2667 -0.102524 41.0667 0.164139 36.5333 1.49751Z" />
      <path d="M99.3333 210.297C92.6667 212.964 89.8667 223.764 94.1333 229.764C98.2667 235.764 98.1333 235.631 211.733 235.364L320.133 234.964L323.467 231.497C325.333 229.631 327.2 225.897 327.733 223.231C328.4 218.964 327.867 217.764 323.733 213.631L319.067 208.964L210.667 209.097C150.933 209.097 100.8 209.631 99.3333 210.297Z" />
      <path d="M4.00003 239.497L0.133362 243.364V375.497C0.133362 507.097 0.133362 507.497 2.93336 515.231C8.40003 529.897 17.0667 539.097 31.8667 546.031L39.4667 549.631L146.267 550.031L253.2 550.431L257.2 546.964C262 542.964 263.2 537.764 260.667 531.897C257.2 523.364 260.533 523.631 147.467 523.631C48.1334 523.631 45.2 523.497 40.1334 520.964C33.8667 517.764 28.9334 511.497 26.9334 504.297C25.8667 500.564 25.4667 459.364 25.4667 371.231V243.364L21.6 239.497C18.6667 236.697 16.4 235.631 12.8 235.631C9.20003 235.631 6.93336 236.697 4.00003 239.497Z" />
      <path d="M96 304.831C88.2667 312.564 92.1334 324.964 102.933 326.964C105.467 327.364 131.6 327.631 161.2 327.364L215.067 326.964L218.933 322.564C223.067 317.897 223.733 313.764 221.2 308.297C217.867 301.097 217.067 300.964 156.133 300.964H99.8667L96 304.831Z" />
      <path d="M305.067 344.431C302.533 346.831 301.467 349.764 300.933 354.697C300 362.964 289.6 538.431 289.067 553.364C288.8 563.631 288.933 563.897 293.2 567.764C295.6 569.897 299.067 571.631 300.8 571.631C302.667 571.631 316.133 565.631 330.8 558.297C345.467 550.964 357.867 544.964 358.533 544.964C359.067 544.964 365.467 556.964 372.8 571.764C380.133 586.564 387.333 599.364 388.8 600.431C393.867 604.031 400 602.564 428 590.164C443.067 583.497 457.6 576.831 460.267 575.364C465.467 572.431 468.133 568.297 468.133 562.964C468.133 561.231 461.867 547.231 454.133 531.631L440.133 503.497L467.6 490.031C489.733 478.964 495.333 475.764 497.067 472.564C499.6 467.631 498.4 460.964 494.4 457.364C492.8 455.764 452.667 428.964 405.2 397.764C328.533 347.364 318.267 340.964 313.733 340.964C309.867 340.964 307.6 341.897 305.067 344.431ZM393.867 421.897L458.933 464.831L437.333 475.631C425.6 481.631 414.4 487.897 412.667 489.631C406.933 495.364 408.133 499.764 423.467 529.764L437.6 557.097L430.533 560.164C426.667 561.897 419.333 565.231 414.267 567.497C409.2 569.764 404.4 571.631 403.6 571.631C402.8 571.631 396.667 560.697 390.133 547.364C383.467 534.031 376.4 521.097 374.267 518.697C368.267 511.631 363.867 512.431 338.667 525.097C326.8 530.964 316.933 535.631 316.667 535.231C316.4 534.964 318.267 500.297 320.8 458.164C323.333 416.031 325.467 380.564 325.467 379.231C325.467 377.631 326 377.097 327.2 377.764C328 378.431 358.133 398.164 393.867 421.897Z" />
    </svg>
  );
}
