import React from "react";

import { Box, Typography } from "@mui/material";

import { QuestionTypes } from "../../../../types/Blocks";
import NewQuestionRequiredOption from "../../Options/QuestionRequiredOption";
import NewConditionalVisibilityOptions from "../../Options/ConditionalVisibilityOptions";
import DynamicQuestionOption from "../../Options/DynamicQuestionOption";
import ControlledTextField from "../../../Inputs/ControlledTextField";
import ControlledSwitch from "../../../Inputs/ControlledSwitch";
import TabsLayout from "../../../Layout/TabsLayout";
import InfoTooltip from "../../../InfoTooltip";

type SliderBlockSettingsPanelProps = {
  name: string;
  pageIndex: number;
  blockIndex: number;
};

export default function SliderBlockSettingsPanel({
  name,
  pageIndex,
  blockIndex,
}: SliderBlockSettingsPanelProps) {
  return (
    <>
      <TabsLayout
        tabOptions={{
          options: {
            label: "Options",
            tabPanel: (
              <>
                <Box sx={{ mt: 1 }}>
                  <ControlledTextField
                    name={`${name}.sliderMinNumber`}
                    id={`${name}.sliderMinNumber`}
                    label="Min"
                    type="number"
                  />
                </Box>

                <Box sx={{ mt: 3 }}>
                  <ControlledTextField
                    name={`${name}.sliderMaxNumber`}
                    id={`${name}.sliderMaxNumber`}
                    label="Max"
                    type="number"
                  />
                </Box>

                <Box sx={{ mt: 3 }}>
                  <ControlledTextField
                    name={`${name}.sliderStep`}
                    id={`${name}.sliderStep`}
                    label="Step"
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InfoTooltip title="Control the intervals in which the slider can be dragged in." />
                      ),
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    mt: 3,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body1" sx={{ mr: 1 }}>
                        Custom Labels
                      </Typography>
                      <InfoTooltip title="Setup custom labels to appear below the slider at the `minimum` , `middle` and `maximum` positions" />
                    </Box>
                    <ControlledSwitch
                      name={`${name}.sliderHasCustomLabels`}
                      id={`${name}.sliderHasCustomLabels`}
                      size="small"
                    />
                  </Box>
                </Box>

                <Box sx={{ mt: 3 }}>
                  <NewQuestionRequiredOption
                    name={name}
                  ></NewQuestionRequiredOption>
                </Box>
                <Box sx={{ mt: 3 }}>
                  <DynamicQuestionOption
                    name={name}
                    pageIndex={pageIndex}
                    blockIndex={blockIndex}
                  ></DynamicQuestionOption>
                </Box>
              </>
            ),
          },
          visibility: {
            label: "Visibility",
            tabPanel: (
              <NewConditionalVisibilityOptions
                name={name}
                itemType={QuestionTypes.SLIDER}
                itemTitle="question"
                pageIndex={pageIndex}
                blockIndex={blockIndex}
              ></NewConditionalVisibilityOptions>
            ),
          },
        }}
      />
    </>
  );
}
