import React, { useState } from "react";

import { Tabs, Tab } from "@mui/material";

import TabPanel from "../Helpers/TabPanel";

export type TabOption = {
  label: string;
  tabPanel: React.ReactNode;
};

type TabsLayoutProps = {
  tabOptions: {
    [key: string]: TabOption;
  };
};

export default function TabsLayout({ tabOptions }: TabsLayoutProps) {
  const [currentTab, setCurrentTab] = useState(Object.keys(tabOptions)[0]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
      >
        {Object.keys(tabOptions).map((key: string) => {
          return (
            <Tab
              key={key}
              sx={{
                p: 1,
                ...(Object.keys(tabOptions).length > 1 && { flexGrow: 1 }),
              }}
              value={key}
              label={tabOptions[key].label}
            />
          );
        })}
      </Tabs>

      {Object.keys(tabOptions).map((key: string) => {
        return (
          <TabPanel key={key} value={currentTab} index={key}>
            {tabOptions[key].tabPanel}
          </TabPanel>
        );
      })}
    </>
  );
}
