import { alpha } from "@mui/material/styles";
import { LiveSurveyThemeDocument } from "../../types/SurveyTheme";
import { getTextColorForBackground } from "../getTextColorForBackground";

export const generateSliderStyling = (
  surveyTheme: LiveSurveyThemeDocument | null
) => {
  return {
    ...(surveyTheme && {
      color: surveyTheme.colorSettings.answerTextColor,
      "& .MuiSlider-valueLabel": {
        color: getTextColorForBackground(
          surveyTheme.colorSettings.answerTextColor
        ),
        backgroundColor: surveyTheme.colorSettings.answerTextColor,
      },

      "& .MuiSlider-thumb": {
        "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
          boxShadow: `0px 0px 8px ${alpha(
            surveyTheme.colorSettings.answerTextColor,
            0.75
          )}`,
        },
      },
    }),
  };
};
