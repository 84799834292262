import React from "react";

import { Box, Card, Rating } from "@mui/material";

import { useField } from "formik";
import { StarRatingIcons } from "../../../../types/Blocks";
import { STAR_RATING_ICON_SHAPES } from "../../../../data/BlockFields";
import { LiveSurveyThemeDocument } from "../../../../types/SurveyTheme";
import LiveSurveyQuestionText from "../../../SurveyComponents/LiveSurveyQuestionText";

type LiveStarRatingBlockProps = {
  name: string;
  questionText: string;
  questionDescription?: string;
  isQuestionRequired: boolean;
  starRatingScale: string;
  starRatingIcon: StarRatingIcons;
  starRatingColor: string;
  surveyTheme: LiveSurveyThemeDocument | null;
};

export default function LiveStarRatingBlock({
  name,
  questionText,
  questionDescription,
  isQuestionRequired,
  starRatingScale,
  starRatingIcon,
  starRatingColor,
  surveyTheme,
}: LiveStarRatingBlockProps) {
  const [field] = useField<number>(name);

  return (
    <Box sx={{ mb: 3 }}>
      <LiveSurveyQuestionText
        questionText={questionText}
        questionDescription={questionDescription}
        isQuestionRequired={isQuestionRequired}
        surveyTheme={surveyTheme}
        sx={{
          mb: 3,
        }}
      />

      <Rating
        // TODO - Fix the type of the scale value that is set. Make sure it is a number when set
        name={name}
        // @ts-ignore
        value={parseInt(field.value)}
        onChange={field.onChange}
        max={parseInt(starRatingScale)}
        icon={React.cloneElement(STAR_RATING_ICON_SHAPES[starRatingIcon].icon, {
          sx: {
            fontSize: "40px",
            color: starRatingColor,
          },
        })}
        emptyIcon={React.cloneElement(
          STAR_RATING_ICON_SHAPES[starRatingIcon].emptyIcon,
          {
            sx: {
              fontSize: "40px",
              color: starRatingColor,
            },
          }
        )}
      />
    </Box>
  );
}
