import React, { useState } from "react";

import { useField } from "formik";

import { Tabs, Tab } from "@mui/material";

import TabPanel from "./Helpers/TabPanel";

import { EditSurveyThemeSettingsPanelTabs } from "../types/Drawers";
import MySurveyThemesList from "./MySurveyThemesList";

type SurveyThemeSettingsPanelProps = {
  addSurveyThemeClickHandler: () => void;
  editSurveyClickHandler: () => void;
  name: string;
};

export default function SurveyThemeSettingsPanel({
  addSurveyThemeClickHandler,
  editSurveyClickHandler,
  name,
}: SurveyThemeSettingsPanelProps) {
  const [field, meta, helpers] = useField<string>(name);

  const [editViewTab, setEditViewTab] = useState(
    EditSurveyThemeSettingsPanelTabs.MY_THEMES
  );

  const handleEditViewTabChange = (
    event: React.SyntheticEvent,
    newValue: EditSurveyThemeSettingsPanelTabs
  ) => {
    setEditViewTab(newValue);
  };

  return (
    <>
      <Tabs
        value={editViewTab}
        onChange={handleEditViewTabChange}
        textColor="primary"
        indicatorColor="primary"
        variant="fullWidth"
      >
        <Tab
          sx={{ p: 1 }}
          value={EditSurveyThemeSettingsPanelTabs.MY_THEMES}
          label={EditSurveyThemeSettingsPanelTabs.MY_THEMES}
        />
        <Tab
          sx={{ p: 1 }}
          value={EditSurveyThemeSettingsPanelTabs.THEME_TEMPLATES}
          label={EditSurveyThemeSettingsPanelTabs.THEME_TEMPLATES}
        />
      </Tabs>

      <TabPanel
        value={editViewTab}
        index={EditSurveyThemeSettingsPanelTabs.MY_THEMES}
      >
        <MySurveyThemesList
          addClickHandler={() => {
            addSurveyThemeClickHandler();
          }}
          editSurveyClickHandler={(surveyThemeToSetId: string) => {
            helpers.setValue(surveyThemeToSetId);
            editSurveyClickHandler();
          }}
        />
      </TabPanel>
      <TabPanel
        value={editViewTab}
        index={EditSurveyThemeSettingsPanelTabs.THEME_TEMPLATES}
      >
        Theme Gallery
      </TabPanel>
    </>
  );
}
